import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { ITabela } from '../Table/TabelaInterface';
import Paginacao from '../Pagination/index';
import Avatar from '@mui/material/Avatar';
import UA from '../../../src/pages/admOperacional/ajustes/ajusteDeEstoque/interfaces/interfaceUA';

interface ClickableTableProps {
	coluna: ITabela[];
	itemsPerPage: number;
	data: Array<Record<string, any>>;
	onRowClick: (row: string | any | UA) => void;
	usaRowClick?: boolean;
	paddingHead?: string;
	paddingBody?: string;
	posicaoTexto?: string;

	usaAcoes?: boolean;

	usaEditar?: boolean;
	iconeEditar?: string;
	acaoEditar: (row: any) => void;
	corIconeEditar?: string;

	usaExcluir?: boolean;
	iconeExcluir?: string;
	acaoExcluir: (row: any) => void;
	corIconeExcluir?: string;

	usaVisualizar?: boolean;
	iconeVisualizar?: string;
	acaoVisualizar: (row: any) => void;
	corIconeVisualizar?: string;

	usaBotao1?: boolean;
	iconeBotao1?: string;
	acaoBotao1?: (row: any) => void;
	corIconeBotao1?: string;

	tamanhoIcones?: string;
	tamanhoFontBody?: string;
	tamanhoFontHead?: string;

	labelpesquisa?: string;
	acessopesquisa?: string;

	usaCheckBox?: boolean;
	acaoCheckBox?: (row: any, selected: boolean) => void;
	resetKey?: number;
}

const ClickableTable: React.FC<ClickableTableProps> = ({
	coluna,
	itemsPerPage,
	data,
	usaRowClick = false,
	onRowClick,
	paddingHead = '1',
	paddingBody = '1',
	posicaoTexto = 'text-center',

	usaAcoes = false,

	usaEditar = false,
	iconeEditar = 'bi bi-pencil-square',
	acaoEditar,
	corIconeEditar = 'text-primary',

	usaExcluir = false,
	iconeExcluir = 'bi bi-trash3',
	acaoExcluir,
	corIconeExcluir = 'text-danger',

	usaVisualizar = false,
	iconeVisualizar = 'bi bi-eye',
	acaoVisualizar,
	corIconeVisualizar = 'text-dark',

	usaBotao1 = false,
	iconeBotao1 = 'bi bi-thermometer-half',
	acaoBotao1,
	corIconeBotao1 = 'text-dark',

	tamanhoIcones = 'fs-4',
	tamanhoFontBody = '15px',
	tamanhoFontHead = '15px',

	labelpesquisa,
	acessopesquisa = 'id',

	usaCheckBox = false,
	acaoCheckBox = function () { },
	resetKey = 0,
}) => {
	const [currentPage, setCurrentPage] = useState(1);
	const [filteredData, setFilteredData] = useState<any>(data); // Initialize with data
	const [selectedChecks, setSelectedChecks] = useState<number[]>([]);

	const totalPages = Math.ceil(filteredData.length / itemsPerPage);

	const itens = filteredData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

	const verificaAvatar = (item: any, acesso: string) => {
		if (acesso === 'avatar') {
			return item[acesso] ? <Avatar src={`${item[acesso]}`} alt="Avatar" /> : <i className="bi bi-buildings fs-3"></i>;
		} else if (acesso === 'situacao' || acesso === 'ativo' || acesso === 'bloqueio' || acesso === 'emb_controle') {
			return item[acesso] === 1 || item[acesso] === true ? (
				<span className="badge bg-primary">Ativo</span>
			) : (
				<span className="badge bg-danger">Inativo</span>
			);
		} else if (acesso === 'cod_id_filial') {
			return item[acesso] === 1 ? <span className="badge bg-primary">Filial</span> : <span className="badge bg-danger">Genérico</span>;
		} else if (acesso === 'padrao') {
			return item[acesso] ? <span className="badge bg-primary">Sim</span> : <span className="badge bg-danger">Não</span>;
		} else if (acesso === 'tipo_cfop') {
			return item[acesso] === 'Entrada' ? (
				<span className="badge bg-success">{item[acesso]}</span>
			) : (
				<span className="badge bg-danger">{item[acesso]}</span>
			);
		} else if (acesso === 'tipo_ua') {
			return item[acesso] === 'vazio' ? <span className="badge bg-success">{item[acesso]}</span> : item[acesso] === "estoque" ? <span className="badge bg-secondary">{item[acesso]}</span> : <span className="badge bg-danger">{item[acesso]}</span>;
		} else if (acesso === 'executado') {
			return item[acesso] ? <span className="badge bg-success">Sim</span> : <span className="badge bg-danger">Não</span>;
		} else if (acesso === 'tipo') {
			return item[acesso] === 'Picking' ? (
				<span className="badge bg-success">{item[acesso]}</span>
			) : item[acesso] === 'Virtual' ? (
				<span className="badge bg-secondary">{item[acesso]}</span>
			) : item[acesso] === 'Pulmão' ? (
				<span className="badge bg-primary">{item[acesso]}</span>
			) : item[acesso] === 'Blocado' ? (
				<span className="badge bg-warning text-black">{item[acesso]}</span>
			) : (
				<span className="badge bg-danger">{item[acesso]}</span>
			);
		} else if (acesso === 'temperatura') {
			return item[acesso] === 'Seco' ? (
				<span className="badge bg-success">{item[acesso]}</span>
			) : item[acesso] === 'Congelado' ? (
				<span className="badge bg-secondary">{item[acesso]}</span>
			) : item[acesso] === 'Resfriado' ? (
				<span className="badge bg-primary">{item[acesso]}</span>
			) : item[acesso] === 'Climatizado' ? (
				<span className="badge bg-warning text-black">{item[acesso]}</span>
			) : (
				<span className="badge bg-danger">{item[acesso]}</span>
			);
		}
		else if (acesso === 'codigo_retorno') {
			return item[acesso] === '200' ? (
				<span className="badge bg-success">{item[acesso]}</span> // Success (Green)
			) : item[acesso] >= '100' && item[acesso] <= '199' ? (
				<span className="badge bg-info text-dark">{item[acesso]}</span> // Informational (Light Blue)
			) : item[acesso] >= '300' && item[acesso] <= '399' ? (
				<span className="badge bg-warning text-black">{item[acesso]}</span> // Redirection (Amber)
			) : item[acesso] >= '400' && item[acesso] <= '499' ? (
				<span className="badge bg-warning">{item[acesso]}</span> // Client Error (Orange)
			) : item[acesso] >= '500' ? (
				<span className="badge bg-danger">{item[acesso]}</span> // Server Error (Dark Red)
			) : (
				<span className="badge bg-secondary">{item[acesso]}</span> // Default/Unknown (Grey)
			);
		}

		else if (acesso === 'status') {
			//usado na tela de processos
			//aberto, pendente, cancelado e concluido, aguardo autorização,
			//aguardando pesagem, cargadescar, aguadarando pesagem saída,
			//divergencia de peso, aguadrando liberação e liberado

			return item[acesso] === 'Aberto' ? (
				<span className="badge bg-secondary">{item[acesso]}</span>
			) : item[acesso] === 'Cancelado' ? (
				<span className="badge bg-danger">{item[acesso]}</span>
			) : (
				<span className="badge bg-warning">{item[acesso]}</span>
			);
		} else if (acesso === 'bloq') {
			return item[acesso] === true ? <span className="badge bg-danger">Bloqueado</span> : <span className="badge bg-primary">Ativo</span>;
		} else if (acesso === 'consumido_integracao') {
			return item[acesso] === true ? <span className="badge bg-primary">Sim</span> : <span className="badge bg-danger">Não</span>;
		}
		else if (acesso === "gera_faturamento") {
			return item[acesso] === true ? <span className="badge bg-primary">Sim</span> : <span className="badge bg-danger">Não</span>;
		}
		else {
			return item[acesso];
		}
	};

	const Pesquisar = (value: string, acesso: string) => {
		if (value === '') {
			setFilteredData(data);
			setCurrentPage(1);
		} else {
			const pesquisados = data.filter(function (info: any) {
				return info[acesso].toString().toLowerCase().includes(value.toLowerCase());
			});
			setFilteredData(pesquisados);
			setCurrentPage(1);
		}
	};

	const [buscar, setBuscar] = useState('');

	useEffect(() => {
		Pesquisar(buscar, acessopesquisa);
	}, [buscar, data]); // Re-run search when `buscar` or `data` changes

	useEffect(() => {
		setFilteredData(data);
		setCurrentPage(1);
	}, [data]); // Update filteredData when `data` changes

	const handleCheckboxChange = (index: number, row: any) => {
		let newSelectedChecks;

		if (selectedChecks.includes(index)) {
			// Desmarcar o checkbox
			newSelectedChecks = selectedChecks.filter((i) => i !== index);
			acaoCheckBox(row, false);
		} else {
			// Marcar o checkbox
			newSelectedChecks = [...selectedChecks, index];
			acaoCheckBox(row, true);
		}

		setSelectedChecks(newSelectedChecks);
	};

	useEffect(() => {
		setSelectedChecks([]);
	}, [resetKey]);

	return (
		<>
			{labelpesquisa && (
				<div className="col-lg-12 col-sm">
					<div className="form-floating">
						<input
							type="text"
							onChange={(e) => setBuscar(e.target.value)}
							className="form-control bordasInferiorInput text-black"
							aria-describedby="emailHelp"
							placeholder="Procurar por nome de usuários"
						/>
						<label>{labelpesquisa}</label>
					</div>
				</div>
			)}
			<div className="table-responsive">
				<Table striped bordered hover>
					<thead className="table-cabecalho">
						<tr>
							{usaCheckBox && (
								<th className={`p-${paddingHead} ${posicaoTexto} text-nowrap bd-highlight`} style={{ fontSize: tamanhoFontHead }}>
									Check
								</th>
							)}

							{coluna.map((item, index) => (
								<th
									style={{ fontSize: tamanhoFontHead }}
									className={`p-${paddingHead} ${posicaoTexto} text-nowrap bd-highlight`}
									key={index}
								>
									{item.titulo}
								</th>
							))}
							{usaAcoes && (
								<th style={{ fontSize: tamanhoFontHead }} className={`p-${paddingHead} ${posicaoTexto} text-nowrap bd-highlight`}>
									Ações
								</th>
							)}
						</tr>
					</thead>
					<tbody className="overflow-x">
						{itens.map(function (row: any, index: any) {
							return (
								<tr key={index}>
									{usaCheckBox && (
										<td className={`p-${paddingBody} ${posicaoTexto} text-nowrap bd-highlight`}>
											<div className="form-check">
												<input
													className="form-check-input"
													type="checkbox"
													checked={selectedChecks.includes(index)}
													onChange={() => handleCheckboxChange(index, row)}
													value=""
													id={`defaultCheck${index}`}
													style={{ transform: 'scale(0.9)' }}
												/>
												<label className="form-check-label" htmlFor={`defaultCheck${index}`}></label>
											</div>
										</td>
									)}

									{coluna.map((col, i) => (
										<td
											style={{ fontSize: tamanhoFontBody, cursor: usaRowClick ? 'pointer' : 'default' }}
											className={`p-${paddingBody} ${posicaoTexto} text-nowrap bd-highlight`}
											onClick={() => onRowClick(row)}
											key={i}
										>
											{verificaAvatar(row, col.acesso)}
										</td>
									))}
									{usaAcoes && (
										<td className={`p-${paddingBody} ${posicaoTexto} text-nowrap bd-highlight`}>
											{usaEditar && (
												<i
													style={{ cursor: 'pointer' }}
													onClick={() => acaoEditar(row)}
													className={`${corIconeEditar} ${iconeEditar} ${tamanhoIcones} me-2`}
												></i>
											)}
											{usaExcluir && (
												<i
													style={{ cursor: 'pointer' }}
													onClick={() => acaoExcluir(row)}
													className={`${corIconeExcluir} ${iconeExcluir} ${tamanhoIcones} me-2`}
												></i>
											)}
											{usaVisualizar && (
												<i
													style={{ cursor: 'pointer' }}
													onClick={() => acaoVisualizar(row)}
													className={`${corIconeVisualizar} ${iconeVisualizar} ${tamanhoIcones} me-2`}
												></i>
											)}

											{usaBotao1 && acaoBotao1 && (
												<i
													style={{ cursor: 'pointer' }}
													onClick={() => acaoBotao1(row)}
													className={`${corIconeBotao1} ${iconeBotao1} ${tamanhoIcones} me-2`}
												></i>
											)}
										</td>
									)}
								</tr>
							);
						})}
					</tbody>
				</Table>
				<Paginacao currentPage={currentPage} totalPages={totalPages} setCurrentPage={setCurrentPage} dados={filteredData} />
			</div>
		</>
	);
};

export default ClickableTable;
