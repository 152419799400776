import { useNavigate } from 'react-router-dom';
import { ModalIntegracaoSaidaExport } from './modalIntegracaoSaidaExport';

const NovaIntegracaoSaida = (): JSX.Element => {
	const navigate = useNavigate();
	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">Integração Saída - Detalhe Pedido</h4>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<div className="card">
							<div className="card-body">
								<ul className="nav nav-pills bg-nav-pills nav-justified mb-3">
									<li className="nav-item">
										<a
											href="#tab-oper-gestPed-integrSaidaNovo-geral"
											data-bs-toggle="tab"
											aria-expanded="true"
											className="nav-link rounded-0 active"
										>
											<i className="mdi mdi-home-variant d-md-none d-block"></i>
											<span className="d-none d-md-block">Geral</span>
										</a>
									</li>
								</ul>
								<div className="tab-content">
									<div className="tab-pane show active" id="tab-oper-gestPed-integrSaidaNovo-geral">
										<div className="row">
											<div className="col-sm-3">
												<div className="mb-1 form-floating">
													<input
														type="text"
														className="form-control bordasInferiorInput text-black text-capitalize"
														placeholder=""
													/>
													<label htmlFor="id_pedido">ID Pedido</label>
												</div>
											</div>

											<div className="col-sm-3">
												<div className="mb-1 form-floating">
													<input
														type="date"
														className="form-control bordasInferiorInput text-black text-capitalize"
														placeholder=""
													/>
													<label htmlFor="data_carga">Data Carga</label>
												</div>
											</div>

											<div className="col-sm-3">
												<div className="mb-1 form-floating">
													<input
														type="text"
														className="form-control bordasInferiorInput text-black text-capitalize"
														placeholder=""
													/>
													<label htmlFor="pedido_sistema">Pedido Sistema</label>
												</div>
											</div>

											<div className="col-sm-3">
												<div className="mb-1 form-floating">
													<input
														type="text"
														className="form-control bordasInferiorInput text-black text-capitalize"
														placeholder=""
													/>
													<label htmlFor="pedido_cliente">Pedido Cliente</label>
												</div>
											</div>

										</div>
										<div className="row">
											<div className="col-sm-3">
												<div className="mb-1 form-floating">
													<input
														type="text"
														className="form-control bordasInferiorInput text-black text-capitalize"
														placeholder=""
													/>
													<label htmlFor="cliente">Cliente</label>
												</div>
											</div>

											<div className="col-sm-3">
												<div className="mb-1 form-floating">
													<input
														type="text"
														className="form-control bordasInferiorInput text-black text-capitalize"
														placeholder=""
													/>
													<label htmlFor="cnpj">CNPJ</label>
												</div>
											</div>

											<div className="col-sm-3">
												<div className="mb-1 form-floating">
													<input
														type="text"
														className="form-control bordasInferiorInput text-black text-capitalize"
														placeholder=""
													/>
													<label htmlFor="validade_min_aceita">Validade Min Aceita</label>
												</div>
											</div>

										</div>
										<div className="row">
											<div className="col-sm-3">
												<div className="mb-1 form-floating">
													<input
														type="text"
														className="form-control bordasInferiorInput text-black text-capitalize"
														placeholder=""
													/>
													<label htmlFor="destinatario">Destinatário</label>
												</div>
											</div>

										</div>
										<hr className="mt-2 mb-2" />
										<div className="row mt-1">
											<div className="col-sm-3 mb-2">
												<a
													className="btn btn-warning dropdown-toggle"
													data-bs-toggle="dropdown"
													aria-haspopup="true"
													aria-expanded="false"
												>
													Exportação Arqs
												</a>
												<div className="dropdown-menu">
													<a
														className="dropdown-item"
														data-bs-toggle="modal"
														data-bs-target="#modal-oper-gestPed-integrSaida-export"
													>
														Exportar TXT
													</a>
													<a
														className="dropdown-item"
														data-bs-toggle="modal"
														data-bs-target="#modal-oper-gestPed-integrSaida-export"
													>
														Exportar Excel
													</a>
												</div>
											</div>
											<div className="row">
												<div className="table-responsive">
													<table className="table table-centered w-100 dt-responsive nowrap" id="usuarios-datatable">
														<thead className="table-light">
															<tr>
																<th>
																	<input type="checkbox" />
																</th>
																<th>Cod Produto</th>
																<th>Descrição</th>
																<th>Qtde Fiscal</th>
																<th>Qtde Cx</th>
																<th>Peso Bruto</th>
																<th>Peso Liq</th>
																<th>Validade</th>
																<th>Ações</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<th>
																	<input type="checkbox" />
																</th>
																<td>015210-1</td>
																<td>Batata Palito 500gr</td>
																<td>10</td>
																<td>10</td>
																<td>10</td>
																<td>10</td>
																<td>10/02/2022</td>
																<td className="d-flex flex-nowrap table-action">
																	<a
																		className="action-icon"
																		data-bs-toggle="modal"
																		data-bs-target="#modal-oper-gestPed-integrSaidaNovo-prod"
																	>
																		<i className="mdi mdi-square-edit-outline"></i>
																	</a>
																</td>
															</tr>
														</tbody>
													</table>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="card-footer">
								<div className="col">
									<div className="text-sm-end">
										<button
											className="btn btn-danger mb-2 me-1"
											type="button"
											onClick={() => navigate('/pco/contrFisc/gestPedidos/integracaoSaida')}
										>
											Cancelar
										</button>
										<button
											className="btn btn-info mb-2 me-1"
											type="button"
											onClick={() => navigate('/pco/contrFisc/gestPedidos/integracaoSaida')}
										>
											Salvar
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ModalIntegracaoSaidaExport />
			<div
				className="modal fade"
				id="modal-oper-gestPed-integrSaidaNovo-prod"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabIndex={-1}
				aria-labelledby="staticBackdropLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-full-width modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Integração Saída - Detalhe do Item</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
						</div>
						<div className="modal-body">
							<div className="card">
								<div className="card-body">
									<div className="row mb-1">
										<div className="col-sm-3">
											<div className="mb-1 form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
												<label htmlFor="id_pedido">ID Pedido</label>
											</div>
										</div>
										<div className="col-sm-3">
											<div className="mb-1 form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
												<label htmlFor="pedido_sistema">Pedido Sistema</label>
											</div>
										</div>
										<div className="col-sm-3">
											<div className="mb-1 form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
												<label htmlFor="pedido_cliente">Pedido Cliente</label>
											</div>
										</div>
									</div>
									<div className="row mb-1">
										<div className="col-sm-3">
											<div className="mb-1 form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
												<label htmlFor="codigo_produto">Código Produto</label>
											</div>
										</div>
										<div className="col-sm-6">
											<div className="mb-1 form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
												<label htmlFor="descricao">Descrição</label>
											</div>
										</div>
									</div>
									<div className="row mb-1">
										<div className="col-sm-3">
											<div className="mb-1 form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
												<label htmlFor="qtde_cxs">Qtde CxS</label>
											</div>
										</div>
										<div className="col-sm-3">
											<div className="mb-1 form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
												<label htmlFor="peso_bruto">Peso Bruto</label>
											</div>
										</div>
										<div className="col-sm-3">
											<div className="mb-1 form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
												<label htmlFor="peso_liq">Peso Liq</label>
											</div>
										</div>
										<div className="col-sm-3">
											<div className="mb-1 form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
												<label htmlFor="qtde_fiscal">Qtde Fiscal</label>
											</div>
										</div>
									</div>
									<div className="row mb-1">
										<div className="col-sm-3">
											<div className="mb-1 form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
												<label htmlFor="validade">Validade</label>
											</div>
										</div>
									</div>
								</div>

								<div className="modal-footer">
									<div className="text-sm-end">
										<button type="button" className="btn btn-danger" data-bs-dismiss="modal">
											Cancelar
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export { NovaIntegracaoSaida };
