import { mdiCheckCircle, mdiCloseCircle, mdiEye, mdiSkipBackward, mdiSkipForward, mdiSkipNext, mdiSkipPrevious, mdiSquareEditOutline, mdiTrashCanOutline } from '@mdi/js';
import { BsExclamationCircleFill } from "react-icons/bs";
import { Modal, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Api } from '../../../../services/api';
import Icon from '@mdi/react';

const EditarPedido = (): JSX.Element => {
	//#region
	//#region => VARIÁVEIS - PEDIDO
	const token = sessionStorage.getItem('IsFKLTOKEN');
	const user = sessionStorage.getItem('IsPerfilFKL');
	const codfilial = sessionStorage.getItem('IsIDFilialPadraoFKL');
	const { id } = useParams();
	const [dadosDoPedido, setDadosDoPedido] = useState({
		id: '',
		id_pedido: '',
		data_carga: '',
		depositante: '',
		codfilial: '',
		pedido_sistema: '',
		pedido_cliente: '',
		min_validade: '',
		destinatario: '',
		unid_pedido: '',
		pb_pedido: null,
		pl_pedido: null,
		qtd_pedido: null,
		rota: null,
		status: null,
		data_programacao: null,
		os: null,
		data_importacao: null,
		data_adicao: '',
		usuario_adicao: '',
		data_alteracao: null,
		usuario_alteracao: null,
		cnpj_depositante: '',
		nome_filial: null,
		listaDeItens: []
	});
	const [nomeFilial, setNomeFilial] = useState('');
	const [idPedido, setIdPedido] = useState(0);
	const [dataCarga, setDataCarga] = useState('');
	const [pedidoSistema, setPedidoSistema] = useState('');
	const [pedidoCliente, setPedidoCliente] = useState('');
	const [depositante, setDepositante] = useState('');
	const [cnpj, setCnpj] = useState('');
	const [validadeMinAceita, setValidadeMinAceita] = useState('');
	const [unidPedido, setUnidPedido] = useState('');
	const [destinatario, setDestinatario] = useState('');
	const [mostrarModalErro, setMostrarModalErro] = useState(false);
	const [menuUnidPedidos, setMenuUnidPedidos] = useState([]);
	const [menuDepositante, setMenuDepositante] = useState([]);
	const [erroProcessarPedido, setErroProcessarPedido] = useState(false);
	const navigate = useNavigate();
	//#endregion

	//#region => VARIÁVEIS - LISTA DE ITENS
	const [listaDeItens, setListaDeItens] = useState<any>([]);
	const [itemPedido, setItemPedido] = useState<number>(0);
	const [codigoProdutoWMS, setCodigoProdutoWMS] = useState<string>('');
	const [descricao, setDescricao] = useState<string>('');
	const [tipoPeso, setTipoPeso] = useState<string>('');
	const [tipoTemperatura, setTipoTemperatura] = useState<string>('');
	const [pesoBruto, setPesoBruto] = useState<number>(0);
	const [pesoLiquido, setPesoLiquido] = useState<number>(0);
	const [quantidadeOriginal, setQuantidadeOriginal] = useState<number>(0);
	const [quantidadeCaixas, setQuantidadeCaixas] = useState<number>(0);
	const [menuTemperaturas, setMenuTemperaturas] = useState<any>([]);
	const [menuPesos, setMenuPesos] = useState<any>([]);
	const [menuCodigoProdutoWMS, setMenuCodigoProdutoWMS] = useState<any>([]);
	const [itemClicado, setItemClicado] = useState({
		cod_produto: '',
		criterio: null,
		data_adicao: '',
		data_alteracao: null,
		data_importacao: null,
		data_programacao: null,
		data_quebrafefo: null,
		data_validade: null,
		descricao: '',
		id: 0,
		id_chave: null,
		id_pedido: 0,
		id_processo: null,
		os: null,
		pb_pedido: 0,
		pb_separado: null,
		pick_concluido: null,
		pl_pedido: 0,
		pl_separado: null,
		produto_wms: 0,
		qtd_caixas: 0,
		qtd_fiscal_separada: null,
		qtd_original: 0,
		qtd_referencia: null,
		qtd_separada: null,
		quebra_fefo: null,
		romaneado: null,
		rota: null,
		roteiro: null,
		status: null,
		tipo_peso: '',
		tipo_temperatura: '',
		unid_referencia: null,
		unid_separacao: null,
		usuario_adicao: '',
		usuario_alteracao: null,
		usuario_quebrafefo: null
	});
	const [mostrarModalDelecao, setMostrarModalDelecao] = useState(false);
	//#endregion

	//#region => BUSCAR DADOS DO PEDIDO
	const buscarDadosDoPedido = async () => {
		const resposta = await axios.get(Api + `/pedido/?auth=${token}&id=${id}`);
		
		setDadosDoPedido(resposta.data);
		setIdPedido(resposta.data.id_pedido);
		setDataCarga(resposta.data.data_carga.split('T').join(' ').slice(0, 10));
		setPedidoSistema(resposta.data.pedido_sistema);
		setPedidoCliente(resposta.data.pedido_cliente);
		setDepositante(resposta.data.depositante);
		setCnpj(resposta.data.cnpj_depositante);
		setValidadeMinAceita(resposta.data.min_validade.split('T').join(' ').slice(0, 10));
		setUnidPedido(resposta.data.unid_pedido);
		setDestinatario(resposta.data.destinatario);
		setListaDeItens(resposta.data.listaDeItens);
	}
	//#endregion

	//#region => CARREGAR MENU - UNIDADE PEDIDO
	const carregarMenuUnidPedido = async () => {

		try {
			const response = await axios.get(Api + `/menu-unid-pedidos/?auth=${token}`);
			setMenuUnidPedidos(response.data);
			setUnidPedido(response.data[0].codigo);

		} catch (error) {
			//inserir arregamento de modal
			console.log(error);
		}
	}
	//#endregion

	//#region => CARREGAR MENU - DEPOSITANTE
	const carregarMenuDepositante = async () => {
		const resposta = await axios.get(Api + `/depositantes/?auth=${token}`);
		setMenuDepositante(resposta.data);
		setCnpj(resposta.data[0].cnpj)
	}
	//#endregion

	//#region => CARREGAR MENU - Tipos de Temperatura
	const carregarMenuTemperaturas = async () => {
		try {
			const resposta = await axios.get(Api + `/codigosistemadet/json/tabela/?tabela=TIPTEMP&auth=${token}`)
			setMenuTemperaturas(resposta.data);
			setTipoTemperatura(`${resposta.data[0].codigo}`);
		} catch (error) {
			console.log(error)
		}
	}
	//#endregion

	//#region => CARREGAR MENU - Tipos de Peso
	const carregarMenuPesos = async () => {
		try {
			const resposta = await axios.get(Api + `/codigosistemadet/json/tabela/?tabela=TIPOPESO&auth=${token}`)
			setMenuPesos(resposta.data);
			setTipoPeso(`${resposta.data[0].codigo} - ${resposta.data[0].descricao}`);
		} catch (error) {
			console.log(error)
		}
	}
	//#endregion

	//#region => CARREGAR MENU - Codigo Produto WMS
	const carregarMenuCodigoProdutoWMS = async () => {
		const resposta = await axios.get(Api + `/produto/codigowms-descricao/json/?auth=${token}`);
		setMenuCodigoProdutoWMS(resposta.data);
		setCodigoProdutoWMS(`${resposta.data[0].geral_codwms} - ${resposta.data[0].geral_descricao}`)
	}
	//#endregion

	//#region => BUSCAR NOME DA FILIAL
	const buscarNomeFilial = async () => {
		const resposta = await axios.get(Api + `/filial/json/id/?auth=${token}&id=${codfilial}`);
		setNomeFilial(resposta.data[0].fantasia);
	} 
	//#endregion

	//#region => VISUALIZAR ITEM DA LISTA
	const visualizarItem = (id: number) => {
		const item = listaDeItens.filter((itemDaLista: any) => itemDaLista.id === id);
		setItemClicado(item[0]);
	}
	//#endregion

	//#region => EDITAR	ITEM NA LISTA
	const editarItem = (id: number) => {
		const item = listaDeItens.filter((itemDaLista: any) => itemDaLista.id === id);
		setItemClicado(item[0]);
		setItemPedido(item[0].id);
		setCodigoProdutoWMS(`${item[0].produto_wms} - ${item[0].cod_produto}`);
		setDescricao(item[0].descricao);
		setTipoPeso(item[0].tipo_peso);
		setTipoTemperatura(item[0].tipo_temperatura);
		setPesoBruto(item[0].pb_cliente);
		setPesoLiquido(item[0].pl_cliente);
		setQuantidadeOriginal(item[0].qtd_original);
		setQuantidadeCaixas(item[0].qtd_caixas);
	}

	const salvarEdicaoNaLista = () => {

		const item = {
			cod_produto: codigoProdutoWMS.split(' - ')[1],
			criterio: null,
			data_adicao: '',
			data_alteracao: null,
			data_importacao: null,
			data_programacao: null,
			data_quebrafefo: null,
			data_validade: null,
			descricao: descricao,
			id: itemPedido,
			id_chave: null,
			id_pedido: idPedido,
			id_processo: null,
			os: null,
			pb_pedido: pesoBruto,
			pb_separado: null,
			pick_concluido: null,
			pl_pedido: pesoLiquido,
			pl_separado: null,
			produto_wms: codigoProdutoWMS.split(' - ')[0],
			qtd_caixas: quantidadeCaixas,
			qtd_fiscal_separada: null,
			qtd_original: quantidadeOriginal,
			qtd_referencia: null,
			qtd_separada: null,
			quebra_fefo: null,
			romaneado: null,
			rota: null,
			roteiro: null,
			status: null,
			tipo_peso: tipoPeso,
			tipo_temperatura: tipoTemperatura,
			unid_referencia: null,
			unid_separacao: null,
			usuario_adicao: '',
			usuario_alteracao: null,
			usuario_quebrafefo: null
		};

		const aux: any = [];

		for (let itemDaLista of listaDeItens) {
			if(itemDaLista.id === itemPedido) {
				aux.push(item);
			} else {
				aux.push(itemDaLista);
			}
		}
		
		setListaDeItens(aux);
	}
	//#endregion

	//#region => DELETAR ITEM NA LISTA
	const deletarItem = (id: number) => {
		const item = listaDeItens.filter((itemDaLista: any) => itemDaLista.id === id);
		setItemClicado(item[0]);
		setMostrarModalDelecao(true);
	}

	const deletarItemDaLista = async (id: number) => {
		const aux: any = [];

		try {
			for (let itemDaLista of listaDeItens) {			
				if(itemDaLista.id !== itemClicado.id) {
					aux.push(itemDaLista);
				}
			}
	
			const resposta = await axios.delete(Api + `/deletar-item-lista/?auth=${token}&id=${id}`);
			console.log(resposta.data);
			setListaDeItens(aux);
			setMostrarModalDelecao(false);
		} catch(error) {
			console.log(error);
		}
	}
	//#endregion

	//#region => ENVIAR PEDIDO PARA O BACK-END
	const enviarPedido = async (event: any) => {
		event.preventDefault();
		const body = {
			user,
			codfilial,
			nomeFilial,
			idPedido,
			dataCarga,
			pedidoSistema,
			pedidoCliente,
			depositante,
			cnpj,
			validadeMinAceita,
			unidPedido,
			destinatario,
			listaDeItens
		}

		try {
			const resposta = await axios.post(Api + `/editar-pedido/?auth=${token}&id=${idPedido}`, body);
			console.log(resposta.data);
			navigate('/pco/contrFisc/gestPedidos/listagemDosPedidos')
		} catch(error) {
			setErroProcessarPedido(true);
			console.log(error)
		}
	}
	//#endregion

	//#region => CARREGAR DADOS NA TELA
	useEffect(() => {
		carregarMenuUnidPedido();
		carregarMenuDepositante();
		carregarMenuTemperaturas();
		carregarMenuPesos();
		carregarMenuCodigoProdutoWMS();
		buscarNomeFilial();
		buscarDadosDoPedido();
	}, [])
	//#endregion

	//#endregion
	//#region Paginação  dos Itens do Pedido
	const [qtdItensPorPagina, setQtdItensPorPagina] = useState(10);
	const [paginaAtual, setPaginaAtual] = useState(0);

	const pagina = Math.ceil(listaDeItens.length / qtdItensPorPagina);

	const indiceInicial = paginaAtual * qtdItensPorPagina;
	const indiceFinal = indiceInicial + qtdItensPorPagina;
	const itensSelecionados = listaDeItens.slice(indiceInicial, indiceFinal);
	//#endregion

	//#region  Modifica pagina da listagem de Itens de Pedido
	const primeiraPagina = () => {
		if (paginaAtual > 0) {
			setPaginaAtual(0)
		}
	}
	const paginaAnterior = () => {
		if (paginaAtual > 0) {
			setPaginaAtual(paginaAtual - 1)
		}
	}
	const proxPagina = () => {
		let pagina = 1;
		pagina = pagina + paginaAtual
		if (pagina < pagina) {
			setPaginaAtual(paginaAtual + 1)
		}
	}
	const ultimaPagina = () => {
		let pagina = 1;
		pagina = pagina + paginaAtual
		if (pagina < pagina) {
			setPaginaAtual(pagina - 1)
		}
	}
	//#endregion
	const itensPorPagina = (itens: any) => {
		setQtdItensPorPagina(Number(itens));
		setPaginaAtual(0);
	}
	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">Editar Pedido: {id}</h4>
						</div>
					</div>
				</div>
				<form id="form-utilitarios" onSubmit={enviarPedido}>
					<div className="card">

						<div className="card-body">
							<ul className="nav nav-pills bg-nav-pills nav-justified mb-2">
								<li className="nav-item">
									<a
										href="#tab-porcEnt-digNotFisc-Geral"
										data-bs-toggle="tab"
										aria-expanded="true"
										className="nav-link rounded-0 active"
									>
										<i className="mdi mdi-home-variant d-md-none d-block"></i>
										<span className="d-none d-md-block">GERAL</span>
									</a>
								</li>
							</ul>
							<div className="tab-content">
								<div className="tab-pane show active" id="tab-porcEnt-digNotFisc-Geral">
									<div className="row mb-1">
										<div className="col-sm-3">
											<label className="form-label">ID Pedido</label>
											<input type="text" className="form-control" value={id} disabled />
										</div>
										<div className="col-sm-3">
											<label className="form-label">Data Carga</label>
											<input type="date" className="form-control" defaultValue={dadosDoPedido.data_carga.split('T').join(' ').slice(0, 10)} value={dataCarga} onChange={(event: any) => { setDataCarga(event.target.value) }} />
										</div>
										<div className="col-sm-3">
											<label className="form-label">Pedido Sistema</label>
											<input type="text" className="form-control" defaultValue={dadosDoPedido.pedido_sistema} value={pedidoSistema} onChange={(event: any) => { setPedidoSistema(event.target.value) }} />
										</div>
										<div className="col-sm-3">
											<label className="form-label">Pedido Cliente</label>
											<input type="text" className="form-control" defaultValue={dadosDoPedido.pedido_cliente} value={pedidoCliente} onChange={(event: any) => { setPedidoCliente(event.target.value) }} />
										</div>
									</div>
									<div className="row mb-1">
										<div className="col-sm-4">
											<label className="form-label">Depositante</label>
											<select className="form-select" value={dadosDoPedido.depositante} disabled>
												{menuDepositante.map((opcao: any, index: number) => {
													return (
														<option key={index} value={opcao.coddepositante}>{opcao.coddepositante} - {opcao.fantasia}</option>
													)
												})}
											</select>
										</div>
										<div className="col-sm-4">
											<label className="form-label">CNPJ</label>
											<input type="text" className="form-control" value={cnpj} disabled />
										</div>
										<div className="col-sm-4">
											<label className="form-label">Validade Min Aceita</label>
											<input type="date" className="form-control" defaultValue={dadosDoPedido.min_validade.split('T').join(' ').slice(0, 10)} value={validadeMinAceita} onChange={(event: any) => { setValidadeMinAceita(event.target.value) }} />
										</div>
									</div>
									<div className="row mb-1">
										<div className="col-sm-6">
											<label className="form-label">Unid. Pedidos</label>
											<select className="form-select" defaultValue={dadosDoPedido.unid_pedido} value={unidPedido} onChange={(event: any) => { setUnidPedido(event.target.value) }}>
												{menuUnidPedidos.map((opcao: any, index: number) => {
													return (
														<option key={index} value={opcao.codigo}>{opcao.codigo} - {opcao.descricao}</option>
													)
												})}
											</select>
										</div>
										<div className="col-sm-6">
											<label className="form-label">Destinatário</label>
											<input type="text" className="form-control" defaultValue={dadosDoPedido.destinatario} value={destinatario} onChange={(event: any) => { setDestinatario(event.target.value) }} />
										</div>
									</div>

									<hr className="mt-3 mb-3" />

									{/**tabela mostrando os itens adicionados ao pedido */}
									<div className="table-responsive">
										<table className="table table-centered w-100 dt-responsive nowrap table-striped table-hover">
											<thead className="table-cabecalho">
												<tr className="cabecalho-Pedidos-Entrada">
													<th>Item</th>
													<th>Código Produto</th>
													<th>Descrição</th>
													<th>Qtde Original</th>
													<th>Qtde Cx</th>
													<th>Status</th>
													<th>Ações</th>
												</tr>
											</thead>
											<tbody>
												{itensSelecionados.map((item: any, index: number) => {
													return (
														<tr key={index}>
															<td>{item.id}</td>
															<td>{`${item.produto_wms} - ${item.cod_produto}`}</td>
															<td>{item.descricao}</td>
															<td>{item.qtd_original}</td>
															<td>{item.qtd_caixas}</td>
															<td>-</td>
															<td className="table-action">
																<button type="button" className="action-icon" onClick={() => visualizarItem(item.id)} style={{ border: "none", background: "none" }} data-bs-toggle="modal" data-bs-target="#modal-gestPedidos-ListPed-visItemPed" title="Visualizar">
																	<Icon path={mdiEye} size={1} style={{ color: '#EE821C' }} />
																</button>
																<button type="button" className="action-icon" onClick={() => editarItem(item.id)} style={{ border: "none", background: "none" }} data-bs-toggle="modal" data-bs-target="#modal-gestPedidos-ListPed-editItemPed" title="Editar">
																	<Icon path={mdiSquareEditOutline} size={1} style={{ color: '#1776B8' }} />
																</button>
																<button type="button" className="action-icon" onClick={() => deletarItem(item.id)} style={{ border: "none", background: "none" }} title="Excluir">
																	<Icon path={mdiTrashCanOutline} size={1} style={{ color: '#ee0101' }} />
																</button>
															</td>
														</tr>

													)
												})}
											</tbody>
											<tfoot>
												<tr className="rodapeTabelaPaginacao">
													<td colSpan={14}>
														<a onClick={primeiraPagina} title="Primeira Página">
															<Icon path={mdiSkipBackward} size={1} />
														</a>
														<a onClick={paginaAnterior} title="Página Anterior">
															<Icon path={mdiSkipPrevious} size={1} />
														</a>
														Página: {paginaAtual + 1} de {pagina} | Registros Total: {listaDeItens.length}
														<a onClick={proxPagina} title="Próxima Página">
															<Icon path={mdiSkipNext} size={1} />
														</a>
														<a onClick={ultimaPagina} title="Última Página">
															<Icon path={mdiSkipForward} size={1} />
														</a>
													</td>
												</tr>
											</tfoot>
										</table>
									</div>
									{/**modal para visualização de item*/}
									<div className="modal fade" id="modal-gestPedidos-ListPed-visItemPed" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
										<div className="modal-dialog modal-full-width modal-dialog-centered">
											<div className="modal-content">
												<div className="modal-header">
													<h5 className="modal-title" id="staticBackdropLabel">
														Visualização de Item do Pedido
													</h5>
													<button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
												</div>
												<div className="modal-body">
													<div className="card">
														<div className="card-body">
															<div className="row mb-1">
																<div className="col-sm-3">
																	<label className="form-label">ID Pedido</label>
																	<input type="text" className="form-control" value={idPedido} disabled />
																</div>
																<div className="col-sm-3">
																	<label className="form-label">Pedido Sistema</label>
																	<input type="text" className="form-control" value={pedidoSistema} disabled />
																</div>
																<div className="col-sm-3">
																	<label className="form-label">Pedido Cliente</label>
																	<input type="text" className="form-control" value={pedidoCliente} disabled />
																</div>
															</div>
															<div className="row mb-1">
																<div className="col-sm-3">
																	<label className="form-label">Item Pedido</label>
																	<input type="text" className="form-control" value={itemClicado.id} disabled />
																</div>
																<div className="col-sm-3">
																	<label className="form-label">Código Produto WMS</label>
																	<select className="form-select" value={`${itemClicado.produto_wms} - ${itemClicado.cod_produto}`} disabled>
																		<option value={`${itemClicado.produto_wms} - ${itemClicado.cod_produto}`}>{`${itemClicado.produto_wms} - ${itemClicado.cod_produto}`}</option>

																	</select>
																</div>
																<div className="col-sm-6">
																	<label className="form-label">Descrição</label>
																	<input type="text" className="form-control" value={itemClicado.descricao} disabled />
																</div>
															</div>
															<div className="row mb-1">
																<div className="col-sm-3">
																	<label className="form-label">Tipo Peso</label>
																	<select className="form-select" value={itemClicado.tipo_peso} disabled>
																		<option value={itemClicado.tipo_peso}>{itemClicado.tipo_peso}</option>
																	</select>
																</div>
																<div className="col-sm-3">
																	<label className="form-label">Tipo Temperatura</label>
																	<select className="form-select" value={itemClicado.tipo_temperatura} disabled>
																		<option value={itemClicado.tipo_temperatura}>{itemClicado.tipo_temperatura}</option>
																	</select>
																</div>
																<div className="col-sm-3">
																	<label className="form-label">Peso Bruto</label>
																	<input type="number" className="form-control" value={itemClicado.pb_pedido} disabled />
																</div>
																<div className="col-sm-3">
																	<label className="form-label">Peso Líquido</label>
																	<input type="number" className="form-control" value={itemClicado.pl_pedido} disabled />
																</div>
															</div>
															<div className="row mb-1">
																<div className="col-sm-3">
																	<label className="form-label">Quantidade Original</label>
																	<input type="number" className="form-control" value={itemClicado.qtd_original} disabled />
																</div>
																<div className="col-sm-3">
																	<label className="form-label">Qtde CxS</label>
																	<input type="number" className="form-control" value={itemClicado.qtd_caixas} disabled />
																</div>
															</div>
														</div>
														<div className="modal-footer">
															<button type="button" className="btn btn-danger" data-bs-dismiss="modal">
																Voltar
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									{/**modal para edição de item */}
									<div className="modal fade" id="modal-gestPedidos-ListPed-editItemPed" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
										<div className="modal-dialog modal-full-width modal-dialog-centered">
											<div className="modal-content">
												<div className="modal-header">
													<h5 className="modal-title" id="staticBackdropLabel">
														Edição de Item do Pedido
													</h5>
													<button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
												</div>
												<div className="modal-body">
													<div className="card">
														<div className="card-body">
															<div className="row mb-1">
																<div className="col-sm-3">
																	<label className="form-label">ID Pedido</label>
																	<input type="text" className="form-control" value={dadosDoPedido.id_pedido} defaultValue={dadosDoPedido.id_pedido} disabled />
																</div>
																<div className="col-sm-3">
																	<label className="form-label">Pedido Sistema</label>
																	<input type="text" className="form-control" value={dadosDoPedido.pedido_sistema} defaultValue={dadosDoPedido.pedido_sistema} disabled />
																</div>
																<div className="col-sm-3">
																	<label className="form-label">Pedido Cliente</label>
																	<input type="text" className="form-control" value={dadosDoPedido.pedido_cliente} defaultValue={dadosDoPedido.pedido_cliente} disabled />
																</div>
															</div>
															<div className="row mb-1">
																<div className="col-sm-3">
																	<label className="form-label">Item Pedido</label>
																	<input type="text" className="form-control" value={itemPedido} defaultValue={itemClicado.id} disabled />
																</div>
																<div className="col-sm-3">
																	<label className="form-label">Código Produto WMS</label>
																	<select className="form-select" value={codigoProdutoWMS} defaultValue={`${itemClicado.produto_wms} - ${itemClicado.cod_produto}`} onChange={(event: any) => setCodigoProdutoWMS(event.target.value)}>
																		{menuCodigoProdutoWMS.map((opcao: any, index: number) => {
																			return (
																				<option key={index} value={`${opcao.geral_codwms} - ${opcao.geral_descricao}`}>{opcao.geral_codwms} - {opcao.geral_descricao}</option>
																			)
																		})}
																	</select>
																</div>
																<div className="col-sm-6">
																	<label className="form-label">Descrição</label>
																	<input type="text" className="form-control" value={descricao} defaultValue={itemClicado.descricao} onChange={(event: any) => setDescricao(event.target.value)} />
																</div>
															</div>
															<div className="row mb-1">
																<div className="col-sm-3">
																	<label className="form-label">Tipo Peso</label>
																	<select className="form-select" value={tipoPeso} defaultValue={itemClicado.tipo_peso} onChange={(event: any) => setTipoPeso(event.target.value)}>
																		{
																			menuPesos.map((opcao: any, index: number) => {
																				return (
																					<option key={index} value={`${opcao.codigo} - ${opcao.descricao}`}>{opcao.codigo} - {opcao.descricao}</option>
																				)
																			})
																		}
																	</select>
																</div>
																<div className="col-sm-3">
																	<label className="form-label">Tipo Temperatura</label>
																	<select className="form-select" value={tipoTemperatura} defaultValue={itemClicado.tipo_temperatura} onChange={(event: any) => setTipoTemperatura(event.target.value)}>
																		{
																			menuTemperaturas.map((opcao: any, index: number) => {
																				return (
																					<option key={index} value={opcao.codigo}>{index + 1} - {opcao.codigo}</option>
																				)
																			})
																		}
																	</select>
																</div>
																<div className="col-sm-3">
																	<label className="form-label">Peso Bruto</label>
																	<input type="number" className="form-control" value={pesoBruto} defaultValue={itemClicado.pb_pedido} onChange={(event: any) => setPesoBruto(event.target.value)} />
																</div>
																<div className="col-sm-3">
																	<label className="form-label">Peso Líquido</label>
																	<input type="number" className="form-control" value={pesoLiquido} defaultValue={itemClicado.pl_pedido} onChange={(event: any) => setPesoLiquido(event.target.value)} />
																</div>
															</div>
															<div className="row mb-1">
																<div className="col-sm-3">
																	<label className="form-label">Quantidade Original</label>
																	<input type="number" className="form-control" value={quantidadeOriginal} defaultValue={itemClicado.qtd_original} onChange={(event: any) => setQuantidadeOriginal(event.target.value)} />
																</div>
																<div className="col-sm-3">
																	<label className="form-label">Qtde CxS</label>
																	<input type="number" className="form-control" value={quantidadeCaixas} defaultValue={itemClicado.qtd_caixas} onChange={(event: any) => setQuantidadeCaixas(event.target.value)} />
																</div>
															</div>
														</div>
														<div className="modal-footer">
															<button type="button" className="btn btn-danger" data-bs-dismiss="modal">
																Cancelar
															</button>
															<button className="btn btn-info me-1" type="button" data-bs-dismiss="modal" onClick={salvarEdicaoNaLista}>
																Salvar
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									{/**modal para deleção de item */}
									<Modal show={mostrarModalDelecao} onHide={() => setMostrarModalDelecao(false)} size="lg" centered>
										<Modal.Header closeButton>
											<Modal.Title>Confirmar Exclusão</Modal.Title>
										</Modal.Header>
										<Modal.Body className="d-flex flex-column align-items-center p-3 gap-2">
											<BsExclamationCircleFill size={"100px"} color="#f58619" />
											<div className="row" style={{ textAlign: 'center' }}>
												<div className="col-sm" >
													<h3>Tem certeza que deseja excluir o item {itemClicado.id}?</h3>
													<h4>- Esta ação não poderá ser desfeita -</h4>
												</div>
											</div>
											<hr />
											<div className="d-flex my-2 gap-1 justify-content-between">
												<Button type="button" onClick={() => deletarItemDaLista(itemClicado.id)} style={{ borderRadius: '20px' }} variant="success"> <Icon path={mdiCheckCircle} size={1} className="me-2" />Sim</Button>
												<Button type="button" onClick={() => setMostrarModalDelecao(false)} style={{ borderRadius: '20px', float: 'left' }}><Icon path={mdiCloseCircle} size={1} className="me-2" />Não</Button>
											</div>
										</Modal.Body>
									</Modal>
									{/**modal para erro no processamento do pedido */}
									<Modal show={erroProcessarPedido} size="lg" centered>
										<Modal.Header closeButton>
											<Modal.Title>Erro ao processar pedido</Modal.Title>
										</Modal.Header>
										<Modal.Body className="d-flex flex-column align-items-center p-3 gap-2">
											<BsExclamationCircleFill size={"100px"} color="#f58619" />
											<div className="row" style={{ textAlign: 'center' }}>
												<div className="col-sm" >
													<h3>Infelizmente seu pedido não pode ser processado. Tente novamente mais tarde.</h3>
												</div>
											</div>
											<hr />
											<div className="d-flex my-2 gap-1 justify-content-between">
												<Button type="button" onClick={() => setErroProcessarPedido(false)} style={{ borderRadius: '20px'}} variant="success"> <Icon path={mdiCheckCircle} size={1} className="me-2" />OK</Button>
											</div>
										</Modal.Body>
									</Modal>
								</div>
							</div>
						</div>

						<div className="card-footer">
							<div className="col">
								<div className="text-sm-end mb-2">
									<button
										className="btn btn-danger me-1"
										type="button"
										onClick={() => navigate('/pco/contrFisc/gestPedidos/listagemDosPedidos')}
									>
										Voltar
									</button>
									<button className="btn btn-info me-1" type="submit">
										Salvar
									</button>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>

			{/*MODAL - ERRO AO CRIAR PEDIDO*/}
			<Modal show={mostrarModalErro} size="lg" centered>
				<Modal.Header closeButton>
					<Modal.Title>Erro ao processar pedido.</Modal.Title>
				</Modal.Header>
				<Modal.Body className="d-flex flex-column align-items-center p-3 gap-2">
					<BsExclamationCircleFill size={"100px"} color="#f58619" />
					<div className="row" style={{ textAlign: 'center' }}>
						<div className="col-sm" >
							<h3>Infelizmente não foi possível processar o seu pedido.</h3>
						</div>
					</div>
					<hr />
					<div className="d-flex my-2 gap-1 justify-content-between">
						<Button type="button" onClick={() => setMostrarModalErro(false)} style={{ borderRadius: '20px' }} variant="success"> <Icon path={mdiCheckCircle} size={1} className="me-2" />OK</Button>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export { EditarPedido };
