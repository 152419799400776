import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { Button, Card, Form, Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Api } from '../../../../services/api';
import { xml2json } from 'xml-js';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalConfirmacao from '../../../../components/ModalDeConfirmacao/ModalConfirmacao';
import ModalCarregando from '../../../../components/ModalCarregando/ModalCarregando';
import ClickableTable from '../../../../components/ClickableTable/ClickableTabl';
import { ITabela } from '../../../../components/Table/TabelaInterface';
import { BotaoCustomizado } from '../../../../components/componentes/formulario/BotaoCustomizado';
import { mdiCheckCircle, mdiKeyboardReturn, mdiPlusCircle } from '@mdi/js';
import LerXml from '../../../../components/functions/lerXml';
import { BuscarPermissoesUsuario } from '../../../../hooks/buscarPermissoesUsuario';

export function ImportNotasFiscaisF() {
	const navigate = useNavigate();

	const token = sessionStorage.getItem('IsFKLTOKEN')!;
	const id_filial = sessionStorage.getItem('IsIDFilialPadraoFKL')!;
	const id_perfil = sessionStorage.getItem('IsIdPerfilFKL')!;
	const nivelPerfil = sessionStorage.getItem('NivelPerfil')!;
	const usuarioLogado = sessionStorage.getItem('IsUserFKL')!;

	// Usa o hook com os parâmetros de ID de filial e ID de perfil de permissão
	const { permissoes, erro } = BuscarPermissoesUsuario(id_filial, id_perfil);	
	const [permissaoImportar, setPermissaoImportar] = useState<boolean>(false);
	const [permissaoDetalhar, setPermissaoDetalhar] = useState<boolean>(false);
	const [permissaoExcluir, setPermissaoExcluir] = useState<boolean>(false);

	// Utiliza o resultado da requisição dentro de um efeito ou qualquer lógica de negócio
	useEffect(() => {
		if (erro) {
			toast.error(`Falha ao buscar permissões do usuário. Motivo: ${erro}`);
		}
		if (permissoes) {
			if (!permissoes.perfilPermissao.visualizarNfeEntradasArmz) {
				toast.warning('Você não tem permissão para acessar Lista de Notas Fiscais de Entrada Armazenagem');
				navigate('/'); 
			} else {				
				setPermissaoImportar(permissoes.perfilPermissao.importarNfeEntradasArmz);
				setPermissaoDetalhar(permissoes.perfilPermissao.detalharNfeEntradaArmz);
				setPermissaoExcluir(permissoes.perfilPermissao.excluirNfeEntradasArmz);
			}
		}
	}, [permissoes, erro, navigate]);

	const [showCarregando, setShowCarregando] = useState(false);

	const [showModalListaNF, setShowModalListaNF] = useState(false);

	const [ListaNotasImportadas, setListaNotasImportadas] = useState<any>([]);
	const [ListaNotasNaoImportadas, setListaNotasNaoImportadas] = useState<any>([]);

	//#region IMPORTAÇÃO NF -- inicio
	const todasNotasFiscais = [
		{
			id_filial: '',
			nfestatus: '',
			nfechave: '',
			nfecuf: '',
			nfenatoper: '',
			nfecfop: '',
			nfemod: '',
			nfeserie: '',
			nfennf: '',
			nfedtemissao: '',
			nfedtsaida: '',
			nfentpnf: '',
			nfeiddest: '',
			nfecmunfg: '',
			tpimp: '',
			nfetpemis: '',
			nfecdv: '',
			nfetpamb: '',
			nfefinnfe: '',
			nfeindfinal: '',
			nfeindpres: '',
			nfeprocemi: '',
			nfeverproc: '',
			nfedhconting: '',
			nfejustconting: '',

			// DadosEmitente '',
			nfeemitcnpj: '',
			nfeemitcpf: '',
			nfeemitnome: '',
			nfeemitfantasia: '',
			nfeemitie: '',
			nfeemitiest: '',
			nfeemitcrt: '',

			// EnderecoEmitente '',
			nfeemitlograd: '',
			nfeemitnumero: '',
			nfeemitcompl: '',
			nfeemitbairro: '',
			nfeemitcmun: '',
			nfeemitmun: '',
			nfeemituf: '',
			nfeemitcep: '',
			nfeemitcpais: '',
			nfeemitpais: '',
			nfeemitfone: '',

			// DadosDestinatario '',
			nfedestcnpj: '',
			nfedestcpf: '',
			nfedestnome: '',
			nfedestindie: '',
			nfedestie: '',
			nfedestisuf: '',
			nfedestim: '',
			nfedestemail: '',

			// EnderecoDestinatario '',
			nfedestlograd: '',
			nfedestnumero: '',
			nfedestcompl: '',
			nfedestbairro: '',
			nfedestcmun: '',
			nfedestmun: '',
			nfedestuf: '',
			nfedestcep: '',
			nfedestcpais: '',
			nfedestpais: '',
			nfedestfone: '',

			//Tributos '',
			nfebcicms: 0,
			nfevicms: 0,
			nfevicmsdeson: 0,
			nfebcicmsst: 0,
			nfevicmsst: 0,
			nfevfcp: 0,
			nfevfcpst: 0,
			nfevfcpstret: 0,
			nfevtotprod: 0,
			nfevfrete: 0,
			nfevseguro: 0,
			nfevdesconto: 0,
			nfevii: 0,
			nfevipi: 0,
			nfevipidevol: 0,
			nfevpis: 0,
			nfevcofins: 0,
			nfevoutros: 0,
			nfevtotnota: 0,
			nfevtottrib: 0,

			//Transporte '',
			transpmodfrete: '',
			transpcnpj: '',
			transpcpf: '',
			transpnome: '',
			transpie: '',
			transpend: '',
			transpmun: '',
			transpuf: '',
			transpvolqtde: 0,
			transpvolespecie: 0,
			transpvolpb: 0,
			transpvolpl: 0,
			transplacre: '',

			//Cobrança '',
			cobrindpag: '',
			cobrtpag: '',
			cobrvpag: 0,

			//Informações '',
			nfeinfadfisco: '',
			nfeinfcpl: '',
			protocol_nprot: '',
			protocol_nrecbto: '',
			protocol_dhrecbto: '',
			protocol_digval: '',
			nfecstat: '',
			nfexmotivo: '',
			frasehomologacao: '',
			frasecontingencia: '',
			/*VALIADR*/
			protcanc: '',
			responsavelcancel: '',
			tpevento: '',
			xevento: '',
			protocol_dtcancel: '',
			nfecce: '',
			protcce: '',
			protocol_dtcce: '',
			responsavelcce: '',
			responsavel: '',
			tipo_geracao: '',
			confirmado_envio_user: '',
			nfedtadicao: '',
			id_nf_emissor: null,
			// id_depositante: '',
			nfeenvioemail: '',
			nfedtenvioemail: '',
			nfeprocessofalta: null,
			id_entidade: null,
			nfeplaca: '',
			nfeplacauf: '',
			produtos: [
				{
					itemnitem: '',
					itemcodigo: '',
					itemean: '',
					itemncm: '',
					itemdescricao: '',
					itemchavenfe: '',
					itemnnfe: '',
					itemserie: '',
					itemcest: '',
					itemcfop: '',
					itemucom: '',
					itemqcom: 0,
					itemvunit: 0,
					itemvtotal: 0,
					itemeantrib: '',
					itemuntrib: '',
					itemqtrib: 0,
					itemvuntrib: 0,
					itemindtot: '',
					itemxped: '',
					itemnlote: '',
					itemqlote: 0,
					itemdfab: '',
					itemdval: '',
					itemorig: '',
					itemcst: '',
					itemmodbc: 0,
					itembcicms: 0,
					itempicms: 0,
					itemvicms: 0,
					itempredbc: 0,
					itembcicmsst: 0,
					itempicmsst: 0,
					itemvicmsst: 0,
					itemcenqipi: '',
					itemcstipi: '',
					itembcipi: 0,
					itemvipi: 0,
					itemipi: 0,
					itemcstpis: '',
					itembcpis: 0,
					itemppis: 0,
					itemvpis: 0,
					itemcstcofins: '',
					itembccofins: 0,
					itempcofins: 0,
					itemvcofins: 0,
					iteminfadprod: '',
					id_nf_emissor: null,
					id_filial_emissor: null,
					id_produto_emissor: null,
					itemindescala: '',
					itemcnpjfab: '',
					itemcbenef: '',
					itempipi: '',
				},
			],
		},
	];
	const importarXmlConverterJson = async (xml: any) => {
		setShowCarregando(true);

		const arquivos = xml || null;

		if (arquivos) {
			for (let i = 0; i < arquivos.length; i++) {
				const file = arquivos[i];

				const xmlConteudo = await LerXml(file);

				const reader = new FileReader();
				reader.onload = () => {
					const xmlString = reader.result as string;

					const json = xml2json(xmlString, { compact: true, spaces: 2 });
					const nota = JSON.parse(json);

					const modeloHomologacao = nota.NFe?.infNFe?.ide?.mod?._text;
					const modelo = nota.nfeProc?.NFe?.infNFe?.ide?.mod?._text;

					if (modeloHomologacao === '55') {
						let itens = nota.NFe.infNFe.det;

						// Verifica se 'itens' é um array; se não for, converte em um array
						if (!Array.isArray(itens)) {
							itens = [itens];
						}
						const qtdItens = itens.length;
						const produtos = [];

						if (qtdItens === 1) {
							const prod = {
								itemnitem: nota.NFe.infNFe.det._attributes.nItem || '',
								itemcodigo: nota.NFe.infNFe.det.prod.cProd?._text || '',
								itemean: nota.NFe.infNFe.det.prod.cEAN?._text || '',
								itemncm: nota.NFe.infNFe.det.prod.NCM?._text || '',
								itemdescricao: nota.NFe.infNFe.det.prod.xProd?._text || '',
								itemchavenfe: nota.NFe.infNFe?._attributes?.Id?.substring(3, 50) || '',
								itemnnfe: nota.NFe.infNFe.ide.nNF?._text || '',
								itemserie: nota.NFe.infNFe.ide.serie?._text || '',
								itemcest: nota.NFe.infNFe.det.prod.CEST?._text || '',
								itemcfop: nota.NFe.infNFe.det.prod.CFOP?._text || '',
								itemucom: nota.NFe.infNFe.det.prod.uCom?._text || '',
								itemqcom: parseFloat(nota.NFe.infNFe.det.prod.qCom?._text || 0),
								itemvunit: parseFloat(nota.NFe.infNFe.det.prod.vUnCom?._text || 0),
								itemvtotal: parseFloat(nota.NFe.infNFe.det.prod.vProd?._text || 0),
								itemeantrib: nota.NFe.infNFe.det.prod.cEANTrib?._text || '',
								itemuntrib: nota.NFe.infNFe.det.prod.uTrib?._text || '',
								itemqtrib: parseFloat(nota.NFe.infNFe.det.prod.qTrib?._text || 0),
								itemvuntrib: parseFloat(nota.NFe.infNFe.det.prod.vUnTrib?._text || 0),
								itemindtot: nota.NFe.infNFe.det.prod.indTot?._text || '',
								itemxped: nota.NFe.infNFe.det.prod.xPed?._text || '',
								itemnlote: nota.NFe.infNFe.det.prod.nLote?._text || '',
								itemqlote: parseFloat(nota.NFe.infNFe.det.prod.qLote?._text || 0),
								itemdfab: nota.NFe.infNFe.det.prod.dFab?._text || '',
								itemdval: nota.NFe.infNFe.det.prod.dVal?._text || '',
								itemorig:
									nota.NFe.infNFe.det.imposto.ICMS.ICMS00?.orig?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS10?.orig?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS20?.orig?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS30?.orig?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS40?.orig?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS41?.orig?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS50?.orig?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS51?.orig?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS60?.orig?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS70?.orig?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS90?.orig?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMSSN101?.orig?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMSSN102?.orig?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMSSN201?.orig?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMSSN202?.orig?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMSSN500?.orig?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMSSN900?.orig?._text ||
									'',
								itemcst:
									nota.NFe.infNFe.det.imposto.ICMS.ICMS00?.CST?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS10?.CST?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS20?.CST?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS30?.CST?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS40?.CST?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS41?.CST?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS50?.CST?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS51?.CST?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS60?.CST?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS70?.CST?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS90?.CST?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMSSN101?.CSOSN?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMSSN102?.CSOSN?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMSSN201?.CSOSN?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMSSN202?.CSOSN?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMSSN500?.CSOSN?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMSSN900?.CSOSN?._text ||
									'',
								itemmodbc:
									nota.NFe.infNFe.det.imposto.ICMS.ICMS00?.modBC?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS10?.modBC?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS20?.modBC?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS30?.modBC?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS40?.modBC?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS41?.modBC?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS50?.modBC?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS51?.modBC?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS60?.modBC?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS70?.modBC?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMS90?.modBC?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMSSN101?.modBCST?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMSSN102?.modBCST?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMSSN201?.modBCST?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMSSN202?.modBCST?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMSSN500?.modBCST?._text ||
									nota.NFe.infNFe.det.imposto.ICMS.ICMSSN900?.modBCST?._text ||
									0,
								itembcicms: parseFloat(
									nota.NFe.infNFe.det.imposto.ICMS.ICMS00?.vBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS10?.vBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS20?.vBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS30?.vBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS40?.vBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS41?.vBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS50?.vBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS51?.vBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS60?.vBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS70?.vBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS90?.vBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMSSN101?.vBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMSSN102?.vBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMSSN201?.vBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMSSN202?.vBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMSSN500?.vBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMSSN900?.vBC?._text ||
										0
								),
								itempicms: parseFloat(
									nota.NFe.infNFe.det.imposto.ICMS.ICMS00?.pICMS?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS10?.pICMS?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS20?.pICMS?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS30?.pICMS?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS40?.pICMS?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS41?.pICMS?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS50?.pICMS?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS51?.pICMS?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS60?.pICMS?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS70?.pICMS?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS90?.pICMS?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMSSN101?.pCredSN?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMSSN102?.pCredSN?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMSSN201?.pCredSN?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMSSN202?.pCredSN?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMSSN500?.pCredSN?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMSSN900?.pCredSN?._text ||
										0
								),
								itemvicms: parseFloat(
									nota.NFe.infNFe.det.imposto.ICMS.ICMS00?.vICMS?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS10?.vICMS?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS20?.vICMS?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS30?.vICMS?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS40?.vICMS?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS41?.vICMS?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS50?.vICMS?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS51?.vICMS?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS60?.vICMS?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS70?.vICMS?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS90?.vICMS?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMSSN101?.vCredICMSSN?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMSSN102?.vCredICMSSN?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMSSN201?.vCredICMSSN?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMSSN202?.vCredICMSSN?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMSSN500?.vCredICMSSN?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMSSN900?.vCredICMSSN?._text ||
										0
								),
								itempredbc: parseFloat(
									nota.NFe.infNFe.det.imposto.ICMS.ICMS00?.pRedBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS10?.pRedBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS20?.pRedBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS30?.pRedBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS40?.pRedBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS41?.pRedBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS50?.pRedBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS51?.pRedBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS60?.pRedBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS70?.pRedBC?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS90?.pRedBC?._text ||
										0
								),
								itembcicmsst: parseFloat(
									nota.NFe.infNFe.det.imposto.ICMS.ICMS00?.vBCST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS10?.vBCST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS20?.vBCST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS30?.vBCST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS40?.vBCST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS41?.vBCST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS50?.vBCST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS51?.vBCST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS60?.vBCST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS70?.vBCST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS90?.vBCST?._text ||
										0
								),
								itempicmsst: parseFloat(
									nota.NFe.infNFe.det.imposto.ICMS.ICMS00?.pICMSST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS10?.pICMSST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS20?.pICMSST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS30?.pICMSST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS40?.pICMSST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS41?.pICMSST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS50?.pICMSST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS51?.pICMSST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS60?.pICMSST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS70?.pICMSST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS90?.pICMSST?._text ||
										0
								),
								itemvicmsst: parseFloat(
									nota.NFe.infNFe.det.imposto.ICMS.ICMS00?.vICMSST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS10?.vICMSST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS20?.vICMSST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS30?.vICMSST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS40?.vICMSST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS41?.vICMSST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS50?.vICMSST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS51?.vICMSST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS60?.vICMSST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS70?.vICMSST?._text ||
										nota.NFe.infNFe.det.imposto.ICMS.ICMS90?.vICMSST?._text ||
										0
								),
								itemcenqipi: nota.NFe.infNFe.det.imposto.IPI?.cEnq?._text || '',
								itemcstipi: nota.NFe.infNFe.det.imposto.IPI?.IPITrib?.CST?._text || '',
								itembcipi: parseFloat(nota.NFe.infNFe.det.imposto.IPI?.IPITrib?.vBC?._text || 0),
								itemvipi: parseFloat(nota.NFe.infNFe.det.imposto.IPI?.IPITrib?.vIPI?._text || 0),
								itemipi: parseFloat(nota.NFe.infNFe.det.imposto.IPI?.IPITrib?.pIPI?._text || 0),
								itemcstpis: nota.NFe.infNFe.det.imposto.PIS?.PISAliq?.CST?._text || '',
								itembcpis: parseFloat(nota.NFe.infNFe.det.imposto.PIS?.PISAliq?.vBC?._text || 0),
								itemppis: parseFloat(nota.NFe.infNFe.det.imposto.PIS?.PISAliq?.pPIS?._text || 0),
								itemvpis: parseFloat(nota.NFe.infNFe.det.imposto.PIS?.PISAliq?.vPIS?._text || 0),
								itemcstcofins: nota.NFe.infNFe.det.imposto.COFINS?.COFINSAliq?.CST?._text || '',
								itembccofins: parseFloat(nota.NFe.infNFe.det.imposto.COFINS?.COFINSAliq?.vBC?._text || 0),
								itempcofins: parseFloat(nota.NFe.infNFe.det.imposto.COFINS?.COFINSAliq?.pCOFINS?._text || 0),
								itemvcofins: parseFloat(nota.NFe.infNFe.det.imposto.COFINS?.COFINSAliq?.vCOFINS?._text || 0),
								iteminfadprod: nota.NFe.infNFe.det.infAdProd?._text || '',
								id_nf_emissor: null,
								id_filial_emissor: null,
								id_produto_emissor: null,
								itemindescala: nota.NFe.infNFe.det.prod.indEscala?._text || '',
								itemcnpjfab: nota.NFe.infNFe.det.prod?.CNPJFab?._text || '',
								itemcbenef: nota.NFe.infNFe.det.prod?.cBenef?._text || '',
								itempipi: nota.NFe.infNFe.det.imposto.IPI?.IPITrib?.pIPI?._text || 0,
							};
							produtos.push(prod);
						} else {
							for (let i = 0; i < qtdItens; i++) {
								const prod = {
									itemnitem: nota.NFe.infNFe.det[i]._attributes.nItem || '',
									itemcodigo: nota.NFe.infNFe.det[i].prod.cProd?._text || '',
									itemean: nota.NFe.infNFe.det[i].prod.cEAN?._text || '',
									itemncm: nota.NFe.infNFe.det[i].prod.NCM?._text || '',
									itemdescricao: nota.NFe.infNFe.det[i].prod.xProd?._text || '',
									itemchavenfe: nota.NFe.infNFe?._attributes?.Id?.substring(3, 50) || '',
									itemnnfe: nota.NFe.infNFe.ide.nNF?._text || '',
									itemserie: nota.NFe.infNFe.ide.serie?._text || '',
									itemcest: nota.NFe.infNFe.det[i].prod.CEST?._text || '',
									itemcfop: nota.NFe.infNFe.det[i].prod.CFOP?._text || '',
									itemucom: nota.NFe.infNFe.det[i].prod.uCom?._text || '',
									itemqcom: parseFloat(nota.NFe.infNFe.det[i].prod.qCom?._text || 0),
									itemvunit: parseFloat(nota.NFe.infNFe.det[i].prod.vUnCom?._text || 0),
									itemvtotal: parseFloat(nota.NFe.infNFe.det[i].prod.vProd?._text || 0),
									itemeantrib: nota.NFe.infNFe.det[i].prod.cEANTrib?._text || '',
									itemuntrib: nota.NFe.infNFe.det[i].prod.uTrib?._text || '',
									itemqtrib: parseFloat(nota.NFe.infNFe.det[i].prod.qTrib?._text || 0),
									itemvuntrib: parseFloat(nota.NFe.infNFe.det[i].prod.vUnTrib?._text || 0),
									itemindtot: nota.NFe.infNFe.det[i].prod.indTot?._text || '',
									itemxped: nota.NFe.infNFe.det[i].prod.xPed?._text || '',
									itemnlote: nota.NFe.infNFe.det[i].prod.nLote?._text || '',
									itemqlote: parseFloat(nota.NFe.infNFe.det[i].prod.qLote?._text || 0),
									itemdfab: nota.NFe.infNFe.det[i].prod.dFab?._text || '',
									itemdval: nota.NFe.infNFe.det[i].prod.dVal?._text || '',
									itemorig:
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.orig?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.orig?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.orig?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.orig?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.orig?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.orig?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.orig?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.orig?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.orig?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.orig?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.orig?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN101?.orig?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN102?.orig?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN201?.orig?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN202?.orig?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN500?.orig?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN900?.orig?._text ||
										'',
									itemcst:
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.CST?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.CST?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.CST?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.CST?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.CST?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.CST?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.CST?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.CST?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.CST?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.CST?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.CST?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN101?.CSOSN?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN102?.CSOSN?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN201?.CSOSN?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN202?.CSOSN?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN500?.CSOSN?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN900?.CSOSN?._text ||
										'',
									itemmodbc:
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.modBC?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.modBC?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.modBC?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.modBC?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.modBC?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.modBC?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.modBC?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.modBC?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.modBC?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.modBC?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.modBC?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN101?.modBCST?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN102?.modBCST?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN201?.modBCST?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN202?.modBCST?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN500?.modBCST?._text ||
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN900?.modBCST?._text ||
										0,
									itembcicms: parseFloat(
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.vBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.vBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.vBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.vBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.vBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.vBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.vBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.vBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.vBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.vBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.vBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN101?.vBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN102?.vBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN201?.vBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN202?.vBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN500?.vBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN900?.vBC?._text ||
											0
									),
									itempicms: parseFloat(
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.pICMS?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.pICMS?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.pICMS?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.pICMS?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.pICMS?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.pICMS?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.pICMS?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.pICMS?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.pICMS?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.pICMS?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.pICMS?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN101?.pCredSN?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN102?.pCredSN?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN201?.pCredSN?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN202?.pCredSN?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN500?.pCredSN?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN900?.pCredSN?._text ||
											0
									),
									itemvicms: parseFloat(
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.vICMS?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.vICMS?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.vICMS?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.vICMS?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.vICMS?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.vICMS?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.vICMS?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.vICMS?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.vICMS?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.vICMS?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.vICMS?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN101?.vCredICMSSN?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN102?.vCredICMSSN?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN201?.vCredICMSSN?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN202?.vCredICMSSN?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN500?.vCredICMSSN?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMSSN900?.vCredICMSSN?._text ||
											0
									),
									itempredbc: parseFloat(
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.pRedBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.pRedBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.pRedBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.pRedBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.pRedBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.pRedBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.pRedBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.pRedBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.pRedBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.pRedBC?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.pRedBC?._text ||
											0
									),
									itembcicmsst: parseFloat(
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.vBCST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.vBCST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.vBCST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.vBCST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.vBCST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.vBCST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.vBCST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.vBCST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.vBCST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.vBCST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.vBCST?._text ||
											0
									),
									itempicmsst: parseFloat(
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.pICMSST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.pICMSST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.pICMSST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.pICMSST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.pICMSST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.pICMSST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.pICMSST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.pICMSST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.pICMSST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.pICMSST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.pICMSST?._text ||
											0
									),
									itemvicmsst: parseFloat(
										nota.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.vICMSST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.vICMSST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.vICMSST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.vICMSST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.vICMSST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.vICMSST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.vICMSST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.vICMSST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.vICMSST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.vICMSST?._text ||
											nota.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.vICMSST?._text ||
											0
									),
									itemcenqipi: nota.NFe.infNFe.det[i].imposto.IPI?.cEnq?._text || '',
									itemcstipi: nota.NFe.infNFe.det[i].imposto.IPI?.IPITrib?.CST?._text || '',
									itembcipi: parseFloat(nota.NFe.infNFe.det[i].imposto.IPI?.IPITrib?.vBC?._text || 0),
									itemvipi: parseFloat(nota.NFe.infNFe.det[i].imposto.IPI?.IPITrib?.vIPI?._text || 0),
									itemipi: parseFloat(nota.NFe.infNFe.det[i].imposto.IPI?.IPITrib?.pIPI?._text || 0),
									itemcstpis: nota.NFe.infNFe.det[i].imposto.PIS?.PISAliq?.CST?._text || '',
									itembcpis: parseFloat(nota.NFe.infNFe.det[i].imposto.PIS?.PISAliq?.vBC?._text || 0),
									itemppis: parseFloat(nota.NFe.infNFe.det[i].imposto.PIS?.PISAliq?.pPIS?._text || 0),
									itemvpis: parseFloat(nota.NFe.infNFe.det[i].imposto.PIS?.PISAliq?.vPIS?._text || 0),
									itemcstcofins: nota.NFe.infNFe.det[i].imposto.COFINS?.COFINSAliq?.CST?._text || '',
									itembccofins: parseFloat(nota.NFe.infNFe.det[i].imposto.COFINS?.COFINSAliq?.vBC?._text || 0),
									itempcofins: parseFloat(nota.NFe.infNFe.det[i].imposto.COFINS?.COFINSAliq?.pCOFINS?._text || 0),
									itemvcofins: parseFloat(nota.NFe.infNFe.det[i].imposto.COFINS?.COFINSAliq?.vCOFINS?._text || 0),
									iteminfadprod: nota.NFe.infNFe.det[i].infAdProd?._text || '',
									id_nf_emissor: null,
									id_filial_emissor: null,
									id_produto_emissor: null,
									itemindescala: nota.NFe.infNFe.det[i].prod.indEscala?._text || '',
									itemcnpjfab: nota.NFe.infNFe.det[i].prod?.CNPJFab?._text || '',
									itemcbenef: nota.NFe.infNFe.det[i].prod?.cBenef?._text || '',
									itempipi: nota.NFe.infNFe.det[i].imposto.IPI?.IPITrib?.pIPI?._text || 0,
								};
								produtos.push(prod);
							}
						}
						const dadosNFe = {
							// DadosNota
							id_filial: id_filial,
							nfestatus: nota.protNFe?.infProt?.cStat?._text || '',
							nfechave: nota.NFe.infNFe?._attributes?.Id?.substring(3, 50) || '',
							nfecuf: nota.NFe.infNFe.ide.cUF?._text || '',
							nfenatoper: nota.NFe.infNFe.ide.natOp?._text || '',
							nfecfop: nota.NFe.infNFe.ide.CFOP?._text || '',
							nfemod: nota.NFe.infNFe.ide.mod?._text || '',
							nfeserie: nota.NFe.infNFe.ide.serie?._text || '',
							nfennf: nota.NFe.infNFe.ide.nNF?._text || '',
							nfedtemissao: nota.NFe.infNFe.ide.dhEmi?._text || '',
							nfedtsaida: nota.NFe.infNFe.ide.dhSaiEnt?._text || '',
							nfentpnf: nota.NFe.infNFe.ide.tpNF?._text || '',
							nfeiddest: nota.NFe.infNFe.ide.iDest?._text || '',
							nfecmunfg: nota.NFe.infNFe.ide.cMunFG?._text || '',
							tpimp: nota.NFe.infNFe.ide.tpImp?._text || '',
							nfetpemis: nota.NFe.infNFe.ide.tpEmis?._text || '',
							nfecdv: nota.NFe.infNFe.ide.cDV?._text || '',
							nfetpamb: nota.NFe.infNFe.ide.tpAmb?._text || '',
							nfefinnfe: nota.NFe.infNFe.ide.finNFe?._text || '',
							nfeindfinal: nota.NFe.infNFe.ide.indFinal?._text || '',
							nfeindpres: nota.NFe.infNFe.ide.indPres?._text || '',
							nfeprocemi: nota.NFe.infNFe.ide.procEmi?._text || '',
							nfeverproc: nota.NFe.infNFe.ide.verProc?._text || '',
							nfedhconting: nota.NFe.infNFe.ide.dhCont?._text || '',
							nfejustconting: nota.NFe.infNFe.ide.xJust?._text || '',

							// DadosEmitente
							nfeemitcnpj: nota.NFe.infNFe.emit.CNPJ?._text || '',
							nfeemitcpf: nota.NFe.infNFe.emit.CPF?._text || '',
							nfeemitnome: nota.NFe.infNFe.emit.xNome?._text || '',
							nfeemitfantasia: nota.NFe.infNFe.emit.xFant?._text || '',
							nfeemitie: nota.NFe.infNFe.emit.IE?._text || '',
							nfeemitiest: nota.NFe.infNFe.emit.IEST?._text || '',
							nfeemitcrt: nota.NFe.infNFe.emit.CRT?._text || '',

							// EnderecoEmitente
							nfeemitlograd: nota.NFe.infNFe.emit.enderEmit.xLgr?._text || '',
							nfeemitnumero: nota.NFe.infNFe.emit.enderEmit.nro?._text || '',
							nfeemitcompl: nota.NFe.infNFe.emit.enderEmit.xCpl?._text || '',
							nfeemitbairro: nota.NFe.infNFe.emit.enderEmit.xBairro?._text || '',
							nfeemitcmun: nota.NFe.infNFe.emit.enderEmit.cMun?._text || '',
							nfeemitmun: nota.NFe.infNFe.emit.enderEmit.xMun?._text || '',
							nfeemituf: nota.NFe.infNFe.emit.enderEmit.UF?._text || '',
							nfeemitcep: nota.NFe.infNFe.emit.enderEmit.CEP?._text || '',
							nfeemitcpais: nota.NFe.infNFe.emit.enderEmit.cPais?._text || '',
							nfeemitpais: nota.NFe.infNFe.emit.enderEmit.xPais?._text || '',
							nfeemitfone: nota.NFe.infNFe.emit.enderEmit.fone?._text || '',

							// DadosDestinatario
							nfedestcnpj: nota.NFe.infNFe.dest.CNPJ?._text || '',
							nfedestcpf: nota.NFe.infNFe.dest.CPF?._text || '',
							nfedestnome: nota.NFe.infNFe.dest.xNome?._text || '',
							nfedestindie: nota.NFe.infNFe.dest.indIEDest?._text || '',
							nfedestie: nota.NFe.infNFe.dest.IE?._text || '',
							nfedestisuf: nota.NFe.infNFe.dest.ISUF?._text || '',
							nfedestim: nota.NFe.infNFe.dest.IM?._text || '',
							nfedestemail: nota.NFe.infNFe.dest.email?._text || '',

							// EnderecoDestinatario
							nfedestlograd: nota.NFe.infNFe.dest.enderDest.xLgr?._text || '',
							nfedestnumero: nota.NFe.infNFe.dest.enderDest.nro?._text || '',
							nfedestcompl: nota.NFe.infNFe.dest.enderDest.xCpl?._text || '',
							nfedestbairro: nota.NFe.infNFe.dest.enderDest.xBairro?._text || '',
							nfedestcmun: nota.NFe.infNFe.dest.enderDest.cMun?._text || '',
							nfedestmun: nota.NFe.infNFe.dest.enderDest.xMun?._text || '',
							nfedestuf: nota.NFe.infNFe.dest.enderDest.UF?._text || '',
							nfedestcep: nota.NFe.infNFe.dest.enderDest.CEP?._text || '',
							nfedestcpais: nota.NFe.infNFe.dest.enderDest.cPais?._text || '',
							nfedestpais: nota.NFe.infNFe.dest.enderDest.xPais?._text || '',
							nfedestfone: nota.NFe.infNFe.dest.enderDest.fone?._text || '',

							//Tributos/Totais
							nfebcicms: parseFloat(nota.NFe.infNFe.total.ICMSTot.vBC?._text || 0),
							nfevicms: parseFloat(nota.NFe.infNFe.total.ICMSTot.vICMS?._text || 0),
							nfevicmsdeson: parseFloat(nota.NFe.infNFe.total.ICMSTot.vICMSDeson?._text || 0),
							nfebcicmsst: parseFloat(nota.NFe.infNFe.total.ICMSTot.vBCST?._text || 0),
							nfevicmsst: parseFloat(nota.NFe.infNFe.total.ICMSTot.vST?._text || 0),
							nfevfcp: parseFloat(nota.NFe.infNFe.total.ICMSTot.vFCP?._text || 0),
							nfevfcpst: parseFloat(nota.NFe.infNFe.total.ICMSTot.vFCPST?._text || 0),
							nfevfcpstret: parseFloat(nota.NFe.infNFe.total.ICMSTot.vFCPSTRet?._text || 0),
							nfevtotprod: parseFloat(nota.NFe.infNFe.total.ICMSTot.vProd?._text || 0),
							nfevfrete: parseFloat(nota.NFe.infNFe.total.ICMSTot.vFrete?._text || 0),
							nfevseguro: parseFloat(nota.NFe.infNFe.total.ICMSTot.vSeg?._text || 0),
							nfevdesconto: parseFloat(nota.NFe.infNFe.total.ICMSTot.vDesc?._text || 0),
							nfevii: parseFloat(nota.NFe.infNFe.total.ICMSTot.vII?._text || 0),
							nfevipi: parseFloat(nota.NFe.infNFe.total.ICMSTot.vIPI?._text || 0),
							nfevipidevol: parseFloat(nota.NFe.infNFe.total.ICMSTot.vIPIDevol?._text || 0),
							nfevpis: parseFloat(nota.NFe.infNFe.total.ICMSTot.vPIS?._text || 0),
							nfevcofins: parseFloat(nota.NFe.infNFe.total.ICMSTot.vCOFINS?._text || 0),
							nfevoutros: parseFloat(nota.NFe.infNFe.total.ICMSTot.vOutro?._text || 0),
							nfevtotnota: parseFloat(nota.NFe.infNFe.total.ICMSTot.vNF?._text || 0),
							nfevtottrib: parseFloat(nota.NFe.infNFe.total.ICMSTot.vTotTrib?._text || 0),

							//Transporte nota.nfeProc.NFe.infNFe.transp

							//nota.nfeProc.NFe.infNFe.transp.transporta?.xNome._text
							transpmodfrete: nota.nfeProc.NFe.infNFe.transp.transporta?.modFrete?._text || '',
							transpcnpj: nota.nfeProc.NFe.infNFe.transp.transporta?.CNPJ?._text || '',
							transpcpf: nota.nfeProc.NFe.infNFe.transp.transporta?.CPF?._text || '',
							transpnome: nota.nfeProc.NFe.infNFe.transp.transporta?.xNome?._text || '',
							transpie: nota.nfeProc.NFe.infNFe.transp.transporta?.IE?._text || '',
							transpend: nota.nfeProc.NFe.infNFe.transp.transporta?.xEnder?._text || '',
							transpmun: nota.nfeProc.NFe.infNFe.transp.transporta?.xMun?._text || '',
							transpuf: nota.nfeProc.NFe.infNFe.transp.transporta?.UF?._text || '',
							transpvolqtde: parseFloat(nota.NFe.infNFe.transp?.vol?.qVol?._text || 0),
							transpvolespecie: parseFloat(nota.NFe.infNFe.transp?.vol?.esp?._text || 0),
							transpvolpb: parseFloat(nota.NFe.infNFe.transp?.vol?.pesoB?._text || 0),
							transpvolpl: parseFloat(nota.NFe.infNFe.transp?.vol?.pesoL?._text || 0),
							transplacre: nota.NFe.infNFe.transp?.vol?.Lacres?._text || '',

							//Cobrança
							cobrindpag: nota.NFe.infNFe.cobr?.dup?.nDup?._text || '',
							cobrtpag: nota.NFe.infNFe.cobr?.pag?.detPag?.tPag?._text || '',
							cobrvpag: parseFloat(nota.NFe.infNFe.cobr?.dup?.vDup?._text || ''),

							//Informações Adicionais
							nfeinfadfisco: nota.NFe.infNFe.infAdic?.infAdFisco?._text || '',
							nfeinfcpl: nota.NFe.infNFe.infAdic?.infCpl?._text || '',
							protocol_nprot: nota.protNFe?.infProt?.nProt?._text || '',
							protocol_nrecbto: nota.protNFe?.infProt?.nRec?._text || '',
							protocol_dhrecbto: nota.protNFe?.infProt?.dhRecbto?._text || '',
							protocol_digval: nota.protNFe?.infProt?.digVal?._text || '',
							nfecstat: nota.protNFe?.infProt?.cStat?._text || '',
							nfexmotivo: nota.protNFe?.infProt?.xMotivo?._text || '',
							frasehomologacao: nota.protNFe?.infProt?.xMotivo?._text || '',
							frasecontingencia: nota.protNFe?.infProt.xJust?._text || '',
							/*VALIADR COM FRANKLIN */
							protcanc: nota.protNFe?.infProt?.cStat?._text || '',
							responsavelcancel: nota.protNFe?.infProt?.xMotivo?._text || '',
							tpevento: nota.retEvento?.infEvento?.tpEvento?._text || '',
							xevento: nota.retEvento?.infEvento?.xEvento?._text || '',
							protocol_dtcancel: nota.retEvento?.infEvento?.dhRegEvento?._text || '',
							nfecce: nota.retEvento?.infEvento?.cStat?._text || '',
							protcce: nota.retEvento?.infEvento?.xMotivo?._text || '',
							protocol_dtcce: nota.retEvento?.infEvento?.dhRegEvento?._text || '',
							responsavelcce: nota.retEvento?.infEvento?.xJust?._text || '',
							responsavel: nota.retEvento?.infEvento?.xNome?._text || '',
							tipo_geracao: '',
							confirmado_envio_user: '',
							nfedtadicao: '',
							id_nf_emissor: null,
							//id_depositante: selecionaDepostitante || '',
							nfeenvioemail: '',
							nfedtenvioemail: '',
							nfeprocessofalta: null,
							id_entidade: null,
							nfeplaca: '',
							nfeplacauf: '',
							produtos: produtos,
						};

						todasNotasFiscais.push(dadosNFe);
					} else if (modelo === '55') {
						let itens = nota.nfeProc.NFe.infNFe.det;

						// Verifica se 'itens' é um array; se não for, converte em um array
						if (!Array.isArray(itens)) {
							itens = [itens];
						}
						const qtdItens = itens.length;

						const chavenfe = nota.nfeProc.protNFe.infProt.chNFe?._text || '';
						const produtos = [];
						if (qtdItens === 1) {
							const prod = {
								itemnitem: nota.nfeProc.NFe.infNFe.det._attributes.nItem || '',
								itemcodigo: nota.nfeProc.NFe.infNFe.det.prod.cProd?._text || '',
								itemean: nota.nfeProc.NFe.infNFe.det.prod.cEAN?._text || '',
								itemncm: nota.nfeProc.NFe.infNFe.det.prod.NCM?._text || '',
								itemdescricao: nota.nfeProc.NFe.infNFe.det.prod.xProd?._text || '',
								itemchavenfe: chavenfe,
								itemnnfe: nota.nfeProc.NFe.infNFe.ide.nNF?._text || '',
								itemserie: nota.nfeProc.NFe.infNFe.ide.serie?._text || '',
								itemcest: nota.nfeProc.NFe.infNFe.det.prod.CEST?._text || '',
								itemcfop: nota.nfeProc.NFe.infNFe.det.prod.CFOP?._text || '',
								itemucom: nota.nfeProc.NFe.infNFe.det.prod.uCom?._text || '',
								itemqcom: parseFloat(nota.nfeProc.NFe.infNFe.det.prod.qCom?._text || 0),
								itemvunit: parseFloat(nota.nfeProc.NFe.infNFe.det.prod.vUnCom?._text || 0),
								itemvtotal: parseFloat(nota.nfeProc.NFe.infNFe.det.prod.vProd?._text || 0),
								itemeantrib: nota.nfeProc.NFe.infNFe.det.prod.cEANTrib?._text || '',
								itemuntrib: nota.nfeProc.NFe.infNFe.det.prod.uTrib?._text || '',
								itemqtrib: parseFloat(nota.nfeProc.NFe.infNFe.det.prod.qTrib?._text || 0),
								itemvuntrib: parseFloat(nota.nfeProc.NFe.infNFe.det.prod.vUnTrib?._text || 0),
								itemindtot: nota.nfeProc.NFe.infNFe.det.prod.indTot?._text || '',
								itemxped: nota.nfeProc.NFe.infNFe.det.prod.xPed?._text || '',
								itemnlote: nota.nfeProc.NFe.infNFe.det.prod.nLote?._text || '',
								itemqlote: parseFloat(nota.nfeProc.NFe.infNFe.det.prod.qLote?._text || 0),
								itemdfab: nota.nfeProc.NFe.infNFe.det.prod.dFab?._text || '',
								itemdval: nota.nfeProc.NFe.infNFe.det.prod.dVal?._text || '',
								itemorig:
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS00?.orig?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS10?.orig?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS20?.orig?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS30?.orig?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS40?.orig?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS41?.orig?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS50?.orig?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS51?.orig?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS60?.orig?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS70?.orig?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS90?.orig?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN101?.orig?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN102?.orig?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN201?.orig?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN202?.orig?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN500?.orig?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN900?.orig?._text ||
									'',
								itemcst:
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS00?.CST?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS10?.CST?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS20?.CST?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS30?.CST?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS40?.CST?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS41?.CST?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS50?.CST?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS51?.CST?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS60?.CST?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS70?.CST?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS90?.CST?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN101?.CSOSN?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN102?.CSOSN?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN201?.CSOSN?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN202?.CSOSN?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN500?.CSOSN?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN900?.CSOSN?._text ||
									'',
								itemmodbc:
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS00?.modBC?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS10?.modBC?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS20?.modBC?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS30?.modBC?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS40?.modBC?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS41?.modBC?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS50?.modBC?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS51?.modBC?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS60?.modBC?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS70?.modBC?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS90?.modBC?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN101?.modBCST?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN102?.modBCST?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN201?.modBCST?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN202?.modBCST?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN500?.modBCST?._text ||
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN900?.modBCST?._text ||
									0,
								itembcicms: parseFloat(
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS00?.vBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS10?.vBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS20?.vBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS30?.vBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS40?.vBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS41?.vBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS50?.vBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS51?.vBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS60?.vBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS70?.vBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS90?.vBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN101?.vBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN102?.vBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN201?.vBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN202?.vBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN500?.vBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN900?.vBC?._text ||
										0
								),
								itempicms: parseFloat(
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS00?.pICMS?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS10?.pICMS?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS20?.pICMS?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS30?.pICMS?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS40?.pICMS?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS41?.pICMS?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS50?.pICMS?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS51?.pICMS?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS60?.pICMS?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS70?.pICMS?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS90?.pICMS?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN101?.pCredSN?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN102?.pCredSN?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN201?.pCredSN?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN202?.pCredSN?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN500?.pCredSN?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN900?.pCredSN?._text ||
										0
								),
								itemvicms: parseFloat(
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS00?.vICMS?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS10?.vICMS?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS20?.vICMS?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS30?.vICMS?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS40?.vICMS?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS41?.vICMS?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS50?.vICMS?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS51?.vICMS?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS60?.vICMS?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS70?.vICMS?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS90?.vICMS?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN101?.vCredICMSSN?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN102?.vCredICMSSN?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN201?.vCredICMSSN?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN202?.vCredICMSSN?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN500?.vCredICMSSN?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMSSN900?.vCredICMSSN?._text ||
										0
								),
								itempredbc: parseFloat(
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS00?.pRedBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS10?.pRedBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS20?.pRedBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS30?.pRedBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS40?.pRedBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS41?.pRedBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS50?.pRedBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS51?.pRedBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS60?.pRedBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS70?.pRedBC?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS90?.pRedBC?._text ||
										0
								),
								itembcicmsst: parseFloat(
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS00?.vBCST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS10?.vBCST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS20?.vBCST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS30?.vBCST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS40?.vBCST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS41?.vBCST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS50?.vBCST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS51?.vBCST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS60?.vBCST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS70?.vBCST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS90?.vBCST?._text ||
										0
								),
								itempicmsst: parseFloat(
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS00?.pICMSST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS10?.pICMSST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS20?.pICMSST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS30?.pICMSST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS40?.pICMSST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS41?.pICMSST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS50?.pICMSST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS51?.pICMSST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS60?.pICMSST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS70?.pICMSST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS90?.pICMSST?._text ||
										0
								),
								itemvicmsst: parseFloat(
									nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS00?.vICMSST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS10?.vICMSST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS20?.vICMSST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS30?.vICMSST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS40?.vICMSST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS41?.vICMSST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS50?.vICMSST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS51?.vICMSST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS60?.vICMSST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS70?.vICMSST?._text ||
										nota.nfeProc.NFe.infNFe.det.imposto.ICMS.ICMS90?.vICMSST?._text ||
										0
								),
								itemcenqipi: nota.nfeProc.NFe.infNFe.det.imposto.IPI?.cEnq?._text || '',
								itemcstipi: nota.nfeProc.NFe.infNFe.det.imposto.IPI?.IPITrib?.CST?._text || '',
								itembcipi: parseFloat(nota.nfeProc.NFe.infNFe.det.imposto.IPI?.IPITrib?.vBC?._text || 0),
								itemvipi: parseFloat(nota.nfeProc.NFe.infNFe.det.imposto.IPI?.IPITrib?.vIPI?._text || 0),
								itemipi: parseFloat(nota.nfeProc.NFe.infNFe.det.imposto.IPI?.IPITrib?.pIPI?._text || 0),
								itemcstpis: nota.nfeProc.NFe.infNFe.det.imposto.PIS?.PISAliq?.CST?._text || '',
								itembcpis: parseFloat(nota.nfeProc.NFe.infNFe.det.imposto.PIS?.PISAliq?.vBC?._text || 0),
								itemppis: parseFloat(nota.nfeProc.NFe.infNFe.det.imposto.PIS?.PISAliq?.pPIS?._text || 0),
								itemvpis: parseFloat(nota.nfeProc.NFe.infNFe.det.imposto.PIS?.PISAliq?.vPIS?._text || 0),
								itemcstcofins: nota.nfeProc.NFe.infNFe.det.imposto.COFINS?.COFINSAliq?.CST?._text || '',
								itembccofins: parseFloat(nota.nfeProc.NFe.infNFe.det.imposto.COFINS?.COFINSAliq?.vBC?._text || 0),
								itempcofins: parseFloat(nota.nfeProc.NFe.infNFe.det.imposto.COFINS?.COFINSAliq?.pCOFINS?._text || 0),
								itemvcofins: parseFloat(nota.nfeProc.NFe.infNFe.det.imposto.COFINS?.COFINSAliq?.vCOFINS?._text || 0),
								iteminfadprod: nota.nfeProc.NFe.infNFe.det.infAdProd?._text || '',
								id_nf_emissor: null,
								id_filial_emissor: null,
								id_produto_emissor: null,
								itemindescala: nota.nfeProc.NFe.infNFe.det.prod.indEscala?._text || '',
								itemcnpjfab: nota.nfeProc.NFe.infNFe.det.prod?.CNPJFab?._text || '',
								itemcbenef: nota.nfeProc.NFe.infNFe.det.prod?.cBenef?._text || '',
								itempipi: nota.nfeProc.NFe.infNFe.det.imposto.IPI?.IPITrib?.pIPI?._text || 0,
								pfcp: nota.nfeProc.NFe.infNFe.det.imposto.ICMS?.ICMS00?.pFCP || 0,
								vfcp: nota.nfeProc.NFe.infNFe.det.imposto.ICMS?.ICMS00?.vFCP || 0,
							};

							produtos.push(prod);
						} else {
							for (let i = 0; i < qtdItens; i++) {
								const prod = {
									itemnitem: nota.nfeProc.NFe.infNFe.det[i]._attributes.nItem || '',
									itemcodigo: nota.nfeProc.NFe.infNFe.det[i].prod.cProd?._text || '',
									itemean: nota.nfeProc.NFe.infNFe.det[i].prod.cEAN?._text || '',
									itemncm: nota.nfeProc.NFe.infNFe.det[i].prod.NCM?._text || '',
									itemdescricao: nota.nfeProc.NFe.infNFe.det[i].prod.xProd?._text || '',
									itemchavenfe: chavenfe,
									itemnnfe: nota.nfeProc.NFe.infNFe.ide.nNF?._text || '',
									itemserie: nota.nfeProc.NFe.infNFe.ide.serie?._text || '',
									itemcest: nota.nfeProc.NFe.infNFe.det[i].prod.CEST?._text || '',
									itemcfop: nota.nfeProc.NFe.infNFe.det[i].prod.CFOP?._text || '',
									itemucom: nota.nfeProc.NFe.infNFe.det[i].prod.uCom?._text || '',
									itemqcom: parseFloat(nota.nfeProc.NFe.infNFe.det[i].prod.qCom?._text || 0),
									itemvunit: parseFloat(nota.nfeProc.NFe.infNFe.det[i].prod.vUnCom?._text || 0),
									itemvtotal: parseFloat(nota.nfeProc.NFe.infNFe.det[i].prod.vProd?._text || 0),
									itemeantrib: nota.nfeProc.NFe.infNFe.det[i].prod.cEANTrib?._text || '',
									itemuntrib: nota.nfeProc.NFe.infNFe.det[i].prod.uTrib?._text || '',
									itemqtrib: parseFloat(nota.nfeProc.NFe.infNFe.det[i].prod.qTrib?._text || 0),
									itemvuntrib: parseFloat(nota.nfeProc.NFe.infNFe.det[i].prod.vUnTrib?._text || 0),
									itemindtot: nota.nfeProc.NFe.infNFe.det[i].prod.indTot?._text || '',
									itemxped: nota.nfeProc.NFe.infNFe.det[i].prod.xPed?._text || '',
									itemnlote: nota.nfeProc.NFe.infNFe.det[i].prod.nLote?._text || '',
									itemqlote: parseFloat(nota.nfeProc.NFe.infNFe.det[i].prod.qLote?._text || 0),
									itemdfab: nota.nfeProc.NFe.infNFe.det[i].prod.dFab?._text || '',
									itemdval: nota.nfeProc.NFe.infNFe.det[i].prod.dVal?._text || '',
									itemorig:
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.orig?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.orig?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.orig?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.orig?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.orig?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.orig?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.orig?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.orig?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.orig?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.orig?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.orig?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN101?.orig?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN102?.orig?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN201?.orig?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN202?.orig?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN500?.orig?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN900?.orig?._text ||
										'',
									itemcst:
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.CST?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.CST?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.CST?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.CST?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.CST?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.CST?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.CST?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.CST?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.CST?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.CST?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.CST?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN101?.CSOSN?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN102?.CSOSN?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN201?.CSOSN?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN202?.CSOSN?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN500?.CSOSN?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN900?.CSOSN?._text ||
										'',
									itemmodbc:
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.modBC?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.modBC?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.modBC?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.modBC?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.modBC?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.modBC?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.modBC?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.modBC?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.modBC?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.modBC?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.modBC?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN101?.modBCST?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN102?.modBCST?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN201?.modBCST?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN202?.modBCST?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN500?.modBCST?._text ||
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN900?.modBCST?._text ||
										0,
									itembcicms: parseFloat(
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.vBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.vBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.vBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.vBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.vBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.vBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.vBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.vBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.vBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.vBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.vBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN101?.vBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN102?.vBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN201?.vBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN202?.vBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN500?.vBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN900?.vBC?._text ||
											0
									),
									itempicms: parseFloat(
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.pICMS?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.pICMS?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.pICMS?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.pICMS?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.pICMS?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.pICMS?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.pICMS?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.pICMS?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.pICMS?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.pICMS?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.pICMS?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN101?.pCredSN?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN102?.pCredSN?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN201?.pCredSN?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN202?.pCredSN?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN500?.pCredSN?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN900?.pCredSN?._text ||
											0
									),
									itemvicms: parseFloat(
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.vICMS?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.vICMS?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.vICMS?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.vICMS?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.vICMS?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.vICMS?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.vICMS?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.vICMS?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.vICMS?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.vICMS?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.vICMS?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN101?.vCredICMSSN?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN102?.vCredICMSSN?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN201?.vCredICMSSN?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN202?.vCredICMSSN?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN500?.vCredICMSSN?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMSSN900?.vCredICMSSN?._text ||
											0
									),
									itempredbc: parseFloat(
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.pRedBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.pRedBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.pRedBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.pRedBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.pRedBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.pRedBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.pRedBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.pRedBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.pRedBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.pRedBC?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.pRedBC?._text ||
											0
									),
									itembcicmsst: parseFloat(
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.vBCST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.vBCST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.vBCST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.vBCST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.vBCST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.vBCST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.vBCST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.vBCST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.vBCST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.vBCST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.vBCST?._text ||
											0
									),
									itempicmsst: parseFloat(
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.pICMSST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.pICMSST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.pICMSST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.pICMSST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.pICMSST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.pICMSST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.pICMSST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.pICMSST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.pICMSST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.pICMSST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.pICMSST?._text ||
											0
									),
									itemvicmsst: parseFloat(
										nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS00?.vICMSST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS10?.vICMSST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS20?.vICMSST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS30?.vICMSST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS40?.vICMSST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS41?.vICMSST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS50?.vICMSST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS51?.vICMSST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS60?.vICMSST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS70?.vICMSST?._text ||
											nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS.ICMS90?.vICMSST?._text ||
											0
									),
									itemcenqipi: nota.nfeProc.NFe.infNFe.det[i].imposto.IPI?.cEnq?._text || '',
									itemcstipi: nota.nfeProc.NFe.infNFe.det[i].imposto.IPI?.IPITrib?.CST?._text || '',
									itembcipi: parseFloat(nota.nfeProc.NFe.infNFe.det[i].imposto.IPI?.IPITrib?.vBC?._text || 0),
									itemvipi: parseFloat(nota.nfeProc.NFe.infNFe.det[i].imposto.IPI?.IPITrib?.vIPI?._text || 0),
									itemipi: parseFloat(nota.nfeProc.NFe.infNFe.det[i].imposto.IPI?.IPITrib?.pIPI?._text || 0),
									itemcstpis: nota.nfeProc.NFe.infNFe.det[i].imposto.PIS?.PISAliq?.CST?._text || '',
									itembcpis: parseFloat(nota.nfeProc.NFe.infNFe.det[i].imposto.PIS?.PISAliq?.vBC?._text || 0),
									itemppis: parseFloat(nota.nfeProc.NFe.infNFe.det[i].imposto.PIS?.PISAliq?.pPIS?._text || 0),
									itemvpis: parseFloat(nota.nfeProc.NFe.infNFe.det[i].imposto.PIS?.PISAliq?.vPIS?._text || 0),
									itemcstcofins: nota.nfeProc.NFe.infNFe.det[i].imposto.COFINS?.COFINSAliq?.CST?._text || '',
									itembccofins: parseFloat(nota.nfeProc.NFe.infNFe.det[i].imposto.COFINS?.COFINSAliq?.vBC?._text || 0),
									itempcofins: parseFloat(nota.nfeProc.NFe.infNFe.det[i].imposto.COFINS?.COFINSAliq?.pCOFINS?._text || 0),
									itemvcofins: parseFloat(nota.nfeProc.NFe.infNFe.det[i].imposto.COFINS?.COFINSAliq?.vCOFINS?._text || 0),
									iteminfadprod: nota.nfeProc.NFe.infNFe.det[i].infAdProd?._text || '',
									id_nf_emissor: null,
									id_filial_emissor: null,
									id_produto_emissor: null,
									itemindescala: nota.nfeProc.NFe.infNFe.det[i].prod.indEscala?._text || '',
									itemcnpjfab: nota.nfeProc.NFe.infNFe.det[i].prod?.CNPJFab?._text || '',
									itemcbenef: nota.nfeProc.NFe.infNFe.det[i].prod?.cBenef?._text || '',
									itempipi: nota.nfeProc.NFe.infNFe.det[i].imposto.IPI?.IPITrib?.pIPI?._text || 0,
									pfcp: nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS?.ICMS00?.pFCP?._text || 0,
									vfcp: nota.nfeProc.NFe.infNFe.det[i].imposto.ICMS?.ICMS00?.vFCP?._text || 0,
								};
								produtos.push(prod);
							}
						}

						try {
							//console.log(nota.nfeProc.NFe.infNFe.infAdic?.infAdFisco)
							const dadosNFe = {
								// DadosNota
								id_filial: id_filial,
								nfestatus: nota.nfeProc.protNFe.infProt.cStat?._text || '',
								nfechave: chavenfe,
								nfecuf: nota.nfeProc.NFe.infNFe?.ide?.cUF?._text || '',
								nfenatoper: nota.nfeProc.NFe.infNFe.ide.natOp?._text || '',
								nfecfop: nota.nfeProc.NFe.infNFe.ide.CFOP?._text || '',
								nfemod: nota.nfeProc.NFe.infNFe.ide.mod?._text || '',
								nfeserie: nota.nfeProc.NFe.infNFe.ide.serie?._text || '',
								nfennf: nota.nfeProc.NFe.infNFe.ide.nNF?._text || '',
								nfedtemissao: nota.nfeProc.NFe.infNFe.ide.dhEmi?._text || '',
								nfedtsaida: nota.nfeProc.NFe.infNFe.ide.dhSaiEnt?._text || '',
								nfentpnf: nota.nfeProc.NFe.infNFe.ide.tpNF?._text || '',
								nfeiddest: nota.nfeProc.NFe.infNFe.ide.iDest?._text || '',
								nfecmunfg: nota.nfeProc.NFe.infNFe.ide.cMunFG?._text || '',
								tpimp: nota.nfeProc.NFe.infNFe.ide.tpImp?._text || '',
								nfetpemis: nota.nfeProc.NFe.infNFe.ide.tpEmis?._text || '',
								nfecdv: nota.nfeProc.NFe.infNFe.ide.cDV?._text || '',
								nfetpamb: nota.nfeProc.NFe.infNFe.ide.tpAmb?._text || '',
								nfefinnfe: nota.nfeProc.NFe.infNFe.ide.finNFe?._text || '',
								nfeindfinal: nota.nfeProc.NFe.infNFe.ide.indFinal?._text || '',
								nfeindpres: nota.nfeProc.NFe.infNFe.ide.indPres?._text || '',
								nfeprocemi: nota.nfeProc.NFe.infNFe.ide.procEmi?._text || '',
								nfeverproc: nota.nfeProc.NFe.infNFe.ide.verProc?._text || '',
								nfedhconting: nota.nfeProc.NFe.infNFe.ide.dhCont?._text || '',
								nfejustconting: nota.nfeProc.NFe.infNFe.ide.xJust?._text || '',

								// DadosEmitente
								nfeemitcnpj: nota.nfeProc.NFe.infNFe.emit.CNPJ?._text || '',
								nfeemitcpf: nota.nfeProc.NFe.infNFe.emit.CPF?._text || '',
								nfeemitnome: nota.nfeProc.NFe.infNFe.emit.xNome?._text || '',
								nfeemitfantasia: nota.nfeProc.NFe.infNFe.emit.xFant?._text || '',
								nfeemitie: nota.nfeProc.NFe.infNFe.emit.IE?._text || '',
								nfeemitiest: nota.nfeProc.NFe.infNFe.emit.IEST?._text || '',
								nfeemitcrt: nota.nfeProc.NFe.infNFe.emit.CRT?._text || '',

								// EnderecoEmitente
								nfeemitlograd: nota.nfeProc.NFe.infNFe.emit.enderEmit.xLgr?._text || '',
								nfeemitnumero: nota.nfeProc.NFe.infNFe.emit.enderEmit.nro?._text || '',
								nfeemitcompl: nota.nfeProc.NFe.infNFe.emit.enderEmit.xCpl?._text || '',
								nfeemitbairro: nota.nfeProc.NFe.infNFe.emit.enderEmit.xBairro?._text || '',
								nfeemitcmun: nota.nfeProc.NFe.infNFe.emit.enderEmit.cMun?._text || '',
								nfeemitmun: nota.nfeProc.NFe.infNFe.emit.enderEmit.xMun?._text || '',
								nfeemituf: nota.nfeProc.NFe.infNFe.emit.enderEmit.UF?._text || '',
								nfeemitcep: nota.nfeProc.NFe.infNFe.emit.enderEmit.CEP?._text || '',
								nfeemitcpais: nota.nfeProc.NFe.infNFe.emit.enderEmit.cPais?._text || '',
								nfeemitpais: nota.nfeProc.NFe.infNFe.emit.enderEmit.xPais?._text || '',
								nfeemitfone: nota.nfeProc.NFe.infNFe.emit.enderEmit.fone?._text || '',

								// DadosDestinatario
								nfedestcnpj: nota.nfeProc.NFe.infNFe.dest.CNPJ?._text || '',
								nfedestcpf: nota.nfeProc.NFe.infNFe.dest.CPF?._text || '',
								nfedestnome: nota.nfeProc.NFe.infNFe.dest.xNome?._text || '',
								nfedestindie: nota.nfeProc.NFe.infNFe.dest.indIEDest?._text || '',
								nfedestie: nota.nfeProc.NFe.infNFe.dest.IE?._text || '',
								nfedestisuf: nota.nfeProc.NFe.infNFe.dest.ISUF?._text || '',
								nfedestim: nota.nfeProc.NFe.infNFe.dest.IM?._text || '',
								nfedestemail: nota.nfeProc.NFe.infNFe.dest.email?._text || '',

								// EnderecoDestinatario
								nfedestlograd: nota.nfeProc.NFe.infNFe.dest.enderDest.xLgr?._text || '',
								nfedestnumero: nota.nfeProc.NFe.infNFe.dest.enderDest.nro?._text || '',
								nfedestcompl: nota.nfeProc.NFe.infNFe.dest.enderDest.xCpl?._text || '',
								nfedestbairro: nota.nfeProc.NFe.infNFe.dest.enderDest.xBairro?._text || '',
								nfedestcmun: nota.nfeProc.NFe.infNFe.dest.enderDest.cMun?._text || '',
								nfedestmun: nota.nfeProc.NFe.infNFe.dest.enderDest.xMun?._text || '',
								nfedestuf: nota.nfeProc.NFe.infNFe.dest.enderDest.UF?._text || '',
								nfedestcep: nota.nfeProc.NFe.infNFe.dest.enderDest.CEP?._text || '',
								nfedestcpais: nota.nfeProc.NFe.infNFe.dest.enderDest.cPais?._text || '',
								nfedestpais: nota.nfeProc.NFe.infNFe.dest.enderDest.xPais?._text || '',
								nfedestfone: nota.nfeProc.NFe.infNFe.dest.enderDest.fone?._text || '',

								//Tributos/Totais
								nfebcicms: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vBC?._text || 0),
								nfevicms: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vICMS?._text || 0),
								nfevicmsdeson: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vICMSDeson?._text || 0),
								nfebcicmsst: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vBCST?._text || 0),
								nfevicmsst: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vST?._text || 0),
								nfevfcp: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vFCP?._text || 0),
								nfevfcpst: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vFCPST?._text || 0),
								nfevfcpstret: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vFCPSTRet?._text || 0),
								nfevtotprod: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vProd?._text || 0),
								nfevfrete: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vFrete?._text || 0),
								nfevseguro: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vSeg?._text || 0),
								nfevdesconto: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vDesc?._text || 0),
								nfevii: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vII?._text || 0),
								nfevipi: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vIPI?._text || 0),
								nfevipidevol: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vIPIDevol?._text || 0),
								nfevpis: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vPIS?._text || 0),
								nfevcofins: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vCOFINS?._text || 0),
								nfevoutros: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vOutro?._text || 0),
								nfevtotnota: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vNF?._text || 0),
								nfevtottrib: parseFloat(nota.nfeProc.NFe.infNFe.total.ICMSTot.vTotTrib?._text || 0),

								//Transporte
								transpmodfrete: nota.nfeProc.NFe.infNFe.transp.transporta?.modFrete?._text || '',
								transpcnpj: nota.nfeProc.NFe.infNFe.transp.transporta?.CNPJ?._text || '',
								transpcpf: nota.nfeProc.NFe.infNFe.transp.transporta?.CPF?._text || '',
								transpnome: nota.nfeProc.NFe.infNFe.transp.transporta?.xNome?._text || '',
								transpie: nota.nfeProc.NFe.infNFe.transp.transporta?.IE?._text || '',
								transpend: nota.nfeProc.NFe.infNFe.transp.transporta?.xEnder?._text || '',
								transpmun: nota.nfeProc.NFe.infNFe.transp.transporta?.xMun?._text || '',
								transpuf: nota.nfeProc.NFe.infNFe.transp.transporta?.UF?._text || '',
								transpvolqtde: parseFloat(nota.nfeProc.NFe.infNFe.transp?.vol?.qVol?._text || 0),
								transpvolespecie: parseFloat(nota.nfeProc.NFe.infNFe.transp?.vol?.esp?._text || 0),
								transpvolpb: parseFloat(nota.nfeProc.NFe.infNFe.transp?.vol?.pesoB?._text || 0),
								transpvolpl: parseFloat(nota.nfeProc.NFe.infNFe.transp?.vol?.pesoL?._text || 0),
								transplacre: nota.nfeProc.NFe.infNFe.transp?.vol?.Lacres?._text || '',

								//Cobrança
								cobrindpag: nota.nfeProc.NFe.infNFe.cobr?.dup?.nDup?._text || '',
								cobrtpag: nota.nfeProc.NFe.infNFe.cobr?.pag?.detPag?.tPag?._text || '',
								cobrvpag: parseFloat(nota.nfeProc.NFe.infNFe.cobr?.dup?.vDup?._text || ''),

								//Informações Adicionais
								nfeinfadfisco: nota.nfeProc.NFe.infNFe.infAdic?.infAdFisco?._text || '',
								nfeinfcpl: nota.nfeProc.NFe.infNFe.infAdic?.infCpl?._text || '',
								protocol_nprot: nota.nfeProc.protNFe.infProt.nProt?._text || '',
								protocol_nrecbto: nota.nfeProc.protNFe.infProt.nRec?._text || '',
								protocol_dhrecbto: nota.nfeProc.protNFe.infProt.dhRecbto?._text || '',
								protocol_digval: nota.nfeProc.protNFe.infProt.digVal?._text || '',
								nfecstat: nota.nfeProc.protNFe.infProt.cStat?._text || '',
								nfexmotivo: nota.nfeProc.protNFe.infProt.xMotivo?._text || '',
								frasehomologacao: nota.nfeProc.protNFe.infProt.xMotivo?._text || '',
								frasecontingencia: nota.nfeProc.protNFe.infProt.xJust?._text || '',
								/*VALIADR COM FRANKLIN */
								protcanc: nota.nfeProc.protNFe.infProt.cStat?._text || '',
								responsavelcancel: nota.nfeProc.protNFe.infProt.xMotivo?._text || '',
								tpevento: nota.nfeProc.retEvento?.infEvento?.tpEvento?._text || '',
								xevento: nota.nfeProc.retEvento?.infEvento?.xEvento?._text || '',
								protocol_dtcancel: nota.nfeProc.retEvento?.infEvento?.dhRegEvento?._text || '',
								nfecce: nota.nfeProc.retEvento?.infEvento?.cStat?._text || '',
								protcce: nota.nfeProc.retEvento?.infEvento?.xMotivo?._text || '',
								protocol_dtcce: nota.nfeProc.retEvento?.infEvento?.dhRegEvento?._text || '',
								responsavelcce: nota.nfeProc.retEvento?.infEvento?.xJust?._text || '',
								responsavel: nota.nfeProc.retEvento?.infEvento?.xNome?._text || '',
								tipo_geracao: '',
								confirmado_envio_user: '',
								nfedtadicao: '',
								id_nf_emissor: null,
								// id_depositante: selecionaDepostitante || '',
								nfeenvioemail: '',
								nfedtenvioemail: '',
								nfeprocessofalta: null,
								id_entidade: null,
								nfeplaca: '',
								nfeplacauf: '',
								produtos: produtos,
							};
							// postXmlNFe(dadosNFe);

							todasNotasFiscais.push(dadosNFe);
						} catch (error: any) {
							toast.error(error.message || error, {
								autoClose: 900,
							});
						}
					}

					if (i === arquivos.length - 1) {
						const notas = {
							todasNotasFiscais,
							xmlConteudo,
						};
						postXmlNFe(notas);
					}
				};
				reader.readAsText(file);
			}
		}
	};

	const postXmlNFe = (nota: any) => {
		const qtdNotas = nota.todasNotasFiscais.length;
		const ListaNotas_sucesso: any = [];
		const ListaNotas_erro: any = [];
		for (let i = 0; i < qtdNotas; i++) {
			if (nota.todasNotasFiscais[i].nfechave !== '') {
				setShowCarregando(true);

				const dados = {
					nota: nota.todasNotasFiscais[i],
					xmlText: nota.xmlConteudo,
				};
				axios
					.post(`${process.env.REACT_APP_API_BASE_URL}/import/nfe/inserirxml/nota?usuarioLogado=${usuarioLogado}`, dados, {
						headers: {
							authorization: token,
						},
					})
					.then(function (resposta) {
						ListaNotas_sucesso.push(resposta.data.nf);

						if (i == qtdNotas - 1) {
							setListaNotasNaoImportadas(ListaNotas_erro);
							setListaNotasImportadas(ListaNotas_sucesso);
							setShowCarregando(false);
							setShowModalListaNF(true);
							CarregarNFsFilial(id_filial);
						}
					})
					.catch(function (erro) {
						if (erro.response.data.nf) {
							//console.log([...ListaNotasNaoImportadas, erro.response.data.nf])
							ListaNotas_erro.push(erro.response.data.nf);
						}

						if (i == qtdNotas - 1) {
							setListaNotasNaoImportadas(ListaNotas_erro);
							setListaNotasImportadas(ListaNotas_sucesso);
							setShowCarregando(false);
							setShowModalListaNF(true);
							CarregarNFsFilial(id_filial);
						}
					});
			}
		}
	};
	//#region IMPORTAÇÃO NF -- FIM
	const [ListaDeNfs, setListaDeNfs] = useState([]);
	function CarregarNFsFilial(id_filial: string) {
		setShowCarregando(true);
		axios
			.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/lista/nf/${id_filial}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setShowCarregando(false);
				setListaDeNfs(resposta.data.nfs);
			})
			.catch(function (erro) {
				setShowCarregando(false);
				toast.error(erro.response.data.message || erro.message, {
					autoClose: 900,
				});
			});
	}

	const [itensPorPagina, setItensPorPagina] = useState(10);

	const colunasListaNfe: ITabela[] = [
		{
			titulo: 'ID',
			acesso: 'id',
		},
		{
			titulo: 'Id depositante',
			acesso: 'id_cliente',
		},
		{
			titulo: 'Processo',
			acesso: 'id_processo',
		},
		{
			titulo: 'N° Nfe',
			acesso: 'nf',
		},
		{
			titulo: 'Série',
			acesso: 'serie',
		},
		{
			titulo: 'CNPJ Emit.',
			acesso: 'cnpj_emit',
		},
		{
			titulo: 'Emitente',
			acesso: 'razao_emit',
		},
		{
			titulo: 'CNPJ Dest.',
			acesso: 'cnpj_dest',
		},
		{
			titulo: 'Dest',
			acesso: 'razao_dest',
		},
		{
			titulo: 'Dt.Emissão',
			acesso: 'dt_emissao_formatada',
		},
		{
			titulo: 'Usuário',
			acesso: 'usuario',
		},
	];

	useEffect(() => {
		CarregarNFsFilial(id_filial);
	}, []);

	const [showModalConfirma, setShowModalConfirma] = useState(false);
	const [NotaAExcluir, setNotaAExcluir] = useState<any>('');

	function DeletarNf(id_filialNota: string, chave: string) {
		setShowModalConfirma(false);
		setShowCarregando(true);
		axios
			.delete(`${process.env.REACT_APP_API_BASE_URL}/delete/nota/${chave}/${id_filialNota}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setShowCarregando(false);
				toast.success(resposta.data.message, {
					autoClose: 900,
				});
				CarregarNFsFilial(id_filial);
			})
			.catch(function (erro) {
				toast.error(erro.response.data.message || erro.message, {
					autoClose: 900,
				});
				setShowCarregando(false);
				CarregarNFsFilial(id_filial);
			});
	}

	return (
		<div className="main">
			<div className="tituloDaPagina mt-2 ms-1">
				<h4>Importação de notas fiscais</h4>
			</div>
			<div className="card mt-2 pb-3">
				<div className="card-body">
					<div className="container p-0 m-0">
						{permissaoImportar && (
							<div className="row">
								<div className="col-sm col-md col-lg">
									<label htmlFor="impNFS" className="btn btn-primary">
										<i className="bi bi-download"></i> Importar NF's
									</label>
									<input
										id="impNFS"
										type="file"
										style={{ display: 'none' }}
										accept=".xml"
										multiple
										onChange={(e) => importarXmlConverterJson(e.target.files)}
									/>
								</div>
							</div>
						)}
					</div>

					<ClickableTable
						coluna={colunasListaNfe}
						itemsPerPage={itensPorPagina}
						data={ListaDeNfs}
						usaRowClick={permissaoDetalhar}
						onRowClick={function (nota: any) {
							if (permissaoDetalhar) navigate(`/nf/detalhe/visualizar/${nota.id_filial}?chave=${nota.chave_acesso}`);
						}}
						acaoEditar={function () {}}
						acaoExcluir={function (nota: any) {
							setShowModalConfirma(true);
							setNotaAExcluir(nota);
						}}
						acaoVisualizar={function () {}}
						usaAcoes={permissaoExcluir}
						usaEditar={false}
						usaExcluir={permissaoExcluir}
						usaVisualizar={false}
						acessopesquisa="nf"
						labelpesquisa="Procurar por NF."
					/>
					<div className="container">
						<div className="row">
							<div className="col-lg-2 m-auto text-center">
								<label>Itens por Página</label>
							</div>
							<div className="w-100"></div>
							<div className="col-lg-1 m-auto">
								<select
									value={itensPorPagina}
									onChange={function (e: any) {
										setItensPorPagina(e.target.value);
									}}
									className="form-select form-select-sm"
									aria-label=".form-select-sm example"
								>
									<option value={10}>10</option>
									<option value={20}>20</option>
									<option value={30}>30</option>
								</select>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ModalCarregando mostrar={showCarregando} mensagem="Carregando..." />
			<Modal
				centered
				size="lg"
				show={showModalListaNF}
				onHide={function () {
					setListaNotasImportadas([]);
					setListaNotasNaoImportadas([]);
					setShowModalListaNF(false);
				}}
			>
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<div className="container">
						<div className="row">
							<div className="col-sm col-md-12 col-lg-12">
								<h4 className="text-center bg-primary text-white p-1">Importadas com sucesso.</h4>
								<ClickableTable
									coluna={[
										{
											titulo: 'NF',
											acesso: 'nf',
										},
										{
											titulo: 'Mensagem',
											acesso: 'mensagem',
										},
									]}
									itemsPerPage={10}
									data={ListaNotasImportadas}
									onRowClick={function () {}}
									acaoEditar={function () {}}
									acaoExcluir={function () {}}
									acaoVisualizar={function () {}}
									usaAcoes={false}
									usaEditar={false}
									usaExcluir={false}
									usaVisualizar={false}
									acessopesquisa="nf"
									labelpesquisa="Procurar por NF."
								/>
							</div>
							<div className="col-sm col-md-12 col-lg-12">
								<h4 className="text-center bg-danger text-white p-1">Notas não importadas ou com erro ao importar.</h4>
								<ClickableTable
									coluna={[
										{
											titulo: 'NF',
											acesso: 'nf',
										},
										{
											titulo: 'Mensagem',
											acesso: 'mensagem',
										},
									]}
									itemsPerPage={10}
									data={ListaNotasNaoImportadas}
									onRowClick={function () {}}
									acaoEditar={function () {}}
									acaoExcluir={function () {}}
									acaoVisualizar={function () {}}
									usaAcoes={false}
									usaEditar={false}
									usaExcluir={false}
									usaVisualizar={false}
									acessopesquisa="nf"
									labelpesquisa="Procurar por NF."
								/>
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<div className="container">
						<div className="row">
							<div className="col-sm col-md col-lg text-end">
								<BotaoCustomizado
									type="button"
									className="btn btn-light text-danger bg-white border-0"
									descricaoBotao="Fechar"
									icone={mdiKeyboardReturn}
									onclick={() => {
										setListaNotasImportadas([]);
										setListaNotasNaoImportadas([]);
										setShowModalListaNF(false);

										const importacaoDeNota = document.querySelector('#impNFS') as HTMLInputElement;
										importacaoDeNota.value = '';
									}}
								/>
							</div>
						</div>
					</div>
				</Modal.Footer>
			</Modal>
			<ModalConfirmacao
				mostrar={showModalConfirma}
				Acao1={function () {
					setShowModalConfirma(false);
					setNotaAExcluir('');
				}}
				Acao2={function () {
					DeletarNf(NotaAExcluir.id_filial, NotaAExcluir.chave_acesso);
				}}
				msgAcao1="Cancelar"
				msgAcao2="Confirmar"
				msgPrincipal={`Confirmar a exclusão da NF ${NotaAExcluir.nf} ?`}
			/>
		</div>
	);
}
