import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import avatar3 from '../assets/img/avatar-3.png';
import avatar_padrao from '../assets/img/avatar-default.jpg'
import armazem from '../assets/img/armazem.png';
import { Api } from '../services/api';
import { Modal, Spinner, Button } from 'react-bootstrap';
import Inputs from './componentes/formulario/Inputs';
import { mdiCheckCircle, mdiCloseCircle } from '@mdi/js';
import { BsExclamationCircleFill } from 'react-icons/bs';
import avatarPadrao from "../assets/img/avatarPadrao.png"
import ModalConfirmacao from './ModalDeConfirmacao/ModalConfirmacao';
import ModalCarregando from './ModalCarregando/ModalCarregando';

import { toast, ToastContainer } from 'react-toastify';

import Icon from '@mdi/react';
const NavBar = () => {
	const navigate = useNavigate();
	const _usuarioLogado = sessionStorage.getItem('IsUserFKL');
	const token = sessionStorage.getItem('IsFKLTOKEN')!;
	const idusuario = sessionStorage.getItem('IsIDUserFKL');
	const idfilial = sessionStorage.getItem('IsIDFilialPadraoFKL')
	const nomePerfil = sessionStorage.getItem('NomePerfil')
	const avatar = sessionStorage.getItem('Avatar')
	// const [avatar, setAvatar] = useState('');

	//#region Requisição de dados na api - FILIAL
	const [ListaDeFiliais, setListaDeFiliais] = useState([]);
	const [dadosfilialPadrao, setDadosFilialPadrao] = useState<any>([]);
	const carregarFilial = async () => {


		setShowCarregando(true)
		axios.get(Api + `/usuario/carregar/filial/${idusuario}`, {
			headers: {
				authorization: token
			}
		}).then(function (response) {
			if (response.data.grupoDeFiliais.length > 0) {

				setShowCarregando(false)
				setDadosFilialPadrao(response.data.grupoDeFiliais.find(function(filial: any){

					return filial.padrao == 1
				}));
				setListaDeFiliais(response.data.grupoDeFiliais)
			}
			else {

				alert("Nenhuma Filial vinculada.")
			}
		}).catch(function (erro) {

			if (erro.response.status == 403) {

				navigate("/login")
			}
		})
	};
	useEffect(() => {
		carregarFilial();
	}, [])


	//#region Modifica o status do Modal Carregando
	const [showCarregando, setShowCarregando] = useState(false);
	//#endregion    


	//alterar senha - inicio

	const [senhaAtual, setSenhaAtual] = useState<string>("") //grava e seta a senha nova do usuario
	const [modalAltSenha, setModalAltSenha] = useState<boolean>(false)//controle do modal ''show ''
	const [newSenha, setNewSenha] = useState<string>("")
	const [confirmarSenha, setConfirmarSenha] = useState<string>("")
	const [ModalConfirmarNewSenha, setModalConfrimarNewSenha] = useState<boolean>(false)

	function abrirModalAltSenha() {
		setModalAltSenha(true)
	}

	function fecharModalAltSenha() {
		setModalAltSenha(false)
		setConfirmarSenha("")
		setNewSenha("")
		setSenhaAtual("")
	}

	function MostrarModalConfirmarNewSenha() {//abre o modal para confirmar alteração de senha

		if (newSenha == "" || confirmarSenha == "" || senhaAtual == "") {
			toast.error("Preencha todos os campos", {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: true,
				progress: undefined,
				theme: "dark",
			})
		}
		else if (newSenha == confirmarSenha) {
			setModalConfrimarNewSenha(true)
			setModalAltSenha(false)
		}
		else {
			toast.error("As senhas não são iguais.", {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: true,
				progress: undefined,
				theme: "dark",
			})
		}
	}

	function FecharModalConfirmarNewSenha() {//fecha o modal para confirmar alteração de senha
		setModalConfrimarNewSenha(false)
		setModalAltSenha(false)
		setConfirmarSenha("")
		setNewSenha("")
		setSenhaAtual("")
	}

	async function AtualizarSenhaUser() {
		setShowCarregando(true)
		setModalConfrimarNewSenha(false)

		const senhas = {
			idUser: idusuario,
			senhaAtualUser: senhaAtual,
			newSenha: newSenha,
			cpf: sessionStorage.getItem("CPFusuario")
		}

		axios.put(`${process.env.REACT_APP_API_BASE_URL}/alterar/senha/user`, senhas, {
			headers: {
				authorization: token
			}
		}).then(function (resposta) {


			setShowCarregando(false)
			toast.success(resposta.data.message, {
				autoClose: 900
			})
		}).catch(function (erro) {

			setShowCarregando(false)
			toast.error(erro.response.data.message || erro.message, {
				autoClose: 900
			})
		})

		setConfirmarSenha("")
		setNewSenha("")
		setSenhaAtual("")
		setShowCarregando(false)
	}


	function VerificaValidadeToken(token: any) {


		axios.get(`${process.env.REACT_APP_API_BASE_URL}/consulta/validade/jwt`, {
			headers: {
				authorization: token
			}
		}).then(function (resposta) {


		}).catch(function (erro) {

			alert(erro.response.data.message || erro.message)
			navigate("/login")
		})
	}

	useEffect(function () {

		setTimeout(() => {

			VerificaValidadeToken(sessionStorage.getItem("IsFKLTOKEN"))
		}, 3600000);
		//a cada uma hora
	}, [])

	//alterar senha - fim

	function AlterarFilial(filial: any) {

		if (filial.id_filial != idfilial) {

			const dados = {
				idAtividadeAtual: sessionStorage.getItem("AtividadeCriada"),
				idFilialNovaAtividade: filial.id_filial,
				idUsuario: idusuario
			}
			setShowCarregando(true)
			axios.post(`${process.env.REACT_APP_API_BASE_URL}/sinc/nova/ativ/filial`, dados, {
				headers: {
					authorization: token
				}
			}).then(function (resposta) {

				setDadosFilialPadrao(filial)
				sessionStorage.setItem('IsIDFilialPadraoFKL', filial.id_filial)
				sessionStorage.setItem("AtividadeCriada", resposta.data.atividade)
				navigate("/")
				setShowCarregando(false)
			}).catch(function (erro) {

				setShowCarregando(false)
				toast.error(erro.response.data.message, {
					autoClose: 900
				})
			})
		}
	}

	function LogoutAtividade() {

		const dados = {
			idAtividadeAtual: sessionStorage.getItem("AtividadeCriada")
		}
		setShowCarregando(true)
		axios.put(`${process.env.REACT_APP_API_BASE_URL}/encerrar/atividade`, dados, {
			headers: {
				authorization: token
			}
		}).then(function (resposta) {


			navigate("/Login")
			setShowCarregando(false)
			sessionStorage.clear()
		}).catch(function (erro) {

			setShowCarregando(false)
			toast.error(erro.response.data.message, {
				autoClose: 900
			})
		})
	}





	useEffect(function () {

		window.addEventListener('beforeunload', LogoutAtividade)
		return () => {
			window.removeEventListener('beforeunload', LogoutAtividade);
		};
	}, [])

	return (
		<>
			<div className="navbar-custom">
				<ul className="list-unstyled topbar-menu float-end mb-0">
					<li className="dropdown notification-list topbar-dropdown">

						{
							<a
								className="nav-link dropdown-toggle arrow-none"

								data-bs-toggle="dropdown"
								href="#"
								role="button"
								aria-haspopup="false"
								aria-expanded="false"
							>
								<span className="account-user-avatar">
									<img src={armazem} style={{ width: '30px', height: '30px', marginTop: '-5px' }} alt="filial" className="rounded-action-icon" />
								</span>
								<label className="filial text-dark">&nbsp;{dadosfilialPadrao.id_filial} - {dadosfilialPadrao.fantasia}</label>
								<i className="mdi mdi-chevron-down d-none d-sm-inline-block align-middle" />
							</a>
						}

						<div className="dropdown-menu dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu profile-dropdown">
							{
								ListaDeFiliais.map(function (dados: any, index: number) {
									return (
										<a key={index} className="dropdown-item notify-item" onClick={function () {
											AlterarFilial(dados)
										}}>
											<img src={armazem} style={{ width: '30px', height: '30px', marginTop: '-5px' }} alt="filial" className="rounded-action-icon" />
											<label className='form-label' >&nbsp;{dados.id_filial} - {dados.fantasia}</label>
										</a>
									)
								})
							}

						</div>
					</li>

					<li className="dropdown notification-list">
						<a
							className="nav-link dropdown-toggle nav-user arrow-none me-0"
							data-bs-toggle="dropdown"
							href="#"
							role="button"
							aria-haspopup="false"
							aria-expanded="false"
						>
							<span className="account-user-avatar">
								<img src={avatar ? avatar : avatarPadrao} alt="user" className="rounded-circle" />
							</span>
							<span>
								<span className="account-user-name" style={{ color: 'black' }}>{_usuarioLogado}</span>
								<span className="account-position">{nomePerfil}</span>
							</span>
						</a>
						<div className="dropdown-menu dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu profile-dropdown">
							<div className=" dropdown-header noti-title">
								<h6 className="text-overflow m-0">Bem vindo !</h6>
							</div>
							<a href="#" className="dropdown-item notify-item">
								<i className="mdi mdi-account-circle me-1"></i>
								<span>Minha conta</span>
							</a>
							<a style={{ cursor: 'pointer' }} className="dropdown-item notify-item" onClick={abrirModalAltSenha}>
								<i className="mdi mdi-account-key me-1"></i>
								<span>Alterar Senha</span>
							</a>
							<a href="" className="dropdown-item notify-item">
								<i className="mdi mdi-lifebuoy me-1"></i>
								<span>Suporte</span>
							</a>
							<a style={{ cursor: 'pointer' }} className="dropdown-item notify-item" onClick={LogoutAtividade}>
								<i className="mdi mdi-logout me-1"></i>
								<span>Logout</span>
							</a>
						</div>
					</li>
				</ul>


				<button className="button-menu-mobile open-left">
					<i className="mdi mdi-menu"></i>
				</button>
			</div>
			<ToastContainer newestOnTop />
			{/*MODAL CARREGANDO */}
			<Modal
				show={showCarregando}
				//onHide={fecharModalClassificaAR}
				backdrop="static"
				size="sm"
				//dialogClassName="modal-90w"
				keyboard={false}
				centered

			>
				<div className="row" style={{ textAlign: 'center' }}>
					<div className="col-sm" >
						<Spinner
							animation="border"
							role="status"
							variant="dark"
							size="sm"
						/>
						<Spinner
							animation="border"
							role="status"
							variant="dark"

						/>

					</div>
				</div>
				<div className="row">
					<div className="col-sm" style={{ textAlign: 'center', color: 'black' }}>
						<label className="form-label" htmlFor="">Carregando...</label>
					</div>
				</div>

			</Modal>



			{/*Modal par alterar senha*/}
			<Modal show={modalAltSenha} backdrop="static" size="xl" keyboard={false} centered>
				<Modal.Header>
					<Modal.Title>Alterar senha</Modal.Title>
				</Modal.Header>
				<Modal.Body className="d-flex flex-column align-items-center p-3 gap-2">


					<div className="col-sm-2 form-floating" style={{ width: "25%" }}>
						<Inputs label="Senha Atual" type="password" classNameInput="form-control bordasInferiorInput text-black"
							classNameLabel="" value={senhaAtual} onchange={setSenhaAtual} />
					</div>

					<div className="col-sm-2 form-floating" style={{ width: "25%" }}>
						<Inputs label="Nova Senha" type="password" classNameInput="form-control bordasInferiorInput text-black"
							classNameLabel="" value={newSenha} onchange={setNewSenha} />
					</div>

					<div className="col-sm-2 form-floating" style={{ width: "25%" }}>
						<Inputs label="Confirmar nova senha" type="password" classNameInput="form-control bordasInferiorInput text-black"
							classNameLabel="" value={confirmarSenha} onchange={setConfirmarSenha} />
					</div>



					<div className="d-flex my-2 gap-1 justify-content-between">

						<button onClick={MostrarModalConfirmarNewSenha} className="btn btn-primary me-2">Alterar Senha</button>

						<button onClick={fecharModalAltSenha} className='btn btn-danger'>Cancelar</button>
					</div>



				</Modal.Body>
			</Modal>

			<ModalConfirmacao
				msgAcao1='Cancelar'
				msgAcao2='Confirmar'
				msgPrincipal='Confirmar alteração de senha ?'
				mostrar={ModalConfirmarNewSenha}
				Acao2={AtualizarSenhaUser}
				Acao1={FecharModalConfirmarNewSenha} />
		</>
	);
};

export { NavBar };
