const MovimentoSaidaNF = (): JSX.Element => {
	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<h4 className="page-title">Movimentação Saída NFS</h4>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<div className="card">
							<div className="card-body">
								<div className="row mb-2">
									<div className="col-3 ">
										<div className="input-group flex-nowrap">
											<span className="input-group-text">
												<b>DATA INICIO</b>
											</span>
											<input type="date" className="form-control" />
										</div>
									</div>
									<div className="col-3">
										<div className="input-group flex-nowrap">
											<span className="input-group-text">
												<b>DATA FIM</b>
											</span>
											<input type="date" className="form-control" />
										</div>
									</div>
									<div className="col-1">
										<button className="btn btn-secondary">
											<i className="mdi mdi-magnify"></i>
										</button>
									</div>
									<div className="col-5 d-flex justify-content-end">
										<button className="btn btn-success rounded">
											<span className="iconify" data-icon="foundation:page-export"></span>
											Exportar
										</button>
									</div>
								</div>
								<div className="table-responsive">
									<table className="table table-centered w-100 dt-responsive nowrap" id="endereco-datatable">
										<thead style={{ backgroundColor: "#1c4b9b", color: "white" }}>
											<tr>
												<th>Data Carga Prevista</th>
												<th>Data Programação</th>
												<th>Cliente</th>
												<th>Pedido</th>
												<th>WMS</th>
												<th>Cod Cliente</th>
												<th>Descrição</th>
												<th>Ações</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>16/10/2021</td>
												<td>16/10/2021 09:00</td>
												<td>Cliente 1</td>
												<td>1234|345|HRR</td>
												<td>37</td>
												<td>001119-3</td>
												<td>Queijo Mussarela Fatiado Polenghi 2.735KG</td>
												<td className="d-flex flex-nowrap table-action">
													<a data-bs-toggle="modal" data-bs-target="#modal-movimentacaoSaidaNF" className="action-icon">
														<i className="mdi mdi-eye"></i>
													</a>
													<a href="javascript:void(0);" className="action-icon">
														<i className="mdi mdi-delete"></i>
													</a>
												</td>
											</tr>
											<tr>
												<td>15/10/2021</td>
												<td>15/10/2021 00:00</td>
												<td>Cliente 2</td>
												<td>1234|34</td>
												<td>36</td>
												<td>001160-6</td>
												<td>HB ANGUS 110G (108UND) FSP</td>
												<td className="d-flex flex-nowrap table-action">
													<a
														data-bs-toggle="modal"
														data-bs-target="#modal-movimentacaoSaidaNF"
														href="javascript:void(0);"
														className="action-icon"
													>
														<i className="mdi mdi-eye"></i>
													</a>
													<a href="javascript:void(0);" className="action-icon">
														<i className="mdi mdi-delete"></i>
													</a>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="modal fade"
				id="modal-movimentacaoSaidaNF"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabIndex={-1}
				aria-labelledby="staticBackdropLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-full-width modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Detalhe da Nota Fiscal</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
						</div>
						<div className="modal-body">
							<div className="card">
								<div className="card-body">
									<div className="row mb-2">
										<div className="col-3">
											<div className="form-floating">

												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
												<label>Cod Cliente:</label>
											</div>
										</div>
										<div className="col-3">
											<div className="form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
												<label>Cod Cliente:</label>
											</div>
										</div>

									</div>
									<div className="row mb-2">
										<div className="col-2">
											<div className="form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="Unid" />
												<label>Unid:</label>
											</div>
										</div>

										<div className="col-2">
											<div className="form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="Qtd Fiscal" />
												<label>Qtd Fiscal:</label>
											</div>
										</div>

										<div className="col-2">
											<div className="form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="Vlr Unitário" />
												<label>Vlr Unitário:</label>
											</div>
										</div>

										<div className="col-2">
											<div className="form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="NF Entrada" />
												<label>NF Entrada:</label>
											</div>
										</div>

										<div className="col-4">
											<div className="form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="Chave Acesso" />
												<label>Chave Acesso:</label>
											</div>
										</div>

									</div>
									<div className="row mb-2">
										<div className="col-2">
											<div className="form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="Cxs" />
												<label>Cxs:</label>
											</div>
										</div>

										<div className="col-2">
											<div className="form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="Peso Bruto" />
												<label>Peso Bruto:</label>
											</div>
										</div>

										<div className="col-2">
											<div className="form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="Pelo Liq." />
												<label>Pelo Liq.:</label>
											</div>
										</div>

										<div className="col-2">
											<div className="form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="Proc Entrada" />
												<label>Proc Entrada:</label>
											</div>
										</div>

										<div className="col-2">
											<div className="form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="Proc Saída" />
												<label>Proc Saída:</label>
											</div>
										</div>

									</div>
									<div className="row mb-4">
										<div className="col-3">
											<div className="form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="Dt Baixa" />
												<label>Dt Baixa:</label>
											</div>
										</div>

										<div className="col-2">
											<div className="form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="OS" />
												<label>OS:</label>
											</div>
										</div>

									</div>
									<div className="row mb-2">
										<div className="col-3">
											<div className="form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="CNPJ Emitente" />
												<label>CNPJ Emitente:</label>
											</div>
										</div>

										<div className="col-3">
											<div className="form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="IE Emitente" />
												<label>IE Emitente:</label>
											</div>
										</div>

										<div className="col-4">
											<div className="form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="Razão Social Emitente" />
												<label>Razão Social Emitente:</label>
											</div>
										</div>

									</div>
									<div className="row mb-2">
										<div className="col-3">
											<div className="form-floating">
												<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="CNPJ Cliente" />
												<label>CNPJ Cliente:</label>
											</div>
										</div>

									</div>
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-danger" data-bs-dismiss="modal">
										Cancelar
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export { MovimentoSaidaNF };
