import { Backdrop, SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import React, { useState } from 'react';
import { Modal, Row, Alert } from 'react-bootstrap';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import ScaleIcon from '@mui/icons-material/Scale';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import StorageIcon from '@mui/icons-material/Storage';
import InventoryIcon from '@mui/icons-material/Inventory';
import ClickableTable from '../../../../../components/ClickableTable/ClickableTabl';
import { ITabela } from '../../../../../components/Table/TabelaInterface';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

// Interface para receber as informações por parâmetros
interface ModalQuebraTarefaProps {
    isOpen: boolean;
    fecharModal: () => void;
    token: string;
    idFilial: string;
    arrayDadosCheck: [];
}

// Criação do componente
const ModalQuebraTarefa: React.FC<ModalQuebraTarefaProps> = ({
    isOpen,
    fecharModal,
    token,
    idFilial,
    arrayDadosCheck
}) => {

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [data, setData] = useState([])
    const [alert, setAlert] = useState<boolean>(false);
    const [messageAlert, setMessageAlert] = useState<string>()
    const [disabledButtons, setDisabledButtons] = useState<string[]>([]);

    const [gerarOSPorTarefa, setGerarOSPorTarefa] = useState<boolean>(false)

    const usuarioLogado = sessionStorage.getItem("IsUserFKL")

    const navigate = useNavigate()

    const colunas: ITabela[] = [
        { titulo: "Id Tarefa", acesso: "id_tarefa" },
        { titulo: "Id Pedido", acesso: "id_pedido" },
        { titulo: "Dt. Carga", acesso: "data_carga" },
        { titulo: "Tipo de quebra", acesso: "tipo_quebra" },
        { titulo: "Usuário", acesso: "usuario" }
    ]

    const actions = [
        { icon: <DeviceThermostatIcon />, name: 'Temperatura', onClick: quebraPorTemperatura },
        { icon: <ProductionQuantityLimitsIcon />, name: 'Família', onClick: quebrarPorFamilia },
        { icon: <ScaleIcon />, name: 'Peso', onClick: quebrarPorPeso },
        { icon: <WarehouseIcon />, name: 'Armazém', onClick: function () { } },
        { icon: <StorageIcon />, name: 'Amz/Corredor', onClick: function () { } },
        { icon: <InventoryIcon />, name: 'Amz/Família', onClick: function () { } },
    ];

    function verificaBotoesAtivos() {
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/verifica/botoes/ativos`, {
            arrayDadosCheck,
            idFilial
        }, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {
            setAlert(false)
            setDisabledButtons([])
        }).catch(function (erro) {
            const status = erro.response.status
            if (status === 403 || status === 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                setAlert(true)
                setMessageAlert(erro.response.data.message)
                setDisabledButtons(['Armazém', 'Amz/Corredor', 'Amz/Família']);
            }
        })
    }

    function quebraPorTemperatura() {
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/quebrar/temperatura`, {
            idFilial,
            arrayDadosCheck,
            usuarioLogado,
            gerarOSPorTarefa
        }, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {
            toast.success(resposta.data.message)
            carregarGrid()
        }).catch(function (erro) {
            const status = erro.response.status
            console.log(erro.response.data.message)
            if (status === 403 || status === 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                toast.error(erro.response.data.message)
            }
        })
    }

    function quebrarPorFamilia() {
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/quebrar/familia`, {
            idFilial,
            arrayDadosCheck,
            usuarioLogado,
            gerarOSPorTarefa
        }, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {
            toast.success(resposta.data.message)
            carregarGrid()
        }).catch(function (erro) {
            const status = erro.response.status
            if (status === 403 || status === 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                toast.error(erro.response.data.message)
            }
        })
    }

    function quebrarPorPeso() {
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/quebrar/peso`, {
            idFilial,
            arrayDadosCheck,
            usuarioLogado,
            gerarOSPorTarefa
        }, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {
            toast.success(resposta.data.message)
            carregarGrid()
        }).catch(function (erro) {
            const status = erro.response.status
            if (status === 403 || status === 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                toast.error(erro.response.data.message)
            }
        })
    }

    function carregarGrid() {
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/carregar/tarefas/pedidos`, {
            idFilial,
            arrayDadosCheck
        }, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {
            setData(resposta.data.data)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status === 403 || status === 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                console.info(erro.response.data.message)
            }
        })
    }

    return (
        <>
            <Modal
                className='mt-2 rounded'
                show={isOpen}
                size='xl'
                onShow={() => {
                    setData([])
                    carregarGrid()
                    verificaBotoesAtivos()
                    setAlert(false)
                    setDisabledButtons([])
                }}
            >
                <Modal.Header className="bg-white" style={{ justifyContent: 'center' }}>
                    <Modal.Title style={{ flex: '1', textAlign: 'center', color: 'black' }}>{"Quebrar Pedido em Tarefas"}</Modal.Title>
                    <i
                        className="bi bi-x-circle"
                        style={{ fontSize: '2rem', cursor: 'pointer' }}
                        onClick={fecharModal}
                    ></i>
                </Modal.Header>
                <Modal.Body className="bg-white" style={{ overflow: 'hidden', position: 'relative' }}>

                    <div className='mb-3'>
                        <Backdrop open={open} />
                        <SpeedDial
                            ariaLabel="SpeedDial tooltip example"
                            sx={{ position: 'absolute', top: 30, right: 20 }}
                            icon={<SpeedDialIcon />}
                            onClose={handleClose}
                            onOpen={handleOpen}
                            open={open}
                            direction="left"
                        >
                            {actions.map((action, index) => {
                                const isDisabled = disabledButtons.includes(action.name);
                                return (
                                    <SpeedDialAction
                                        key={action.name}
                                        icon={action.icon}
                                        tooltipTitle={action.name}
                                        tooltipOpen
                                        sx={{
                                            '& .MuiSpeedDialAction-staticTooltipLabel': {
                                                bottom: 55,
                                                left: '50%',
                                                transform: 'translateX(-50%)',
                                            },
                                            opacity: isDisabled ? 0.5 : 1, // Ajuste de opacidade para indicar desabilitado
                                            pointerEvents: isDisabled ? 'none' : 'auto', // Desabilitar eventos de clique se desabilitado
                                            marginRight: index < actions.length - 1 ? 1 : 0,
                                            marginLeft: 15,
                                        }}
                                        onClick={() => {
                                            if (!isDisabled) {
                                                action.onClick();
                                                handleClose();
                                            }
                                        }}
                                    />
                                );
                            })}
                        </SpeedDial>
                    </div>

                    {/* <Row className='mt-2'></Row> */}

                    <Row className='mt-5 d-flex justify-content-end'>
                        <div className='col-sm-4 mt-3 form-check form-switch'>
                            <input
                                className="form-check-input ms-2"
                                type="checkbox"
                                checked={gerarOSPorTarefa}
                                id="gerarOSPorTarefa"
                                onChange={(e) => setGerarOSPorTarefa(e.target.checked)}
                                style={{
                                    width: '55px'
                                }}
                            />
                            <label className="ms-2 form-check-label mt-1 col" htmlFor="gerarOSPorTarefa">
                                Gerar 1 O.S para cada Tarefa
                            </label>
                        </div>
                    </Row>

                    <Row className='mt-2'>
                        <ClickableTable
                            coluna={colunas}
                            data={data}
                            onRowClick={function () { }}
                            itemsPerPage={10}
                            acaoEditar={function () { }}
                            acaoExcluir={function () { }}
                            acaoVisualizar={function () { }}
                            usaAcoes={false}
                            usaEditar={false}
                            usaExcluir={false}
                            usaVisualizar={false}
                        />
                    </Row>

                    <Row className='mt-2'>
                        {alert && (
                            <Alert variant="danger" onClose={() => { }} dismissible>
                                <Alert.Heading>{"Não é possível quebrar os pedidos por endereço"}</Alert.Heading>
                                <ul>
                                    {messageAlert}
                                </ul>
                            </Alert>
                        )}
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ModalQuebraTarefa;
