import axios from 'axios'
import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import ModalCarregando from '../../../../../components/ModalCarregando/ModalCarregando'
import { toast } from 'react-toastify'
import ClickableTable from '../../../../../components/ClickableTable/ClickableTabl'
import { ITabela } from '../../../../../components/Table/TabelaInterface'
import ModalConfirmacao from '../../../../../components/ModalDeConfirmacao/ModalConfirmacao'
interface modalVincUsuario {
    idTarefa: number | string,
    show: boolean,
    idPedido: number | string,
    fecharModal: () => void
}
export default function ModalVincUsuario({
    idTarefa,
    show,
    idPedido,
    fecharModal
}: modalVincUsuario) {
    const idFilial = sessionStorage.getItem("IsIDFilialPadraoFKL")!
    const token = sessionStorage.getItem('IsFKLTOKEN')!
    const usuarioLogado = sessionStorage.getItem("IsUserFKL")!
    const url = process.env.REACT_APP_API_BASE_URL
    const [CpfUsuario, setCpfUsuario] = useState("")
    function setValueCpfUsuario(e: React.ChangeEvent<HTMLInputElement>) {
        setCpfUsuario(e.target.value)
    }
    const [ShowModalCarregando, setShowModalCarregando] = useState(false)
    //carregar usuarios ainda nao vinculados as tarefas
    const [listaUsuariosSemVinc, setListaUsuariosSemVinc] = useState([])
    function carregarUsuariosSemVinc() {
        setShowModalCarregando(true)
        axios.get(url + `/carregar/lista/usuarios/sem/vinculo/tarefa/${idTarefa}/${idFilial}`, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {
            setListaUsuariosSemVinc(resposta.data.usuariosNaoVinculados)
            setShowModalCarregando(false)
        }).catch(function (erro) {
            toast.error(erro.response.data.message || erro.message)
            setShowModalCarregando(false)
        })
    }
    const [nomeUsuarioCarregado, setNomeUsuarioCarregado] = useState("")
    const [idUsuarioCarregado, setIdUsuarioCarregado] = useState("")
    const [mostrarBotaoVincular, setMostrarBotaoVincular] = useState(false)
    function carregarUsuario() {
        const usuarioEncontrado: any = listaUsuariosSemVinc.find(function (usuario: any) {
            return usuario.cpf == CpfUsuario
        })
        if (usuarioEncontrado) {
            setMostrarBotaoVincular(true)
            setNomeUsuarioCarregado(usuarioEncontrado.nome)
            setIdUsuarioCarregado(usuarioEncontrado.id_usuario)
        }
        else {
            setMostrarBotaoVincular(false)
        }
    }
    function vincularUsuarioTarefa() {
        setShowModalCarregando(true)
        axios.post(url + `/vincular/usuario/tarefa/pedido/${idTarefa}/${idPedido}/${idFilial}/${idUsuarioCarregado}?usuario=${usuarioLogado}`, null, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {
            toast.success(resposta.data.message)
            setCpfUsuario("")
            setIdUsuarioCarregado("")
            setNomeUsuarioCarregado("")
            setMostrarBotaoVincular(false)
            setShowModalCarregando(false)
            carregarUsuariosVinculados()
        }).catch(function (erro) {
            toast.error(erro.response.data.message || erro.message)
            setShowModalCarregando(false)
        })
    }
    const [limitarSeparacao, setLimitarSeparacao] = useState(false)
    function carregarCodicaoSeparacaoTarefa() {
        setShowModalCarregando(true)
        axios.get(url + `/carregar/condicao/separacao/${idFilial}/${idTarefa}`, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {
            setShowModalCarregando(false)
            setLimitarSeparacao(resposta.data.tarefa.limitarUserVinc == 1 ? true : false)
        }).catch(function (erro) {
            toast.error(erro.response.data.message || erro.message)
            setShowModalCarregando(false)
        })
    }
    function alterarLimitarSeparacao(e: React.ChangeEvent<HTMLInputElement>) {
        setLimitarSeparacao(e.target.checked)
        //alterar no banco - requisição
        setShowModalCarregando(true)
        const dados = {
            limitar: e.target.checked
        }
        axios.put(url + `/atualizar/separao/tarefa/condicao/${idTarefa}/${idFilial}`, dados, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {
            toast.success(resposta.data.message)
            setShowModalCarregando(false)
        }).catch(function (erro) {
            toast.error(erro.response.data.message || erro.message)
            setShowModalCarregando(false)
        })
    }
    //carregando usuario já vinculados 
    const coluna: ITabela[] = [
        { titulo: "Id.Tarefa", acesso: "id_tarefa" },
        { titulo: "Id.Pedido", acesso: "id_pedido" },
        { titulo: "Usuário Vinc.", acesso: "nome" },
        { titulo: "Cadastro", acesso: "cadastro" },
        { titulo: "Usuário", acesso: "usuario" }
    ]
    const [usuariosVinculados, setUsuariosVinculados] = useState([])
    function carregarUsuariosVinculados() {
        setShowModalCarregando(true)
        axios.get(url + `/carregar/usuarios/${idFilial}/${idTarefa}`, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {
            setUsuariosVinculados(resposta.data.usuarios)
            setShowModalCarregando(false)
        }).catch(function (erro) {
            toast.error(erro.response.data.message || erro.message)
            setShowModalCarregando(false)
        })
    }
    //desvincular usuario
    const [showModalConfirmacao, setShowModalConfirmacao] = useState(false)
    const [idUsuarioCarregadoDesvincular, setidUsuarioCarregadoDesvincular] = useState<string | number>("")
    function manipularModalConfirmarDesvincular() {
        setShowModalConfirmacao(!showModalConfirmacao)
    }
    function abrirModalDesvincular(dados: any) {
        manipularModalConfirmarDesvincular()
        setidUsuarioCarregadoDesvincular(dados.usuarioVinculado)
    }
    function desvincularUsuario() {
        manipularModalConfirmarDesvincular()
        setShowModalCarregando(true)
        axios.delete(url + `/remover/vinculo/tarefa/${idUsuarioCarregadoDesvincular}/${idTarefa}/${idFilial}`, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {
            toast.success(resposta.data.message)
            carregarUsuariosVinculados()
            setShowModalCarregando(false)
        }).catch(function (erro) {
            toast.success(erro.response.data.message || erro.message)
            setShowModalCarregando(false)
        })
    }
    useEffect(function () {
        if (show == true) {
            carregarUsuariosSemVinc()
            carregarUsuariosVinculados()
            carregarCodicaoSeparacaoTarefa()
        }
        else {
            setCpfUsuario("")
            setIdUsuarioCarregado("")
            setNomeUsuarioCarregado("")
            setMostrarBotaoVincular(false)
        }
    }, [show, idTarefa])
    return (
        <Modal show={show} centered size='lg' onHide={fecharModal}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm col-md-4 col-lg-4'>
                            <label>Buscar usuário</label>
                            <input list="browsers" type="text"
                                className="form-control text-black"
                                value={CpfUsuario}
                                placeholder=' '
                                onChange={setValueCpfUsuario}
                            />
                            <datalist id="browsers">
                                {listaUsuariosSemVinc.map(function (usuario: any, index) {
                                    return <option value={usuario.cpf} key={index}>{usuario.nome}</option>
                                })}
                            </datalist>
                        </div>
                        <div className='col-sm col-md-4 col-lg-2 pt-3'>
                            <button disabled={CpfUsuario.length < 11 || CpfUsuario.length > 11} onClick={carregarUsuario} className='btn btn-primary px-2 py-1'>
                                <i className="bi bi-arrow-repeat"></i>
                            </button>
                        </div>
                        <div className='col-sm col-md-4 col-lg-6 pt-3 text-end'>
                            <label className='me-1'>Limitar separação à usuários vinculados</label>
                            <input
                                checked={limitarSeparacao}
                                onChange={alterarLimitarSeparacao}
                                className="form-check-input w-50" type="checkbox"
                            />
                        </div>
                    </div>
                    {
                        mostrarBotaoVincular == true && CpfUsuario.length == 11 ?
                            <div className='row mt-2'>
                                <div className='col-sm col-md-12 col-lg-12'>
                                    <label>Informações do usuário selecionado</label>
                                </div>
                                <div className='col-sm col-md-4 col-lg-4'>
                                    <div className='form-floating'>
                                        <input value={nomeUsuarioCarregado} type="text" disabled className='form-control bordasInferiorInput text-black' placeholder=' ' />
                                        <label>Nome</label>
                                    </div>
                                </div>
                                <div className='col-sm col-md-4 col-lg-4'>
                                    <div className='form-floating'>
                                        <input value={idUsuarioCarregado} type="email" disabled className='form-control bordasInferiorInput text-black' placeholder=' ' />
                                        <label>Id Usuário</label>
                                    </div>
                                </div>
                                <div className='col-sm col-md-4 col-lg-4 pt-2'>
                                    <button onClick={vincularUsuarioTarefa} className='btn btn-primary w-100'>Vincular</button>
                                </div>
                            </div> : <></>
                    }
                    <div className='row mt-2'>
                        <div className='col-sm col-md-12 col-lg-12'>
                            <label>Usuários vinculados à tarefa</label>
                        </div>
                        <ClickableTable
                            coluna={coluna}
                            data={usuariosVinculados}
                            onRowClick={function () {

                            }}
                            itemsPerPage={10}
                            acaoEditar={function () { }}
                            acaoExcluir={abrirModalDesvincular}
                            acaoVisualizar={function () { }}
                            usaAcoes={true}
                            usaEditar={false}
                            usaExcluir={true}
                            usaVisualizar={false}
                            iconeExcluir="bi bi-x-circle"
                            acessopesquisa="usuario_vinculado"
                            labelpesquisa="Procurar por usuário vinculado"
                        />
                    </div>
                </div>
            </Modal.Body>
            <ModalConfirmacao
                mostrar={showModalConfirmacao}
                msgPrincipal='Confirmar a desvinculação do usuário da tarefa?'
                msgAcao1='Cancelar'
                msgAcao2='Confirmar'
                Acao1={manipularModalConfirmarDesvincular}
                Acao2={desvincularUsuario}
            />
            <ModalCarregando mostrar={ShowModalCarregando} mensagem='Carregando...' />
        </Modal >
    )
}