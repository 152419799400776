import { toast } from "react-toastify";
import ClickableTable from "../../../../components/ClickableTable/ClickableTabl";
import { BuscarPermissoesUsuario } from "../../../../hooks/buscarPermissoesUsuario";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ITabela } from "../../../../components/Table/TabelaInterface";
import axios from "axios";
import ModalCarregando from "../../../../components/ModalCarregando/ModalCarregando";
import exceljs from "exceljs"
import FileSaver from "file-saver";
const HistoricoEnderecamento = (): JSX.Element => {
	const token = sessionStorage.getItem('IsFKLTOKEN')!
	const id_filial = sessionStorage.getItem('IsIDFilialPadraoFKL')!
	const id_perfil = sessionStorage.getItem('IsIdPerfilFKL')!
	const id_usuario = sessionStorage.getItem('IsIDUserFKL')!
	const navigate = useNavigate()
	//verificando permissoes
	const { permissoes, erro } = BuscarPermissoesUsuario(id_filial, id_perfil)
	const [visualizar, setVisualizar] = useState(false)
	useEffect(function () {
		if (erro) {
			toast.warning('Você não tem permissão para acessar Estoque Detalhado. ');
			navigate('/');
		}
		else if (permissoes) {
			setVisualizar(permissoes?.perfilPermissao.visualizarRelHistoricoEnderecamento)
		}
	}, [permissoes, erro, navigate])
	const [itensPorPagina, setItensPorPagina] = useState(10)
	const colunas: ITabela[] = [
		{ acesso: "id_ua", titulo: "U.A" },
		{ acesso: "id_processo", titulo: "Processo" },
		{ acesso: "fantasia", titulo: "Depositante" },
		{ acesso: "cod_prod", titulo: "Cód.Produto" },
		{ acesso: "qtd_emb", titulo: "Quantidade" },
		{ acesso: "amz_ant", titulo: "Arm.Orig" },
		{ acesso: "box_ant", titulo: "Box.Orig" },
		{ acesso: "alt_ant", titulo: "Alt.Orig" },
		{ acesso: "prof_ant", titulo: "Prof.Orig" },
		{ acesso: "data_cadastro", titulo: "Data de Cadastro" },
		{ acesso: "amz", titulo: "Arm.Atual" },
		{ acesso: "box", titulo: "Box.Atual" },
		{ acesso: "alt", titulo: "Alt.Atual" },
		{ acesso: "prof", titulo: "Prof.Atual" },
		{ acesso: "user", titulo: "Usuário" },
		{ acesso: "modulo", titulo: "Módulo" },
	]
	const [historicoDeEnderacamento, sethistoricoDeEnderacamento] = useState([])
	const [inputsPesquisa, setInputsPesquisa] = useState({
		dataInicio: "",
		dataFim: "",
		usuario: "",
		depositante: ""
	})
	function onChangeDepositante(e: React.ChangeEvent<HTMLSelectElement>) {
		setInputsPesquisa({
			...inputsPesquisa,
			depositante: e.target.value
		})
	}
	function onChangeUsuario(e: React.ChangeEvent<HTMLSelectElement>) {
		setInputsPesquisa({
			...inputsPesquisa,
			usuario: e.target.value
		})
	}
	function onChangeDataInicio(e: React.ChangeEvent<HTMLInputElement>) {
		setInputsPesquisa({
			...inputsPesquisa,
			dataInicio: e.target.value
		})
	}
	function onChangeDataFim(e: React.ChangeEvent<HTMLInputElement>) {
		setInputsPesquisa({
			...inputsPesquisa,
			dataFim: e.target.value
		})
	}
	const [ListaDepositantes, setListaDepositantes] = useState([])
	const [showCarregando, setShowCarregando] = useState(false)
	async function CarregarDepositantes(id_filial: string, id_usuario: string) {
		setShowCarregando(true);
		try {
			const resposta = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/depositantes/usuario/${id_filial}/${id_usuario}`, {
				headers: {
					authorization: token,
				},
			});
			setListaDepositantes(resposta.data.depositantes)
			setShowCarregando(false)
		} catch (error: any) {
			setShowCarregando(false)
			toast.error(error.response.data.message || error.message)
		}
	}
	const [ListaUsuarios, setListaUsuarios] = useState([])
	function CarregarUsuarios(id_filial: string) {
		setShowCarregando(true)
		axios.get(`${process.env.REACT_APP_API_BASE_URL}/load/usuarios/sem/master/filial/${id_filial}`, {
			headers: {
				authorization: token
			}
		}).then(function (resposta) {
			setListaUsuarios(resposta.data.usuarios)
			setShowCarregando(false)
		}).catch(function (erro) {
			toast.error(erro.response.data.message || erro.message)
			setShowCarregando(false)
		})
	}
	useEffect(function () {
		CarregarDepositantes(id_filial, id_usuario)
		CarregarUsuarios(id_filial)
	}, [])
	function OnSubmitConsultarHistorico(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault()
		CarregarHistorico(id_filial, inputsPesquisa.dataInicio, inputsPesquisa.dataFim, inputsPesquisa.usuario)
	}
	function CarregarHistorico(id_filial: string, dataInicio: string, dataFim: string, usuario: string) {
		setShowCarregando(true)
		axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/historico/endereco/${id_filial}/${dataInicio}/${dataFim}/?usuario=${usuario}&depositante=${inputsPesquisa.depositante}`, {
			headers: {
				authorization: token
			}
		}).then(function (resposta) {
			sethistoricoDeEnderacamento(resposta.data.historico)
			setShowCarregando(false)
		}).catch(function (erro) {
			toast.error(erro.response.data.message || erro.message)
			setShowCarregando(false)
		})
	}
	async function ExportarExcel() {
		try {
			setShowCarregando(true)
			const workbook = new exceljs.Workbook()
			const worksheet = workbook.addWorksheet("Histórico de Endereçamento")
			//preenchendo o cabecalho
			const alfabeto = [
				"A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M",
				"N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"
			]
			//montando o cabecalho
			for (let i = 0; i < colunas.length; i = i + 1) {
				const cell = worksheet.getCell(`${alfabeto[i]}${1}`)
				cell.value = colunas[i].titulo
				cell.font = {
					bold: true
				}
				const colum = worksheet.getColumn(i + 1)
				colum.width = 25
			}
			//montando o corpo
			for (let i = 0; i < historicoDeEnderacamento.length; i = i + 1) {
				const HistoricoEnderecamento: any = historicoDeEnderacamento[i]
				const cellA = worksheet.getCell(`A${i + 2}`)
				cellA.value = HistoricoEnderecamento.id_ua
				const cellB = worksheet.getCell(`B${i + 2}`)
				cellB.value = HistoricoEnderecamento.id_processo
				const cellN = worksheet.getCell(`C${i + 2}`)
				cellN.value = HistoricoEnderecamento.fantasia
				const cellC = worksheet.getCell(`D${i + 2}`)
				cellC.value = HistoricoEnderecamento.cod_prod
				const cellD = worksheet.getCell(`E${i + 2}`)
				cellD.value = HistoricoEnderecamento.qtd_emb
				const cellE = worksheet.getCell(`F${i + 2}`)
				cellE.value = HistoricoEnderecamento.amz_ant
				const cellF = worksheet.getCell(`G${i + 2}`)
				cellF.value = HistoricoEnderecamento.box_ant
				const cellG = worksheet.getCell(`H${i + 2}`)
				cellG.value = HistoricoEnderecamento.alt_ant
				const cellH = worksheet.getCell(`I${i + 2}`)
				cellH.value = HistoricoEnderecamento.prof_ant
				const cellI = worksheet.getCell(`J${i + 2}`)
				cellI.value = HistoricoEnderecamento.data_cadastro
				const cellJ = worksheet.getCell(`K${i + 2}`)
				cellJ.value = HistoricoEnderecamento.amz
				const cellK = worksheet.getCell(`L${i + 2}`)
				cellK.value = HistoricoEnderecamento.box
				const cellL = worksheet.getCell(`M${i + 2}`)
				cellL.value = HistoricoEnderecamento.alt
				const cellM = worksheet.getCell(`N${i + 2}`)
				cellM.value = HistoricoEnderecamento.prof
				const cellO = worksheet.getCell(`O${i + 2}`)
				cellO.value = HistoricoEnderecamento.user
				const cellP = worksheet.getCell(`P${i + 2}`)
				cellP.value = HistoricoEnderecamento.modulo
			}
			//gerando o buffer do arquivo excel
			const buffer = await workbook.xlsx.writeBuffer()
			//gerando o blob do arquivo
			const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
			FileSaver.saveAs(blob, "HistEndereco")
			setShowCarregando(false)
		} catch (error) {
			setShowCarregando(false)
			console.log(error)
		}
	}
	return (
		<div className="main">
			<div className="col-sm col-md-12 col-lg-12 mt-2">
				<h4>Histórico de Endereçamento</h4>
			</div>
			<div className="container-fluid">
				<div className="row">
					<div className="card">
						<div className="card-body">
							<form onSubmit={OnSubmitConsultarHistorico}>
								<div className="row">
									<div className="col-sm col-md-3 col-lg-3">
										<div className="form-floating">
											<select
												value={inputsPesquisa.depositante}
												onChange={onChangeDepositante}
												className="form-select bordasInferiorInput text-black"
												id="floatingSelect"
												aria-label="Floating label select example"
											>
												<option value="">Selecione...</option>
												{ListaDepositantes.map(function (depositante: any) {
													return (
														<option value={depositante.id_cliente}>{depositante.fantasia}</option>
													);
												})}
											</select>
											<label>Depositante</label>
										</div>
									</div>
									<div className="col-sm col-md-2 col-lg-2">
										<div className="form-floating">
											<select
												value={inputsPesquisa.usuario}
												onChange={onChangeUsuario}
												className="form-select bordasInferiorInput text-black"
												id="floatingSelect"
												aria-label="Floating label select example"
											>
												<option value="">Selecione...</option>
												{ListaUsuarios.map(function (usuario: any) {
													return (
														<option value={usuario.nome}>{usuario.nome}</option>
													);
												})}
											</select>
											<label>Usuário</label>
										</div>
									</div>
									<div className="col-sm col-md-2 col-lg-2">
										<div className="form-floating">
											<input
												value={inputsPesquisa.dataInicio}
												onChange={onChangeDataInicio}
												type="date"
												required
												className="form-control bordasInferiorInput text-black"
											/>
											<label>Data Início</label>
										</div>
									</div>
									<div className="col-sm col-md-2 col-lg-2">
										<div className="form-floating">
											<input
												value={inputsPesquisa.dataFim}
												onChange={onChangeDataFim}
												type="date"
												required
												className="form-control bordasInferiorInput text-black"
											/>
											<label>Data Fim</label>
										</div>
									</div>
									<div className="col-sm col-md-2 col-lg-3 pt-2">
										<button type="submit" className="btn btn-primary me-1">
											Filtrar
											<i className="bi bi-search ms-2"></i>
										</button>
										{
											historicoDeEnderacamento.length > 0 ?
												<button hidden={!visualizar} type="button" onClick={ExportarExcel} className="btn btn-success">
													Exportar .xlsx
												</button>
												:
												<>
												</>
										}
									</div>
								</div>
							</form>
							<div className="row">
								<ClickableTable
									coluna={colunas}
									itemsPerPage={itensPorPagina}
									data={historicoDeEnderacamento}
									usaRowClick={false}
									onRowClick={function () {

									}}
									usaAcoes={false}
									usaEditar={false}
									usaVisualizar={false}
									usaExcluir={false}
									usaCheckBox={false}
									acaoEditar={function () { }}
									acaoExcluir={function () { }}
									acaoVisualizar={function () { }}
									acaoCheckBox={function () { }}
									labelpesquisa="Procurar por U.A"
									acessopesquisa="id_ua"
								/>
								<div className="col-lg-12 m-auto text-center">
									<label>Itens por Página</label>
								</div>
								<div className="col-lg-12 m-auto text-center">
									<select
										value={itensPorPagina}
										onChange={function (e: any) {
											setItensPorPagina(e.target.value);
										}}
										className="form-select form-select-sm m-auto"
										style={{ width: "80px" }}
										aria-label=".form-select-sm example"
									>
										<option value={10}>10</option>
										<option value={20}>20</option>
										<option value={30}>30</option>
									</select>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ModalCarregando
				mostrar={showCarregando}
				mensagem="Carregando..."
			/>
		</div>
	);
};

export { HistoricoEnderecamento };
