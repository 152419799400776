import { Link } from 'react-router-dom';

const Ocorrencia = (): JSX.Element => {
	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<div className="page-title-box">
						<h4 className="page-title">Listagem das Ocorrências</h4>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="card">
						<div className="card-body">
							<div className="row">
								<div className="col-sm-4">
									<Link to={`/pco/contrFisc/procEVeiculos/ocorrencia/${0}`} className="btn rounded mb-2" style={{backgroundColor: "#1c4b9b", color:"white"}}>
										<i className="mdi mdi-plus-circle me-2"></i>
										Nova Ocorrência
									</Link>
								</div>
								<div className="col-8 d-flex justify-content-center">
									<a href="#" className="btn btn-primary rounded mb-2" style={{ marginRight: '5px' }}>
										<i className="mdi mdi-printer me-1"></i>
										Imprimir
									</a>
									<a href="#" className="btn btn-primary rounded mb-2" style={{ marginRight: '5px' }}>
										<i className="mdi mdi-sync me-1"></i>
										Fazer Trâmite
									</a>
									<a href="#" className="btn btn-primary rounded mb-2">
										<i className="mdi mdi-check me-1"></i>
										Fazer Concluír
									</a>
								</div>
							</div>
							<div className="table-responsive">
								<table className="table table-centered w-100 dt-responsive nowrap" id="endereco-datatable">
									<thead style={{backgroundColor: "#1c4b9b", color:"white"}}>
										<tr>
											<th>ID Ocorrência</th>
											<th>Data Ocorrência</th>
											<th>Cliente</th>
											<th>Status</th>
											<th>Ações</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>1234</td>
											<td>03/12/2021 08:00</td>
											<td>Cliente 1 Ltd</td>
											<td>Concluído</td>
											<td className="d-flex flex-nowrap table-action">
												<a href="#" className="action-icon">
													<i className="mdi mdi-square-edit-outline"></i>
												</a>
												<a href="#" className="action-icon">
													<i className="mdi mdi-delete"></i>
												</a>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export { Ocorrencia };
