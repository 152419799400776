import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import { Card, Col, Row } from "react-bootstrap"
import { FaFileExcel, FaFilePdf } from "react-icons/fa";
import ClickableTable from "../../../components/ClickableTable/ClickableTabl";
import { ITabela } from "../../../components/Table/TabelaInterface";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ChangeEvent, useEffect, useState } from "react";
import ModalCarregando from "../../../components/ModalCarregando/ModalCarregando";
import convertDataBRParaEUA from "../../../components/functions/convertDataBRParaEUA";
import * as XLSX from 'xlsx';
import jsPDF from "jspdf";
import 'jspdf-autotable';
import FileSaver from "file-saver";

function ReposicaoPrimaria() {

    const token = sessionStorage.getItem('IsFKLTOKEN') || '';
    const idFilial = sessionStorage.getItem('IsIDFilialPadraoFKL')

    const [dados, setDados] = useState([])
    const [depositantes, setDepositantes] = useState<string[]>([""])
    const [idCliente, setIdCliente] = useState<number>()
    const [referenciaDias, setReferenciaDias] = useState<number>()
    const [dataCarga, setDataCarga] = useState<string>('')
    const [mostraModalCarregando, setMostraModalCarregando] = useState<boolean>(false)

    const navigate = useNavigate()

    const actions = [
        {
            icon: <FaFilePdf />,
            name: "PDF",
            onClick: gerarPDFReposicaoPrimaria
        },
        {
            icon: <FaFileExcel />,
            name: "Excel",
            onClick: gerarExcelReposicaoPrimaria
        }

    ];

    const colunas: ITabela[] = [
        { titulo: 'Código', acesso: 'codigo' },
        { titulo: 'Descrição', acesso: 'descricao' },
        { titulo: 'Endereço Picking', acesso: 'endereco_picking' },
        { titulo: 'U.A', acesso: 'id_ua' },
        { titulo: 'Endereço U.A', acesso: 'endereco_ua' }
    ];

    async function gerarExcelReposicaoPrimaria() {
        if (dados.length === 0) {
            return toast.error("Nenhum dado de reposição encontrado");
        } else {
            setMostraModalCarregando(true);
            // Criar uma nova planilha
            const ws = XLSX.utils.aoa_to_sheet([]);

            // Definir o cabeçalho
            const header = [
                "Código",
                "Descrição",
                "Endereço de Picking",
                "U.A",
                "Endereço U.A"
            ];
            XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });

            // Adicionar os dados dos usuários ao array
            const dataRows = dados.map((item: any) => [
                item.codigo,
                item.descricao,
                item.endereco_picking,
                item.id_ua,
                item.endereco_ua
            ]);

            // Adicionar os dados ao arquivo Excel
            XLSX.utils.sheet_add_aoa(ws, dataRows, { origin: "A2" });

            // Criar um novo livro de Excel
            const wb = XLSX.utils.book_new();

            // Adicionar a planilha ao livro de Excel
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

            // Configurar opções de escrita, incluindo o tipo de livro e o tipo de saída
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

            // Criar um Blob a partir do buffer de Excel
            const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // Salvar o Blob como um arquivo Excel
            FileSaver.saveAs(blob, `ReposicaoPicking.xlsx`);
        }
        setMostraModalCarregando(false);
    }

    async function buscaDepositantes() {
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/busca/depositantes/reposicaoPrimaria/${idFilial}`, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {
            setDepositantes(resposta.data.data)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                toast.error(erro.response.data.message)
            }
        })
    }

    const handleDataCargaChange = (e: ChangeEvent<HTMLInputElement>) => {
        // Remove todos os caracteres não numéricos
        let input = e.target.value.replace(/\D/g, '');
        if (input.length > 8) {
            // Limita a entrada a 8 caracteres
            input = input.slice(0, 8);
        }

        if (input.length <= 2) {
            input = input;
        } else if (input.length <= 4) {
            input = `${input.slice(0, 2)}/${input.slice(2)}`;
        } else {
            input = `${input.slice(0, 2)}/${input.slice(2, 4)}/${input.slice(4)}`;
        }

        setDataCarga(input);
    };

    async function gerarCalculo() {
        if (!idCliente || !dataCarga || !referenciaDias) {
            return toast.info("Preencha todas as informações para prosseguir")
        }

        const newDataCarga = convertDataBRParaEUA(dataCarga)

        setMostraModalCarregando(true)
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/gerarCalculo/reposicaoPrimaria`, {
            idFilial,
            idCliente,
            newDataCarga,
            referenciaDias
        }, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {
            carregarDadosGrid()
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                setDados([])
                toast.error(erro.response.data.message)
            }
        }).finally(() => {
            setMostraModalCarregando(false)
        })
    }

    async function gerarPDFReposicaoPrimaria() {

        if (dados.length === 0) {
            return toast.error("Dados de reposição não encontrados")
        }

        setMostraModalCarregando(true)
        const doc = new jsPDF();

        doc.setFontSize(12);
        doc.text('Relatório de Reposição Primária', 14, 16);

        // Configurar a tabela
        (doc as any).autoTable({
            head: [[
                "Código",
                "Descrição",
                "Endereço de Picking",
                "U.A",
                "Endereço U.A"
            ]],
            body: dados.map((item: any) => [
                item.codigo,
                item.descricao,
                item.endereco_picking,
                item.id_ua,
                item.endereco_ua
            ]),
            startY: 22,
        });

        doc.save(`ReposiçãoPrimária.pdf`);
        setMostraModalCarregando(false)
    };

    async function carregarDadosGrid() {
        setMostraModalCarregando(true)
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregarDados/reposicaoPrimaria/${idFilial}/${idCliente}`, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {
            toast.success("Cálculo gerado com sucesso")
            setDados(resposta.data.data)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                toast.error(erro.response.data.message)
                setDados([])
            }
        }).finally(() => {
            setMostraModalCarregando(false)
        })
    }

    useEffect(() => {
        buscaDepositantes()
    }, [])

    return (
        <>

            <div className="main">
                <div className="tituloDaPagina mt-2 ms-1">
                    <h4>Reposição Primária</h4>
                </div>

                <Card>
                    <Card.Body>

                        <Row>
                            <SpeedDial
                                ariaLabel="SpeedDial basic example"
                                direction="left"
                                sx={{
                                    right: 25,
                                    position: "absolute",
                                }}
                                icon={<SpeedDialIcon />}
                            >
                                {actions.map((action) => (
                                    <SpeedDialAction
                                        key={action.name}
                                        icon={action.icon}
                                        tooltipTitle={action.name}
                                        onClick={action.onClick}
                                    />
                                ))}
                            </SpeedDial>

                            <Col md={4} sm={12}>
                                <div className="form-floating">
                                    <select
                                        value={idCliente}
                                        onChange={(e) => setIdCliente(parseInt(e.target.value))}
                                        className="form-select bordasInferiorInput text-black"
                                        id="floatingSelect"
                                        aria-label="Floating label select example"
                                    >
                                        <option value="">Selecione...</option>
                                        {depositantes.map(function (depositante: any) {
                                            return (
                                                <option
                                                    key={depositante.id_cliente}
                                                    value={depositante.id_cliente}
                                                >
                                                    {depositante.fantasia}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    <label>Depositante</label>
                                </div>
                            </Col>

                            <Col lg={4} md={6} sm={12}>
                                <div className="form-floating" style={{ marginTop: '-2.2px' }}>
                                    <input
                                        type="number"
                                        className="form-control bordasInferiorInput text-black text-capitalize"
                                        value={referenciaDias}
                                        onChange={(event) => setReferenciaDias(parseInt(event.target.value))}
                                    />
                                    <label className="form-label" style={{ whiteSpace: 'nowrap' }}>Referência em dias para cálculo de média</label>
                                </div>
                            </Col>
                        </Row>

                        <Row className="mt-1">
                            <Col md={4} sm={12}>
                                <div className="form-floating">
                                    <input
                                        type="text"
                                        className="form-control bordasInferiorInput text-black text-capitalize"
                                        value={dataCarga}
                                        onChange={handleDataCargaChange}
                                    />
                                    <label className="form-label">Data de Carga Pedidos</label>
                                </div>
                            </Col>
                            <Col md={3} sm={12}>
                                <button
                                    type="button"
                                    className="btn btn-novo mt-3 w-100 rounded"
                                    onClick={gerarCalculo}
                                >
                                    <span className='me-1'>Gerar cálculo</span><i className="bi bi-check-lg"></i>
                                </button>
                            </Col>
                        </Row>

                        <Row className="mt-4">

                            <ClickableTable
                                coluna={colunas}
                                data={dados}
                                itemsPerPage={10}
                                usaAcoes={false}
                                usaRowClick={false}
                                onRowClick={() => { }}
                                acaoEditar={() => { }}
                                acaoExcluir={() => { }}
                                acaoVisualizar={() => { }}
                            />

                        </Row>

                    </Card.Body>
                </Card>

            </div>

            <ModalCarregando
                mostrar={mostraModalCarregando}
                mensagem="Carregando..."
            />
        </>
    )
}

export default ReposicaoPrimaria