import { useEffect, useState } from 'react';
import ClickableTable from '../../../components/ClickableTable/ClickableTabl';
import { ITabela } from '../../../components/Table/TabelaInterface';
import axios from 'axios';
import { toast } from 'react-toastify';
import file from 'file-saver';
import ExcelJS from 'exceljs';
import { useNavigate } from 'react-router-dom';
import { BuscarPermissoesUsuario } from '../../../hooks/buscarPermissoesUsuario';

function EstoqueSintetico() {
	const token = sessionStorage.getItem('IsFKLTOKEN')!;
	const idFilial = sessionStorage.getItem('IsIDFilialPadraoFKL')!;
	const id_perfil = sessionStorage.getItem('IsIdPerfilFKL')!;
	const nivelPerfil = sessionStorage.getItem('NivelPerfil')!;
	const usuarioLogado = sessionStorage.getItem('IsUserFKL')!;
	const id_usuario = sessionStorage.getItem('IsIDUserFKL')!;
	const navigate = useNavigate();

	// Usa o hook com os parâmetros de ID de filial e ID de perfil de permissão
	const { permissoes, erro } = BuscarPermissoesUsuario(idFilial, id_perfil);
	const [permissaoGerarExcel, setPermissaoGerarExcel] = useState(false);

	// Utiliza o resultado da requisição dentro de um efeito ou qualquer lógica de negócio
	useEffect(() => {
		if (erro) {
			toast.error(`Falha ao buscar permissões do usuário. Motivo: ${erro}`);
		}
		if (permissoes) {
			if (!permissoes.perfilPermissao.visualizarEstoqueSintetico) {
				toast.warning('Você não tem permissão para acessar Gerar Estoque Sintético.');
				navigate('/');
			} else {
				setPermissaoGerarExcel(permissoes.perfilPermissao.gerarExcelEstoqueSintetico);
			}
		}
	}, [permissoes, erro, navigate]);

	const [dados, setDados] = useState<any>([]);
	const [ListaDepositantes, setListaDepositantes] = useState([]);
	const [depositanteSelecionado, setDepositanteSelecionado] = useState('');
	const [showCarregando, setShowCarregando] = useState(false);

	const colunas: ITabela[] = [
		{ titulo: 'Id WMS', acesso: 'id_wms' },
		{ titulo: 'Código', acesso: 'codigo' },
		{ titulo: 'Descrição', acesso: 'descricao' },
		{ titulo: 'Unid.Controle', acesso: 'und_emb' },
		{ titulo: 'Qtde. Estoque', acesso: 'qtde' },
		{ titulo: 'Qtde. Reservado', acesso: 'qtde_reserva' },
		{ titulo: 'Qtde. Disponível', acesso: 'qtde_disp' },
		{ titulo: 'Volume Embal.1', acesso: 'volume_1' },
		{ titulo: 'Volume Embal.2', acesso: 'volume_2' },
		{ titulo: 'Peso Líq', acesso: 'pl' },
		{ titulo: 'Bloq. Padrao', acesso: 'bloq' },
	];

	async function CarregarDepositantes(id_filial: string, id_usuario: string) {
		setShowCarregando(true);
		try {
			const resposta = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/depositantes/usuario/${id_filial}/${id_usuario}`, {
				headers: {
					authorization: token,
				},
			});
			setListaDepositantes(resposta.data.depositantes);
			setShowCarregando(false);
		} catch (error: any) {
			setShowCarregando(false);
			toast.error(error.response.data.message || error.message);
		}
	}

	useEffect(function () {
		CarregarDepositantes(idFilial, id_usuario);
	}, []);

	async function carregaGrid(idCliente: number) {
		if (!idCliente) {
			return;
		}

		setShowCarregando(true);
		try {
			const resposta = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/carrega/infos/sintetico/${idCliente}/${idFilial}`, {
				headers: {
					authorization: token,
				},
			});
			console.log(resposta);
			setShowCarregando(false);
			setDados(resposta.data.data);
		} catch (error: any) {
			setShowCarregando(false);
			toast.error(error.response);
		}
	}

	async function gerarNovoExcel(data: any) {
		try {
			const workbook = new ExcelJS.Workbook();
			const sheet = workbook.addWorksheet('Estoque Sintético');

			//#region Cabeçalho da planílha
			const columA = sheet.getColumn('A');
			columA.width = 25;
			const celA = sheet.getCell('A1');
			celA.value = 'ID WMS';
			celA.font = {
				bold: true,
			};

			const columB = sheet.getColumn('B');
			columB.width = 25;
			const celB = sheet.getCell('B1');
			celB.value = 'Código';
			celB.font = {
				bold: true,
			};

			const columC = sheet.getColumn('C');
			columC.width = 25;
			const celC = sheet.getCell('C1');
			celC.value = 'Descrição';
			celC.font = {
				bold: true,
			};

			const columD = sheet.getColumn('D');
			columD.width = 25;
			const celD = sheet.getCell('D1');
			celD.value = 'Unid. Controle';
			celD.font = {
				bold: true,
			};

			const columE = sheet.getColumn('E');
			columE.width = 25;
			const celE = sheet.getCell('E1');
			celE.value = 'Qtde. Estoque';
			celE.font = {
				bold: true,
			};

			const columF = sheet.getColumn('F');
			columF.width = 25;
			const celF = sheet.getCell('F1');
			celF.value = 'Qtde. Reservado';
			celF.font = {
				bold: true,
			};

			const columG = sheet.getColumn('G');
			columG.width = 25;
			const celG = sheet.getCell('G1');
			celG.value = 'Qtde. Disponível';
			celG.font = {
				bold: true,
			};

			const columH = sheet.getColumn('H');
			columH.width = 25;
			const celH = sheet.getCell('H1');
			celH.value = 'Volume Embal. 1';
			celH.font = {
				bold: true,
			};

			const columI = sheet.getColumn('I');
			columI.width = 25;
			const celI = sheet.getCell('I1');
			celI.value = 'Volume Embal. 2';
			celI.font = {
				bold: true,
			};

			const columJ = sheet.getColumn('J');
			columJ.width = 25;
			const celJ = sheet.getCell('J1');
			celJ.value = 'Peso Líq';
			celJ.font = {
				bold: true,
			};

			const columK = sheet.getColumn('K');
			columK.width = 25;
			const celK = sheet.getCell('K1');
			celK.value = 'Bloq. Padrão';
			celK.font = {
				bold: true,
			};

			//#region Corpo da planílha
			for (let i = 2; i - 2 < dados.length; i++) {
				const obj = dados[i - 2];

				console.log(dados);
				sheet.getCell(`A${i}`).value = obj.id_wms;
				sheet.getCell(`B${i}`).value = obj.codigo;
				sheet.getCell(`C${i}`).value = obj.descricao;
				sheet.getCell(`D${i}`).value = obj.und_emb;
				sheet.getCell(`E${i}`).value = obj.qtde;
				sheet.getCell(`F${i}`).value = obj.qtde_reserva;
				sheet.getCell(`G${i}`).value = obj.qtde_disp;
				sheet.getCell(`H${i}`).value = obj.volume_1;
				sheet.getCell(`I${i}`).value = obj.volume_2;
				sheet.getCell(`J${i}`).value = obj.pl;
				sheet.getCell(`K${i}`).value = obj.bloq;
			}

			const buffer = await workbook.xlsx.writeBuffer();

			// Exemplo de como usar o buffer (por exemplo, criar um Blob e salvar um arquivo)
			const blob: Blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

			file.saveAs(blob, 'Estoque Sintético');
		} catch (error: any) {
			toast.error(error.message);
		}
	}

	return (
		<div className="main">
			<div className="tituloDaPagina mt-2 ms-1">
				<h4>Estoque Sintético</h4>
			</div>
			<div className="card mt-2 pb-3">
				<div className="card-body">
					<div className="container p-0 m-0">
						<div className="row d-flex justify-content-between align-items-center">
							<div className="col-sm col-md-3 col-lg-5">
								<div className="form-floating">
									<select
										value={depositanteSelecionado}
										onChange={(e) => {
											setDepositanteSelecionado(e.target.value);
											carregaGrid(parseInt(e.target.value));
										}}
										className="form-select bordasInferiorInput text-black"
										id="floatingSelect"
										aria-label="Floating label select example"
									>
										<option value="">Selecione...</option>
										{ListaDepositantes.map((depositante: any) => (
											<option key={depositante.id_cliente} value={depositante.id_cliente}>
												{depositante.fantasia}
											</option>
										))}
									</select>
									<label>Depositante</label>
								</div>
							</div>

							{permissaoGerarExcel && (
								<div className="col-sm col-md-3 col-lg-3">
									<button type="button" className="w-100 btn btn-primary" onClick={() => gerarNovoExcel(dados)}>
										<i className="bi bi-file-earmark-excel me-2"></i>Gerar Excel
									</button>
								</div>
							)}
						</div>
					</div>
					<ClickableTable
						coluna={colunas}
						data={dados}
						onRowClick={function () {}}
						itemsPerPage={10}
						acaoEditar={function () {}}
						acaoExcluir={function () {}}
						acaoVisualizar={function () {}}
						usaAcoes={false}
						usaEditar={true}
						usaExcluir={true}
						usaVisualizar={true}
						acessopesquisa="codigo"
						labelpesquisa="Procurar pelo Código"
					/>
				</div>
			</div>
		</div>
	);
}

export default EstoqueSintetico;
