import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import mascaraCPF from '../../../../components/functions/mascaraCPF';
import 'react-toastify/dist/ReactToastify.css';
import mascaraCelular from '../../../../components/functions/mascaraCelular';
import MascaraCNPJ from '../../../../components/functions/mascaraCNPJ';
import { toast } from 'react-toastify';
import { SiTableau } from 'react-icons/si';
import ConverterEmBase64 from '../../../../components/functions/converterEmBase64';
import ModalCarregando from '../../../../components/ModalCarregando/ModalCarregando';
import ClickableTable from '../../../../components/ClickableTable/ClickableTabl';
import ModalConfirmacao from '../../../../components/ModalDeConfirmacao/ModalConfirmacao';
import avatarPadrao from "../../../../assets/img/avatar-default.jpg"
import { BotaoCustomizado } from '../../../../components/componentes/formulario/BotaoCustomizado';
import { mdiCheckCircle, mdiKeyboardReturn, mdiPlusCircle } from '@mdi/js';

const NovoUsuario = (): JSX.Element => {
	const token = sessionStorage.getItem('IsFKLTOKEN')!;
	const id_perfil = sessionStorage.getItem("IsIdPerfilFKL")!
	const id_usuario = sessionStorage.getItem("IsIDUserFKL")!
	const id_filial = sessionStorage.getItem("IsIDFilialPadraoFKL")!
	const nivelPerfil = sessionStorage.getItem("NivelPerfil")!

	const params = useParams()
	const navigate = useNavigate()
	//campos
	const idUsuario = params.id
	const [nomeUsuario, setNomeUsuario] = useState("")
	const [cpfUsuario, setCpfUsuario] = useState("")
	const [usuarioDoUsuario, setusuarioDoUsuario] = useState("")
	const [EmailUsuario, setEmailUsuario] = useState("")
	const [telefoneUsuario, settelefoneUsuario] = useState("")
	const [senhaUsuario, setSenhaUsuario] = useState("")
	const [senhaUsuarioCofirmar, setSenhaUsuarioCofirmar] = useState("")
	const [situacao, setSituacao] = useState(1)
	const [avatar, setAvatar] = useState<any>("")
	const [idFilialPadrao, setIdFilialPadrao] = useState("")
	const [idPerfil, setIdPerfil] = useState("")

	const cpfUsuarioLogado = sessionStorage.getItem("identidadeUsuario")!

	const [ListaFiliaisVinculadas, setListaFiliaisVinculadas] = useState<any>([])
	const [ListaFiliaisDisponiveis, setListaFiliaisDisponiveis] = useState<any>([])
	function CarregaFiliaisDisponiveis(id_usuario: string) {

		setMostrarCarregando(true)
		axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/filais/disponiveis/${id_usuario}/${params.id}`, {
			headers: {
				authorization: token
			}
		}).then(function (response) {

			setMostrarCarregando(false)
			setListaFiliaisDisponiveis(response.data.filiaisDisponiveis)
		}).catch(function (erro) {

			setMostrarCarregando(false)
			alert(erro.response.data.message || erro.message)
		})
	}

	function CarregarUsuario(id_usuario: any, id_filial: any) {

		setMostrarCarregando(true)
		axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/usuario/filial/${id_filial}/${id_usuario}`, {
			headers: {
				authorization: token
			}
		}).then(function (resposta) {

			setNomeUsuario(resposta.data.usuario.nome)
			setCpfUsuario(mascaraCPF(resposta.data.usuario.cpf))
			setSituacao(resposta.data.usuario.situacao)
			setusuarioDoUsuario(resposta.data.usuario.nome_usuario)
			setEmailUsuario(resposta.data.usuario.email)
			settelefoneUsuario(mascaraCelular(resposta.data.usuario.telefone))
			setIdPerfil(resposta.data.usuario.id_perfil)
			setAvatar(resposta.data.usuario.avatar)

			const filialPadrao = resposta.data.filiaisVinculadas.find(function (filial: any) {

				return filial.padrao == 1
			})
			setIdFilialPadrao(filialPadrao.id_filial)
			setListaFiliaisVinculadas(resposta.data.filiaisVinculadas)
			setListaDepositantesVinculados(resposta.data.depositantesVinculados)
			setMostrarCarregando(false)
		}).catch(function (erro) {

			setMostrarCarregando(false)
			toast.error(erro.response.data.message || erro.message)
		})
	}

	useEffect(function () {

		if (idUsuario != "novo") {

			CarregarUsuario(params.id, id_filial)
		}
		CarregaFiliaisDisponiveis(id_usuario)
		CarregarListaDePerfis(id_filial, id_perfil)
		carregarDepositantesDisponiveis()
	}, [])

	function VincularFilialAoUsuario(filial: any) {

		if (params.acao == "criar" && params.id == "novo") {

			setListaFiliaisVinculadas([...ListaFiliaisVinculadas, filial])
			toast.success(`${filial.fantasia} vinculada ao novo usuario.`, {
				autoClose: 900
			})
		}
		else if (params.acao == "editar" && params.id != "novo") {

			const dados = {
				filial,
				idUsarioSelecionado: idUsuario
			}

			setMostrarCarregando(true)
			axios.post(`${process.env.REACT_APP_API_BASE_URL}/vincular/nova/filial`, dados, {
				headers: {
					authorization: token
				}
			}).then(function (resposta) {

				setMostrarCarregando(false)
				CarregarUsuario(params.id, id_filial)
				CarregaFiliaisDisponiveis(id_usuario)
				toast.success(resposta.data.message, {
					autoClose: 900
				})
			}).catch(function (erro) {

				setMostrarCarregando(false)
				toast.error(erro.response.data.message || erro.message, {
					autoClose: 900
				})
			})
		}
	}

	function DesVincularFilialAoUsuario(filial: any) {

		const cpfUsuarioFormatado = cpfUsuario.replaceAll(".", "").replace("-", "")

		if (params.acao == "editar" && params.id != "novo" && cpfUsuarioLogado == cpfUsuarioFormatado) {

			toast.error("O usuário nao pode desvincular uma filial de si mesmo.", {
				autoClose: 900
			})
		}
		else if (params.acao == "criar" && params.id == "novo") {

			const novaLista = ListaFiliaisVinculadas.filter(function (filialVinculada: any) {


				return filialVinculada.id_filial !== filial.id_filial
			})

			setListaFiliaisDisponiveis([...ListaFiliaisDisponiveis, filial])
			setListaFiliaisVinculadas(novaLista)
			toast.success(`${filial.fantasia} desvinculada do usuario.`, {
				autoClose: 900
			})
		}
		else if (params.acao == "editar" && params.id != "novo") {

			setMostrarCarregando(true)
			axios.delete(`${process.env.REACT_APP_API_BASE_URL}/vincular/nova/filial?filial=${filial.id_filial}&idUsarioSelecionado=${idUsuario}`, {
				headers: {
					authorization: token
				}
			}).then(function (resposta) {

				setMostrarCarregando(false)
				CarregarUsuario(params.id, id_filial)
				CarregaFiliaisDisponiveis(id_usuario)
				toast.success(resposta.data.message, {
					autoClose: 900
				})

			}).catch(function (erro) {

				setMostrarCarregando(false)
				toast.error(erro.response.data.message || erro.message, {
					autoClose: 900
				})
			})
		}
	}


	//realizar a criação do usuario
	function CriarUsuario(e: any) {
		e.preventDefault()
		setMostrarCarregando(true)
		const dados = {

			nomeUsuario,
			cpfUsuario: cpfUsuario.replaceAll(".", "").replace("-", ""),
			usuarioDoUsuario,
			EmailUsuario,
			telefoneUsuario: telefoneUsuario.replace("(", "").replace(")", "").replace(" ", "").replace("-", ""),
			senhaUsuario,
			situacao,
			avatar,
			id_perfil: idPerfil,
			usuarioCadastrando: sessionStorage.getItem("IsUserFKL"),
			idFilialPadrao,
			ListaDepositantes: ListaDepositantesVinculados,
			ListaFiliais: ListaFiliaisVinculadas
		}

		if (senhaUsuario !== senhaUsuarioCofirmar) {

			setMostrarCarregando(false)
			toast.error("As senhas não são identicas.", {
				autoClose: 900
			})
		}
		else if (ListaFiliaisVinculadas.length == 0) {

			setMostrarCarregando(false)
			toast.error("Vincule ao menos 1 filial ao usuario.", {
				autoClose: 900
			})
		}
		else if (idFilialPadrao == "") {

			setMostrarCarregando(false)
			toast.error("Defina a filial padrão.", {
				autoClose: 900
			})
		}
		else {

			//requisição para criar usuario.
			axios.post(`${process.env.REACT_APP_API_BASE_URL}/criar/novo/usuario/${id_filial}`, dados, {
				headers: {
					authorization: token
				}
			}).then(function (resposta) {

				setMostrarCarregando(false)
				toast.success(resposta.data.message, {
					autoClose: 900
				})

				setTimeout(function () {
					navigate(-1)
				}, 500)
			}).catch(function (erro) {

				setMostrarCarregando(false)
				toast.error(erro.response.data.message || erro.message, {
					autoClose: 900
				})
			})
		}
	}

	const [ListaPerfis, setListaPerfis] = useState([])
	function CarregarListaDePerfis(id_filial: any, id_perfil: any) {

		setMostrarCarregando(true)
		axios.get(`${process.env.REACT_APP_API_BASE_URL}/listar/perfil/inferiores/${id_filial}/${id_perfil}/${nivelPerfil}`, {
			headers: {
				authorization: token
			}
		}).then(function (resposta) {

			setMostrarCarregando(false)
			setListaPerfis(resposta.data.perfis)
		}).catch(function (erro) {

			setMostrarCarregando(false)
			toast.error(erro.response.data || erro.message)
		})
	}

	function AtualizarUsuario(e: any) {


		e.preventDefault()
		setMostrarCarregando(true)
		const dados = {

			nomeUsuario,
			cpfUsuario: cpfUsuario.replaceAll(".", "").replace("-", ""),
			usuarioDoUsuario,
			EmailUsuario,
			telefoneUsuario: telefoneUsuario.replace("(", "").replace(")", "").replace(" ", "").replace("-", ""),
			senhaUsuario,
			situacao,
			avatar,
			id_perfil: idPerfil,
			usuarioCadastrando: sessionStorage.getItem("IsUserFKL"),
			idFilialPadrao
		}

		if (idFilialPadrao == "") {

			setMostrarCarregando(false)
			toast.error("Defina a filial padrão.", {
				autoClose: 900
			})
		}
		else {

			//requisição para atualizar usuario.
			axios.put(`${process.env.REACT_APP_API_BASE_URL}/atualizar/usuario/filial`, dados, {
				headers: {
					authorization: token
				}
			}).then(function (resposta) {

				setMostrarCarregando(false)
				toast.success(resposta.data.message, {
					autoClose: 900
				})

				setTimeout(function () {
					navigate(-1)
				}, 500)
			}).catch(function (erro) {

				setMostrarCarregando(false)
				toast.error(erro.response.data.message || erro.message, {
					autoClose: 900
				})
			})
		}
	}

	const [ListaDepositantesDisponiveis, setListaDepositantesDisponiveis] = useState<any>([])
	const [ListaDepositantesDisponiveisFilial, setListaDepositantesDisponiveisFilial] = useState<any>([])
	const [ListaDepositantesVinculados, setListaDepositantesVinculados] = useState<any>([])

	function carregarDepositantesDisponiveis() {
		axios.get(`${process.env.REACT_APP_API_BASE_URL}/listar/depositantes/disponiveis/${id_usuario}/${params.id}`, {
			headers: {
				authorization: token
			}
		}).then(function (resposta) {

			setListaDepositantesDisponiveis(resposta.data.DepositantesDisponiveis)
		}).catch(function (erro) {

			toast.error(erro.response.data.message || erro.message, {
				autoClose: 900
			})
		})
	}

	function VincularDepositanteAoUsuario(depositante: any) {

		if (params.acao == "criar" && params.id == "novo") {

			const FilialVinculada = ListaFiliaisVinculadas.find(function (filial: any) {

				return filial.id_filial == depositante.id_filial
			})

			if (FilialVinculada) {

				setListaDepositantesVinculados([...ListaDepositantesVinculados, depositante])
				toast.success(`${depositante.fantasia} vinculado ao novo usuario.`, {
					autoClose: 900
				})
			}
			else {

				toast.error("Filial de depositante não vinculada", {
					autoClose: 900
				})
			}
		}
		else if (params.acao == "editar" && params.id != "novo") {

			const FilialVinculada = ListaFiliaisVinculadas.find(function (filial: any) {

				return filial.id_filial == depositante.id_filial
			})

			if (FilialVinculada) {

				const dados = {
					depositante,
					idUsuario,
					usuarioCadastro: sessionStorage.getItem("IsUserFKL")
				}

				setMostrarCarregando(true)
				axios.post(`${process.env.REACT_APP_API_BASE_URL}/vincular/depositante`, dados, {
					headers: {
						authorization: token
					}
				}).then(function (resposta) {


					setMostrarCarregando(false)
					carregarDepositantesDisponiveis()
					CarregarUsuario(params.id, id_filial)
					toast.success(resposta.data.message, {
						autoClose: 900
					})
				}).catch(function (erro) {

					setMostrarCarregando(false)
					toast.error(erro.response.data.message || erro.message)
				})
			}
			else {


				toast.error("Filial de depositante não vinculada", {
					autoClose: 900
				})
			}
		}
	}

	function DesVincularDepositanteAoUsuario(depositante: any) {

		const cpfUsuarioFormatado = cpfUsuario.replaceAll(".", "").replace("-", "")

		if (params.acao == "editar" && params.id != "novo" && cpfUsuarioLogado == cpfUsuarioFormatado) {

			toast.error("O usuário nao pode desvincular um depositante de si mesmo", {
				autoClose: 900
			})
		}
		else if (params.acao == "criar" && params.id == "novo") {

			const novaLista = ListaDepositantesVinculados.filter(function (depositanteVinculado: any) {


				return depositanteVinculado.id_cliente !== depositante.id_cliente && depositanteVinculado.id_filial !== depositante.id_filial
			})

			setListaDepositantesDisponiveisFilial([...ListaDepositantesDisponiveisFilial, depositante])
			setListaDepositantesVinculados(novaLista)
			toast.success(`${depositante.fantasia} desvinculada do usuario.`, {
				autoClose: 900
			})

		}
		else if (params.acao == "editar" && params.id != "novo") {

			setMostrarCarregando(true)
			axios.delete(`${process.env.REACT_APP_API_BASE_URL}/desvincular/depositante?depositante=${depositante.id_cliente}&idUsarioSelecionado=${params.id}`, {
				headers: {
					authorization: token
				}
			}).then(function (resposta) {

				setMostrarCarregando(false)
				carregarDepositantesDisponiveis()
				CarregarUsuario(params.id, id_filial)
				toast.success(resposta.data.message, {
					autoClose: 900
				})
			}).catch(function (erro) {

				setMostrarCarregando(false)
				toast.error(erro.response.data.message || erro.message, {
					autoClose: 900
				})
			})
		}
	}

	useEffect(function () {


		if (ListaDepositantesVinculados.length > 0) {
			const DepositantesFiltrados = ListaDepositantesDisponiveisFilial.filter(function (depositante: any) {
				// Verifica se não existe nenhum depositante vinculado com o mesmo id_cliente e id_filial
				return !ListaDepositantesVinculados.some(function (depositanteVinculado: any) {
					return depositanteVinculado.id_cliente === depositante.id_cliente && depositanteVinculado.id_filial === depositante.id_filial;
				})
			})
			// Supondo que setListaDepositantesDisponiveisFilial seja uma função para atualizar a lista
			setListaDepositantesDisponiveisFilial(DepositantesFiltrados);
		}
	}, [ListaDepositantesVinculados])

	const [MostrarCarregando, setMostrarCarregando] = useState(true)

	function AtualizarFilialPadrao(filial: any) {

		if (params.id != "novo" && params.acao == "editar" && filial != "") {

			setMostrarCarregando(true)
			const dados = {
				id_filial: filial,
				idUsuarioSelecionado: idUsuario
			}

			axios.put(`${process.env.REACT_APP_API_BASE_URL}/atualizar/filial/padrao`, dados, {
				headers: {
					authorization: token
				}
			}).then(function (resposta) {

				setMostrarCarregando(false)
				CarregarUsuario(params.id, id_filial)
				toast.success(resposta.data.message, {
					autoClose: 900
				})
			}).catch(function (erro) {

				setMostrarCarregando(false)
				toast.error(erro.response.data.message || erro.message, {
					autoClose: 900
				})
			})
		}
	}


	const [ShowModalConfirmacao, setShowModalConfirmacao] = useState(false)


	function VincularTodosDepositantesAoUsuario() {



		const ListaIDSfiliais = ListaFiliaisVinculadas.map(function (filial: any) {

			return filial.id_filial
		})

		if (params.acao == "criar" && params.id == "novo") {

			const novosDepositantes = ListaDepositantesDisponiveis.filter(function (depositante: any) {

				return ListaIDSfiliais.includes(depositante.id_filial)
			})

			if (novosDepositantes.length > 0) {

				setShowModalConfirmacao(false)
				toast.success(novosDepositantes.length + " Depositantes vinculados", {
					autoClose: 900
				})
				setListaDepositantesVinculados([...ListaDepositantesVinculados, ...novosDepositantes])
			}
			else {

				setShowModalConfirmacao(false)
				toast.error("Nenhuma Filial vinculada ao usuário ou nenhum depositante para a filial vinculada.", {
					autoClose: 1300
				})
			}
		}
		else if (params.acao == "editar" && params.id != "novo") {


			const novosDepositantes = ListaDepositantesDisponiveis.filter(function (depositante: any) {

				return ListaIDSfiliais.includes(depositante.id_filial)
			})

			if (novosDepositantes.length > 0) {

				const dados = {
					novosDepositantes,
					idUsuario,
					usuarioCadastro: sessionStorage.getItem("IsUserFKL")
				}
				setMostrarCarregando(true)
				axios.put(`${process.env.REACT_APP_API_BASE_URL}/vincular/todos/depositantes`, dados, {
					headers: {
						authorization: token
					}
				}).then(function (resposta) {

					carregarDepositantesDisponiveis()
					CarregarUsuario(params.id, id_filial)
					setMostrarCarregando(false)
					setShowModalConfirmacao(false)
					toast.success(resposta.data.message, {
						autoClose: 900
					})
				}).catch(function (erro) {

					setMostrarCarregando(false)
					setShowModalConfirmacao(false)
					toast.error(erro.response.data.message || erro.message, {
						autoClose: 900
					})
				})
			}
			else {

				setShowModalConfirmacao(false)
				toast.error("Nenhuma Filial vinculada ao usuário ou nenhum depositante para a filial vinculada.", {
					autoClose: 1300
				})
			}
		}
	}


	useEffect(function () {

		//ListaDepositantesDisponiveisFilial

		// Verifica se há filiais vinculadas para filtrar
		if (ListaFiliaisVinculadas.length > 0) {

			// Filtra as filiais disponíveis removendo as que já estão vinculadas
			const FiliaisFiltradas = ListaFiliaisDisponiveis.filter(function (filial: any) {

				// Retorna true se a filial não estiver na lista de vinculadas
				return ListaFiliaisVinculadas.every(function (filialVinculada: any) {

					return filialVinculada.id_filial !== filial.id_filial;
				});
			});

			// Atualiza a lista de filiais disponíveis com as filtradas
			setListaFiliaisDisponiveis(FiliaisFiltradas);

			const DepositantesFilial = ListaFiliaisVinculadas.flatMap(function (filial: any) {

				return ListaDepositantesDisponiveis.filter(function (depositante: any) {

					return depositante.id_filial === filial.id_filial;
				})
			})

			setListaDepositantesDisponiveisFilial(DepositantesFilial)
		}
		else {

			setListaDepositantesDisponiveisFilial([])
		}
	}, [ListaFiliaisVinculadas])

	useEffect(function () {

		const ListaIDSfiliais = ListaFiliaisVinculadas.map(function (filial: any) {

			return filial.id_filial
		})

		const novosDepositantes = ListaDepositantesDisponiveis.filter(function (depositante: any) {

			return ListaIDSfiliais.includes(depositante.id_filial)
		})

		if (params.acao == "editar" && params.id != "novo") {

			//ListaFiliaisVinculadas

			const disponveis = novosDepositantes.filter(function (depositanteDisponivel: any) {

				return !ListaDepositantesVinculados.some(function (depositantesVinculados: any) {


					return depositanteDisponivel.id_cliente == depositantesVinculados.id_cliente && depositanteDisponivel.id_filial == depositantesVinculados.id_filial
				})
			})

			setListaDepositantesDisponiveisFilial(disponveis)
		}
	}, [ListaDepositantesDisponiveis, ListaDepositantesVinculados])

	return (
		<>
			<ModalCarregando mostrar={MostrarCarregando} mensagem='Carregando' />
			<div className='main'>
				<div className='mt-2 ms-1'>
					<h4>{params.acao == "criar" ? "Cadastro de novo usuário" : params.acao == "editar" ? "Editando usuário" : "Visualizando usuário"}</h4>
				</div>
				<div className="card mt-2 p-3">
					<ul className="nav nav-pills bg-nav-pills nav-justified mb-3">
						<li className="nav-item">
							<a className="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Dados Básicos</a>
						</li>
						<li className="nav-item">
							<a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Filiais Disponiveis</a>
						</li>
						<li className="nav-item">
							<a className="nav-link" id="pills-filiais-vinculadas-tab" data-toggle="pill" href="#pills-filiais-vinculadas" role="tab" aria-controls="pills-filiais-vinculadas" aria-selected="false">Filiais Vinculadas</a>
						</li>
						<li className="nav-item">
							<a className="nav-link" id="pills-depositante-tab" data-toggle="pill" href="#pills-depositante" role="tab" aria-controls="pills-profile" aria-selected="false">Depositantes Disponiveis</a>
						</li>
						<li className="nav-item">
							<a className="nav-link" id="pills-depositante-vinculados-tab" data-toggle="pill" href="#pills-depositante-vinculados" role="tab" aria-controls="pills-profile" aria-selected="false">Depositantes Vinculados</a>
						</li>
					</ul>
					<div className="tab-content" id="pills-tabContent">
						<div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">

							<form onSubmit={params.id == "novo" ? CriarUsuario : AtualizarUsuario}>
								<div className='container p-0 m-0'>
									<div className='row'>
										<div className='col-lg-1 col-md-3 col-sm'>
											<div className="form-floating">

												<input readOnly value={idUsuario} type="text" className="form-control bordasInferiorInput text-black text-center" placeholder="" />
												<label>ID</label>
											</div>
										</div>
										<div className='col-lg-2 col-md-3 col-sm'>
											<div className="form-floating">
												<input onChange={function (e) {

													setCpfUsuario(e.target.value)
												}}
													readOnly={params.acao == "visualizar" ? true : false}
													onBlur={function () {
														setCpfUsuario(mascaraCPF(cpfUsuario))
													}}
													required
													value={cpfUsuario} type="text" className="form-control bordasInferiorInput text-black text-center" placeholder="XXX.XXX.XXX-XX" />
												<label>CPF</label>
											</div>
										</div>
										<div className='col-lg-3 col-md-3 col-sm'>
											<div className="form-floating">

												<input required readOnly={params.acao == "visualizar" ? true : false} onChange={function (e) {
													setNomeUsuario(e.target.value)
												}} value={nomeUsuario} type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
												<label>Nome</label>
											</div>
										</div>
										<div className='col-lg-2 col-md-3 pt-3 col-sm'>
											<div className="form-check form-switch">
												<input
													checked={situacao == 1 ? true : false}
													onClick={function () {
														setSituacao(situacao == 1 ? 0 : 1)
													}}
													className="form-check-input w-50" type="checkbox" />
												<label className="ms-1 form-check-label">Ativo</label>
											</div>
										</div>
										<div className='w-100'></div>
										<div className='col-lg-3 col-md-3 col-sm'>
											<div className="form-floating">

												<input required readOnly={params.acao == "visualizar" ? true : false} onChange={function (e) {
													setusuarioDoUsuario(e.target.value)
												}} value={usuarioDoUsuario} type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder="" />
												<label>Usuário</label>
											</div>
										</div>
										<div className='col-lg-3 col-md-3 col-sm'>
											<div className="form-floating">

												<input required readOnly={params.acao == "visualizar" ? true : false} onChange={function (e) {
													setEmailUsuario(e.target.value)
												}} value={EmailUsuario} type="email" className="form-control bordasInferiorInput text-black" placeholder="XXXXX@XXXXXX.COM" />
												<label>Email</label>
											</div>
										</div>
										<div className='w-100'></div>
										<div className='col-lg-3 col-md-3 col-sm'>
											<div className="form-floating">

												<input readOnly={params.acao == "visualizar" ? true : false} onChange={function (e) {
													settelefoneUsuario(e.target.value)
												}}
													onBlur={function () {
														settelefoneUsuario(mascaraCelular(telefoneUsuario))
													}}
													value={telefoneUsuario} type="tel" className="form-control bordasInferiorInput text-black" placeholder="(XX) XXXXX-XXXX" />
												<label>Telefone</label>
											</div>
										</div>
										<div className="col-lg-3 col-md-7 col-sm">
											<div className='form-floating'>
												<select onChange={function (e) {
													setIdPerfil(e.target.value)
												}} required disabled={params.acao == "visualizar" ? true : false} value={idPerfil} className="form-select bordasInferiorInput text-black" aria-label="Default select example">
													<option value="">Selecione o perfil...</option>
													{
														ListaPerfis.map(function (perfil: any) {

															return (
																<>
																	<option value={`${perfil.id_perfil}`}>{perfil.nome_perfil}</option>
																</>
															)
														})
													}
												</select>
												<label htmlFor=""></label>
											</div>
										</div>

										<div className='w-100'></div>
										{params.acao == "editar" || params.acao == "visualizar" ?
											""
											:
											<>
												<div className='col-lg-3 col-md-3 col-sm'>
													<div className="form-floating">

														<input required readOnly={params.acao == "visualizar" ? true : false} onChange={function (e) {
															setSenhaUsuario(e.target.value)
														}} value={senhaUsuario} minLength={5} type="password" className="form-control bordasInferiorInput text-black" placeholder="**********" />
														<label>Senha</label>
													</div>
												</div>
												<div className='col-lg-3 col-md-3 col-sm'>
													<div className="form-floating">

														<input required readOnly={params.acao == "visualizar" ? true : false} onChange={function (e) {
															setSenhaUsuarioCofirmar(e.target.value)
														}} value={senhaUsuarioCofirmar} minLength={5} type="password" className="form-control bordasInferiorInput text-black" placeholder="**********" />
														<label>Confirmar Senha</label>
													</div>
												</div>

											</>
										}
										<div className='w-100'></div>
										<div className='col-sm col-md-4 col-lg-6'>
											<div className="text-start p-2">
												<img src={avatar != "" ? avatar : avatarPadrao} className="rounded h-50 d-inline-block w-25" alt="..." />
											</div>
										</div>
										<div className='row'>
											<div className='col-4'>
												<span className='d-block'>Carregue a imagem de perfil</span>
												<input disabled={params.acao == "visualizar" ? true : false} type="file" className='w-100' onChange={async function (event) {

													const files = event.target.files
													if (files && files.length > 0) {

														const file = files[0]
														const base64: any = await ConverterEmBase64(file)
														setAvatar(base64)
													}
												}} />
											</div>
											<div className='col-2 mt-3'>
												<button
													onClick={(e) => {
														e.preventDefault()
														setAvatar("")
													}}
													className='btn btn-danger me-2 w-100'
												>
													Excluir foto
												</button>
											</div>

										</div>
									</div>
								</div>
								<div className="card-footer">
									<div className="col-sm">
										<div className="text-sm-end">
											<BotaoCustomizado
												type="button"
												className="btn btn-light text-danger bg-white border-0"
												descricaoBotao="Voltar"
												icone={mdiKeyboardReturn}
												onclick={() => {
													navigate(-1);
												}}
											/>
											<BotaoCustomizado
												type="submit"
												className="btn btn-light text-info-emphasis bg-white border-0"
												descricaoBotao="Salvar"
												icone={mdiCheckCircle}
												disabled={params.acao === 'criar' ? false : params.acao === 'editar' ? false : true}
											/>
										</div>
									</div>
								</div>
							</form>
						</div>
						<div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
							<div className="card-body p-0">
								<div className='container p-0 m-0'>
									<div className='row'>
										<ClickableTable
											coluna={[{
												acesso: "fantasia", titulo: "Filial"
											},
											{
												acesso: "cnpj", titulo: "CNPJ"
											}
											]}
											itemsPerPage={10}
											data={ListaFiliaisDisponiveis}
											onRowClick={function () {

											}}
											acaoExcluir={function () {

											}}
											acaoEditar={VincularFilialAoUsuario}
											acaoVisualizar={function (dado: any) {

											}}
											iconeEditar='bi bi-building-add'
											usaAcoes={true}
											usaEditar={true}
											usaExcluir={false}
											usaVisualizar={false}
											acessopesquisa='fantasia'
											labelpesquisa='Procurar por fantasia'
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="tab-pane fade" id="pills-filiais-vinculadas" role="tabpanel" aria-labelledby="pills-filiais-vinculadas-tab">
							<div className='container p-0 m-0'>
								<div className='row'>
									<div className="col-lg-3 col-md-7 col-sm">

										<div className='form-floating'>
											<select onChange={function (e) {
												AtualizarFilialPadrao(e.target.value)
												setIdFilialPadrao(e.target.value)
											}} required disabled={params.acao == "visualizar" ? true : false} value={idFilialPadrao} className="form-select bordasInferiorInput text-black text-center" aria-label="Default select example">
												<option value="" hidden={params.id == "novo" ? false : true}>Selecione a filial padrão...</option>
												{
													ListaFiliaisVinculadas.map(function (filial: any) {

														return (
															<>
																<option value={`${filial.id_filial}`}>{filial.fantasia} - {MascaraCNPJ(filial.cnpj)}</option>
															</>
														)
													})
												}
											</select>
											<label htmlFor="">Filial padrão</label>
										</div>
									</div>
								</div>
							</div>
							<div className='container p-0 m-0'>
								<div className='row'>

									<ClickableTable
										coluna={[{
											acesso: "fantasia", titulo: "Filial"
										},
										{
											acesso: "cnpj", titulo: "CNPJ"
										}
										]}
										itemsPerPage={10}
										data={ListaFiliaisVinculadas}
										onRowClick={function () {

										}}
										acaoExcluir={function () {

										}}
										acaoEditar={DesVincularFilialAoUsuario}
										acaoVisualizar={function (dado: any) {

										}}
										iconeEditar='bi bi-unlock'
										usaAcoes={true}
										usaEditar={true}
										usaExcluir={false}
										usaVisualizar={false}
										acessopesquisa='fantasia'
										labelpesquisa='Procurar por fantasia'
									/>
								</div>
							</div>
						</div>
						<div className="tab-pane fade" id="pills-depositante" role="tabpanel" aria-labelledby="pills-depositante-tab">
							<div className="card-body p-0">
								<div className='container p-0 m-0'>
									<div className='row'>
										<div className='col-lg-8 col-md-7 col-sm'>
											<span className="d-inline-block ms-2" tabIndex={0} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content="Vincular todos os depositantes">
												<button type="button" onClick={function () {
													setShowModalConfirmacao(true)
												}} className='btn btn-primary px-2 py-0 m-0'><i className='bi bi-building-add fs-4 me-1'></i>Vinc. todos</button>
											</span>
										</div>
										{/*
										{ListaDepositantesDisponiveis.map(function (depositante: any) {

											return (
												<>
													<div className='col-lg-2 col-md-4 col-sm text-center'>
														<p className="form-check-label mb-0"><b>{depositante.fantasia.slice(0, 20)}</b></p>
														<p className="form-check-label mt-0">{MascaraCNPJ(depositante.cnpj)}</p>
														<button className='btn btn-primary' disabled={params.acao == "visualizar" ? true : false} onClick={function () {
															VincularDepositanteAoUsuario(depositante)
														}}>Vincular</button>
													</div>
												</>
											)
										})}
										*/}
										<ClickableTable
											coluna={[{
												acesso: "fantasia", titulo: "Depositante"
											},
											{
												acesso: "cnpj", titulo: "CNPJ - Depositante"
											},
											{
												acesso: "fantasia_filial", titulo: "Filial"
											},
											{
												acesso: "cnpj_filial", titulo: "CNPJ - Filial"
											}
											]}
											itemsPerPage={10}
											data={ListaDepositantesDisponiveisFilial}
											onRowClick={function () {

											}}
											acaoExcluir={function () {

											}}
											acaoEditar={VincularDepositanteAoUsuario}
											acaoVisualizar={function (dado: any) {

											}}
											iconeEditar='bi bi-building-add'
											usaAcoes={true}
											usaEditar={true}
											usaExcluir={false}
											usaVisualizar={false}
											acessopesquisa='fantasia'
											labelpesquisa='Procurar por fantasia'
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="tab-pane fade" id="pills-depositante-vinculados" role="tabpanel" aria-labelledby="pills-depositante-vinculados-tab">
							<div className='container p-0 m-0'>
								<div className='row'>
									<ClickableTable
										coluna={[{
											acesso: "fantasia", titulo: "Depositante"
										},
										{
											acesso: "cnpj", titulo: "CNPJ - Depositante"
										},
										{
											acesso: "fantasia_filial", titulo: "Filial"
										},
										{
											acesso: "cnpj_filial", titulo: "CNPJ - Filial"
										}
										]}
										itemsPerPage={10}
										data={ListaDepositantesVinculados}
										onRowClick={function () {

										}}
										acaoExcluir={function () {

										}}
										acaoEditar={DesVincularDepositanteAoUsuario}
										acaoVisualizar={function (dado: any) {

										}}
										iconeEditar='bi bi-unlock'
										usaAcoes={true}
										usaEditar={true}
										usaExcluir={false}
										usaVisualizar={false}
										acessopesquisa='fantasia'
										labelpesquisa='Procurar por fantasia'
									/>
								</div>
							</div>
						</div>
					</div>
				</div >
			</div >

			<ModalConfirmacao
				mostrar={ShowModalConfirmacao}
				msgPrincipal='Vincular todos os depositantes das filiais ao usuário ?'
				msgAcao1='Cancelar'
				msgAcao2='Vincular'
				Acao1={function () {
					setShowModalConfirmacao(false)
				}}
				Acao2={VincularTodosDepositantesAoUsuario}
			/>
		</>
	);
};

export { NovoUsuario };
