import { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import axios from "axios"
import { toast } from "react-toastify"
import ModalCarregando from "../ModalCarregando/ModalCarregando"
import Checkbox from '@mui/material/Checkbox';
import { BotaoCustomizado } from "../componentes/formulario/BotaoCustomizado"
import { mdiCheckCircle } from "@mdi/js"
function SelecionarDepositantes({ show, textoHeader, funcaoFechar, receberDepositantesSelecionado }: {
    show: boolean, textoHeader: string, funcaoFechar: () => void, receberDepositantesSelecionado: (listaDepositantesSelecionados: []) => void
}) {
    const [ListaDepositantes, setListaDepositantes] = useState([])
    const [showCarregando, setShowCarregando] = useState(false)
    const token = sessionStorage.getItem('IsFKLTOKEN')!
    const id_filial = sessionStorage.getItem('IsIDFilialPadraoFKL')!
    const id_usuario = sessionStorage.getItem('IsIDUserFKL')!
    async function CarregarDepositantes(id_filial: string, id_usuario: string) {
        setShowCarregando(true)
        try {
            const resposta = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/depositantes/usuario/${id_filial}/${id_usuario}`, {
                headers: {
                    authorization: token,
                },
            });
            setListaDepositantes(resposta.data.depositantes)
            setShowCarregando(false)
        } catch (error: any) {
            setShowCarregando(false)
            toast.error(error.response.data.message || error.message)
        }
    }
    const [ListaDepositantesSelecionados, setListaDepositantesSelecionados] = useState<any>([])
    function addDepositanteSelecionado(idDepositante: number | string) {
        setListaDepositantesSelecionados([...ListaDepositantesSelecionados, {
            idDepositante
        }])
    }
    useEffect(function () {
        CarregarDepositantes(id_filial, id_usuario)
    }, [])
    function OnHideModal() {
        funcaoFechar()
        setListaDepositantesSelecionados([])
    }
    return (
        <Modal show={show} size="xl" onHide={OnHideModal}>
            <Modal.Header closeButton>
                <h4>{textoHeader}</h4>
            </Modal.Header>
            <Modal.Body>
                <div className="container-fluid">
                    <div className="row">
                        {ListaDepositantes.map(function (depositante: any) {
                            return (
                                <>
                                    <div className="col-sm col-md-4 col-lg-3">
                                        <Checkbox
                                            onChange={function (e) {
                                                if (e.target.checked) {
                                                    addDepositanteSelecionado(depositante.id_cliente)
                                                }
                                            }}
                                        />
                                        <label>{depositante.fantasia}</label>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-sm col-md col-lg-12 text-end">
                            <BotaoCustomizado
                                type="button"
                                className="btn btn-light text-info-emphasis bg-white border-0"
                                descricaoBotao="Confirmar"
                                icone={mdiCheckCircle}
                                onclick={function () {
                                    OnHideModal()
                                    receberDepositantesSelecionado(ListaDepositantesSelecionados)
                                }}
                                disabled={false}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <ModalCarregando mostrar={showCarregando} mensagem="Carregando Depositantes..." />
        </Modal>
    )
}
export default SelecionarDepositantes