import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ModalCarregando from '../../../../components/ModalCarregando/ModalCarregando';
import { toast } from 'react-toastify';
import ModalConfirmacao from '../../../../components/ModalDeConfirmacao/ModalConfirmacao';
import { BotaoCustomizado } from '../../../../components/componentes/formulario/BotaoCustomizado';
import { mdiCheckCircle, mdiKeyboardReturn } from '@mdi/js';
import CheckBox from '../../../../components/componentes/formulario/Checkbox';
import { ListaNiveis } from './components/DadosNiveis';
import InputSelect from '../../../../components/componentes/formulario/InputSelect';
import { Api } from '../../../../services/api';

const PerfilPermissaoForm = (): JSX.Element => {
	const params = useParams();
	const navigate = useNavigate();
	const token = sessionStorage.getItem('IsFKLTOKEN')!;
	const id_filial = sessionStorage.getItem('IsIDFilialPadraoFKL')!;
	// const id_perfil = sessionStorage.getItem('IsIdPerfilFKL')!;
	const nivelPerfil = sessionStorage.getItem('NivelPerfil')!;
	const usuarioCadastro = sessionStorage.getItem('IsUserFKL')!;

	const [showModalCarregando, setShowModalCarregando] = useState(false);

	const [modalConfirmacao, setModalConfirmacao] = useState(false);

	// PERMISSOES
	const [nomePerfil, setNomePerfil] = useState('');
	const [nivel, setNivel] = useState<string>('0');
	const [situacao, setSituacao] = useState(true);
	const [isMaster, setIsMaster] = useState(false);

	//#region Permissões -> Administrativo -> Controle de Acesso -> Usuários
	const [visualizarUsuario, setVisualizarUsuario] = useState(false);
	const [criarUsuario, setCriarUsuario] = useState(false);
	const [editarUsuario, setEditarUsuario] = useState(false);
	const [excluirUsuario, setExcluirUsuario] = useState(false);
	const [acessoTotalUsuario, setAcessoTotalUsuario] = useState(false);
	//#endregion

	//#region Permissões -> Administrativo -> Controle de Acesso -> Perfil/Permissão
	const [visualizarPerfilPermissao, setVisualizarPerfilPermissao] = useState(false);
	const [criarPerfilPermissao, setCriarPerfilPermissao] = useState(false);
	const [editarPerfilPermissao, setEditarPerfilPermissao] = useState(false);
	const [excluirPerfilPermissao, setExcluirPerfilPermissao] = useState(false);
	const [acessoTotalPerfilPermissao, setAcessoTotalPerfilPermissao] = useState(false);
	//#endregion

	//#region Permissões -> Administrativo -> Controle de Acesso -> Usuários Filial
	const [visualizarUsuariosFilial, setVisualizarUsuariosFilial] = useState(false);
	//#endregion

	//#region Permissões -> Administrativo -> Controle de Acesso -> Atividades Usuário
	const [visualizarAtividadesUsuario, setVisualizarAtividadesUsuario] = useState(false);
	//#endregion

	//#region Permissões -> Administrativo -> Controle de Acesso -> Atividades Usuário
	const [visualizarPainelMonitoramento, setVisualizarPainelMonitoramento] = useState(false);
	//#endregion

	//#region Permissões -> Administrativo -> Controle de Acesso -> Codigos Sistema
	const [visualizarCodSistema, setVisualizarCodSistema] = useState(false);
	const [criarCodSistema, setCriarCodSistema] = useState(false);
	const [editarCodSistema, setEditarCodSistema] = useState(false);
	const [excluirCodSistema, setExcluirCodSistema] = useState(false);
	const [acessoTotalCodigosSistema, setAcessoTotalCodigosSistema] = useState(false);
	//#endregion

	//#region Permissões -> Administrativo -> Cadastro Administrativo -> Cadastro de Filial
	const [visualizarCadastroFilial, setVisualizarCadastroFilial] = useState(false);
	const [criarCadastroFilial, setCriarCadastroFilial] = useState(false);
	const [editarCadastroFilial, setEditarCadastroFilial] = useState(false);
	const [excluirCadastroFilial, setExcluirCadastroFilial] = useState(false);
	const [acessoTotalCadastroFilial, setAcessoTotalCadastroFilial] = useState(false);
	//#endregion

	//#region Permissões -> Administrativo -> Cadastro Administrativo -> Controle de Numeracao
	const [visualizarControleNumeracao, setVisualizarControleNumeracao] = useState(false);
	const [criarControleNumeracao, setCriarControleNumeracao] = useState(false);
	const [editarControleNumeracao, setEditarControleNumeracao] = useState(false);
	const [excluirControleNumeracao, setExcluirControleNumeracao] = useState(false);
	const [acessoTotalControleNumeracao, setAcessoTotalControleNumeracao] = useState(false);
	//#endregion

	//#region Cadastros -> Entidade -> Entidade
	const [visualizarEntidade, setVisualizarEntidade] = useState(false);
	const [criarEntidade, setCriarEntidade] = useState(false);
	const [editarEntidade, setEditarEntidade] = useState(false);
	const [excluirEntidade, setExcluirEntidade] = useState(false);
	const [acessoTotalEntidade, setAcessoTotalEntidade] = useState(false);
	//#endregion

	//#region Cadastros -> Entidade -> Transportadora
	const [visualizarTransportadora, setVisualizarTransportadora] = useState(false);
	const [vincularTransportadoraDepositante, setVincularTransportadoraDepositante] = useState(false);
	const [acessoTotalTransportadora, setAcessoTotalTransportadora] = useState(false);
	//#endregion

	//#region Cadastros -> Depositante -> Depositante
	const [visualizarDepositante, setVisualizarDepositante] = useState(false);
	const [criarDepositante, setCriarDepositante] = useState(false);
	const [editarDepositante, setEditarDepositante] = useState(false);
	const [excluirDepositante, setExcluirDepositante] = useState(false);
	const [acessoTotalDepositante, setAcessoTotalDepositante] = useState(false);
	//#endregion

	//#region Cadastros -> Produtos -> Produtos de Armazenagem
	const [visualizarProdutosArmazenagem, setVisualizarProdutosArmazenagem] = useState(false);
	const [criarProdutosArmazenagem, setCriarProdutosArmazenagem] = useState(false);
	const [editarProdutosArmazenagem, setEditarProdutosArmazenagem] = useState(false);
	const [excluirProdutosArmazenagem, setExcluirProdutosArmazenagem] = useState(false);
	const [acessoTotalProdutosArmazenagem, setAcessoTotalProdutosArmazenagem] = useState(false);
	//#endregion

	//#region Cadastros -> Produtos -> Produtos de Fornecedor
	const [visualizarProdutosFornecedor, setVisualizarProdutosFornecedor] = useState(false);
	const [criarProdutosFornecedor, setCriarProdutosFornecedor] = useState(false);
	const [editarProdutosFornecedor, setEditarProdutosFornecedor] = useState(false);
	const [excluirProdutosFornecedor, setExcluirProdutosFornecedor] = useState(false);
	const [acessoTotalProdutosFornecedor, setAcessoTotalProdutosFornecedor] = useState(false);
	//#endregion

	//#region Cadastros -> Produtos -> Produtos de Cross
	const [visualizarProdutosCross, setVisualizarProdutosCross] = useState(false);
	const [criarProdutosCross, setCriarProdutosCross] = useState(false);
	const [editarProdutosCross, setEditarProdutosCross] = useState(false);
	const [excluirProdutosCross, setExcluirProdutosCross] = useState(false);
	const [acessoTotalProdutosCross, setAcessoTotalProdutosCross] = useState(false);
	//#endregion

	//#region Cadastros -> Produtos -> Produtos de Picking
	const [visualizarProdutosPicking, setVisualizarProdutosPicking] = useState(false);
	const [criarProdutosPicking, setCriarProdutosPicking] = useState(false);
	const [editarProdutosPicking, setEditarProdutosPicking] = useState(false);
	const [excluirProdutosPicking, setExcluirProdutosPicking] = useState(false);
	const [acessoTotalProdutosPicking, setAcessoTotalProdutosPicking] = useState(false);
	//#endregion

	//#region Cadastros -> Endereços -> Endereços de Armazém
	const [visualizarEnderecosArmz, setVisualizarEnderecosArmz] = useState(false);
	const [criarEnderecosArmz, setCriarEnderecosArmz] = useState(false);
	const [editarEnderecosArmz, setEditarEnderecosArmz] = useState(false);
	const [editarTempEnderecosArmz, setEditarTempEnderecosArmz] = useState(false);
	const [excluirEnderecosArmz, setExcluirEnderecosArmz] = useState(false);
	const [acessoTotalEnderecosArmz, setAcessoTotalEnderecosArmz] = useState(false);
	//#endregion

	//#region Cadastros -> Veiculos -> Veiculos
	const [visualizarVeiculos, setVisualizarVeiculos] = useState(false);
	const [criarVeiculos, setCriarVeiculos] = useState(false);
	const [editarVeiculos, setEditarVeiculos] = useState(false);
	const [excluirVeiculos, setExcluirVeiculos] = useState(false);
	const [acessoTotalVeiculos, setAcessoTotalVeiculos] = useState(false);
	//#endregion

	//#region Cadastros -> Veiculos -> Motoristas
	const [visualizarMotoristas, setVisualizarMotoristas] = useState(false);
	const [criarMotoristas, setCriarMotoristas] = useState(false);
	const [editarMotoristas, setEditarMotoristas] = useState(false);
	const [excluirMotoristas, setExcluirMotoristas] = useState(false);
	const [acessoTotalMotoristas, setAcessoTotalMotoristas] = useState(false);
	//#endregion

	//#region Cadastros -> Equipamento -> Equipamento
	const [visualizarEquipamento, setVisualizarEquipamento] = useState(false);
	const [criarEquipamento, setCriarEquipamento] = useState(false);
	const [editarEquipamento, setEditarEquipamento] = useState(false);
	const [excluirEquipamento, setExcluirEquipamento] = useState(false);
	const [acessoTotalEquipamento, setAcessoTotalEquipamento] = useState(false);
	//#endregion

	//#region Cadastros -> Colaborador -> Colaborador
	const [visualizarColaborador, setVisualizarColaborador] = useState(false);
	const [criarColaborador, setCriarColaborador] = useState(false);
	const [editarColaborador, setEditarColaborador] = useState(false);
	const [excluirColaborador, setExcluirColaborador] = useState(false);
	const [acessoTotalColaborador, setAcessoTotalColaborador] = useState(false);
	//#endregion

	//#region Cadastros -> Fiscal -> CFOP
	const [visualizarCFOP, setVisualizarCFOP] = useState(false);
	const [importarArqCFOP, setImportarArqCFOP] = useState(false);
	const [baixarModeloCFOP, setBaixarModeloCFOP] = useState(false);
	const [criarCFOP, setCriarCFOP] = useState(false);
	const [editarCFOP, setEditarCFOP] = useState(false);
	const [excluirCFOP, setExcluirCFOP] = useState(false);
	const [acessoTotalCFOP, setAcessoTotalCFOP] = useState(false);
	//#endregion

	//#region Cadastros -> Fiscal -> CFOP Entrada x Saida
	const [visualizarCFOPEntradaSaida, setVisualizarCFOPEntradaSaida] = useState(false);
	const [criarCFOPEntradaSaida, setCriarCFOPEntradaSaida] = useState(false);
	const [editarCFOPEntradaSaida, setEditarCFOPEntradaSaida] = useState(false);
	const [excluirCFOPEntradaSaida, setExcluirCFOPEntradaSaida] = useState(false);
	const [acessoTotalCFOPEntradaSaida, setAcessoTotalCFOPEntradaSaida] = useState(false);
	//#endregion

	//#region Cadastros -> Fiscal -> Grupo Tributário
	const [visualizarGrupoTributario, setVisualizarGrupoTributario] = useState(false);
	const [criarGrupoTributario, setCriarGrupoTributario] = useState(false);
	const [editarGrupoTributario, setEditarGrupoTributario] = useState(false);
	const [excluirGrupoTributario, setExcluirGrupoTributario] = useState(false);
	const [acessoTotalGrupoTributario, setAcessoTotalGrupoTributario] = useState(false);
	//#endregion

	//#region Administracao Operacional -> Fiscal Entrada -> Nfe Entradas Armazenagem
	const [visualizarNfeEntradasArmz, setVisualizarNfeEntradasArmz] = useState(false);
	const [detalharNfeEntradaArmz, setDetalharNfeEntradaArmz] = useState(false);
	const [importarNfeEntradasArmz, setImportarNfeEntradasArmz] = useState(false);
	const [excluirNfeEntradasArmz, setExcluirNfeEntradasArmz] = useState(false);
	const [acessoTotalNfeEntradasArmz, setAcessoTotalNfeEntradasArmz] = useState(false);
	//#endregion

	//#region Administracao Operacional -> Fiscal Entrada -> Nfe Entrada CrossDocking
	const [visualizarNfeEntradaCrossDocking, setVisualizarNfeEntradaCrossDocking] = useState(false);
	const [detalharNfeEntradaCrossDocking, setDetalharNfeEntradaCrossDocking] = useState(false);
	const [acessoTotalNfeEntradaCrossDocking, setAcessoTotalNfeEntradaCrossDocking] = useState(false);
	//#endregion

	//#region Administracao Operacional -> Fiscal Entrada -> Nfe Pedido
	const [visualizarNfePedido, setVisualizarNfePedido] = useState(false);
	const [detalharNfePedido, setDetalharNfePedido] = useState(false);
	const [excluirNfePedido, setExcluirNfePedido] = useState(false);
	const [acessoTotalNfePedido, setAcessoTotalNfePedido] = useState(false);
	//#endregion

	//#region Administracao Operacional -> Fiscal Saída -> Saldo Fiscal
	const [visualizarSaldoFiscal, setVisualizarSaldoFiscal] = useState(false);
	//#endregion

	//#region Administracao Operacional -> Fiscal Saída -> Nfe Emitido
	const [visualizarNfeEmitido, setVisualizarNfeEmitido] = useState(false);
	const [detalharNfeEmitido, setDetalharNfeEmitido] = useState(false);
	const [acessoTotalNfeEmitido, setAcessoTotalNfeEmitido] = useState(false);
	//#endregion

	//#region Administracao Operacional -> Fiscal Saída -> Emissao Nfe
	const [visualizarEmissaoNfe, setVisualizarEmissaoNfe] = useState(false);
	const [criarEmissaoNfe, setCriarEmissaoNfe] = useState(false);
	const [editarEmissaoNfe, setEditarEmissaoNfe] = useState(false);
	const [excluirEmissaoNfe, setExcluirEmissaoNfe] = useState(false);
	const [acessoTotalEmissaoNfe, setAcessoTotalEmissaoNfe] = useState(false);
	//#endregion

	//#region Administracao Operacional -> Fiscal Saída -> Manifestar Nfe
	const [visualizarManifestarNfe, setVisualizarManifestarNfe] = useState(false);
	const [manifestarNfe, setManifestarNfe] = useState(false);
	const [acessoTotalManifestarNfe, setAcessoTotalManifestarNfe] = useState(false);
	//#endregion

	//#region Administracao Operacional -> Processo -> Processo de Entrada
	const [visualizarProcEntrada, setVisualizarProcEntrada] = useState(false);
	const [criarProcEntrada, setCriarProcEntrada] = useState(false);
	const [editarProcEntrada, setEditarProcEntrada] = useState(false);
	const [cancelarProcEntrada, setCancelarProcEntrada] = useState(false);
	const [acessoTotalProcEntrada, setAcessoTotalProcEntrada] = useState(false);
	//#endregion

	//#region Administracao Operacional -> Processo -> Processo de Saída
	const [visualizarProcSaida, setVisualizarProcSaida] = useState(false);
	const [criarProcSaida, setCriarProcSaida] = useState(false);
	const [editarProcSaida, setEditarProcSaida] = useState(false);
	const [cancelarProcSaida, setCancelarProcSaida] = useState(false);
	const [acessoTotalProcSaida, setAcessoTotalProcSaida] = useState(false);
	//#endregion

	//#region Administracao Operacional -> Pedidos -> Criação de Pedido -> Importação de Arquivo
	const [visualizarImportacaoArquivo, setVisualizarImportacaoArquivo] = useState(false);
	const [importarArquivo, setImportarArquivo] = useState(false);
	const [acessoTotalImportacaoArquivo, setAcessoTotalImportacaoArquivo] = useState(false);
	//#endregion

	//#region Administracao Operacional -> Pedidos -> Criação de Pedido -> NFe para Pedido
	const [visualizarNfeParaPedido, setVisualizarNfeParaPedido] = useState(false);
	const [importarNfeParaPedido, setImportarNfeParaPedido] = useState(false);
	const [acessoTotalNfeParaPedido, setAcessoTotalNfeParaPedido] = useState(false);
	//#endregion

	//#region Administracao Operacional -> Pedidos -> Criação de Pedido -> Pedido Manual
	const [visualizarPedidoManual, setVisualizarPedidoManual] = useState(false);
	const [adicionarItemPedidoManual, setAdicionarItemPedidoManual] = useState(false);
	const [excluirItemPedidoManual, setExcluirItemPedidoManual] = useState(false);
	const [criarPedidoManual, setCriarPedidoManual] = useState(false);
	const [acessoTotalPedidoManual, setAcessoTotalPedidoManual] = useState(false);
	//#endregion

	//#region Administracao Operacional -> Pedidos -> Pedidos Pendentes
	const [visualizarPedidosPendentes, setVisualizarPedidosPendentes] = useState(false);
	const [quebrarPedidoTarefas, setQuebrarPedidoTarefas] = useState(false);
	const [liberarPedidoParaPicking, setLiberarPedidoParaPicking] = useState(false);
	const [edtiarItemPedidoPendente, setEdtiarItemPedidoPendente] = useState(false);
	const [excluirItemPedidoPendente, setExcluirItemPedidoPendente] = useState(false);
	const [editarPedidoPendente, setEditarPedidoPendente] = useState(false);
	const [excluirPedidoPendente, setExcluirPedidoPendente] = useState(false);
	const [acessoTotalPedidosPendentes, setAcessoTotalPedidosPendentes] = useState(false);
	//#endregion

	//#region Administracao Operacional -> Pedidos -> Pedidos em Separação
	const [visualizarPedidosSep, setVisualizarPedidosSep] = useState(false);
	//#endregion

	//#region Administracao Operacional -> Inventário -> Inventário
	const [visualizarInventario, setVisualizarInventario] = useState(false);
	//#endregion

	//#region Administracao Operacional -> Ajustes -> Ajuste de Estoque
	const [visualizarAjusteEstoque, setVisualizarAjusteEstoque] = useState(false);
	const [detalharAjusteEstoque, setDetalharAjusteEstoque] = useState(false);
	const [acessoTotalAjusteEstoque, setAcessoTotalAjusteEstoque] = useState(false);
	//#endregion

	//#region Administracao Operacional -> Ajustes -> Ajustes em Recebimento
	const [visualizarAjusteRecebimento, setVisualizarAjusteRecebimento] = useState(false);
	//#endregion

	//#region Administracao Operacional -> Ajustes -> Ajustes de Movimento
	const [visualizarAjusteMovimento, setVisualizarAjusteMovimento] = useState(false);
	const [detalharAjusteMovimento, setDetalharAjusteMovimento] = useState(false);
	const [excluirItemAjusteMovimento, setExcluirItemAjusteMovimento] = useState(false);
	const [acessoTotalAjusteMovimento, setAcessoTotalAjusteMovimento] = useState(false);
	//#endregion

	//#region Estoque -> Estoque Detalhado
	const [visualizarEstoqueDetalhado, setVisualizarEstoqueDetalhado] = useState(false);
	const [detalharPorUAEstoqueDetalhado, setDetalharPorUAEstoqueDetalhado] = useState(false);
	const [gerarExcelDetalharPorUAEstoqueDetalhado, setGerarExcelDetalharPorUAEstoqueDetalhado] = useState(false);
	const [gerarResumoValidadeEstoqueDetalhado, setGerarResumoValidadeEstoqueDetalhado] = useState(false);
	const [gerarExcelResumoValidadeEstoqueDetalhado, setGerarExcelResumoValidadeEstoqueDetalhado] = useState(false);
	const [gerarPDFResumoValidadeEstoqueDetalhado, setGerarPDFResumoValidadeEstoqueDetalhado] = useState(false);
	const [enviarEstoqueEmailEstoqueDetalhado, setEnviarEstoqueEmailEstoqueDetalhado] = useState(false);
	const [bloquearDesbloquearItemEstoqueDetalhado, setBloquearDesbloquearItemEstoqueDetalhado] = useState(false);
	const [visualizarEmpenhoEstoqueDetalhado, setVisualizarEmpenhoEstoqueDetalhado] = useState(false);
	const [visualizarBarrasUAEstoqueDetalhado, setVisualizarBarrasUAEstoqueDetalhado] = useState(false);
	const [adicionarBarrasUAEstoqueDetalhado, setAdicionarBarrasUAEstoqueDetalhado] = useState(false);
	const [editarBarrasUAEstoqueDetalhado, setEditarBarrasUAEstoqueDetalhado] = useState(false);
	const [excluirBarrasUAEstoqueDetalhado, setExcluirBarrasUAEstoqueDetalhado] = useState(false);
	const [acessoTotalEstoqueDetalhado, setAcessoTotalEstoqueDetalhado] = useState(false);

	//#endregion

	//#region Estoque -> Estoque Sintético
	const [visualizarEstoqueSintetico, setVisualizarEstoqueSintetico] = useState(false);
	const [gerarExcelEstoqueSintetico, setGerarExcelEstoqueSintetico] = useState(false);
	const [acessoTotalEstoqueSintetico, setAcessoTotalEstoqueSintetico] = useState(false);
	//#endregion

	//#region Estoque -> Romaneio de Barras Ativas
	const [visualizarRomaneioBarrasAtivas, setVisualizarRomaneioBarrasAtivas] = useState(false);
	const [criarRomaneioBarrasAtivas, setCriarRomaneioBarrasAtivas] = useState(false);
	const [imprimirTodasBarrasRomaneioBarrasAtivas, setImprimirTodasBarrasRomaneioBarrasAtivas] = useState(false);
	const [gerarExcelRomaneioBarrasAtivas, setGerarExcelRomaneioBarrasAtivas] = useState(false);
	const [verTodasRomaneioBarrasAtivas, setVerTodasRomaneioBarrasAtivas] = useState(false);
	const [gerarExcelDetalhesRomaneioBarrasAtivas, setGerarExcelDetalhesRomaneioBarrasAtivas] = useState(false);
	const [editarRomaneioBarrasAtivas, setEditarRomaneioBarrasAtivas] = useState(false);
	const [excluirRomaneioBarrasAtivas, setExcluirRomaneioBarrasAtivas] = useState(false);
	const [imprimirItemRomaneioBarrasAtivas, setImprimirItemRomaneioBarrasAtivas] = useState(false);
	const [acessoTotalRomaneioBarrasAtivas, setAcessoTotalRomaneioBarrasAtivas] = useState(false);
	//#endregion

	//#region Estoque -> UA Vazio/Saida Sem Uso
	const [visualizarUAVazioSaidaSemUsoVazio, setVisualizarUAVazioSaidaSemUsoVazio] = useState(false);
	const [excluirUAVazioSaidaSemUsoVazio, setExcluirUAVazioSaidaSemUsoVazio] = useState(false);
	const [imprimirUAVazioSaidaSemUsoVazio, setImprimirUAVazioSaidaSemUsoVazio] = useState(false);
	const [visualizarUAVazioSaidaSemUsoSaida, setVisualizarUAVazioSaidaSemUsoSaida] = useState(false);
	const [excluirUAVazioSaidaSemUsoSaida, setExcluirUAVazioSaidaSemUsoSaida] = useState(false);
	const [imprimirUAVazioSaidaSemUsoSaida, setImprimirUAVazioSaidaSemUsoSaida] = useState(false);
	const [acessoTotalUAVazioSaidaSemUso, setAcessoTotalUAVazioSaidaSemUso] = useState(false);
	//#endregion

	//#region Operacional -> Conferencia de Entrada -> Conferir Recebimento
	const [visualizarConferirRecebimento, setVisualizarConferirRecebimento] = useState(false);
	//#endregion

	//#region Operacional -> Conferencia de Saída -> Conferir Carregamento
	const [visualizarConferirCarregamento, setVisualizarConferirCarregamento] = useState(false);
	//#endregion

	//#region Operacional -> Endereçar -> Endereçar U.A.
	const [visualizarEnderecarUA, setVisualizarEnderecarUA] = useState(false);
	//#endregion

	//#region Operacional -> Desfragmentação -> Desfragmentar U.A.
	const [visualizarDesfragmentarUA, setVisualizarDesfragmentarUA] = useState(false);
	//#endregion

	//#region Operacional -> Desfragmentação -> Análise de Otimização
	const [visualizarAnaliseOtimizacao, setVisualizarAnaliseOtimizacao] = useState(false);
	//#endregion

	//#region Operacional -> Reposição de Picking -> Reposição Primária
	const [visualizarReposicaoPrimaria, setVisualizarReposicaoPrimaria] = useState(false);
	//#endregion
	//#region Operacional -> Reposição de Picking -> Reposição Complementar
	const [visualizarReposicaoComplementar, setVisualizarReposicaoComplementar] = useState(false);
	//#endregion

	//#region Operacional -> Impressão de Etiquetas -> Impressão de Etiquetas
	const [visualizarImpressaoEtiquetas, setVisualizarImpressaoEtiquetas] = useState(false);
	const [acessoTotalImpressaoEtiquetas, setAcessoTotalImpressaoEtiquetas] = useState(false);
	//#endregion

	//#region Operacional -> Impressão de Etiquetas -> Impressão de Etiquetas - Reimpressao U.A.
	const [visualizarImpEtqReimpressaoUA, setVisualizarImpEtqReimpressaoUA] = useState(false);
	//#endregion

	//#region Operacional -> Impressão de Etiquetas -> Impressão de Etiquetas - Gerar U.A. Vazio/Saída
	const [visualizarImpEtqGerarUAVazioSaida, setVisualizarImpEtqGerarUAVazioSaida] = useState(false);
	//#endregion

	//#region Operacional -> Impressão de Etiquetas -> Impressão de Etiquetas - Endereço
	const [visualizarImpEtqImpressaoEndereco, setVisualizarImpEtqImpressaoEndereco] = useState(false);
	//#endregion
	//#region Operacional -> Impressão de Etiquetas -> Impressão de Etiquetas - Produto Picking
	const [visualizarImpEtqImpressaoProdutoPicking, setVisualizarImpEtqImpressaoProdutoPicking] = useState(false);
	//#endregion

	//#region Gerencial -> Exceção -> Quebra-FEFO
	const [visualizarExcecaoQuebraFefo, setVisualizarExcecaoQuebraFefo] = useState(false);
	//#endregion
	//#region Gerencial -> Exceção -> Ler GTIN
	const [visualizarExcecaoLerGtin, setVisualizarExcecaoLerGtin] = useState(false);
	//#endregion
	//#region Gerencial -> Exceção -> Ler Volume
	const [visualizarExcecaoLerVolume, setVisualizarExcecaoLerVolume] = useState(false);
	//#endregion
	//#region Gerencial -> Exceção -> Endereco
	const [visualizarExcecaoEndereco, setVisualizarExcecaoEndereco] = useState(false);
	//#endregion
	//#region Gerencial -> Exceção -> Zona de Endereçamento
	const [visualizarExcecaoZonaEnderecamento, setVisualizarExcecaoZonaEnderecamento] = useState(false);
	//#endregion

	//#region Relatórios -> Rastreabilidade -> Rastreabilidade Produto
	const [visualizarRelRastreabilidadeProduto, setVisualizarRelRastreabilidadeProduto] = useState(false);
	//#endregion
	//#region Relatórios -> Pedidos -> Romaneio de Carga
	const [visualizarRelRomaneioCarga, setVisualizarRelRomaneioCarga] = useState(false);
	//#endregion
	//#region Relatórios -> Movimentação -> Movimentação Período
	const [visualizarRelMovimentacaoPeriodo, setVisualizarRelMovimentacaoPeriodo] = useState(false);
	//#endregion
	//#region Relatórios -> Movimentação -> Curva ABC
	const [visualizarRelCurvaABC, setVisualizarRelCurvaABC] = useState(false);
	//#endregion
	//#region Relatórios -> Movimentação -> Histórico de Endereçamento
	const [visualizarRelHistoricoEnderecamento, setVisualizarRelHistoricoEnderecamento] = useState(false);
	//#endregion

	//#region Faturamento de Serviços -> Tarifas -> Cadastrar Tarifas
	const [visualizarCadastrarTarifas, setVisualizarCadastrarTarifas] = useState(false);
	const [criarCadastrarTarifas, setCriarCadastrarTarifas] = useState(false);
	const [editarCadastrarTarifas, setEditarCadastrarTarifas] = useState(false);
	const [excluirCadastrarTarifas, setExcluirCadastrarTarifas] = useState(false);
	const [acessoTotalCadastrarTarifas, setAcessoTotalCadastrarTarifas] = useState(false);
	//#endregion
	//#region Faturamento de Serviços -> Corte Período -> Gerar Corte
	const [visualizarGerarCorte, setVisualizarGerarCorte] = useState(false);
	//#endregion
	//#region Faturamento de Serviços -> Corte Período -> Estornar Corte
	const [visualizarEstornarCorte, setVisualizarEstornarCorte] = useState(false);
	//#endregion
	//#region Faturamento de Serviços -> Emissão de Faturas -> Emissão de Fatura
	const [visualizarEmissaoFatura, setVisualizarEmissaoFatura] = useState(false);
	//#endregion
	//#region Faturamento de Serviços -> Serviço Extra -> Lançar Serviço Extra
	const [visualizarLancarServicoExtra, setVisualizarLancarServicoExtra] = useState(false);
	//#endregion
	//#region Faturamento de Serviços -> Liberar Faturamento -> Bonificar Faturamento
	const [visualizarBonificarFaturamento, setVisualizarBonificarFaturamento] = useState(false);
	//#endregion
	//#region Indicadores -> Movimentação
	const [visualizarIndicadoresMovimentacao, setVisualizarIndicadoresMovimentacao] = useState(false);
	//#endregion
	//#region Indicadores -> Produtividade
	const [visualizarIndicadoresProdutividade, setVisualizarIndicadoresProdutividade] = useState(false);
	//#endregion
	//#region Indicadores -> Acuracidade
	const [visualizarIndicadoresAcuracidade, setVisualizarIndicadoresAcuracidade] = useState(false);
	//#endregion
	//#region Log's do Sistema -> Log de Ação do Usuário
	const [visualizarLogAcaoUsuario, setVisualizarLogAcaoUsuario] = useState(false);
	//#endregion
	//#region Log's do Sistema -> Log API Pedidos
	const [visualizarLogApiPedidos, setVisualizarLogApiPedidos] = useState(false);
	//#endregion
	//#region Log's do Sistema -> Log Integração
	const [visualizarLogIntegracao, setVisualizarLogIntegracao] = useState(false);
	//#endregion

	// PERMISSOES ESPECIAIS
	const [procEntradaAprovaDivQtdeReceb, setProcEntradaAprovaDivQtdeReceb] = useState(false);
	const [endExcecaoEndereco, setEndExcecaoEndereco] = useState(false);
	const [endExcecaoZonaEnderecamento, setEndExcecaoZonaEnderecamento] = useState(false);
	const [pedidoManutencaoPedido, setPedidoManutencaoPedido] = useState(false);
	const [pedidoAutorizaQuebraFefoPicking, setPedidoAutorizaQuebraFefoPicking] = useState(false);
	const [confCargaExcLeituraGtin, setConfCargaExcLeituraGtin] = useState(false);
	const [confCargaExcLeituraVolume, setConfCargaExcLeituraVolume] = useState(false);
	const [pesagemAprovaDivBalanRodviaria, setPesagemAprovaDivBalanRodviaria] = useState(false);
	const [pesagemAprovaDivBalancaOS, setPesagemAprovaDivBalancaOS] = useState(false);
	const [estoqueRealizaAjusteQualitativo, setEstoqueRealizaAjusteQualitativo] = useState(false);
	const [estoqueRealizaAjusteQuantitativo, setEstoqueRealizaAjusteQuantitativo] = useState(false);
	const [estoqueRealizaDesfragEstoque, setEstoqueRealizaDesfragEstoque] = useState(false);
	const [estoqueAlteraDadosBarra, setEstoqueAlteraDadosBarra] = useState(false);
	const [inventarioDefineToleranciaQuebraInv, setInventarioDefineToleranciaQuebraInv] = useState(false);
	const [inventarioAprovaDivergenciaInventarioAcimaTolerancia, setInventarioAprovaDivergenciaInventarioAcimaTolerancia] = useState(false);
	const [ajusteEstoque, setAjusteEstoque] = useState(false);
	const [ajusteEntrada, setAjusteEntrada] = useState(false);
	const [ajusteMovimentacao, setAjusteMovimentacao] = useState(false);
	const [aprovaValidadeVencida, setAprovaValidadeVencida] = useState(false)

	const dadosEnvio = {
		permissoes: {
			id_filial: id_filial,
			nomePerfil: nomePerfil,
			nivel: nivel,
			situacao: situacao ? 1 : 0,
			isMaster: isMaster ? 1 : 0,
			usuarioCadastro: usuarioCadastro,
			visualizarUsuario: visualizarUsuario ? 1 : 0,
			criarUsuario: criarUsuario ? 1 : 0,
			editarUsuario: editarUsuario ? 1 : 0,
			excluirUsuario: excluirUsuario ? 1 : 0,
			visualizarPerfilPermissao: visualizarPerfilPermissao ? 1 : 0,
			criarPerfilPermissao: criarPerfilPermissao ? 1 : 0,
			editarPerfilPermissao: editarPerfilPermissao ? 1 : 0,
			excluirPerfilPermissao: excluirPerfilPermissao ? 1 : 0,
			visualizarUsuariosFilial: visualizarUsuariosFilial ? 1 : 0,
			visualizarAtividadesUsuario: visualizarAtividadesUsuario ? 1 : 0,
			visualizarCodSistema: visualizarCodSistema ? 1 : 0,
			criarCodSistema: criarCodSistema ? 1 : 0,
			editarCodSistema: editarCodSistema ? 1 : 0,
			excluirCodSistema: excluirCodSistema ? 1 : 0,
			visualizarNfeEntradasArmz: visualizarNfeEntradasArmz ? 1 : 0,
			detalharNfeEntradaArmz: detalharNfeEntradaArmz ? 1 : 0,
			importarNfeEntradasArmz: importarNfeEntradasArmz ? 1 : 0,
			excluirNfeEntradasArmz: excluirNfeEntradasArmz ? 1 : 0,
			visualizarCadastroFilial: visualizarCadastroFilial ? 1 : 0,
			criarCadastroFilial: criarCadastroFilial ? 1 : 0,
			editarCadastroFilial: editarCadastroFilial ? 1 : 0,
			excluirCadastroFilial: excluirCadastroFilial ? 1 : 0,
			visualizarControleNumeracao: visualizarControleNumeracao ? 1 : 0,
			criarControleNumeracao: criarControleNumeracao ? 1 : 0,
			editarControleNumeracao: editarControleNumeracao ? 1 : 0,
			excluirControleNumeracao: excluirControleNumeracao ? 1 : 0,

			visualizarEntidade: visualizarEntidade ? 1 : 0,
			criarEntidade: criarEntidade ? 1 : 0,
			editarEntidade: editarEntidade ? 1 : 0,
			excluirEntidade: excluirEntidade ? 1 : 0,
			acessoTotalEntidade: acessoTotalEntidade ? 1 : 0,
			visualizarTransportadora: visualizarTransportadora ? 1 : 0,
			vincularTransportadoraDepositante: vincularTransportadoraDepositante ? 1 : 0,
			acessoTotalTransportadora: acessoTotalTransportadora ? 1 : 0,
			visualizarDepositante: visualizarDepositante ? 1 : 0,
			criarDepositante: criarDepositante ? 1 : 0,
			editarDepositante: editarDepositante ? 1 : 0,
			excluirDepositante: excluirDepositante ? 1 : 0,
			acessoTotalDepositante: acessoTotalDepositante ? 1 : 0,
			visualizarProdutosArmazenagem: visualizarProdutosArmazenagem ? 1 : 0,
			criarProdutosArmazenagem: criarProdutosArmazenagem ? 1 : 0,
			editarProdutosArmazenagem: editarProdutosArmazenagem ? 1 : 0,
			excluirProdutosArmazenagem: excluirProdutosArmazenagem ? 1 : 0,
			acessoTotalProdutosArmazenagem: acessoTotalProdutosArmazenagem ? 1 : 0,
			visualizarProdutosFornecedor: visualizarProdutosFornecedor ? 1 : 0,
			criarProdutosFornecedor: criarProdutosFornecedor ? 1 : 0,
			editarProdutosFornecedor: editarProdutosFornecedor ? 1 : 0,
			excluirProdutosFornecedor: excluirProdutosFornecedor ? 1 : 0,
			acessoTotalProdutosFornecedor: acessoTotalProdutosFornecedor ? 1 : 0,
			visualizarProdutosCross: visualizarProdutosCross ? 1 : 0,
			criarProdutosCross: criarProdutosCross ? 1 : 0,
			editarProdutosCross: editarProdutosCross ? 1 : 0,
			excluirProdutosCross: excluirProdutosCross ? 1 : 0,
			acessoTotalProdutosCross: acessoTotalProdutosCross ? 1 : 0,
			visualizarProdutosPicking: visualizarProdutosPicking ? 1 : 0,
			criarProdutosPicking: criarProdutosPicking ? 1 : 0,
			editarProdutosPicking: editarProdutosPicking ? 1 : 0,
			excluirProdutosPicking: excluirProdutosPicking ? 1 : 0,
			acessoTotalProdutosPicking: acessoTotalProdutosPicking ? 1 : 0,
			visualizarEnderecosArmz: visualizarEnderecosArmz ? 1 : 0,
			criarEnderecosArmz: criarEnderecosArmz ? 1 : 0,
			editarEnderecosArmz: editarEnderecosArmz ? 1 : 0,
			editarTempEnderecosArmz: editarTempEnderecosArmz ? 1 : 0,
			excluirEnderecosArmz: excluirEnderecosArmz ? 1 : 0,
			acessoTotalEnderecosArmz: acessoTotalEnderecosArmz ? 1 : 0,
			visualizarVeiculos: visualizarVeiculos ? 1 : 0,
			criarVeiculos: criarVeiculos ? 1 : 0,
			editarVeiculos: editarVeiculos ? 1 : 0,
			excluirVeiculos: excluirVeiculos ? 1 : 0,
			acessoTotalVeiculos: acessoTotalVeiculos ? 1 : 0,
			visualizarMotoristas: visualizarMotoristas ? 1 : 0,
			criarMotoristas: criarMotoristas ? 1 : 0,
			editarMotoristas: editarMotoristas ? 1 : 0,
			excluirMotoristas: excluirMotoristas ? 1 : 0,
			acessoTotalMotoristas: acessoTotalMotoristas ? 1 : 0,
			visualizarEquipamento: visualizarEquipamento ? 1 : 0,
			criarEquipamento: criarEquipamento ? 1 : 0,
			editarEquipamento: editarEquipamento ? 1 : 0,
			excluirEquipamento: excluirEquipamento ? 1 : 0,
			acessoTotalEquipamento: acessoTotalEquipamento ? 1 : 0,
			visualizarColaborador: visualizarColaborador ? 1 : 0,
			criarColaborador: criarColaborador ? 1 : 0,
			editarColaborador: editarColaborador ? 1 : 0,
			excluirColaborador: excluirColaborador ? 1 : 0,
			acessoTotalColaborador: acessoTotalColaborador ? 1 : 0,
			visualizarCFOP: visualizarCFOP ? 1 : 0,
			importarArqCFOP: importarArqCFOP ? 1 : 0,
			baixarModeloCFOP: baixarModeloCFOP ? 1 : 0,
			criarCFOP: criarCFOP ? 1 : 0,
			editarCFOP: editarCFOP ? 1 : 0,
			excluirCFOP: excluirCFOP ? 1 : 0,
			acessoTotalCFOP: acessoTotalCFOP ? 1 : 0,
			visualizarCFOPEntradaSaida: visualizarCFOPEntradaSaida ? 1 : 0,
			criarCFOPEntradaSaida: criarCFOPEntradaSaida ? 1 : 0,
			editarCFOPEntradaSaida: editarCFOPEntradaSaida ? 1 : 0,
			excluirCFOPEntradaSaida: excluirCFOPEntradaSaida ? 1 : 0,
			acessoTotalCFOPEntradaSaida: acessoTotalCFOPEntradaSaida ? 1 : 0,
			visualizarGrupoTributario: visualizarGrupoTributario ? 1 : 0,
			criarGrupoTributario: criarGrupoTributario ? 1 : 0,
			editarGrupoTributario: editarGrupoTributario ? 1 : 0,
			excluirGrupoTributario: excluirGrupoTributario ? 1 : 0,
			acessoTotalGrupoTributario: acessoTotalGrupoTributario ? 1 : 0,

			visualizarNfeEntradaCrossDocking: visualizarNfeEntradaCrossDocking ? 1 : 0,
			detalharNfeEntradaCrossDocking: detalharNfeEntradaCrossDocking ? 1 : 0,
			visualizarNfePedido: visualizarNfePedido ? 1 : 0,
			detalharNfePedido: detalharNfePedido ? 1 : 0,
			excluirNfePedido: excluirNfePedido ? 1 : 0,
			visualizarSaldoFiscal: visualizarSaldoFiscal ? 1 : 0,
			visualizarNfeEmitido: visualizarNfeEmitido ? 1 : 0,
			detalharNfeEmitido: detalharNfeEmitido ? 1 : 0,
			visualizarEmissaoNfe: visualizarEmissaoNfe ? 1 : 0,
			criarEmissaoNfe: criarEmissaoNfe ? 1 : 0,
			editarEmissaoNfe: editarEmissaoNfe ? 1 : 0,
			excluirEmissaoNfe: excluirEmissaoNfe ? 1 : 0,
			visualizarManifestarNfe: visualizarManifestarNfe ? 1 : 0,
			manifestarNfe: manifestarNfe ? 1 : 0,
			visualizarProcEntrada: visualizarProcEntrada ? 1 : 0,
			criarProcEntrada: criarProcEntrada ? 1 : 0,
			editarProcEntrada: editarProcEntrada ? 1 : 0,
			cancelarProcEntrada: cancelarProcEntrada ? 1 : 0,
			visualizarProcSaida: visualizarProcSaida ? 1 : 0,
			criarProcSaida: criarProcSaida ? 1 : 0,
			editarProcSaida: editarProcSaida ? 1 : 0,
			cancelarProcSaida: cancelarProcSaida ? 1 : 0,
			visualizarImportacaoArquivo: visualizarImportacaoArquivo ? 1 : 0,
			importarArquivo: importarArquivo ? 1 : 0,
			visualizarNfeParaPedido: visualizarNfeParaPedido ? 1 : 0,
			importarNfeParaPedido: importarNfeParaPedido ? 1 : 0,
			visualizarPedidoManual: visualizarPedidoManual ? 1 : 0,
			adicionarItemPedidoManual: adicionarItemPedidoManual ? 1 : 0,
			excluirItemPedidoManual: excluirItemPedidoManual ? 1 : 0,
			criarPedidoManual: criarPedidoManual ? 1 : 0,
			visualizarPedidosPendentes: visualizarPedidosPendentes ? 1 : 0,
			quebrarPedidoTarefas: quebrarPedidoTarefas ? 1 : 0,
			liberarPedidoParaPicking: liberarPedidoParaPicking ? 1 : 0,
			edtiarItemPedidoPendente: edtiarItemPedidoPendente ? 1 : 0,
			excluirItemPedidoPendente: excluirItemPedidoPendente ? 1 : 0,
			editarPedidoPendente: editarPedidoPendente ? 1 : 0,
			excluirPedidoPendente: excluirPedidoPendente ? 1 : 0,
			visualizarPedidosSep: visualizarPedidosSep ? 1 : 0,
			visualizarInventario: visualizarInventario ? 1 : 0,
			visualizarAjusteEstoque: visualizarAjusteEstoque ? 1 : 0,
			detalharAjusteEstoque: detalharAjusteEstoque ? 1 : 0,
			visualizarAjusteRecebimento: visualizarAjusteRecebimento ? 1 : 0,
			visualizarAjusteMovimento: visualizarAjusteMovimento ? 1 : 0,
			detalharAjusteMovimento: detalharAjusteMovimento ? 1 : 0,
			excluirItemAjusteMovimento: excluirItemAjusteMovimento ? 1 : 0,
			visualizarEstoqueDetalhado: visualizarEstoqueDetalhado ? 1 : 0,
			detalharPorUAEstoqueDetalhado: detalharPorUAEstoqueDetalhado ? 1 : 0,
			gerarExcelDetalharPorUAEstoqueDetalhado: gerarExcelDetalharPorUAEstoqueDetalhado ? 1 : 0,
			gerarResumoValidadeEstoqueDetalhado: gerarResumoValidadeEstoqueDetalhado ? 1 : 0,
			gerarExcelResumoValidadeEstoqueDetalhado: gerarExcelResumoValidadeEstoqueDetalhado ? 1 : 0,
			gerarPDFResumoValidadeEstoqueDetalhado: gerarPDFResumoValidadeEstoqueDetalhado ? 1 : 0,
			enviarEstoqueEmailEstoqueDetalhado: enviarEstoqueEmailEstoqueDetalhado ? 1 : 0,
			bloquearDesbloquearItemEstoqueDetalhado: bloquearDesbloquearItemEstoqueDetalhado ? 1 : 0,
			visualizarEmpenhoEstoqueDetalhado: visualizarEmpenhoEstoqueDetalhado ? 1 : 0,
			visualizarBarrasUAEstoqueDetalhado: visualizarBarrasUAEstoqueDetalhado ? 1 : 0,
			adicionarBarrasUAEstoqueDetalhado: adicionarBarrasUAEstoqueDetalhado ? 1 : 0,
			editarBarrasUAEstoqueDetalhado: editarBarrasUAEstoqueDetalhado ? 1 : 0,
			excluirBarrasUAEstoqueDetalhado: excluirBarrasUAEstoqueDetalhado ? 1 : 0,
			visualizarEstoqueSintetico: visualizarEstoqueSintetico ? 1 : 0,
			gerarExcelEstoqueSintetico: gerarExcelEstoqueSintetico ? 1 : 0,
			visualizarRomaneioBarrasAtivas: visualizarRomaneioBarrasAtivas ? 1 : 0,
			criarRomaneioBarrasAtivas: criarRomaneioBarrasAtivas ? 1 : 0,
			imprimirTodasBarrasRomaneioBarrasAtivas: imprimirTodasBarrasRomaneioBarrasAtivas ? 1 : 0,
			gerarExcelRomaneioBarrasAtivas: gerarExcelRomaneioBarrasAtivas ? 1 : 0,
			verTodasRomaneioBarrasAtivas: verTodasRomaneioBarrasAtivas ? 1 : 0,
			gerarExcelDetalhesRomaneioBarrasAtivas: gerarExcelDetalhesRomaneioBarrasAtivas ? 1 : 0,
			editarRomaneioBarrasAtivas: editarRomaneioBarrasAtivas ? 1 : 0,
			excluirRomaneioBarrasAtivas: excluirRomaneioBarrasAtivas ? 1 : 0,
			imprimirItemRomaneioBarrasAtivas: imprimirItemRomaneioBarrasAtivas ? 1 : 0,
			visualizarUAVazioSaidaSemUsoVazio: visualizarUAVazioSaidaSemUsoVazio ? 1 : 0,
			excluirUAVazioSaidaSemUsoVazio: excluirUAVazioSaidaSemUsoVazio ? 1 : 0,
			imprimirUAVazioSaidaSemUsoVazio: imprimirUAVazioSaidaSemUsoVazio ? 1 : 0,
			visualizarUAVazioSaidaSemUsoSaida: visualizarUAVazioSaidaSemUsoSaida ? 1 : 0,
			excluirUAVazioSaidaSemUsoSaida: excluirUAVazioSaidaSemUsoSaida ? 1 : 0,
			imprimirUAVazioSaidaSemUsoSaida: imprimirUAVazioSaidaSemUsoSaida ? 1 : 0,
			visualizarConferirRecebimento: visualizarConferirRecebimento ? 1 : 0,
			visualizarConferirCarregamento: visualizarConferirCarregamento ? 1 : 0,
			visualizarEnderecarUA: visualizarEnderecarUA ? 1 : 0,
			visualizarDesfragmentarUA: visualizarDesfragmentarUA ? 1 : 0,
			visualizarAnaliseOtimizacao: visualizarAnaliseOtimizacao ? 1 : 0,
			visualizarReposicaoPrimaria: visualizarReposicaoPrimaria ? 1 : 0,
			visualizarReposicaoComplementar: visualizarReposicaoComplementar ? 1 : 0,
			visualizarImpressaoEtiquetas: visualizarImpressaoEtiquetas ? 1 : 0,
			visualizarImpEtqReimpressaoUA: visualizarImpEtqReimpressaoUA ? 1 : 0,
			visualizarImpEtqGerarUAVazioSaida: visualizarImpEtqGerarUAVazioSaida ? 1 : 0,
			visualizarImpEtqImpressaoEndereco: visualizarImpEtqImpressaoEndereco ? 1 : 0,
			visualizarImpEtqImpressaoProdutoPicking: visualizarImpEtqImpressaoProdutoPicking ? 1 : 0,
			visualizarExcecaoQuebraFefo: visualizarExcecaoQuebraFefo ? 1 : 0,
			visualizarExcecaoLerGtin: visualizarExcecaoLerGtin ? 1 : 0,
			visualizarExcecaoLerVolume: visualizarExcecaoLerVolume ? 1 : 0,
			visualizarExcecaoEndereco: visualizarExcecaoEndereco ? 1 : 0,
			visualizarExcecaoZonaEnderecamento: visualizarExcecaoZonaEnderecamento ? 1 : 0,
			visualizarRelRastreabilidadeProduto: visualizarRelRastreabilidadeProduto ? 1 : 0,
			visualizarRelRomaneioCarga: visualizarRelRomaneioCarga ? 1 : 0,
			visualizarRelMovimentacaoPeriodo: visualizarRelMovimentacaoPeriodo ? 1 : 0,
			visualizarRelCurvaABC: visualizarRelCurvaABC ? 1 : 0,
			visualizarRelHistoricoEnderecamento: visualizarRelHistoricoEnderecamento ? 1 : 0,
			visualizarCadastrarTarifas: visualizarCadastrarTarifas ? 1 : 0,
			criarCadastrarTarifas: criarCadastrarTarifas ? 1 : 0,
			editarCadastrarTarifas: editarCadastrarTarifas ? 1 : 0,
			excluirCadastrarTarifas: excluirCadastrarTarifas ? 1 : 0,
			visualizarGerarCorte: visualizarGerarCorte ? 1 : 0,
			visualizarEstornarCorte: visualizarEstornarCorte ? 1 : 0,
			visualizarEmissaoFatura: visualizarEmissaoFatura ? 1 : 0,
			visualizarLancarServicoExtra: visualizarLancarServicoExtra ? 1 : 0,
			visualizarBonificarFaturamento: visualizarBonificarFaturamento ? 1 : 0,
			visualizarIndicadoresMovimentacao: visualizarIndicadoresMovimentacao ? 1 : 0,
			visualizarIndicadoresProdutividade: visualizarIndicadoresProdutividade ? 1 : 0,
			visualizarIndicadoresAcuracidade: visualizarIndicadoresAcuracidade ? 1 : 0,
			visualizarLogAcaoUsuario: visualizarLogAcaoUsuario ? 1 : 0,
			visualizarLogApiPedidos: visualizarLogApiPedidos ? 1 : 0,
			visualizarLogIntegracao: visualizarLogIntegracao ? 1 : 0,
			visualizarPainelMonitoramento: visualizarPainelMonitoramento ? 1 : 0
		},
		permissoesEspeciais: {
			procEntradaAprovaDivQtdeReceb: procEntradaAprovaDivQtdeReceb ? 1 : 0,
			endExcecaoEndereco: endExcecaoEndereco ? 1 : 0,
			endExcecaoZonaEnderecamento: endExcecaoZonaEnderecamento ? 1 : 0,
			pedidoManutencaoPedido: pedidoManutencaoPedido ? 1 : 0,
			pedidoAutorizaQuebraFefoPicking: pedidoAutorizaQuebraFefoPicking ? 1 : 0,
			confCargaExcLeituraGtin: confCargaExcLeituraGtin ? 1 : 0,
			confCargaExcLeituraVolume: confCargaExcLeituraVolume ? 1 : 0,
			confCargaAprovaValVencida: aprovaValidadeVencida ? 1 : 0,
			pesagemAprovaDivBalanRodviaria: pesagemAprovaDivBalanRodviaria ? 1 : 0,
			pesagemAprovaDivBalancaOS: pesagemAprovaDivBalancaOS ? 1 : 0,
			estoqueRealizaAjusteQualitativo: estoqueRealizaAjusteQualitativo ? 1 : 0,
			estoqueRealizaAjusteQuantitativo: estoqueRealizaAjusteQuantitativo ? 1 : 0,
			estoqueRealizaDesfragEstoque: estoqueRealizaDesfragEstoque ? 1 : 0,
			estoqueAlteraDadosBarra: estoqueAlteraDadosBarra ? 1 : 0,
			inventarioDefineToleranciaQuebraInv: inventarioDefineToleranciaQuebraInv ? 1 : 0,
			inventarioAprovaDivergenciaInventarioAcimaTolerancia: inventarioAprovaDivergenciaInventarioAcimaTolerancia ? 1 : 0,
			ajusteEstoque: ajusteEstoque ? 1 : 0,
			ajusteEntrada: ajusteEntrada ? 1 : 0,
			ajusteMovimentacao: ajusteMovimentacao ? 1 : 0,
		},
	};

	function CriarPerfil(id_filial: string) {
		setShowModalCarregando(true);
		axios
			.post(Api + `/criar/perfil/novo/${id_filial}`, dadosEnvio, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setShowModalCarregando(false);
				setModalConfirmacao(false);
				toast.success(resposta.data.message, {
					autoClose: 900,
				});

				setTimeout(() => {
					navigate(-1);
				}, 1800);
			})
			.catch(function (erro) {
				setShowModalCarregando(false);
				setModalConfirmacao(false);
				toast.error(erro.response.data.message || erro.message, {
					autoClose: 900,
				});
			});
	}

	function CarregarPerfil(id_perfil: string, id_filial: string) {
		setShowModalCarregando(true);
		axios
			.get(Api + `/carregar/perfil/criado/${id_filial}/${id_perfil}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				const perfil = resposta.data.perfilPermissao;

				setNomePerfil(perfil.nome_perfil);
				setNivel(perfil.nivel.toString());
				setSituacao(perfil.situacao);
				setIsMaster(perfil.master);

				// PERMISSOES
				// Controlde de Acesso - Usuários
				setVisualizarUsuario(perfil.visualizarUsuario);
				setCriarUsuario(perfil.criarUsuario);
				setEditarUsuario(perfil.editarUsuario);
				setExcluirUsuario(perfil.excluirUsuario);
				setAcessoTotalUsuario(perfil.visualizarUsuario && perfil.criarUsuario && perfil.editarUsuario && perfil.excluirUsuario);
				// Controlde de Acesso - Perfil/Permissão
				setVisualizarPerfilPermissao(perfil.visualizarPerfilPermissao);
				setCriarPerfilPermissao(perfil.criarPerfilPermissao);
				setEditarPerfilPermissao(perfil.editarPerfilPermissao);
				setExcluirPerfilPermissao(perfil.excluirPerfilPermissao);
				setAcessoTotalPerfilPermissao(
					perfil.visualizarPerfilPermissao && perfil.criarPerfilPermissao && perfil.editarPerfilPermissao && perfil.excluirPerfilPermissao
				);
				// Controlde de Acesso - Usuários Filial
				setVisualizarUsuariosFilial(perfil.visualizarUsuariosFilial);
				// Controlde de Acesso - Atividades Usuário
				setVisualizarAtividadesUsuario(perfil.visualizarAtividadesUsuario);
				// Controle de Acesso - Painel de Monitoramento
				setVisualizarPainelMonitoramento(perfil.visualizarPainelMonitoramento)
				// Controlde de Acesso - Codigos Sistema
				setVisualizarCodSistema(perfil.visualizarCodSistema);
				setCriarCodSistema(perfil.criarCodSistema);
				setEditarCodSistema(perfil.editarCodSistema);
				setExcluirCodSistema(perfil.excluirCodSistema);
				setAcessoTotalCodigosSistema(
					perfil.visualizarCodSistema && perfil.criarCodSistema && perfil.editarCodSistema && perfil.excluirCodSistema
				);
				// Controlde de Acesso - Cadastro Administrativo - Cadastro de Filial
				setVisualizarCadastroFilial(perfil.visualizarCadastroFilial);
				setCriarCadastroFilial(perfil.criarCadastroFilial);
				setEditarCadastroFilial(perfil.editarCadastroFilial);
				setExcluirCadastroFilial(perfil.excluirCadastroFilial);
				setAcessoTotalCadastroFilial(
					perfil.visualizarCadastroFilial && perfil.criarCadastroFilial && perfil.editarCadastroFilial && perfil.excluirCadastroFilial
				);
				// Controlde de Acesso - Cadastro Administrativo - Controle de Numeracao
				setVisualizarControleNumeracao(perfil.visualizarControleNumeracao);
				setCriarControleNumeracao(perfil.criarControleNumeracao);
				setEditarControleNumeracao(perfil.editarControleNumeracao);
				setExcluirControleNumeracao(perfil.excluirControleNumeracao);
				setAcessoTotalControleNumeracao(
					perfil.visualizarControleNumeracao &&
					perfil.criarControleNumeracao &&
					perfil.editarControleNumeracao &&
					perfil.excluirControleNumeracao
				);

				// Cadastros - Entidade - Entidade
				setVisualizarEntidade(perfil.visualizarEntidade);
				setCriarEntidade(perfil.criarEntidade);
				setEditarEntidade(perfil.editarEntidade);
				setExcluirEntidade(perfil.excluirEntidade);
				setAcessoTotalEntidade(perfil.visualizarEntidade && perfil.criarEntidade && perfil.editarEntidade && perfil.excluirEntidade);
				// Cadastros - Entidade - Transportadora
				setVisualizarTransportadora(perfil.visualizarTransportadora);
				setVincularTransportadoraDepositante(perfil.vincularTransportadoraDepositante);
				setAcessoTotalTransportadora(perfil.visualizarTransportadora && perfil.vincularTransportadoraDepositante);
				// Cadastros - Depositante - Depositante
				setVisualizarDepositante(perfil.visualizarDepositante);
				setCriarDepositante(perfil.criarDepositante);
				setEditarDepositante(perfil.editarDepositante);
				setExcluirDepositante(perfil.excluirDepositante);
				setAcessoTotalDepositante(
					perfil.visualizarDepositante && perfil.criarDepositante && perfil.editarDepositante && perfil.excluirDepositante
				);
				// Cadastros - Produtos - Produtos de Armazenagem
				setVisualizarProdutosArmazenagem(perfil.visualizarProdutosArmazenagem);
				setCriarProdutosArmazenagem(perfil.criarProdutosArmazenagem);
				setEditarProdutosArmazenagem(perfil.editarProdutosArmazenagem);
				setExcluirProdutosArmazenagem(perfil.excluirProdutosArmazenagem);
				setAcessoTotalProdutosArmazenagem(
					perfil.visualizarProdutosArmazenagem &&
					perfil.criarProdutosArmazenagem &&
					perfil.editarProdutosArmazenagem &&
					perfil.excluirProdutosArmazenagem
				);
				// Cadastros - Produtos - Produtos de Fornecedor
				setVisualizarProdutosFornecedor(perfil.visualizarProdutosFornecedor);
				setCriarProdutosFornecedor(perfil.criarProdutosFornecedor);
				setEditarProdutosFornecedor(perfil.editarProdutosFornecedor);
				setExcluirProdutosFornecedor(perfil.excluirProdutosFornecedor);
				setAcessoTotalProdutosFornecedor(
					perfil.visualizarProdutosFornecedor &&
					perfil.criarProdutosFornecedor &&
					perfil.editarProdutosFornecedor &&
					perfil.excluirProdutosFornecedor
				);
				// Cadastros - Produtos - Produtos de Cross
				setVisualizarProdutosCross(perfil.visualizarProdutosCross);
				setCriarProdutosCross(perfil.criarProdutosCross);
				setEditarProdutosCross(perfil.editarProdutosCross);
				setExcluirProdutosCross(perfil.excluirProdutosCross);
				setAcessoTotalProdutosCross(
					perfil.visualizarProdutosCross && perfil.criarProdutosCross && perfil.editarProdutosCross && perfil.excluirProdutosCross
				);
				// Cadastros - Produtos - Produtos de Picking
				setVisualizarProdutosPicking(perfil.visualizarProdutosPicking);
				setCriarProdutosPicking(perfil.criarProdutosPicking);
				setEditarProdutosPicking(perfil.editarProdutosPicking);
				setExcluirProdutosPicking(perfil.excluirProdutosPicking);
				setAcessoTotalProdutosPicking(
					perfil.visualizarProdutosPicking && perfil.criarProdutosPicking && perfil.editarProdutosPicking && perfil.excluirProdutosPicking
				);
				// Cadastros - Endereços - Endereços de Armazém
				setVisualizarEnderecosArmz(perfil.visualizarEnderecosArmz);
				setCriarEnderecosArmz(perfil.criarEnderecosArmz);
				setEditarEnderecosArmz(perfil.editarEnderecosArmz);
				setEditarTempEnderecosArmz(perfil.editarTempEnderecosArmz);
				setExcluirEnderecosArmz(perfil.excluirEnderecosArmz);
				setAcessoTotalEnderecosArmz(
					perfil.visualizarEnderecosArmz && perfil.criarEnderecosArmz && perfil.editarEnderecosArmz && perfil.excluirEnderecosArmz
				);
				// Cadastros - Veiculos - Veiculos
				setVisualizarVeiculos(perfil.visualizarVeiculos);
				setCriarVeiculos(perfil.criarVeiculos);
				setEditarVeiculos(perfil.editarVeiculos);
				setExcluirVeiculos(perfil.excluirVeiculos);
				setAcessoTotalVeiculos(perfil.visualizarVeiculos && perfil.criarVeiculos && perfil.editarVeiculos && perfil.excluirVeiculos);
				// Cadastros - Veiculos - Motoristas
				setVisualizarMotoristas(perfil.visualizarMotoristas);
				setCriarMotoristas(perfil.criarMotoristas);
				setEditarMotoristas(perfil.editarMotoristas);
				setExcluirMotoristas(perfil.excluirMotoristas);
				setAcessoTotalMotoristas(
					perfil.visualizarMotoristas && perfil.criarMotoristas && perfil.editarMotoristas && perfil.excluirMotoristas
				);
				// Cadastros - Equipamento - Equipamento
				setVisualizarEquipamento(perfil.visualizarEquipamento);
				setCriarEquipamento(perfil.criarEquipamento);
				setEditarEquipamento(perfil.editarEquipamento);
				setExcluirEquipamento(perfil.excluirEquipamento);
				setAcessoTotalEquipamento(
					perfil.visualizarEquipamento && perfil.criarEquipamento && perfil.editarEquipamento && perfil.excluirEquipamento
				);
				// Cadastros - Colaborador - Colaborador
				setVisualizarColaborador(perfil.visualizarColaborador);
				setCriarColaborador(perfil.criarColaborador);
				setEditarColaborador(perfil.editarColaborador);
				setExcluirColaborador(perfil.excluirColaborador);
				setAcessoTotalColaborador(
					perfil.visualizarColaborador && perfil.criarColaborador && perfil.editarColaborador && perfil.excluirColaborador
				);
				// Cadastros - Fiscal - CFOP
				setVisualizarCFOP(perfil.visualizarCFOP);
				setImportarArqCFOP(perfil.importarArqCFOP);
				setBaixarModeloCFOP(perfil.baixarModeloCFOP);
				setCriarCFOP(perfil.criarCFOP);
				setEditarCFOP(perfil.editarCFOP);
				setExcluirCFOP(perfil.excluirCFOP);
				setAcessoTotalCFOP(
					perfil.visualizarCFOP &&
					perfil.importarArqCFOP &&
					perfil.baixarModeloCFOP &&
					perfil.criarCFOP &&
					perfil.editarCFOP &&
					perfil.excluirCFOP
				);
				// Cadastros - Fiscal - CFOP Entrada x Saida
				setVisualizarCFOPEntradaSaida(perfil.visualizarCFOPEntradaSaida);
				setCriarCFOPEntradaSaida(perfil.criarCFOPEntradaSaida);
				setEditarCFOPEntradaSaida(perfil.editarCFOPEntradaSaida);
				setExcluirCFOPEntradaSaida(perfil.excluirCFOPEntradaSaida);
				setAcessoTotalCFOPEntradaSaida(
					perfil.visualizarCFOPEntradaSaida &&
					perfil.criarCFOPEntradaSaida &&
					perfil.editarCFOPEntradaSaida &&
					perfil.excluirCFOPEntradaSaida
				);
				// Cadastros - Fiscal - Grupo Tributário
				setVisualizarGrupoTributario(perfil.visualizarGrupoTributario);
				setCriarGrupoTributario(perfil.criarGrupoTributario);
				setEditarGrupoTributario(perfil.editarGrupoTributario);
				setExcluirGrupoTributario(perfil.excluirGrupoTributario);
				setAcessoTotalGrupoTributario(
					perfil.visualizarGrupoTributario && perfil.criarGrupoTributario && perfil.editarGrupoTributario && perfil.excluirGrupoTributario
				);

				// Administracao Operacional - Fiscal Entrada -  Nfe Entradas Armzenagem
				setVisualizarNfeEntradasArmz(perfil.visualizarNfeEntradasArmz);
				setDetalharNfeEntradaArmz(perfil.detalharNfeEntradaArmz);
				setImportarNfeEntradasArmz(perfil.importarNfeEntradasArmz);
				setExcluirNfeEntradasArmz(perfil.excluirNfeEntradasArmz);
				setAcessoTotalNfeEntradasArmz(perfil.visualizarNfeEntradasArmz && perfil.importarNfeEntradasArmz && perfil.excluirNfeEntradasArmz);

				// Administracao Operacional - Fiscal Entrada -  Nfe Entradas CrossDocking
				setVisualizarNfeEntradaCrossDocking(perfil.visualizarNfeEntradaCrossDocking);
				setDetalharNfeEntradaCrossDocking(perfil.detalharNfeEntradaCrossDocking);
				setAcessoTotalNfeEntradaCrossDocking(perfil.visualizarNfeEntradaCrossDocking && perfil.detalharNfeEntradaCrossDocking);

				// Administracao Operacional - Fiscal Entrada -  Nfe Pedido
				setVisualizarNfePedido(perfil.visualizarNfePedido);
				setDetalharNfePedido(perfil.detalharNfePedido);
				setExcluirNfePedido(perfil.excluirNfePedido);
				setAcessoTotalNfePedido(perfil.visualizarNfePedido && perfil.detalharNfePedido && perfil.excluirNfePedido);

				// Administracao Operacional - Fiscal Saída - Saldo Fiscal
				setVisualizarSaldoFiscal(perfil.visualizarSaldoFiscal);

				// Administracao Operacional - Fiscal Saída - NFe Emitido
				setVisualizarNfeEmitido(perfil.visualizarNfeEmitido);
				setDetalharNfeEmitido(perfil.detalharNfeEmitido);
				setAcessoTotalNfeEmitido(perfil.visualizarNfeEmitido && perfil.detalharNfeEmitido);

				// Administracao Operacional - Fiscal Saída - Emissão NFe
				setVisualizarEmissaoNfe(perfil.visualizarEmissaoNfe);
				setCriarEmissaoNfe(perfil.criarEmissaoNfe);
				setEditarEmissaoNfe(perfil.editarEmissaoNfe);
				setExcluirEmissaoNfe(perfil.excluirEmissaoNfe);
				setAcessoTotalEmissaoNfe(
					perfil.visualizarEmissaoNfe && perfil.criarEmissaoNfe && perfil.editarEmissaoNfe && perfil.excluirEmissaoNfe
				);

				// Administracao Operacional - Fiscal Saída - Manifestar NFe
				setVisualizarManifestarNfe(perfil.visualizarManifestarNfe);
				setManifestarNfe(perfil.manifestarNfe);
				setAcessoTotalManifestarNfe(perfil.visualizarManifestarNfe && perfil.manifestarNfe);

				// Administracao Operacional - Controle Processo - Processo de Entrada
				setVisualizarProcEntrada(perfil.visualizarProcEntrada);
				setCriarProcEntrada(perfil.criarProcEntrada);
				setEditarProcEntrada(perfil.editarProcEntrada);
				setCancelarProcEntrada(perfil.cancelarProcEntrada);
				setAcessoTotalProcEntrada(
					perfil.visualizarProcEntrada && perfil.criarProcEntrada && perfil.editarProcEntrada && perfil.cancelarProcEntrada
				);

				// Administracao Operacional - Controle Processo - Processo de Saída
				setVisualizarProcSaida(perfil.visualizarProcSaida);
				setCriarProcSaida(perfil.criarProcSaida);
				setEditarProcSaida(perfil.editarProcSaida);
				setCancelarProcSaida(perfil.cancelarProcSaida);
				setAcessoTotalProcSaida(perfil.visualizarProcSaida && perfil.criarProcSaida && perfil.editarProcSaida && perfil.cancelarProcSaida);

				// Administracao Operacional - Pedidos - Criar Pedidos -> Importaçãp de Arquivos
				setVisualizarImportacaoArquivo(perfil.visualizarImportacaoArquivo);
				setImportarArquivo(perfil.importarArquivo);
				setAcessoTotalImportacaoArquivo(perfil.visualizarImportacaoArquivo && perfil.importarArquivo);

				// Administracao Operacional - Pedidos - Criar Pedidos -> NFe para Pedido
				setVisualizarNfeParaPedido(perfil.visualizarNfeParaPedido);
				setImportarNfeParaPedido(perfil.importarNfeParaPedido);
				setAcessoTotalNfeParaPedido(perfil.visualizarNfeParaPedido && perfil.importarNfeParaPedido);

				// Administracao Operacional - Pedidos - Criar Pedidos -> Pedido Manual
				setVisualizarPedidoManual(perfil.visualizarPedidoManual);
				setCriarPedidoManual(perfil.criarPedidoManual);
				setAdicionarItemPedidoManual(perfil.adicionarItemPedidoManual);
				setExcluirItemPedidoManual(perfil.excluirItemPedidoManual);
				setAcessoTotalPedidoManual(
					perfil.visualizarPedidoManual && perfil.criarPedidoManual && perfil.adicionarItemPedidoManual && perfil.excluirItemPedidoManual
				);

				// Administracao Operacional - Pedidos - Pedidos Pendentes
				setVisualizarPedidosPendentes(perfil.visualizarPedidosPendentes);
				setQuebrarPedidoTarefas(perfil.quebrarPedidoTarefas);
				setLiberarPedidoParaPicking(perfil.liberarPedidoParaPicking);
				setEditarPedidoPendente(perfil.editarPedidoPendente);
				setExcluirPedidoPendente(perfil.excluirPedidoPendente);
				setEdtiarItemPedidoPendente(perfil.edtiarItemPedidoPendente);
				setExcluirItemPedidoPendente(perfil.excluirItemPedidoPendente);
				setAcessoTotalPedidosPendentes(
					perfil.visualizarPedidosPendentes &&
					perfil.quebrarPedidoTarefas &&
					perfil.liberarPedidoParaPicking &&
					perfil.editarPedidoPendente &&
					perfil.excluirPedidoPendente &&
					perfil.edtiarItemPedidoPendente &&
					perfil.excluirItemPedidoPendente
				);

				// Administracao Operacional - Pedidos - Pedidos em Separação
				setVisualizarPedidosSep(perfil.visualizarPedidosSep);

				// Administracao Operacional - Inventário - Inventário
				setVisualizarInventario(perfil.visualizarInventario);

				// Administracao Operacional - Ajustes - Ajustes de Estoque
				setVisualizarAjusteEstoque(perfil.visualizarAjusteEstoque);
				setDetalharAjusteEstoque(perfil.detalharAjusteEstoque);
				setAcessoTotalAjusteEstoque(perfil.visualizarAjusteEstoque && perfil.detalharAjusteEstoque);

				// Administracao Operacional - Ajustes - Ajustes em Recebimento
				setVisualizarAjusteRecebimento(perfil.visualizarAjusteRecebimento);

				// Administracao Operacional - Ajustes - Ajustes de Movimento
				setVisualizarAjusteMovimento(perfil.visualizarAjusteMovimento);
				setDetalharAjusteMovimento(perfil.detalharAjusteMovimento);
				setExcluirItemAjusteMovimento(perfil.excluirItemAjusteMovimento);
				setAcessoTotalAjusteMovimento(
					perfil.visualizarAjusteMovimento &&
					perfil.detalharAjusteMovimento &&
					perfil.excluirItemAjusteMovimento
				);

				// Estoque -> Estoque Detalhado
				setVisualizarEstoqueDetalhado(perfil.visualizarEstoqueDetalhado);
				setDetalharPorUAEstoqueDetalhado(perfil.detalharPorUAEstoqueDetalhado);
				setGerarExcelDetalharPorUAEstoqueDetalhado(perfil.gerarExcelDetalharPorUAEstoqueDetalhado);
				setGerarResumoValidadeEstoqueDetalhado(perfil.gerarResumoValidadeEstoqueDetalhado);
				setGerarExcelResumoValidadeEstoqueDetalhado(perfil.gerarExcelResumoValidadeEstoqueDetalhado);
				setGerarPDFResumoValidadeEstoqueDetalhado(perfil.gerarPDFResumoValidadeEstoqueDetalhado);
				setEnviarEstoqueEmailEstoqueDetalhado(perfil.enviarEstoqueEmailEstoqueDetalhado);
				setBloquearDesbloquearItemEstoqueDetalhado(perfil.bloquearDesbloquearItemEstoqueDetalhado);
				setVisualizarEmpenhoEstoqueDetalhado(perfil.visualizarEmpenhoEstoqueDetalhado);
				setVisualizarBarrasUAEstoqueDetalhado(perfil.visualizarBarrasUAEstoqueDetalhado);
				setAdicionarBarrasUAEstoqueDetalhado(perfil.adicionarBarrasUAEstoqueDetalhado);
				setEditarBarrasUAEstoqueDetalhado(perfil.editarBarrasUAEstoqueDetalhado);
				setExcluirBarrasUAEstoqueDetalhado(perfil.excluirBarrasUAEstoqueDetalhado);
				setAcessoTotalEstoqueDetalhado(
					perfil.visualizarEstoqueDetalhado &&
					perfil.detalharPorUAEstoqueDetalhado &&
					perfil.gerarExcelDetalharPorUAEstoqueDetalhado &&
					perfil.gerarResumoValidadeEstoqueDetalhado &&
					perfil.gerarExcelResumoValidadeEstoqueDetalhado &&
					perfil.gerarPDFResumoValidadeEstoqueDetalhado &&
					perfil.enviarEstoqueEmailEstoqueDetalhado &&
					perfil.bloquearDesbloquearItemEstoqueDetalhado &&
					perfil.visualizarEmpenhoEstoqueDetalhado &&
					perfil.visualizarBarrasUAEstoqueDetalhado &&
					perfil.adicionarBarrasUAEstoqueDetalhado &&
					perfil.editarBarrasUAEstoqueDetalhado &&
					perfil.excluirBarrasUAEstoqueDetalhado
				);

				// Estoque -> Estoque Sintético
				setVisualizarEstoqueSintetico(perfil.visualizarEstoqueSintetico);
				setGerarExcelEstoqueSintetico(perfil.gerarExcelEstoqueSintetico);
				setAcessoTotalEstoqueSintetico(perfil.visualizarEstoqueSintetico && perfil.gerarExcelEstoqueSintetico);

				// Estoque -> Romaneio de Barras Ativas
				setVisualizarRomaneioBarrasAtivas(perfil.visualizarRomaneioBarrasAtivas);
				setCriarRomaneioBarrasAtivas(perfil.criarRomaneioBarrasAtivas);
				setImprimirTodasBarrasRomaneioBarrasAtivas(perfil.imprimirTodasBarrasRomaneioBarrasAtivas);
				setGerarExcelRomaneioBarrasAtivas(perfil.gerarExcelRomaneioBarrasAtivas);
				setVerTodasRomaneioBarrasAtivas(perfil.verTodasRomaneioBarrasAtivas);
				setGerarExcelDetalhesRomaneioBarrasAtivas(perfil.gerarExcelDetalhesRomaneioBarrasAtivas);
				setEditarRomaneioBarrasAtivas(perfil.editarRomaneioBarrasAtivas);
				setExcluirRomaneioBarrasAtivas(perfil.excluirRomaneioBarrasAtivas);
				setImprimirItemRomaneioBarrasAtivas(perfil.imprimirItemRomaneioBarrasAtivas);
				setAcessoTotalRomaneioBarrasAtivas(
					perfil.visualizarRomaneioBarrasAtivas &&
					perfil.criarRomaneioBarrasAtivas &&
					perfil.imprimirTodasBarrasRomaneioBarrasAtivas &&
					perfil.gerarExcelRomaneioBarrasAtivas &&
					perfil.verTodasRomaneioBarrasAtivas &&
					perfil.gerarExcelDetalhesRomaneioBarrasAtivas &&
					perfil.editarRomaneioBarrasAtivas &&
					perfil.excluirRomaneioBarrasAtivas &&
					perfil.imprimirItemRomaneioBarrasAtivas
				);

				// Estoque -> UA Vazio/Saida Sem Uso
				setVisualizarUAVazioSaidaSemUsoVazio(perfil.visualizarUAVazioSaidaSemUsoVazio);
				setExcluirUAVazioSaidaSemUsoVazio(perfil.excluirUAVazioSaidaSemUsoVazio);
				setImprimirUAVazioSaidaSemUsoVazio(perfil.imprimirUAVazioSaidaSemUsoVazio);
				setVisualizarUAVazioSaidaSemUsoSaida(perfil.visualizarUAVazioSaidaSemUsoSaida);
				setExcluirUAVazioSaidaSemUsoSaida(perfil.excluirUAVazioSaidaSemUsoSaida);
				setImprimirUAVazioSaidaSemUsoSaida(perfil.imprimirUAVazioSaidaSemUsoSaida);
				setAcessoTotalUAVazioSaidaSemUso(
					perfil.visualizarUAVazioSaidaSemUsoVazio &&
					perfil.excluirUAVazioSaidaSemUsoVazio &&
					perfil.imprimirUAVazioSaidaSemUsoVazio &&
					perfil.visualizarUAVazioSaidaSemUsoSaida &&
					perfil.excluirUAVazioSaidaSemUsoSaida &&
					perfil.imprimirUAVazioSaidaSemUsoSaida
				);

				// Operacional - Conferência de Entrada - Conferência de Recebimento
				setVisualizarConferirRecebimento(perfil.visualizarConferirRecebimento);
				// Operacional - Conferência de Saída - Conferir Carregamento
				setVisualizarConferirCarregamento(perfil.visualizarConferirCarregamento);
				// Operacional - Endereçar - Endereçar UA
				setVisualizarEnderecarUA(perfil.visualizarEnderecarUA);
				// Operacional - Desfragmentação - Desfragmentar UA
				setVisualizarDesfragmentarUA(perfil.visualizarDesfragmentarUA);
				// Operacional - Desfragmentação - Analise de Otimização
				setVisualizarAnaliseOtimizacao(perfil.visualizarAnaliseOtimizacao);
				// Operacional - Reposição de Picking - Reposição Primária
				setVisualizarReposicaoPrimaria(perfil.visualizarReposicaoPrimaria);
				// Operacional - Reposição de Picking - Reposição Complementar
				setVisualizarReposicaoComplementar(perfil.visualizarReposicaoComplementar);
				// Operacional - Impressão de Etiquetas - Impressão de Etiquetas
				setVisualizarImpressaoEtiquetas(perfil.visualizarImpressaoEtiquetas);
				setVisualizarImpEtqReimpressaoUA(perfil.visualizarImpEtqReimpressaoUA);
				setVisualizarImpEtqGerarUAVazioSaida(perfil.visualizarImpEtqGerarUAVazioSaida);
				setVisualizarImpEtqImpressaoEndereco(perfil.visualizarImpEtqImpressaoEndereco);
				setVisualizarImpEtqImpressaoProdutoPicking(perfil.visualizarImpEtqImpressaoProdutoPicking);
				setAcessoTotalImpressaoEtiquetas(
					perfil.visualizarImpressaoEtiquetas &&
					perfil.visualizarImpEtqReimpressaoUA &&
					perfil.visualizarImpEtqGerarUAVazioSaida &&
					perfil.visualizarImpEtqImpressaoEndereco &&
					perfil.visualizarImpEtqImpressaoProdutoPicking
				);
				setVisualizarExcecaoQuebraFefo(perfil.visualizarExcecaoQuebraFefo);
				setVisualizarExcecaoLerGtin(perfil.visualizarExcecaoLerGtin);
				setVisualizarExcecaoLerVolume(perfil.visualizarExcecaoLerVolume);
				setVisualizarExcecaoEndereco(perfil.visualizarExcecaoEndereco);
				setVisualizarExcecaoZonaEnderecamento(perfil.visualizarExcecaoZonaEnderecamento);
				// Relatórios - Rastreabilidade - Rastreabilidade de Produto
				setVisualizarRelRastreabilidadeProduto(perfil.visualizarRelRastreabilidadeProduto);
				// Relatórios - Pedidos - Romaneio de Carga
				setVisualizarRelRomaneioCarga(perfil.visualizarRelRomaneioCarga);
				// Relatórios - Movimentação - Movimentação por Período
				setVisualizarRelMovimentacaoPeriodo(perfil.visualizarRelMovimentacaoPeriodo);
				// Relatórios - Movimentação - Curva ABC
				setVisualizarRelCurvaABC(perfil.visualizarRelCurvaABC);
				// Relatórios - Movimentação - Histotico de Endereçamento
				setVisualizarRelHistoricoEnderecamento(perfil.visualizarRelHistoricoEnderecamento);
				// Faturamento de serviços - Tarifas
				setVisualizarCadastrarTarifas(perfil.visualizarCadastrarTarifas);
				setCriarCadastrarTarifas(perfil.criarCadastrarTarifas);
				setEditarCadastrarTarifas(perfil.editarCadastrarTarifas);
				setExcluirCadastrarTarifas(perfil.excluirCadastrarTarifas);
				setAcessoTotalCadastrarTarifas(
					perfil.visualizarCadastrarTarifas &&
					perfil.criarCadastrarTarifas &&
					perfil.editarCadastrarTarifas &&
					perfil.excluirCadastrarTarifas
				);
				// Faturamento de serviços - Corte Período - Gerar Corte
				setVisualizarGerarCorte(perfil.visualizarGerarCorte);
				// Faturamento de serviços - Corte Período - Estornar Corte
				setVisualizarEstornarCorte(perfil.visualizarEstornarCorte);
				// Faturamento de serviços - Emissão Fatura
				setVisualizarEmissaoFatura(perfil.visualizarEmissaoFatura);
				// Faturamento de serviços - Serviço Extra - Lançar Serviço Extra
				setVisualizarLancarServicoExtra(perfil.visualizarLancarServicoExtra);
				// Faturamento de serviços - Liberar Faturamento - Bonificar Faturamento
				setVisualizarBonificarFaturamento(perfil.visualizarBonificarFaturamento);
				// Indicadores -> Movimentação
				setVisualizarIndicadoresMovimentacao(perfil.visualizarIndicadoresMovimentacao);
				// Indicadores -> Produtividade
				setVisualizarIndicadoresProdutividade(perfil.visualizarIndicadoresProdutividade);
				// Indicadores -> Acuracidade
				setVisualizarIndicadoresAcuracidade(perfil.visualizarIndicadoresAcuracidade);
				//Logs do Sistema -> Log de Ação do Usuário
				setVisualizarLogAcaoUsuario(perfil.visualizarLogAcaoUsuario);
				//Logs do Sistema -> Log API Pedidos
				setVisualizarLogApiPedidos(perfil.visualizarLogApiPedidos);
				//Logs do Sistema -> Log de Integração
				setVisualizarLogIntegracao(perfil.visualizarLogIntegracao);

				// PERMISSOES ESPECIAIS
				setProcEntradaAprovaDivQtdeReceb(perfil.permEspAprovaDivegProcesso);
				setEndExcecaoEndereco(perfil.permEspExcecaoEndereco);
				setEndExcecaoZonaEnderecamento(perfil.permEspExcecaoZonaEndereco);
				setPedidoManutencaoPedido(perfil.permEspExcecaoManutencaoPedido);
				setPedidoAutorizaQuebraFefoPicking(perfil.permEspExcecaoQuebraFefo);
				setConfCargaExcLeituraGtin(perfil.permEspExcecaoLerGtin);
				setConfCargaExcLeituraVolume(perfil.permEspExcecaoLerVolume);
				setPesagemAprovaDivBalanRodviaria(perfil.permEspEprovaDivegBalanca);
				setPesagemAprovaDivBalancaOS(perfil.permEspAprovaDivegBalancaOs);
				setEstoqueRealizaAjusteQualitativo(perfil.permEspAjusteQualitativo);
				setEstoqueRealizaAjusteQuantitativo(perfil.permEspAjusteQuantitativo);
				setEstoqueRealizaDesfragEstoque(perfil.permEspDesfragmentar);

				setEstoqueAlteraDadosBarra(perfil.permEspAlteraDadosBarra);

				setInventarioDefineToleranciaQuebraInv(perfil.permEspDefineToleranciaQuebra);
				setInventarioAprovaDivergenciaInventarioAcimaTolerancia(perfil.permEspAprovaDivegInventario);

				setAjusteEstoque(perfil.permEspAjusteEstoque);
				setAjusteEntrada(perfil.permEspAjusteEntrada);
				setAjusteMovimentacao(perfil.permEspAjusteMovimentacao);

				setAprovaValidadeVencida(perfil.aprovaValVencida)

				setShowModalCarregando(false);
			})
			.catch(function (erro) {
				setShowModalCarregando(false);
				toast.error(erro.response.data.message || erro.message, {
					autoClose: 900,
				});
			});
	}

	function AtualizarPerfil(id_perfil: string, id_filial: string) {
		setShowModalCarregando(true);
		axios
			.put(Api + `/atualizar/perfil/${id_filial}/${id_perfil}`, dadosEnvio, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setShowModalCarregando(false);
				toast.success(resposta.data.message, {
					autoClose: 900,
				});

				setTimeout(() => {
					navigate(-1);
				}, 1800);
			})
			.catch(function (erro) {
				setShowModalCarregando(false);
				toast.error(erro.response.data.message || erro.message, {
					autoClose: 900,
				});
			});
	}

	useEffect(function () {
		if (params.id !== 'novo' && params.acao !== 'criar') {
			CarregarPerfil(params.id!, params.id_filial!);
		}
	}, []);

	//#region Administrativo -> Controle de Acesso -> Usuario
	const controleAcessoUsuarioVisualizar = (situacao: boolean) => {
		// Verifica se qualquer uma das permissões (Criar, Editar, Excluir) está ativa
		if (criarUsuario || editarUsuario || excluirUsuario) {
			// Se qualquer uma estiver ativa, "Visualizar" deve sempre ser true
			setVisualizarUsuario(true);
		} else {
			// Se todas estiverem desativadas, "Visualizar" pode ser controlado manualmente
			setVisualizarUsuario(situacao);
		}
	};
	const controleAcessoUsuarioCriar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarUsuario(situacao);
			setCriarUsuario(situacao);
		} else {
			setCriarUsuario(situacao);
		}
	};
	const controleAcessoUsuarioEditar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarUsuario(situacao);
			setEditarUsuario(situacao);
		} else {
			setEditarUsuario(situacao);
		}
	};

	const controleAcessoUsuarioExcluir = (situacao: boolean) => {
		if (situacao) {
			setVisualizarUsuario(situacao);
			setExcluirUsuario(situacao);
		} else {
			setExcluirUsuario(situacao);
		}
	};

	const controleAcessoTotalUsuario = (situacao: boolean) => {
		if (situacao) {
			setVisualizarUsuario(true);
			setCriarUsuario(true);
			setEditarUsuario(true);
			setExcluirUsuario(true);
			setAcessoTotalUsuario(true);
		} else {
			setVisualizarUsuario(false);
			setCriarUsuario(false);
			setEditarUsuario(false);
			setExcluirUsuario(false);
			setAcessoTotalUsuario(false);
		}
	};
	//verificar se todas as opções estão marcadas (visualizar, criar, editar, excluir) se tiverem marca automaticamente o acesso total, caso alguma seja desativada o acesso total é desativado
	useEffect(() => {
		if (visualizarUsuario && criarUsuario && editarUsuario && excluirUsuario) {
			setAcessoTotalUsuario(true);
		} else {
			setAcessoTotalUsuario(false);
		}
	}, [visualizarUsuario, criarUsuario, editarUsuario, excluirUsuario]);

	//#endregion
	//#region Administrativo -> Controle de Acesso -> Perfil /Permissão
	const controleAcessoPerfilPermissaoVisualizar = (situacao: boolean) => {
		// Verifica se qualquer uma das permissões (Criar, Editar, Excluir) está ativa
		if (criarPerfilPermissao || editarPerfilPermissao || excluirPerfilPermissao) {
			// Se qualquer uma estiver ativa, "Visualizar" deve sempre ser true
			setVisualizarPerfilPermissao(true);
		} else {
			// Se todas estiverem desativadas, "Visualizar" pode ser controlado manualmente
			setVisualizarPerfilPermissao(situacao);
		}
	};
	const controleAcessoPerfilPermissaoCriar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarPerfilPermissao(situacao);
			setCriarPerfilPermissao(situacao);
		} else {
			setCriarPerfilPermissao(situacao);
		}
	};
	const controleAcessoPerfilPermissaoEditar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarPerfilPermissao(situacao);
			setEditarPerfilPermissao(situacao);
		} else {
			setEditarPerfilPermissao(situacao);
		}
	};

	const controleAcessoPerfilPermissaoExcluir = (situacao: boolean) => {
		if (situacao) {
			setVisualizarPerfilPermissao(situacao);
			setExcluirPerfilPermissao(situacao);
		} else {
			setExcluirPerfilPermissao(situacao);
		}
	};

	const controleAcessoTotalPerfilPermissao = (situacao: boolean) => {
		if (situacao) {
			setVisualizarPerfilPermissao(true);
			setCriarPerfilPermissao(true);
			setEditarPerfilPermissao(true);
			setExcluirPerfilPermissao(true);
			setAcessoTotalPerfilPermissao(true);
		} else {
			setVisualizarPerfilPermissao(false);
			setCriarPerfilPermissao(false);
			setEditarPerfilPermissao(false);
			setExcluirPerfilPermissao(false);
			setAcessoTotalPerfilPermissao(false);
		}
	};
	//verificar se todas as opções estão marcadas (visualizar, criar, editar, excluir) se tiverem marca automaticamente o acesso total, caso alguma seja desativada o acesso total é desativado
	useEffect(() => {
		if (visualizarPerfilPermissao && criarPerfilPermissao && editarPerfilPermissao && excluirPerfilPermissao) {
			setAcessoTotalPerfilPermissao(true);
		} else {
			setAcessoTotalPerfilPermissao(false);
		}
	}, [visualizarPerfilPermissao, criarPerfilPermissao, editarPerfilPermissao, excluirPerfilPermissao]);

	//#endregion

	//#region Administrativo ->  Cadastro Administrativo -> Cadastro de Filial
	const controleAcessoCadastroFilialVisualizar = (situacao: boolean) => {
		// Verifica se qualquer uma das permissões (Criar, Editar, Excluir) está ativa
		if (criarCadastroFilial || editarCadastroFilial || excluirCadastroFilial) {
			// Se qualquer uma estiver ativa, "Visualizar" deve sempre ser true
			setVisualizarCadastroFilial(true);
		} else {
			// Se todas estiverem desativadas, "Visualizar" pode ser controlado manualmente
			setVisualizarCadastroFilial(situacao);
		}
	};
	const controleAcessoCadastroFilialCriar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarCadastroFilial(situacao);
			setCriarCadastroFilial(situacao);
		} else {
			setCriarCadastroFilial(situacao);
		}
	};
	const controleAcessoCadastroFilialEditar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarCadastroFilial(situacao);
			setEditarCadastroFilial(situacao);
		} else {
			setEditarCadastroFilial(situacao);
		}
	};

	const controleAcessoCadastroFilialExcluir = (situacao: boolean) => {
		if (situacao) {
			setVisualizarCadastroFilial(situacao);
			setExcluirCadastroFilial(situacao);
		} else {
			setExcluirCadastroFilial(situacao);
		}
	};

	const controleAcessoTotalCadastroFilial = (situacao: boolean) => {
		if (situacao) {
			setVisualizarCadastroFilial(true);
			setCriarCadastroFilial(true);
			setEditarCadastroFilial(true);
			setExcluirCadastroFilial(true);
			setAcessoTotalCadastroFilial(true);
		} else {
			setVisualizarCadastroFilial(false);
			setCriarCadastroFilial(false);
			setEditarCadastroFilial(false);
			setExcluirCadastroFilial(false);
			setAcessoTotalCadastroFilial(false);
		}
	};
	//verificar se todas as opções estão marcadas (visualizar, criar, editar, excluir) se tiverem marca automaticamente o acesso total, caso alguma seja desativada o acesso total é desativado
	useEffect(() => {
		if (visualizarCadastroFilial && criarCadastroFilial && editarCadastroFilial && excluirCadastroFilial) {
			setAcessoTotalCadastroFilial(true);
		} else {
			setAcessoTotalCadastroFilial(false);
		}
	}, [visualizarCadastroFilial, criarCadastroFilial, editarCadastroFilial, excluirCadastroFilial]);

	//#endregion

	//#region Administrativo -> Codigos do Sistema -> Codigos do Sistema
	const controleAcessoCodSistemaVisualizar = (situacao: boolean) => {
		// Verifica se qualquer uma das permissões (Criar, Editar, Excluir) está ativa
		if (criarCodSistema || editarCodSistema || excluirCodSistema) {
			// Se qualquer uma estiver ativa, "Visualizar" deve sempre ser true
			setVisualizarCodSistema(true);
		} else {
			// Se todas estiverem desativadas, "Visualizar" pode ser controlado manualmente
			setVisualizarCodSistema(situacao);
		}
	};
	const controleAcessoCodSistemaCriar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarCodSistema(situacao);
			setCriarCodSistema(situacao);
		} else {
			setCriarCodSistema(situacao);
		}
	};
	const controleAcessoCodSistemaEditar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarCodSistema(situacao);
			setEditarCodSistema(situacao);
		} else {
			setEditarCodSistema(situacao);
		}
	};

	const controleAcessoCodSistemaExcluir = (situacao: boolean) => {
		if (situacao) {
			setVisualizarCodSistema(situacao);
			setExcluirCodSistema(situacao);
		} else {
			setExcluirCodSistema(situacao);
		}
	};

	const controleAcessoTotalCodSistema = (situacao: boolean) => {
		if (situacao) {
			setVisualizarCodSistema(true);
			setCriarCodSistema(true);
			setEditarCodSistema(true);
			setExcluirCodSistema(true);
			setAcessoTotalCodigosSistema(true);
		} else {
			setVisualizarCodSistema(false);
			setCriarCodSistema(false);
			setEditarCodSistema(false);
			setExcluirCodSistema(false);
			setAcessoTotalCodigosSistema(false);
		}
	};
	//verificar se todas as opções estão marcadas (visualizar, criar, editar, excluir) se tiverem marca automaticamente o acesso total, caso alguma seja desativada o acesso total é desativado
	useEffect(() => {
		if (visualizarCodSistema && criarCodSistema && editarCodSistema && excluirCodSistema) {
			setAcessoTotalCodigosSistema(true);
		} else {
			setAcessoTotalCodigosSistema(false);
		}
	}, [visualizarCodSistema, criarCodSistema, editarCodSistema, excluirCodSistema]);

	//#endregion

	//#region Administrativo -> Cadastro Administrativo -> Controle de Numeracao
	const controleAcessoControleNumeracaoVisualizar = (situacao: boolean) => {
		// Verifica se qualquer uma das permissões (Criar, Editar, Excluir) está ativa
		if (criarControleNumeracao || editarControleNumeracao || excluirControleNumeracao) {
			// Se qualquer uma estiver ativa, "Visualizar" deve sempre ser true
			setVisualizarControleNumeracao(true);
		} else {
			// Se todas estiverem desativadas, "Visualizar" pode ser controlado manualmente
			setVisualizarControleNumeracao(situacao);
		}
	};
	const controleAcessoControleNumeracaoCriar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarControleNumeracao(situacao);
			setCriarControleNumeracao(situacao);
		} else {
			setCriarControleNumeracao(situacao);
		}
	};
	const controleAcessoControleNumeracaoEditar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarControleNumeracao(situacao);
			setEditarControleNumeracao(situacao);
		} else {
			setEditarControleNumeracao(situacao);
		}
	};

	const controleAcessoControleNumeracaoExcluir = (situacao: boolean) => {
		if (situacao) {
			setVisualizarControleNumeracao(situacao);
			setExcluirControleNumeracao(situacao);
		} else {
			setExcluirControleNumeracao(situacao);
		}
	};

	const controleAcessoTotalControleNumeracao = (situacao: boolean) => {
		if (situacao) {
			setVisualizarControleNumeracao(true);
			setCriarControleNumeracao(true);
			setEditarControleNumeracao(true);
			setExcluirControleNumeracao(true);
			setAcessoTotalControleNumeracao(true);
		} else {
			setVisualizarControleNumeracao(false);
			setCriarControleNumeracao(false);
			setEditarControleNumeracao(false);
			setExcluirControleNumeracao(false);
			setAcessoTotalControleNumeracao(false);
		}
	};
	//verificar se todas as opções estão marcadas (visualizar, criar, editar, excluir) se tiverem marca automaticamente o acesso total, caso alguma seja desativada o acesso total é desativado
	useEffect(() => {
		if (visualizarControleNumeracao && criarControleNumeracao && editarControleNumeracao && excluirControleNumeracao) {
			setAcessoTotalControleNumeracao(true);
		} else {
			setAcessoTotalControleNumeracao(false);
		}
	}, [visualizarControleNumeracao, criarControleNumeracao, editarControleNumeracao, excluirControleNumeracao]);

	//#endregion

	//#region Cadastros -> Entidade -> Entidade
	const controleAcessoEntidadeVisualizar = (situacao: boolean) => {
		// Verifica se qualquer uma das permissões (Criar, Editar, Excluir) está ativa
		if (criarEntidade || editarEntidade || excluirEntidade) {
			// Se qualquer uma estiver ativa, "Visualizar" deve sempre ser true
			setVisualizarEntidade(true);
		} else {
			// Se todas estiverem desativadas, "Visualizar" pode ser controlado manualmente
			setVisualizarEntidade(situacao);
		}
	};
	const controleAcessoEntidadeCriar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarEntidade(situacao);
			setCriarEntidade(situacao);
		} else {
			setCriarEntidade(situacao);
		}
	};
	const controleAcessoEntidadeEditar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarEntidade(situacao);
			setEditarEntidade(situacao);
		} else {
			setEditarEntidade(situacao);
		}
	};

	const controleAcessoEntidadeExcluir = (situacao: boolean) => {
		if (situacao) {
			setVisualizarEntidade(situacao);
			setExcluirEntidade(situacao);
		} else {
			setExcluirEntidade(situacao);
		}
	};

	const controleAcessoTotalEntidade = (situacao: boolean) => {
		if (situacao) {
			setVisualizarEntidade(true);
			setCriarEntidade(true);
			setEditarEntidade(true);
			setExcluirEntidade(true);
			setAcessoTotalEntidade(true);
		} else {
			setVisualizarEntidade(false);
			setCriarEntidade(false);
			setEditarEntidade(false);
			setExcluirEntidade(false);
			setAcessoTotalEntidade(false);
		}
	};
	//verificar se todas as opções estão marcadas (visualizar, criar, editar, excluir) se tiverem marca automaticamente o acesso total, caso alguma seja desativada o acesso total é desativado
	useEffect(() => {
		if (visualizarEntidade && criarEntidade && editarEntidade && excluirEntidade) {
			setAcessoTotalEntidade(true);
		} else {
			setAcessoTotalEntidade(false);
		}
	}, [visualizarEntidade, criarEntidade, editarEntidade, excluirEntidade]);

	//#endregion

	//#region Cadastros -> Entidade -> Transportadora
	const controleAcessoTransportadoraVisualizar = (situacao: boolean) => {
		// Verifica se qualquer uma das permissões (Criar, Editar, Excluir) está ativa
		if (vincularTransportadoraDepositante) {
			// Se qualquer uma estiver ativa, "Visualizar" deve sempre ser true
			setVisualizarTransportadora(true);
		} else {
			// Se todas estiverem desativadas, "Visualizar" pode ser controlado manualmente
			setVisualizarTransportadora(situacao);
		}
	};
	const controleAcessoTransportadoraDepositanteVincular = (situacao: boolean) => {
		if (situacao) {
			setVisualizarTransportadora(situacao);
			setVincularTransportadoraDepositante(situacao);
		} else {
			setVincularTransportadoraDepositante(situacao);
		}
	};

	const controleAcessoTotalTransportadora = (situacao: boolean) => {
		if (situacao) {
			setVisualizarTransportadora(true);
			setVincularTransportadoraDepositante(true);
			setAcessoTotalTransportadora(true);
		} else {
			setVisualizarTransportadora(false);
			setVincularTransportadoraDepositante(false);
			setAcessoTotalTransportadora(false);
		}
	};
	//verificar se todas as opções estão marcadas (visualizar, transportadoradepositante) se tiverem marca automaticamente o acesso total, caso alguma seja desativada o acesso total é desativado
	useEffect(() => {
		if (visualizarTransportadora && vincularTransportadoraDepositante) {
			setAcessoTotalTransportadora(true);
		} else {
			setAcessoTotalTransportadora(false);
		}
	}, [visualizarTransportadora, vincularTransportadoraDepositante]);

	//#endregion

	//#region Cadastros -> Depositante -> Depositante
	const controleAcessoDepositanteVisualizar = (situacao: boolean) => {
		// Verifica se qualquer uma das permissões (Criar, Editar, Excluir) está ativa
		if (criarDepositante || editarDepositante || excluirDepositante) {
			// Se qualquer uma estiver ativa, "Visualizar" deve sempre ser true
			setVisualizarDepositante(true);
		} else {
			// Se todas estiverem desativadas, "Visualizar" pode ser controlado manualmente
			setVisualizarDepositante(situacao);
		}
	};
	const controleAcessoDepositanteCriar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarDepositante(situacao);
			setCriarDepositante(situacao);
		} else {
			setCriarDepositante(situacao);
		}
	};
	const controleAcessoDepositanteEditar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarDepositante(situacao);
			setEditarDepositante(situacao);
		} else {
			setEditarDepositante(situacao);
		}
	};

	const controleAcessoDepositanteExcluir = (situacao: boolean) => {
		if (situacao) {
			setVisualizarDepositante(situacao);
			setExcluirDepositante(situacao);
		} else {
			setExcluirDepositante(situacao);
		}
	};

	const controleAcessoTotalDepositante = (situacao: boolean) => {
		if (situacao) {
			setVisualizarDepositante(true);
			setCriarDepositante(true);
			setEditarDepositante(true);
			setExcluirDepositante(true);
			setAcessoTotalDepositante(true);
		} else {
			setVisualizarDepositante(false);
			setCriarDepositante(false);
			setEditarDepositante(false);
			setExcluirDepositante(false);
			setAcessoTotalDepositante(false);
		}
	};
	//verificar se todas as opções estão marcadas (visualizar, criar, editar, excluir) se tiverem marca automaticamente o acesso total, caso alguma seja desativada o acesso total é desativado
	useEffect(() => {
		if (visualizarDepositante && criarDepositante && editarDepositante && excluirDepositante) {
			setAcessoTotalDepositante(true);
		} else {
			setAcessoTotalDepositante(false);
		}
	}, [visualizarDepositante, criarDepositante, editarDepositante, excluirDepositante]);

	//#endregion

	//#region Cadastros -> Produto -> Produto de Armazenagem
	const controleAcessoProdutosArmazenagemVisualizar = (situacao: boolean) => {
		// Verifica se qualquer uma das permissões (Criar, Editar, Excluir) está ativa
		if (criarProdutosArmazenagem || editarProdutosArmazenagem || excluirProdutosArmazenagem) {
			// Se qualquer uma estiver ativa, "Visualizar" deve sempre ser true
			setVisualizarProdutosArmazenagem(true);
		} else {
			// Se todas estiverem desativadas, "Visualizar" pode ser controlado manualmente
			setVisualizarProdutosArmazenagem(situacao);
		}
	};
	const controleAcessoProdutosArmazenagemCriar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarProdutosArmazenagem(situacao);
			setCriarProdutosArmazenagem(situacao);
		} else {
			setCriarProdutosArmazenagem(situacao);
		}
	};
	const controleAcessoProdutosArmazenagemEditar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarProdutosArmazenagem(situacao);
			setEditarProdutosArmazenagem(situacao);
		} else {
			setEditarProdutosArmazenagem(situacao);
		}
	};

	const controleAcessoProdutosArmazenagemExcluir = (situacao: boolean) => {
		if (situacao) {
			setVisualizarProdutosArmazenagem(situacao);
			setExcluirProdutosArmazenagem(situacao);
		} else {
			setExcluirProdutosArmazenagem(situacao);
		}
	};

	const controleAcessoTotalProdutosArmazenagem = (situacao: boolean) => {
		if (situacao) {
			setVisualizarProdutosArmazenagem(true);
			setCriarProdutosArmazenagem(true);
			setEditarProdutosArmazenagem(true);
			setExcluirProdutosArmazenagem(true);
			setAcessoTotalProdutosArmazenagem(true);
		} else {
			setVisualizarProdutosArmazenagem(false);
			setCriarProdutosArmazenagem(false);
			setEditarProdutosArmazenagem(false);
			setExcluirProdutosArmazenagem(false);
			setAcessoTotalProdutosArmazenagem(false);
		}
	};
	//verificar se todas as opções estão marcadas (visualizar, criar, editar, excluir) se tiverem marca automaticamente o acesso total, caso alguma seja desativada o acesso total é desativado
	useEffect(() => {
		if (visualizarProdutosArmazenagem && criarProdutosArmazenagem && editarProdutosArmazenagem && excluirProdutosArmazenagem) {
			setAcessoTotalProdutosArmazenagem(true);
		} else {
			setAcessoTotalProdutosArmazenagem(false);
		}
	}, [visualizarProdutosArmazenagem, criarProdutosArmazenagem, editarProdutosArmazenagem, excluirProdutosArmazenagem]);

	//#endregion

	//#region Cadastros -> Produto -> Produto de Fornecedor
	const controleAcessoProdutosFornecedorVisualizar = (situacao: boolean) => {
		// Verifica se qualquer uma das permissões (Criar, Editar, Excluir) está ativa
		if (criarProdutosFornecedor || editarProdutosFornecedor || excluirProdutosFornecedor) {
			// Se qualquer uma estiver ativa, "Visualizar" deve sempre ser true
			setVisualizarProdutosFornecedor(true);
		} else {
			// Se todas estiverem desativadas, "Visualizar" pode ser controlado manualmente
			setVisualizarProdutosFornecedor(situacao);
		}
	};
	const controleAcessoProdutosFornecedorCriar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarProdutosFornecedor(situacao);
			setCriarProdutosFornecedor(situacao);
		} else {
			setCriarProdutosFornecedor(situacao);
		}
	};
	const controleAcessoProdutosFornecedorEditar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarProdutosFornecedor(situacao);
			setEditarProdutosFornecedor(situacao);
		} else {
			setEditarProdutosFornecedor(situacao);
		}
	};

	const controleAcessoProdutosFornecedorExcluir = (situacao: boolean) => {
		if (situacao) {
			setVisualizarProdutosFornecedor(situacao);
			setExcluirProdutosFornecedor(situacao);
		} else {
			setExcluirProdutosFornecedor(situacao);
		}
	};

	const controleAcessoTotalProdutosFornecedor = (situacao: boolean) => {
		if (situacao) {
			setVisualizarProdutosFornecedor(true);
			setCriarProdutosFornecedor(true);
			setEditarProdutosFornecedor(true);
			setExcluirProdutosFornecedor(true);
			setAcessoTotalProdutosFornecedor(true);
		} else {
			setVisualizarProdutosFornecedor(false);
			setCriarProdutosFornecedor(false);
			setEditarProdutosFornecedor(false);
			setExcluirProdutosFornecedor(false);
			setAcessoTotalProdutosFornecedor(false);
		}
	};
	//verificar se todas as opções estão marcadas (visualizar, criar, editar, excluir) se tiverem marca automaticamente o acesso total, caso alguma seja desativada o acesso total é desativado
	useEffect(() => {
		if (visualizarProdutosFornecedor && criarProdutosFornecedor && editarProdutosFornecedor && excluirProdutosFornecedor) {
			setAcessoTotalProdutosFornecedor(true);
		} else {
			setAcessoTotalProdutosFornecedor(false);
		}
	}, [visualizarProdutosFornecedor, criarProdutosFornecedor, editarProdutosFornecedor, excluirProdutosFornecedor]);

	//#endregion

	//#region Cadastros -> Produto -> Produto de Cross
	const controleAcessoProdutosCrossVisualizar = (situacao: boolean) => {
		// Verifica se qualquer uma das permissões (Criar, Editar, Excluir) está ativa
		if (criarProdutosCross || editarProdutosCross || excluirProdutosCross) {
			// Se qualquer uma estiver ativa, "Visualizar" deve sempre ser true
			setVisualizarProdutosCross(true);
		} else {
			// Se todas estiverem desativadas, "Visualizar" pode ser controlado manualmente
			setVisualizarProdutosCross(situacao);
		}
	};
	const controleAcessoProdutosCrossCriar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarProdutosCross(situacao);
			setCriarProdutosCross(situacao);
		} else {
			setCriarProdutosCross(situacao);
		}
	};
	const controleAcessoProdutosCrossEditar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarProdutosCross(situacao);
			setEditarProdutosCross(situacao);
		} else {
			setEditarProdutosCross(situacao);
		}
	};

	const controleAcessoProdutosCrossExcluir = (situacao: boolean) => {
		if (situacao) {
			setVisualizarProdutosCross(situacao);
			setExcluirProdutosCross(situacao);
		} else {
			setExcluirProdutosCross(situacao);
		}
	};

	const controleAcessoTotalProdutosCross = (situacao: boolean) => {
		if (situacao) {
			setVisualizarProdutosCross(true);
			setCriarProdutosCross(true);
			setEditarProdutosCross(true);
			setExcluirProdutosCross(true);
			setAcessoTotalProdutosCross(true);
		} else {
			setVisualizarProdutosCross(false);
			setCriarProdutosCross(false);
			setEditarProdutosCross(false);
			setExcluirProdutosCross(false);
			setAcessoTotalProdutosCross(false);
		}
	};
	//verificar se todas as opções estão marcadas (visualizar, criar, editar, excluir) se tiverem marca automaticamente o acesso total, caso alguma seja desativada o acesso total é desativado
	useEffect(() => {
		if (visualizarProdutosCross && criarProdutosCross && editarProdutosCross && excluirProdutosCross) {
			setAcessoTotalProdutosCross(true);
		} else {
			setAcessoTotalProdutosCross(false);
		}
	}, [visualizarProdutosCross, criarProdutosCross, editarProdutosCross, excluirProdutosCross]);

	//#endregion

	//#region Cadastros -> Produto -> Produto Picking
	const controleAcessoProdutosPickingVisualizar = (situacao: boolean) => {
		// Verifica se qualquer uma das permissões (Criar, Editar, Excluir) está ativa
		if (criarProdutosPicking || editarProdutosPicking || excluirProdutosPicking) {
			// Se qualquer uma estiver ativa, "Visualizar" deve sempre ser true
			setVisualizarProdutosPicking(true);
		} else {
			// Se todas estiverem desativadas, "Visualizar" pode ser controlado manualmente
			setVisualizarProdutosPicking(situacao);
		}
	};
	const controleAcessoProdutosPickingCriar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarProdutosPicking(situacao);
			setCriarProdutosPicking(situacao);
		} else {
			setCriarProdutosPicking(situacao);
		}
	};
	const controleAcessoProdutosPickingEditar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarProdutosPicking(situacao);
			setEditarProdutosPicking(situacao);
		} else {
			setEditarProdutosPicking(situacao);
		}
	};

	const controleAcessoProdutosPickingExcluir = (situacao: boolean) => {
		if (situacao) {
			setVisualizarProdutosPicking(situacao);
			setExcluirProdutosPicking(situacao);
		} else {
			setExcluirProdutosPicking(situacao);
		}
	};

	const controleAcessoTotalProdutosPicking = (situacao: boolean) => {
		if (situacao) {
			setVisualizarProdutosPicking(true);
			setCriarProdutosPicking(true);
			setEditarProdutosPicking(true);
			setExcluirProdutosPicking(true);
			setAcessoTotalProdutosPicking(true);
		} else {
			setVisualizarProdutosPicking(false);
			setCriarProdutosPicking(false);
			setEditarProdutosPicking(false);
			setExcluirProdutosPicking(false);
			setAcessoTotalProdutosPicking(false);
		}
	};
	//verificar se todas as opções estão marcadas (visualizar, criar, editar, excluir) se tiverem marca automaticamente o acesso total, caso alguma seja desativada o acesso total é desativado
	useEffect(() => {
		if (visualizarProdutosPicking && criarProdutosPicking && editarProdutosPicking && excluirProdutosPicking) {
			setAcessoTotalProdutosPicking(true);
		} else {
			setAcessoTotalProdutosPicking(false);
		}
	}, [visualizarProdutosPicking, criarProdutosPicking, editarProdutosPicking, excluirProdutosPicking]);

	//#endregion

	//#region Cadastros -> Endereco -> Endereço Armazém
	const controleAcessoEnderecosArmzVisualizar = (situacao: boolean) => {
		// Verifica se qualquer uma das permissões (Criar, Editar, Excluir) está ativa
		if (criarEnderecosArmz || editarTempEnderecosArmz || editarEnderecosArmz || excluirEnderecosArmz) {
			// Se qualquer uma estiver ativa, "Visualizar" deve sempre ser true
			setVisualizarEnderecosArmz(true);
		} else {
			// Se todas estiverem desativadas, "Visualizar" pode ser controlado manualmente
			setVisualizarEnderecosArmz(situacao);
		}
	};
	const controleAcessoEnderecosArmzCriar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarEnderecosArmz(situacao);
			setCriarEnderecosArmz(situacao);
		} else {
			setCriarEnderecosArmz(situacao);
		}
	};
	const controleAcessoEnderecosArmzEditarTemp = (situacao: boolean) => {
		if (situacao) {
			setVisualizarEnderecosArmz(situacao);
			setEditarTempEnderecosArmz(situacao);
		} else {
			setEditarTempEnderecosArmz(situacao);
		}
	};
	const controleAcessoEnderecosArmzEditar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarEnderecosArmz(situacao);
			setEditarEnderecosArmz(situacao);
		} else {
			setEditarEnderecosArmz(situacao);
		}
	};

	const controleAcessoEnderecosArmzExcluir = (situacao: boolean) => {
		if (situacao) {
			setVisualizarEnderecosArmz(situacao);
			setExcluirEnderecosArmz(situacao);
		} else {
			setExcluirEnderecosArmz(situacao);
		}
	};

	const controleAcessoTotalEnderecosArmz = (situacao: boolean) => {
		if (situacao) {
			setVisualizarEnderecosArmz(true);
			setCriarEnderecosArmz(true);
			setEditarEnderecosArmz(true);
			setEditarTempEnderecosArmz(true);
			setExcluirEnderecosArmz(true);
			setAcessoTotalEnderecosArmz(true);
		} else {
			setVisualizarEnderecosArmz(false);
			setCriarEnderecosArmz(false);
			setEditarEnderecosArmz(false);
			setEditarTempEnderecosArmz(false);
			setExcluirEnderecosArmz(false);
			setAcessoTotalEnderecosArmz(false);
		}
	};
	//verificar se todas as opções estão marcadas (visualizar, criar, editar, excluir) se tiverem marca automaticamente o acesso total, caso alguma seja desativada o acesso total é desativado
	useEffect(() => {
		if (visualizarEnderecosArmz && criarEnderecosArmz && editarTempEnderecosArmz && editarEnderecosArmz && excluirEnderecosArmz) {
			setAcessoTotalEnderecosArmz(true);
		} else {
			setAcessoTotalEnderecosArmz(false);
		}
	}, [visualizarEnderecosArmz, criarEnderecosArmz, editarEnderecosArmz, editarTempEnderecosArmz, excluirEnderecosArmz]);

	//#endregion

	//#region Cadastros -> Veiculo -> Veiculo
	const controleAcessoVeiculosVisualizar = (situacao: boolean) => {
		// Verifica se qualquer uma das permissões (Criar, Editar, Excluir) está ativa
		if (criarVeiculos || editarVeiculos || excluirVeiculos) {
			// Se qualquer uma estiver ativa, "Visualizar" deve sempre ser true
			setVisualizarVeiculos(true);
		} else {
			// Se todas estiverem desativadas, "Visualizar" pode ser controlado manualmente
			setVisualizarVeiculos(situacao);
		}
	};
	const controleAcessoVeiculosCriar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarVeiculos(situacao);
			setCriarVeiculos(situacao);
		} else {
			setCriarVeiculos(situacao);
		}
	};
	const controleAcessoVeiculosEditar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarVeiculos(situacao);
			setEditarVeiculos(situacao);
		} else {
			setEditarVeiculos(situacao);
		}
	};

	const controleAcessoVeiculosExcluir = (situacao: boolean) => {
		if (situacao) {
			setVisualizarVeiculos(situacao);
			setExcluirVeiculos(situacao);
		} else {
			setExcluirVeiculos(situacao);
		}
	};

	const controleAcessoTotalVeiculos = (situacao: boolean) => {
		if (situacao) {
			setVisualizarVeiculos(true);
			setCriarVeiculos(true);
			setEditarVeiculos(true);
			setExcluirVeiculos(true);
			setAcessoTotalVeiculos(true);
		} else {
			setVisualizarVeiculos(false);
			setCriarVeiculos(false);
			setEditarVeiculos(false);
			setExcluirVeiculos(false);
			setAcessoTotalVeiculos(false);
		}
	};
	//verificar se todas as opções estão marcadas (visualizar, criar, editar, excluir) se tiverem marca automaticamente o acesso total, caso alguma seja desativada o acesso total é desativado
	useEffect(() => {
		if (visualizarVeiculos && criarVeiculos && editarVeiculos && excluirVeiculos) {
			setAcessoTotalVeiculos(true);
		} else {
			setAcessoTotalVeiculos(false);
		}
	}, [visualizarVeiculos, criarVeiculos, editarVeiculos, excluirVeiculos]);

	//#endregion
	//#region Cadastros -> Veiculo -> Motorista
	const controleAcessoMotoristasVisualizar = (situacao: boolean) => {
		// Verifica se qualquer uma das permissões (Criar, Editar, Excluir) está ativa
		if (criarMotoristas || editarMotoristas || excluirMotoristas) {
			// Se qualquer uma estiver ativa, "Visualizar" deve sempre ser true
			setVisualizarMotoristas(true);
		} else {
			// Se todas estiverem desativadas, "Visualizar" pode ser controlado manualmente
			setVisualizarMotoristas(situacao);
		}
	};
	const controleAcessoMotoristasCriar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarMotoristas(situacao);
			setCriarMotoristas(situacao);
		} else {
			setCriarMotoristas(situacao);
		}
	};
	const controleAcessoMotoristasEditar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarMotoristas(situacao);
			setEditarMotoristas(situacao);
		} else {
			setEditarMotoristas(situacao);
		}
	};

	const controleAcessoMotoristasExcluir = (situacao: boolean) => {
		if (situacao) {
			setVisualizarMotoristas(situacao);
			setExcluirMotoristas(situacao);
		} else {
			setExcluirMotoristas(situacao);
		}
	};

	const controleAcessoTotalMotoristas = (situacao: boolean) => {
		if (situacao) {
			setVisualizarMotoristas(true);
			setCriarMotoristas(true);
			setEditarMotoristas(true);
			setExcluirMotoristas(true);
			setAcessoTotalMotoristas(true);
		} else {
			setVisualizarMotoristas(false);
			setCriarMotoristas(false);
			setEditarMotoristas(false);
			setExcluirMotoristas(false);
			setAcessoTotalMotoristas(false);
		}
	};
	//verificar se todas as opções estão marcadas (visualizar, criar, editar, excluir) se tiverem marca automaticamente o acesso total, caso alguma seja desativada o acesso total é desativado
	useEffect(() => {
		if (visualizarMotoristas && criarMotoristas && editarMotoristas && excluirMotoristas) {
			setAcessoTotalMotoristas(true);
		} else {
			setAcessoTotalMotoristas(false);
		}
	}, [visualizarMotoristas, criarMotoristas, editarMotoristas, excluirMotoristas]);

	//#endregion

	//#region Cadastros -> Equipamento -> Equipamento
	const controleAcessoEquipamentoVisualizar = (situacao: boolean) => {
		// Verifica se qualquer uma das permissões (Criar, Editar, Excluir) está ativa
		if (criarEquipamento || editarEquipamento || excluirEquipamento) {
			// Se qualquer uma estiver ativa, "Visualizar" deve sempre ser true
			setVisualizarEquipamento(true);
		} else {
			// Se todas estiverem desativadas, "Visualizar" pode ser controlado manualmente
			setVisualizarEquipamento(situacao);
		}
	};
	const controleAcessoEquipamentoCriar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarEquipamento(situacao);
			setCriarEquipamento(situacao);
		} else {
			setCriarEquipamento(situacao);
		}
	};
	const controleAcessoEquipamentoEditar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarEquipamento(situacao);
			setEditarEquipamento(situacao);
		} else {
			setEditarEquipamento(situacao);
		}
	};

	const controleAcessoEquipamentoExcluir = (situacao: boolean) => {
		if (situacao) {
			setVisualizarEquipamento(situacao);
			setExcluirEquipamento(situacao);
		} else {
			setExcluirEquipamento(situacao);
		}
	};

	const controleAcessoTotalEquipamento = (situacao: boolean) => {
		if (situacao) {
			setVisualizarEquipamento(true);
			setCriarEquipamento(true);
			setEditarEquipamento(true);
			setExcluirEquipamento(true);
			setAcessoTotalEquipamento(true);
		} else {
			setVisualizarEquipamento(false);
			setCriarEquipamento(false);
			setEditarEquipamento(false);
			setExcluirEquipamento(false);
			setAcessoTotalEquipamento(false);
		}
	};
	//verificar se todas as opções estão marcadas (visualizar, criar, editar, excluir) se tiverem marca automaticamente o acesso total, caso alguma seja desativada o acesso total é desativado
	useEffect(() => {
		if (visualizarEquipamento && criarEquipamento && editarEquipamento && excluirEquipamento) {
			setAcessoTotalEquipamento(true);
		} else {
			setAcessoTotalEquipamento(false);
		}
	}, [visualizarEquipamento, criarEquipamento, editarEquipamento, excluirEquipamento]);

	//#endregion

	//#region Cadastros -> Colaborador -> Colaborador
	const controleAcessoColaboradorVisualizar = (situacao: boolean) => {
		// Verifica se qualquer uma das permissões (Criar, Editar, Excluir) está ativa
		if (criarColaborador || editarColaborador || excluirColaborador) {
			// Se qualquer uma estiver ativa, "Visualizar" deve sempre ser true
			setVisualizarColaborador(true);
		} else {
			// Se todas estiverem desativadas, "Visualizar" pode ser controlado manualmente
			setVisualizarColaborador(situacao);
		}
	};
	const controleAcessoColaboradorCriar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarColaborador(situacao);
			setCriarColaborador(situacao);
		} else {
			setCriarColaborador(situacao);
		}
	};
	const controleAcessoColaboradorEditar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarColaborador(situacao);
			setEditarColaborador(situacao);
		} else {
			setEditarColaborador(situacao);
		}
	};

	const controleAcessoColaboradorExcluir = (situacao: boolean) => {
		if (situacao) {
			setVisualizarColaborador(situacao);
			setExcluirColaborador(situacao);
		} else {
			setExcluirColaborador(situacao);
		}
	};

	const controleAcessoTotalColaborador = (situacao: boolean) => {
		if (situacao) {
			setVisualizarColaborador(true);
			setCriarColaborador(true);
			setEditarColaborador(true);
			setExcluirColaborador(true);
			setAcessoTotalColaborador(true);
		} else {
			setVisualizarColaborador(false);
			setCriarColaborador(false);
			setEditarColaborador(false);
			setExcluirColaborador(false);
			setAcessoTotalColaborador(false);
		}
	};
	//verificar se todas as opções estão marcadas (visualizar, criar, editar, excluir) se tiverem marca automaticamente o acesso total, caso alguma seja desativada o acesso total é desativado
	useEffect(() => {
		if (visualizarColaborador && criarColaborador && editarColaborador && excluirColaborador) {
			setAcessoTotalColaborador(true);
		} else {
			setAcessoTotalColaborador(false);
		}
	}, [visualizarColaborador, criarColaborador, editarColaborador, excluirColaborador]);

	//#endregion

	//#region Cadastros -> Fiscal -> CFOP
	const controleAcessoCFOPVisualizar = (situacao: boolean) => {
		// Verifica se qualquer uma das permissões (Criar, Editar, Excluir) está ativa
		if (criarCFOP || importarArqCFOP || baixarModeloCFOP || editarCFOP || excluirCFOP) {
			// Se qualquer uma estiver ativa, "Visualizar" deve sempre ser true
			setVisualizarCFOP(true);
		} else {
			// Se todas estiverem desativadas, "Visualizar" pode ser controlado manualmente
			setVisualizarCFOP(situacao);
		}
	};
	const controleAcessoCFOPImpArq = (situacao: boolean) => {
		if (situacao) {
			setVisualizarCFOP(situacao);
			setImportarArqCFOP(situacao);
		} else {
			setImportarArqCFOP(situacao);
		}
	};
	const controleAcessoCFOPBaixarModelo = (situacao: boolean) => {
		if (situacao) {
			setVisualizarCFOP(situacao);
			setBaixarModeloCFOP(situacao);
		} else {
			setBaixarModeloCFOP(situacao);
		}
	};
	const controleAcessoCFOPCriar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarCFOP(situacao);
			setCriarCFOP(situacao);
		} else {
			setCriarCFOP(situacao);
		}
	};
	const controleAcessoCFOPEditar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarCFOP(situacao);
			setEditarCFOP(situacao);
		} else {
			setEditarCFOP(situacao);
		}
	};

	const controleAcessoCFOPExcluir = (situacao: boolean) => {
		if (situacao) {
			setVisualizarCFOP(situacao);
			setExcluirCFOP(situacao);
		} else {
			setExcluirCFOP(situacao);
		}
	};

	const controleAcessoTotalCFOP = (situacao: boolean) => {
		if (situacao) {
			setVisualizarCFOP(true);
			setImportarArqCFOP(true);
			setBaixarModeloCFOP(true);
			setCriarCFOP(true);
			setEditarCFOP(true);
			setExcluirCFOP(true);
			setAcessoTotalCFOP(true);
		} else {
			setVisualizarCFOP(false);
			setImportarArqCFOP(false);
			setBaixarModeloCFOP(false);
			setCriarCFOP(false);
			setEditarCFOP(false);
			setExcluirCFOP(false);
			setAcessoTotalCFOP(false);
		}
	};
	//verificar se todas as opções estão marcadas (visualizar, criar, editar, excluir) se tiverem marca automaticamente o acesso total, caso alguma seja desativada o acesso total é desativado
	useEffect(() => {
		if (visualizarCFOP && importarArqCFOP && baixarModeloCFOP && criarCFOP && editarCFOP && excluirCFOP) {
			setAcessoTotalCFOP(true);
		} else {
			setAcessoTotalCFOP(false);
		}
	}, [visualizarCFOP, importarArqCFOP, baixarModeloCFOP, criarCFOP, editarCFOP, excluirCFOP]);

	//#endregion

	//#region Cadastros -> Fiscal -> CFOP EntradaxSaida
	const controleAcessoCFOPEntradaSaidaVisualizar = (situacao: boolean) => {
		// Verifica se qualquer uma das permissões (Criar, Editar, Excluir) está ativa
		if (criarCFOPEntradaSaida || editarCFOPEntradaSaida || excluirCFOPEntradaSaida) {
			// Se qualquer uma estiver ativa, "Visualizar" deve sempre ser true
			setVisualizarCFOPEntradaSaida(true);
		} else {
			// Se todas estiverem desativadas, "Visualizar" pode ser controlado manualmente
			setVisualizarCFOPEntradaSaida(situacao);
		}
	};
	const controleAcessoCFOPEntradaSaidaCriar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarCFOPEntradaSaida(situacao);
			setCriarCFOPEntradaSaida(situacao);
		} else {
			setCriarCFOPEntradaSaida(situacao);
		}
	};
	const controleAcessoCFOPEntradaSaidaEditar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarCFOPEntradaSaida(situacao);
			setEditarCFOPEntradaSaida(situacao);
		} else {
			setEditarCFOPEntradaSaida(situacao);
		}
	};

	const controleAcessoCFOPEntradaSaidaExcluir = (situacao: boolean) => {
		if (situacao) {
			setVisualizarCFOPEntradaSaida(situacao);
			setExcluirCFOPEntradaSaida(situacao);
		} else {
			setExcluirCFOPEntradaSaida(situacao);
		}
	};

	const controleAcessoTotalCFOPEntradaSaida = (situacao: boolean) => {
		if (situacao) {
			setVisualizarCFOPEntradaSaida(true);
			setCriarCFOPEntradaSaida(true);
			setEditarCFOPEntradaSaida(true);
			setExcluirCFOPEntradaSaida(true);
			setAcessoTotalCFOPEntradaSaida(true);
		} else {
			setVisualizarCFOPEntradaSaida(false);
			setCriarCFOPEntradaSaida(false);
			setEditarCFOPEntradaSaida(false);
			setExcluirCFOPEntradaSaida(false);
			setAcessoTotalCFOPEntradaSaida(false);
		}
	};
	//verificar se todas as opções estão marcadas (visualizar, criar, editar, excluir) se tiverem marca automaticamente o acesso total, caso alguma seja desativada o acesso total é desativado
	useEffect(() => {
		if (visualizarCFOPEntradaSaida && criarCFOPEntradaSaida && editarCFOPEntradaSaida && excluirCFOPEntradaSaida) {
			setAcessoTotalCFOPEntradaSaida(true);
		} else {
			setAcessoTotalCFOPEntradaSaida(false);
		}
	}, [visualizarCFOPEntradaSaida, criarCFOPEntradaSaida, editarCFOPEntradaSaida, excluirCFOPEntradaSaida]);

	//#endregion

	//#region Cadastros -> Fiscal -> Grupo Tributário
	const controleAcessoGrupoTributarioVisualizar = (situacao: boolean) => {
		// Verifica se qualquer uma das permissões (Criar, Editar, Excluir) está ativa
		if (criarGrupoTributario || editarGrupoTributario || excluirGrupoTributario) {
			// Se qualquer uma estiver ativa, "Visualizar" deve sempre ser true
			setVisualizarGrupoTributario(true);
		} else {
			// Se todas estiverem desativadas, "Visualizar" pode ser controlado manualmente
			setVisualizarGrupoTributario(situacao);
		}
	};
	const controleAcessoGrupoTributarioCriar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarGrupoTributario(situacao);
			setCriarGrupoTributario(situacao);
		} else {
			setCriarGrupoTributario(situacao);
		}
	};
	const controleAcessoGrupoTributarioEditar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarGrupoTributario(situacao);
			setEditarGrupoTributario(situacao);
		} else {
			setEditarGrupoTributario(situacao);
		}
	};

	const controleAcessoGrupoTributarioExcluir = (situacao: boolean) => {
		if (situacao) {
			setVisualizarGrupoTributario(situacao);
			setExcluirGrupoTributario(situacao);
		} else {
			setExcluirGrupoTributario(situacao);
		}
	};

	const controleAcessoTotalGrupoTributario = (situacao: boolean) => {
		if (situacao) {
			setVisualizarGrupoTributario(true);
			setCriarGrupoTributario(true);
			setEditarGrupoTributario(true);
			setExcluirGrupoTributario(true);
			setAcessoTotalGrupoTributario(true);
		} else {
			setVisualizarGrupoTributario(false);
			setCriarGrupoTributario(false);
			setEditarGrupoTributario(false);
			setExcluirGrupoTributario(false);
			setAcessoTotalGrupoTributario(false);
		}
	};
	//verificar se todas as opções estão marcadas (visualizar, criar, editar, excluir) se tiverem marca automaticamente o acesso total, caso alguma seja desativada o acesso total é desativado
	useEffect(() => {
		if (visualizarGrupoTributario && criarGrupoTributario && editarGrupoTributario && excluirGrupoTributario) {
			setAcessoTotalGrupoTributario(true);
		} else {
			setAcessoTotalGrupoTributario(false);
		}
	}, [visualizarGrupoTributario, criarGrupoTributario, editarGrupoTributario, excluirGrupoTributario]);

	//#endregion

	//#region Adm. Operacional -> Fiscal Entrada -> NF-e Armazenagem
	const controleAcessoNfeEntradasArmzVisualizar = (situacao: boolean) => {
		// Verifica se qualquer uma das permissões (Criar, Editar, Excluir) está ativa
		if (importarNfeEntradasArmz || detalharNfeEntradaArmz || excluirNfeEntradasArmz) {
			// Se qualquer uma estiver ativa, "Visualizar" deve sempre ser true
			setVisualizarNfeEntradasArmz(true);
		} else {
			// Se todas estiverem desativadas, "Visualizar" pode ser controlado manualmente
			setVisualizarNfeEntradasArmz(situacao);
		}
	};

	const controleAcessoNfeEntradasArmzDetalhar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarNfeEntradasArmz(situacao);
			setDetalharNfeEntradaArmz(situacao);
		} else {
			setDetalharNfeEntradaArmz(situacao);
		}
	};

	const controleAcessoNfeEntradasArmzImportar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarNfeEntradasArmz(situacao);
			setImportarNfeEntradasArmz(situacao);
		} else {
			setImportarNfeEntradasArmz(situacao);
		}
	};

	const controleAcessoNfeEntradasArmzExcluir = (situacao: boolean) => {
		if (situacao) {
			setVisualizarNfeEntradasArmz(situacao);
			setExcluirNfeEntradasArmz(situacao);
		} else {
			setExcluirNfeEntradasArmz(situacao);
		}
	};

	const controleAcessoTotalNfeEntradasArmz = (situacao: boolean) => {
		if (situacao) {
			setVisualizarNfeEntradasArmz(true);
			setDetalharNfeEntradaArmz(true);
			setImportarNfeEntradasArmz(true);
			setExcluirNfeEntradasArmz(true);
			setAcessoTotalNfeEntradasArmz(true);
		} else {
			setVisualizarNfeEntradasArmz(false);
			setDetalharNfeEntradaArmz(false);
			setImportarNfeEntradasArmz(false);
			setExcluirNfeEntradasArmz(false);
			setAcessoTotalNfeEntradasArmz(false);
		}
	};
	//verificar se todas as opções estão marcadas (visualizar, criar, editar, excluir) se tiverem marca automaticamente o acesso total, caso alguma seja desativada o acesso total é desativado
	useEffect(() => {
		if (visualizarNfeEntradasArmz && detalharNfeEntradaArmz && importarNfeEntradasArmz && excluirNfeEntradasArmz) {
			setAcessoTotalNfeEntradasArmz(true);
		} else {
			setAcessoTotalNfeEntradasArmz(false);
		}
	}, [visualizarNfeEntradasArmz, detalharNfeEntradaArmz, importarNfeEntradasArmz, excluirNfeEntradasArmz]);

	//#endregion
	//#region Adm. Operacional -> Fiscal Entrada -> NF-e Entrada Cross
	const controleAcessoNfeEntradasCrossDockingVisualizar = (situacao: boolean) => {
		// Verifica se qualquer uma das permissões (Criar, Editar, Excluir) está ativa
		if (detalharNfeEntradaCrossDocking) {
			// Se qualquer uma estiver ativa, "Visualizar" deve sempre ser true
			setVisualizarNfeEntradaCrossDocking(true);
		} else {
			// Se todas estiverem desativadas, "Visualizar" pode ser controlado manualmente
			setVisualizarNfeEntradaCrossDocking(situacao);
		}
	};
	const controleAcessoNfeEntradasCrossDockingDetalhar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarNfeEntradaCrossDocking(situacao);
			setDetalharNfeEntradaCrossDocking(situacao);
		} else {
			setDetalharNfeEntradaCrossDocking(situacao);
		}
	};

	const controleAcessoTotalNfeEntradasCrossDocking = (situacao: boolean) => {
		if (situacao) {
			setVisualizarNfeEntradaCrossDocking(true);
			setDetalharNfeEntradaCrossDocking(true);
			setAcessoTotalNfeEntradaCrossDocking(true);
		} else {
			setVisualizarNfeEntradaCrossDocking(false);
			setDetalharNfeEntradaCrossDocking(false);
			setAcessoTotalNfeEntradaCrossDocking(false);
		}
	};
	//verificar se todas as opções estão marcadas (visualizar, criar, editar, excluir) se tiverem marca automaticamente o acesso total, caso alguma seja desativada o acesso total é desativado
	useEffect(() => {
		if (visualizarNfeEntradaCrossDocking && detalharNfeEntradaCrossDocking) {
			setAcessoTotalNfeEntradaCrossDocking(true);
		} else {
			setAcessoTotalNfeEntradaCrossDocking(false);
		}
	}, [visualizarNfeEntradaCrossDocking, detalharNfeEntradaCrossDocking]);

	//#endregion
	//#region Adm. Operacional -> Fiscal Entrada -> NF-e Pedido
	const controleAcessoNfePedidoVisualizar = (situacao: boolean) => {
		// Verifica se qualquer uma das permissões (Criar, Editar, Excluir) está ativa
		if (detalharNfePedido || excluirNfePedido) {
			// Se qualquer uma estiver ativa, "Visualizar" deve sempre ser true
			setVisualizarNfePedido(true);
		} else {
			// Se todas estiverem desativadas, "Visualizar" pode ser controlado manualmente
			setVisualizarNfePedido(situacao);
		}
	};
	const controleAcessoNfePedidoDetalhar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarNfePedido(situacao);
			setDetalharNfePedido(situacao);
		} else {
			setDetalharNfePedido(situacao);
		}
	};
	const controleAcessoNfePedidoExcluir = (situacao: boolean) => {
		if (situacao) {
			setVisualizarNfePedido(situacao);
			setExcluirNfePedido(situacao);
		} else {
			setExcluirNfePedido(situacao);
		}
	};

	const controleAcessoTotalNfesPedido = (situacao: boolean) => {
		if (situacao) {
			setVisualizarNfePedido(true);
			setDetalharNfePedido(true);
			setExcluirNfePedido(true);
			setAcessoTotalNfePedido(true);
		} else {
			setVisualizarNfePedido(false);
			setDetalharNfePedido(false);
			setExcluirNfePedido(false);
			setAcessoTotalNfePedido(false);
		}
	};
	//verificar se todas as opções estão marcadas (visualizar, criar, editar, excluir) se tiverem marca automaticamente o acesso total, caso alguma seja desativada o acesso total é desativado
	useEffect(() => {
		if (visualizarNfePedido && detalharNfePedido && excluirNfePedido) {
			setAcessoTotalNfePedido(true);
		} else {
			setAcessoTotalNfePedido(false);
		}
	}, [visualizarNfePedido, detalharNfePedido, excluirNfePedido]);

	//#endregion

	//#region Adm. Operacional -> Fiscal Saída -> NF-e Emitido
	const controleAcessoNfeEmitidoVisualizar = (situacao: boolean) => {
		// Verifica se qualquer uma das permissões (Criar, Editar, Excluir) está ativa
		if (detalharNfeEmitido) {
			// Se qualquer uma estiver ativa, "Visualizar" deve sempre ser true
			setVisualizarNfeEmitido(true);
		} else {
			// Se todas estiverem desativadas, "Visualizar" pode ser controlado manualmente
			setVisualizarNfeEmitido(situacao);
		}
	};
	const controleAcessoNfeEmitidoDetalhar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarNfeEmitido(situacao);
			setDetalharNfeEmitido(situacao);
		} else {
			setDetalharNfeEmitido(situacao);
		}
	};

	const controleAcessoTotalNfesEmitido = (situacao: boolean) => {
		if (situacao) {
			setVisualizarNfeEmitido(true);
			setDetalharNfeEmitido(true);
			setAcessoTotalNfeEmitido(true);
		} else {
			setVisualizarNfeEmitido(false);
			setDetalharNfeEmitido(false);
			setAcessoTotalNfeEmitido(false);
		}
	};
	//verificar se todas as opções estão marcadas (visualizar, criar, editar, excluir) se tiverem marca automaticamente o acesso total, caso alguma seja desativada o acesso total é desativado
	useEffect(() => {
		if (visualizarNfeEmitido && detalharNfeEmitido) {
			setAcessoTotalNfeEmitido(true);
		} else {
			setAcessoTotalNfeEmitido(false);
		}
	}, [visualizarNfeEmitido, detalharNfeEmitido]);

	//#endregion
	//#region Adm. Operacional -> Fiscal Saída -> Emissão NF-e
	const controleAcessoEmissaoNfeVisualizar = (situacao: boolean) => {
		// Verifica se qualquer uma das permissões (Criar, Editar, Excluir) está ativa
		if (criarEmissaoNfe || editarEmissaoNfe || excluirEmissaoNfe) {
			// Se qualquer uma estiver ativa, "Visualizar" deve sempre ser true
			setVisualizarEmissaoNfe(true);
		} else {
			// Se todas estiverem desativadas, "Visualizar" pode ser controlado manualmente
			setVisualizarEmissaoNfe(situacao);
		}
	};
	const controleAcessoEmissaoNfeCriar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarEmissaoNfe(situacao);
			setCriarEmissaoNfe(situacao);
		} else {
			setCriarEmissaoNfe(situacao);
		}
	};
	const controleAcessoEmissaoNfeEditar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarEmissaoNfe(situacao);
			setEditarEmissaoNfe(situacao);
		} else {
			setEditarEmissaoNfe(situacao);
		}
	};
	const controleAcessoEmissaoNfeExcluir = (situacao: boolean) => {
		if (situacao) {
			setVisualizarEmissaoNfe(situacao);
			setExcluirEmissaoNfe(situacao);
		} else {
			setExcluirEmissaoNfe(situacao);
		}
	};

	const controleAcessoTotalEmissaoNfe = (situacao: boolean) => {
		if (situacao) {
			setVisualizarEmissaoNfe(true);
			setCriarEmissaoNfe(true);
			setEditarEmissaoNfe(true);
			setExcluirEmissaoNfe(true);
			setAcessoTotalEmissaoNfe(true);
		} else {
			setVisualizarEmissaoNfe(false);
			setCriarEmissaoNfe(false);
			setEditarEmissaoNfe(false);
			setExcluirEmissaoNfe(false);
			setAcessoTotalEmissaoNfe(false);
		}
	};
	//verificar se todas as opções estão marcadas (visualizar, criar, editar, excluir) se tiverem marca automaticamente o acesso total, caso alguma seja desativada o acesso total é desativado
	useEffect(() => {
		if (visualizarEmissaoNfe && criarEmissaoNfe && editarEmissaoNfe && excluirEmissaoNfe) {
			setAcessoTotalEmissaoNfe(true);
		} else {
			setAcessoTotalEmissaoNfe(false);
		}
	}, [visualizarEmissaoNfe, criarEmissaoNfe, editarEmissaoNfe, excluirEmissaoNfe]);

	//#endregion
	//#region Adm. Operacional -> Fiscal Saída -> Manifestar NF-e
	const controleAcessoManifestarNfeVisualizar = (situacao: boolean) => {
		// Verifica se qualquer uma das permissões (Criar, Editar, Excluir) está ativa
		if (manifestarNfe) {
			// Se qualquer uma estiver ativa, "Visualizar" deve sempre ser true
			setVisualizarManifestarNfe(true);
		} else {
			// Se todas estiverem desativadas, "Visualizar" pode ser controlado manualmente
			setVisualizarManifestarNfe(situacao);
		}
	};
	const controleAcessoManifestarNfeManifestar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarManifestarNfe(situacao);
			setManifestarNfe(situacao);
		} else {
			setManifestarNfe(situacao);
		}
	};

	const controleAcessoTotalManifestarNFe = (situacao: boolean) => {
		if (situacao) {
			setVisualizarManifestarNfe(true);
			setManifestarNfe(true);
			setAcessoTotalManifestarNfe(true);
		} else {
			setVisualizarManifestarNfe(false);
			setManifestarNfe(false);
			setAcessoTotalManifestarNfe(false);
		}
	};
	//verificar se todas as opções estão marcadas (visualizar, criar, editar, excluir) se tiverem marca automaticamente o acesso total, caso alguma seja desativada o acesso total é desativado
	useEffect(() => {
		if (visualizarManifestarNfe && manifestarNfe) {
			setAcessoTotalManifestarNfe(true);
		} else {
			setAcessoTotalManifestarNfe(false);
		}
	}, [visualizarManifestarNfe, manifestarNfe]);

	//#endregion
	//#region Adm. Operacional -> Controle de Processo -> Processo de Entrada
	const controleAcessoProcessoEntradaVisualizar = (situacao: boolean) => {
		// Verifica se qualquer uma das permissões (Criar, Editar, Excluir) está ativa
		if (criarProcEntrada || editarProcEntrada || cancelarProcEntrada) {
			// Se qualquer uma estiver ativa, "Visualizar" deve sempre ser true
			setVisualizarProcEntrada(true);
		} else {
			// Se todas estiverem desativadas, "Visualizar" pode ser controlado manualmente
			setVisualizarProcEntrada(situacao);
		}
	};
	const controleAcessoProcessoEntradaCriar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarProcEntrada(situacao);
			setCriarProcEntrada(situacao);
		} else {
			setCriarProcEntrada(situacao);
		}
	};
	const controleAcessoProcessoEntradaEditar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarProcEntrada(situacao);
			setEditarProcEntrada(situacao);
		} else {
			setEditarProcEntrada(situacao);
		}
	};
	const controleAcessoProcessoEntradaCancelar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarProcEntrada(situacao);
			setCancelarProcEntrada(situacao);
		} else {
			setCancelarProcEntrada(situacao);
		}
	};

	const controleAcessoTotalProcessoEntrada = (situacao: boolean) => {
		if (situacao) {
			setVisualizarProcEntrada(true);
			setCriarProcEntrada(true);
			setEditarProcEntrada(true);
			setCancelarProcEntrada(true);
			setAcessoTotalProcEntrada(true);
		} else {
			setVisualizarProcEntrada(false);
			setCriarProcEntrada(false);
			setEditarProcEntrada(false);
			setCancelarProcEntrada(false);
			setAcessoTotalProcEntrada(false);
		}
	};
	//verificar se todas as opções estão marcadas (visualizar, criar, editar, excluir) se tiverem marca automaticamente o acesso total, caso alguma seja desativada o acesso total é desativado
	useEffect(() => {
		if (visualizarProcEntrada && criarProcEntrada && editarProcEntrada && cancelarProcEntrada) {
			setAcessoTotalProcEntrada(true);
		} else {
			setAcessoTotalProcEntrada(false);
		}
	}, [visualizarProcEntrada, criarProcEntrada, editarProcEntrada, cancelarProcEntrada]);

	//#endregion
	//#region Adm. Operacional -> Controle de Processo -> Processo de Saída
	const controleAcessoProcessoSaidaVisualizar = (situacao: boolean) => {
		// Verifica se qualquer uma das permissões (Criar, Editar, Excluir) está ativa
		if (criarProcSaida || editarProcSaida || cancelarProcSaida) {
			// Se qualquer uma estiver ativa, "Visualizar" deve sempre ser true
			setVisualizarProcSaida(true);
		} else {
			// Se todas estiverem desativadas, "Visualizar" pode ser controlado manualmente
			setVisualizarProcSaida(situacao);
		}
	};
	const controleAcessoProcessoSaidaCriar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarProcSaida(situacao);
			setCriarProcSaida(situacao);
		} else {
			setCriarProcSaida(situacao);
		}
	};
	const controleAcessoProcessoSaidaEditar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarProcSaida(situacao);
			setEditarProcSaida(situacao);
		} else {
			setEditarProcSaida(situacao);
		}
	};
	const controleAcessoProcessoSaidaCancelar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarProcSaida(situacao);
			setCancelarProcSaida(situacao);
		} else {
			setCancelarProcSaida(situacao);
		}
	};

	const controleAcessoTotalProcessoSaida = (situacao: boolean) => {
		if (situacao) {
			setVisualizarProcSaida(true);
			setCriarProcSaida(true);
			setEditarProcSaida(true);
			setCancelarProcSaida(true);
			setAcessoTotalProcSaida(true);
		} else {
			setVisualizarProcSaida(false);
			setCriarProcSaida(false);
			setEditarProcSaida(false);
			setCancelarProcSaida(false);
			setAcessoTotalProcSaida(false);
		}
	};
	//verificar se todas as opções estão marcadas (visualizar, criar, editar, excluir) se tiverem marca automaticamente o acesso total, caso alguma seja desativada o acesso total é desativado
	useEffect(() => {
		if (visualizarProcSaida && criarProcSaida && editarProcSaida && cancelarProcSaida) {
			setAcessoTotalProcSaida(true);
		} else {
			setAcessoTotalProcSaida(false);
		}
	}, [visualizarProcSaida, criarProcSaida, editarProcSaida, cancelarProcSaida]);

	//#endregion
	//#region Adm. Operacional -> Pedidos -> Criação de Pedido - Importação de Arquivo
	const controleAcessoImportacaoArquivoVisualizar = (situacao: boolean) => {
		// Verifica se qualquer uma das permissões (Criar, Editar, Excluir) está ativa
		if (importarArquivo) {
			// Se qualquer uma estiver ativa, "Visualizar" deve sempre ser true
			setVisualizarImportacaoArquivo(true);
		} else {
			// Se todas estiverem desativadas, "Visualizar" pode ser controlado manualmente
			setVisualizarImportacaoArquivo(situacao);
		}
	};
	const controleAcessoImportacaoArquivoImportar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarImportacaoArquivo(situacao);
			setImportarArquivo(situacao);
		} else {
			setImportarArquivo(situacao);
		}
	};

	const controleAcessoTotalImportacaoArquivo = (situacao: boolean) => {
		if (situacao) {
			setVisualizarImportacaoArquivo(true);
			setImportarArquivo(true);
			setAcessoTotalImportacaoArquivo(true);
		} else {
			setVisualizarImportacaoArquivo(false);
			setImportarArquivo(false);
			setAcessoTotalImportacaoArquivo(false);
		}
	};
	//verificar se todas as opções estão marcadas (visualizar, criar, editar, excluir) se tiverem marca automaticamente o acesso total, caso alguma seja desativada o acesso total é desativado
	useEffect(() => {
		if (visualizarImportacaoArquivo && importarArquivo) {
			setAcessoTotalImportacaoArquivo(true);
		} else {
			setAcessoTotalImportacaoArquivo(false);
		}
	}, [visualizarImportacaoArquivo, importarArquivo]);

	//#endregion
	//#region Adm. Operacional -> Pedidos -> Criação de Pedido - NF-e p/ Pedido
	const controleAcessoNfeParaPedidoVisualizar = (situacao: boolean) => {
		// Verifica se qualquer uma das permissões (Criar, Editar, Excluir) está ativa
		if (importarNfeParaPedido) {
			// Se qualquer uma estiver ativa, "Visualizar" deve sempre ser true
			setVisualizarNfeParaPedido(true);
		} else {
			// Se todas estiverem desativadas, "Visualizar" pode ser controlado manualmente
			setVisualizarNfeParaPedido(situacao);
		}
	};
	const controleAcessoNfeParaPedidoImportar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarNfeParaPedido(situacao);
			setImportarNfeParaPedido(situacao);
		} else {
			setImportarNfeParaPedido(situacao);
		}
	};

	const controleAcessoTotalNfeParaPedido = (situacao: boolean) => {
		if (situacao) {
			setVisualizarNfeParaPedido(true);
			setImportarNfeParaPedido(true);
			setAcessoTotalNfeParaPedido(true);
		} else {
			setVisualizarNfeParaPedido(false);
			setImportarNfeParaPedido(false);
			setAcessoTotalNfeParaPedido(false);
		}
	};
	//verificar se todas as opções estão marcadas (visualizar, criar, editar, excluir) se tiverem marca automaticamente o acesso total, caso alguma seja desativada o acesso total é desativado
	useEffect(() => {
		if (visualizarNfeParaPedido && importarNfeParaPedido) {
			setAcessoTotalNfeParaPedido(true);
		} else {
			setAcessoTotalNfeParaPedido(false);
		}
	}, [visualizarNfeParaPedido, importarNfeParaPedido]);

	//#endregion
	//#region Adm. Operacional -> Pedidos -> Criação de Pedido - Pedido Manual
	const controleAcessoPedidoManualVisualizar = (situacao: boolean) => {
		// Verifica se qualquer uma das permissões (Criar, Editar, Excluir) está ativa
		if (criarPedidoManual || adicionarItemPedidoManual || excluirItemPedidoManual) {
			// Se qualquer uma estiver ativa, "Visualizar" deve sempre ser true
			setVisualizarPedidoManual(true);
		} else {
			// Se todas estiverem desativadas, "Visualizar" pode ser controlado manualmente
			setVisualizarPedidoManual(situacao);
		}
	};
	const controleAcessoPedidoManualCriar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarPedidoManual(situacao);
			setCriarPedidoManual(situacao);
		} else {
			setCriarPedidoManual(situacao);
		}
	};
	const controleAcessoPedidoManualAdicionarItem = (situacao: boolean) => {
		if (situacao) {
			setVisualizarPedidoManual(situacao);
			setAdicionarItemPedidoManual(situacao);
		} else {
			setAdicionarItemPedidoManual(situacao);
		}
	};
	const controleAcessoPedidoManualExcluirItem = (situacao: boolean) => {
		if (situacao) {
			setVisualizarPedidoManual(situacao);
			setExcluirItemPedidoManual(situacao);
		} else {
			setExcluirItemPedidoManual(situacao);
		}
	};

	const controleAcessoTotalPedidoManual = (situacao: boolean) => {
		if (situacao) {
			setVisualizarPedidoManual(true);
			setCriarPedidoManual(true);
			setAdicionarItemPedidoManual(true);
			setExcluirItemPedidoManual(true);
			setAcessoTotalPedidoManual(true);
		} else {
			setVisualizarPedidoManual(false);
			setCriarPedidoManual(false);
			setAdicionarItemPedidoManual(false);
			setExcluirItemPedidoManual(false);
			setAcessoTotalPedidoManual(false);
		}
	};
	//verificar se todas as opções estão marcadas (visualizar, criar, editar, excluir) se tiverem marca automaticamente o acesso total, caso alguma seja desativada o acesso total é desativado
	useEffect(() => {
		if (visualizarPedidoManual && criarPedidoManual && adicionarItemPedidoManual && excluirItemPedidoManual) {
			setAcessoTotalPedidoManual(true);
		} else {
			setAcessoTotalPedidoManual(false);
		}
	}, [visualizarPedidoManual, criarPedidoManual, adicionarItemPedidoManual, excluirItemPedidoManual]);

	//#endregion
	//#region Adm. Operacional -> Pedidos -> Pedidos Pendentes
	const controleAcessoPedidoPendenteVisualizar = (situacao: boolean) => {
		// Verifica se qualquer uma das permissões (Criar, Editar, Excluir) está ativa
		if (
			quebrarPedidoTarefas ||
			liberarPedidoParaPicking ||
			editarPedidoPendente ||
			excluirPedidoPendente ||
			edtiarItemPedidoPendente ||
			excluirItemPedidoPendente
		) {
			// Se qualquer uma estiver ativa, "Visualizar" deve sempre ser true
			setVisualizarPedidosPendentes(true);
		} else {
			// Se todas estiverem desativadas, "Visualizar" pode ser controlado manualmente
			setVisualizarPedidosPendentes(situacao);
		}
	};
	const controleAcessoPedidoPendenteQuebrarPedido = (situacao: boolean) => {
		if (situacao) {
			setVisualizarPedidosPendentes(situacao);
			setQuebrarPedidoTarefas(situacao);
		} else {
			setQuebrarPedidoTarefas(situacao);
		}
	};
	const controleAcessoPedidoPendenteLiberarPicking = (situacao: boolean) => {
		if (situacao) {
			setVisualizarPedidosPendentes(situacao);
			setLiberarPedidoParaPicking(situacao);
		} else {
			setLiberarPedidoParaPicking(situacao);
		}
	};
	const controleAcessoPedidoPendenteEditar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarPedidosPendentes(situacao);
			setEditarPedidoPendente(situacao);
		} else {
			setEditarPedidoPendente(situacao);
		}
	};
	const controleAcessoPedidoPendenteExcluir = (situacao: boolean) => {
		if (situacao) {
			setVisualizarPedidosPendentes(situacao);
			setExcluirPedidoPendente(situacao);
		} else {
			setExcluirPedidoPendente(situacao);
		}
	};
	const controleAcessoPedidoPendenteEditarItem = (situacao: boolean) => {
		if (situacao) {
			setVisualizarPedidosPendentes(situacao);
			setEdtiarItemPedidoPendente(situacao);
		} else {
			setEdtiarItemPedidoPendente(situacao);
		}
	};
	const controleAcessoPedidoPendenteExcluirItem = (situacao: boolean) => {
		if (situacao) {
			setVisualizarPedidosPendentes(situacao);
			setExcluirItemPedidoPendente(situacao);
		} else {
			setExcluirItemPedidoPendente(situacao);
		}
	};

	const controleAcessoTotalPedidoPendente = (situacao: boolean) => {
		if (situacao) {
			setVisualizarPedidosPendentes(true);
			setQuebrarPedidoTarefas(true);
			setLiberarPedidoParaPicking(true);
			setEditarPedidoPendente(true);
			setExcluirPedidoPendente(true);
			setEdtiarItemPedidoPendente(true);
			setExcluirItemPedidoPendente(true);
			setAcessoTotalPedidosPendentes(true);
		} else {
			setVisualizarPedidosPendentes(false);
			setQuebrarPedidoTarefas(false);
			setLiberarPedidoParaPicking(false);
			setEditarPedidoPendente(false);
			setExcluirPedidoPendente(false);
			setEdtiarItemPedidoPendente(false);
			setExcluirItemPedidoPendente(false);
			setAcessoTotalPedidosPendentes(false);
		}
	};
	//verificar se todas as opções estão marcadas (visualizar, criar, editar, excluir) se tiverem marca automaticamente o acesso total, caso alguma seja desativada o acesso total é desativado
	useEffect(() => {
		if (
			visualizarPedidosPendentes &&
			quebrarPedidoTarefas &&
			liberarPedidoParaPicking &&
			editarPedidoPendente &&
			excluirPedidoPendente &&
			edtiarItemPedidoPendente &&
			excluirItemPedidoPendente
		) {
			setAcessoTotalPedidosPendentes(true);
		} else {
			setAcessoTotalPedidosPendentes(false);
		}
	}, [
		visualizarPedidosPendentes,
		quebrarPedidoTarefas,
		liberarPedidoParaPicking,
		editarPedidoPendente,
		excluirPedidoPendente,
		edtiarItemPedidoPendente,
		excluirItemPedidoPendente,
	]);

	//#endregion

	//#region Adm. Operacional -> Ajustes ->Ajuste de Estoque
	const controleAcessoAjusteEstoqueVisualizar = (situacao: boolean) => {
		// Verifica se qualquer uma das permissões (Criar, Editar, Excluir) está ativa
		if (detalharAjusteEstoque) {
			// Se qualquer uma estiver ativa, "Visualizar" deve sempre ser true
			setVisualizarAjusteEstoque(true);
		} else {
			// Se todas estiverem desativadas, "Visualizar" pode ser controlado manualmente
			setVisualizarAjusteEstoque(situacao);
		}
	};
	const controleAcessoAjusteEstoqueDetalhar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarAjusteEstoque(situacao);
			setDetalharAjusteEstoque(situacao);
		} else {
			setDetalharAjusteEstoque(situacao);
		}
	};

	const controleAcessoTotalAjusteEstoque = (situacao: boolean) => {
		if (situacao) {
			setVisualizarAjusteEstoque(true);
			setDetalharAjusteEstoque(true);
			setAcessoTotalAjusteEstoque(true);
		} else {
			setVisualizarAjusteEstoque(false);
			setDetalharAjusteEstoque(false);
			setAcessoTotalAjusteEstoque(false);
		}
	};
	//verificar se todas as opções estão marcadas (visualizar, criar, editar, excluir) se tiverem marca automaticamente o acesso total, caso alguma seja desativada o acesso total é desativado
	useEffect(() => {
		if (visualizarAjusteEstoque && detalharAjusteEstoque) {
			setAcessoTotalAjusteEstoque(true);
		} else {
			setAcessoTotalAjusteEstoque(false);
		}
	}, [visualizarAjusteEstoque, detalharAjusteEstoque]);

	//#endregion
	//#region Adm. Operacional -> Ajustes ->Ajuste de Movimento
	const controleAcessoAjusteMovimentoVisualizar = (situacao: boolean) => {
		// Verifica se qualquer uma das permissões (Criar, Editar, Excluir) está ativa
		if (detalharAjusteMovimento || excluirItemAjusteMovimento) {
			// Se qualquer uma estiver ativa, "Visualizar" deve sempre ser true
			setVisualizarAjusteMovimento(true);
		} else {
			// Se todas estiverem desativadas, "Visualizar" pode ser controlado manualmente
			setVisualizarAjusteMovimento(situacao);
		}
	};
	const controleAcessoAjusteMovimentoDetalhar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarAjusteMovimento(situacao);
			setDetalharAjusteMovimento(situacao);
		} else {
			setDetalharAjusteMovimento(situacao);
		}
	};

	const controleAcessoAjusteMovimentoExcluirItem = (situacao: boolean) => {
		if (situacao) {
			setVisualizarAjusteMovimento(situacao);
			setExcluirItemAjusteMovimento(situacao);
		} else {
			setExcluirItemAjusteMovimento(situacao);
		}
	};

	const controleAcessoTotalAjusteMovimento = (situacao: boolean) => {
		if (situacao) {
			setVisualizarAjusteMovimento(true);
			setDetalharAjusteMovimento(true);
			setExcluirItemAjusteMovimento(true);
			setAcessoTotalAjusteMovimento(true);
		} else {
			setVisualizarAjusteMovimento(false);
			setDetalharAjusteMovimento(false);
			setExcluirItemAjusteMovimento(false);
			setAcessoTotalAjusteMovimento(false);
		}
	};
	//verificar se todas as opções estão marcadas (visualizar, criar, editar, excluir) se tiverem marca automaticamente o acesso total, caso alguma seja desativada o acesso total é desativado
	useEffect(() => {
		if (visualizarAjusteMovimento && detalharAjusteMovimento && excluirItemAjusteMovimento) {
			setAcessoTotalAjusteMovimento(true);
		} else {
			setAcessoTotalAjusteMovimento(false);
		}
	}, [visualizarAjusteMovimento, detalharAjusteMovimento, excluirItemAjusteMovimento]);

	//#endregion

	//#region Estoque -> Estoque Detalhado
	const controleAcessoEstoqueDetalhadoVisualizar = (situacao: boolean) => {
		// Verifica se qualquer uma das permissões (Criar, Editar, Excluir) está ativa
		if (
			detalharPorUAEstoqueDetalhado ||
			gerarExcelDetalharPorUAEstoqueDetalhado ||
			gerarResumoValidadeEstoqueDetalhado ||
			gerarExcelResumoValidadeEstoqueDetalhado ||
			gerarPDFResumoValidadeEstoqueDetalhado ||
			enviarEstoqueEmailEstoqueDetalhado ||
			bloquearDesbloquearItemEstoqueDetalhado ||
			visualizarEmpenhoEstoqueDetalhado ||
			visualizarBarrasUAEstoqueDetalhado ||
			adicionarBarrasUAEstoqueDetalhado ||
			editarBarrasUAEstoqueDetalhado ||
			excluirBarrasUAEstoqueDetalhado
		) {
			// Se qualquer uma estiver ativa, "Visualizar" deve sempre ser true
			setVisualizarEstoqueDetalhado(true);
		} else {
			// Se todas estiverem desativadas, "Visualizar" pode ser controlado manualmente
			setVisualizarEstoqueDetalhado(situacao);
		}
	};

	const controleAcessoEstoqueDetalhadoDetalharPorUA = (situacao: boolean) => {
		if (situacao) {
			setVisualizarEstoqueDetalhado(situacao);
			setDetalharPorUAEstoqueDetalhado(situacao);
		} else {
			setDetalharPorUAEstoqueDetalhado(situacao);
		}
	};

	const controleAcessoEstoqueDetalhadoGerarExcelDetalharPorUA = (situacao: boolean) => {
		if (situacao) {
			setVisualizarEstoqueDetalhado(situacao);
			setGerarExcelDetalharPorUAEstoqueDetalhado(situacao);
		} else {
			setGerarExcelDetalharPorUAEstoqueDetalhado(situacao);
		}
	};

	const controleAcessoEstoqueDetalhadoGerarResumoValidade = (situacao: boolean) => {
		if (situacao) {
			setVisualizarEstoqueDetalhado(situacao);
			setGerarResumoValidadeEstoqueDetalhado(situacao);
		} else {
			setGerarResumoValidadeEstoqueDetalhado(situacao);
		}
	};

	const controleAcessoEstoqueDetalhadoGerarExcelResumoValidade = (situacao: boolean) => {
		if (situacao) {
			setVisualizarEstoqueDetalhado(situacao);
			setGerarExcelResumoValidadeEstoqueDetalhado(situacao);
		} else {
			setGerarExcelResumoValidadeEstoqueDetalhado(situacao);
		}
	};

	const controleAcessoEstoqueDetalhadoGerarPDFResumoValidade = (situacao: boolean) => {
		if (situacao) {
			setVisualizarEstoqueDetalhado(situacao);
			setGerarPDFResumoValidadeEstoqueDetalhado(situacao);
		} else {
			setGerarPDFResumoValidadeEstoqueDetalhado(situacao);
		}
	};

	const controleAcessoEstoqueDetalhadoEnviarEmail = (situacao: boolean) => {
		if (situacao) {
			setVisualizarEstoqueDetalhado(situacao);
			setEnviarEstoqueEmailEstoqueDetalhado(situacao);
		} else {
			setEnviarEstoqueEmailEstoqueDetalhado(situacao);
		}
	};

	const controleAcessoEstoqueDetalhadoBloquearDesbloquearItem = (situacao: boolean) => {
		if (situacao) {
			setVisualizarEstoqueDetalhado(situacao);
			setBloquearDesbloquearItemEstoqueDetalhado(situacao);
		} else {
			setBloquearDesbloquearItemEstoqueDetalhado(situacao);
		}
	};

	const controleAcessoEstoqueDetalhadoVisualizarEmpenho = (situacao: boolean) => {
		if (situacao) {
			setVisualizarEstoqueDetalhado(situacao);
			setVisualizarEmpenhoEstoqueDetalhado(situacao);
		} else {
			setVisualizarEmpenhoEstoqueDetalhado(situacao);
		}
	};

	const controleAcessoEstoqueDetalhadoVisualizarBarrasUA = (situacao: boolean) => {
		//se as opções adicionarbarras u.a. ou editar barras u.a. ou excluir barras u.a. estiverem marcadas, visualizar barras u.a. deve ser marcado automaticamente e não pode ser desmarcado manualmente
		if (adicionarBarrasUAEstoqueDetalhado || editarBarrasUAEstoqueDetalhado || excluirBarrasUAEstoqueDetalhado) {
			setVisualizarEstoqueDetalhado(true);
			setVisualizarBarrasUAEstoqueDetalhado(true);
		} else {
			setVisualizarBarrasUAEstoqueDetalhado(situacao);
		}
	};

	const controleAcessoEstoqueDetalhadoAdicionarBarrasUA = (situacao: boolean) => {
		if (situacao) {
			setVisualizarEstoqueDetalhado(situacao);
			setVisualizarBarrasUAEstoqueDetalhado(situacao);
			setAdicionarBarrasUAEstoqueDetalhado(situacao);
		} else {
			setAdicionarBarrasUAEstoqueDetalhado(situacao);
		}
	};

	const controleAcessoEstoqueDetalhadoEditarBarrasUA = (situacao: boolean) => {
		if (situacao) {
			setVisualizarEstoqueDetalhado(situacao);
			setVisualizarBarrasUAEstoqueDetalhado(situacao);
			setEditarBarrasUAEstoqueDetalhado(situacao);
		} else {
			setEditarBarrasUAEstoqueDetalhado(situacao);
		}
	};

	const controleAcessoEstoqueDetalhadoExcluirBarrasUA = (situacao: boolean) => {
		if (situacao) {
			setVisualizarEstoqueDetalhado(situacao);
			setVisualizarBarrasUAEstoqueDetalhado(situacao);
			setExcluirBarrasUAEstoqueDetalhado(situacao);
		} else {
			setExcluirBarrasUAEstoqueDetalhado(situacao);
		}
	};

	const controleAcessoTotalEstoqueDetalhado = (situacao: boolean) => {
		if (situacao) {
			setVisualizarEstoqueDetalhado(true);
			setDetalharPorUAEstoqueDetalhado(true);
			setGerarExcelDetalharPorUAEstoqueDetalhado(true);
			setGerarResumoValidadeEstoqueDetalhado(true);
			setGerarExcelResumoValidadeEstoqueDetalhado(true);
			setGerarPDFResumoValidadeEstoqueDetalhado(true);
			setEnviarEstoqueEmailEstoqueDetalhado(true);
			setBloquearDesbloquearItemEstoqueDetalhado(true);
			setVisualizarEmpenhoEstoqueDetalhado(true);
			setVisualizarBarrasUAEstoqueDetalhado(true);
			setAdicionarBarrasUAEstoqueDetalhado(true);
			setEditarBarrasUAEstoqueDetalhado(true);
			setExcluirBarrasUAEstoqueDetalhado(true);
			setAcessoTotalEstoqueDetalhado(true);
		} else {
			setVisualizarEstoqueDetalhado(false);
			setDetalharPorUAEstoqueDetalhado(false);
			setGerarExcelDetalharPorUAEstoqueDetalhado(false);
			setGerarResumoValidadeEstoqueDetalhado(false);
			setGerarExcelResumoValidadeEstoqueDetalhado(false);
			setGerarPDFResumoValidadeEstoqueDetalhado(false);
			setEnviarEstoqueEmailEstoqueDetalhado(false);
			setBloquearDesbloquearItemEstoqueDetalhado(false);
			setVisualizarEmpenhoEstoqueDetalhado(false);
			setVisualizarBarrasUAEstoqueDetalhado(false);
			setAdicionarBarrasUAEstoqueDetalhado(false);
			setEditarBarrasUAEstoqueDetalhado(false);
			setExcluirBarrasUAEstoqueDetalhado(false);
			setAcessoTotalEstoqueDetalhado(false);
		}
	};

	// verificar se todas as opções estão marcadas (visualizarEstoqueDetalhado, detalharPorUAEstoqueDetalhado, gerarExcelDetalharPorUAEstoqueDetalhado,
	// gerarResumoValidadeEstoqueDetalhado, gerarExcelResumoValidadeEstoqueDetalhado, gerarPDFResumoValidadeEstoqueDetalhado, enviarEstoqueEmailEstoqueDetalhado,
	// bloquearDesbloquearItemEstoqueDetalhado, visualizarEmpenhoEstoqueDetalhado, visualizarBarrasUAEstoqueDetalhado, adicionarBarrasUAEstoqueDetalhado, editarBarrasUAEstoqueDetalhado, excluirBarrasUAEstoqueDetalhado)
	// se tiverem marca automaticamente o acesso total, caso alguma seja desativada o acesso total é desativado
	useEffect(() => {
		if (
			visualizarEstoqueDetalhado &&
			detalharPorUAEstoqueDetalhado &&
			gerarExcelDetalharPorUAEstoqueDetalhado &&
			gerarResumoValidadeEstoqueDetalhado &&
			gerarExcelResumoValidadeEstoqueDetalhado &&
			gerarPDFResumoValidadeEstoqueDetalhado &&
			enviarEstoqueEmailEstoqueDetalhado &&
			bloquearDesbloquearItemEstoqueDetalhado &&
			visualizarEmpenhoEstoqueDetalhado &&
			visualizarBarrasUAEstoqueDetalhado &&
			adicionarBarrasUAEstoqueDetalhado &&
			editarBarrasUAEstoqueDetalhado &&
			excluirBarrasUAEstoqueDetalhado
		) {
			setAcessoTotalEstoqueDetalhado(true);
		} else {
			setAcessoTotalEstoqueDetalhado(false);
		}
	}, [
		visualizarEstoqueDetalhado,
		detalharPorUAEstoqueDetalhado,
		gerarExcelDetalharPorUAEstoqueDetalhado,
		gerarResumoValidadeEstoqueDetalhado,
		gerarExcelResumoValidadeEstoqueDetalhado,
		gerarPDFResumoValidadeEstoqueDetalhado,
		enviarEstoqueEmailEstoqueDetalhado,
		bloquearDesbloquearItemEstoqueDetalhado,
		visualizarEmpenhoEstoqueDetalhado,
		visualizarBarrasUAEstoqueDetalhado,
		adicionarBarrasUAEstoqueDetalhado,
		editarBarrasUAEstoqueDetalhado,
		excluirBarrasUAEstoqueDetalhado,
	]);

	//#endregion

	//#region Estoque -> Estoque Sintético
	const controleAcessoEstoqueSinteticoVisualizar = (situacao: boolean) => {
		// Verifica se qualquer uma das permissões (gerar excel) está ativa
		if (gerarExcelEstoqueSintetico) {
			// Se qualquer uma estiver ativa, "Visualizar" deve sempre ser true
			setVisualizarEstoqueSintetico(true);
		} else {
			// Se todas estiverem desativadas, "Visualizar" pode ser controlado manualmente
			setVisualizarEstoqueSintetico(situacao);
		}
	};

	const controleAcessoEstoqueSinteticoGerarExcel = (situacao: boolean) => {
		if (situacao) {
			setVisualizarEstoqueSintetico(situacao);
			setGerarExcelEstoqueSintetico(situacao);
		} else {
			setGerarExcelEstoqueSintetico(situacao);
		}
	};

	const controleAcessoTotalEstoqueSintetico = (situacao: boolean) => {
		if (situacao) {
			setVisualizarEstoqueSintetico(true);
			setGerarExcelEstoqueSintetico(true);
			setAcessoTotalEstoqueSintetico(true);
		} else {
			setVisualizarEstoqueSintetico(false);
			setGerarExcelEstoqueSintetico(false);
			setAcessoTotalEstoqueSintetico(false);
		}
	};

	// verificar se todas as opções estão marcadas (visualizarEstoqueSintetico, gerarExcelEstoqueSintetico)
	// se tiverem marca automaticamente o acesso total, caso alguma seja desativada o acesso total é desativado
	useEffect(() => {
		if (visualizarEstoqueSintetico && gerarExcelEstoqueSintetico) {
			setAcessoTotalEstoqueSintetico(true);
		} else {
			setAcessoTotalEstoqueSintetico(false);
		}
	}, [visualizarEstoqueSintetico, gerarExcelEstoqueSintetico]);
	//#endregion

	//#region Estoque -> Romaneio de Barras Ativas
	const controleAcessoRomaneioBarrasAtivasVisualizar = (situacao: boolean) => {
		// Verifica se qualquer uma das permissões (gerar excel) está ativa
		if (
			criarRomaneioBarrasAtivas ||
			imprimirTodasBarrasRomaneioBarrasAtivas ||
			gerarExcelRomaneioBarrasAtivas ||
			verTodasRomaneioBarrasAtivas ||
			gerarExcelDetalhesRomaneioBarrasAtivas ||
			editarRomaneioBarrasAtivas ||
			excluirRomaneioBarrasAtivas ||
			imprimirItemRomaneioBarrasAtivas
		) {
			// Se qualquer uma estiver ativa, "Visualizar" deve sempre ser true
			setVisualizarRomaneioBarrasAtivas(true);
		} else {
			// Se todas estiverem desativadas, "Visualizar" pode ser controlado manualmente
			setVisualizarRomaneioBarrasAtivas(situacao);
		}
	};

	const controleAcessoRomaneioBarrasAtivasCriar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarRomaneioBarrasAtivas(situacao);
			setCriarRomaneioBarrasAtivas(situacao);
		} else {
			setCriarRomaneioBarrasAtivas(situacao);
		}
	};

	const controleAcessoRomaneioBarrasAtivasImprimirTodasBarras = (situacao: boolean) => {
		if (situacao) {
			setVisualizarRomaneioBarrasAtivas(situacao);
			setImprimirTodasBarrasRomaneioBarrasAtivas(situacao);
		} else {
			setImprimirTodasBarrasRomaneioBarrasAtivas(situacao);
		}
	};

	const controleAcessoRomaneioBarrasAtivasGerarExcel = (situacao: boolean) => {
		if (situacao) {
			setVisualizarRomaneioBarrasAtivas(situacao);
			setGerarExcelRomaneioBarrasAtivas(situacao);
		} else {
			setGerarExcelRomaneioBarrasAtivas(situacao);
		}
	};

	const controleAcessoRomaneioBarrasAtivasVerTodas = (situacao: boolean) => {
		if (gerarExcelDetalhesRomaneioBarrasAtivas) {
			setVisualizarRomaneioBarrasAtivas(true);
			setVerTodasRomaneioBarrasAtivas(true);
		} else {
			setVerTodasRomaneioBarrasAtivas(situacao);
		}
	};

	const controleAcessoRomaneioBarrasAtivasGerarExcelDetalhes = (situacao: boolean) => {
		if (situacao) {
			setVisualizarRomaneioBarrasAtivas(true);
			setVerTodasRomaneioBarrasAtivas(true);
			setGerarExcelDetalhesRomaneioBarrasAtivas(situacao);
		} else {
			setGerarExcelDetalhesRomaneioBarrasAtivas(situacao);
		}
	};

	const controleAcessoRomaneioBarrasAtivasEditar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarRomaneioBarrasAtivas(situacao);
			setEditarRomaneioBarrasAtivas(situacao);
		} else {
			setEditarRomaneioBarrasAtivas(situacao);
		}
	};

	const controleAcessoRomaneioBarrasAtivasExcluir = (situacao: boolean) => {
		if (situacao) {
			setVisualizarRomaneioBarrasAtivas(situacao);
			setExcluirRomaneioBarrasAtivas(situacao);
		} else {
			setExcluirRomaneioBarrasAtivas(situacao);
		}
	};

	const controleAcessoRomaneioBarrasAtivasImprimirItem = (situacao: boolean) => {
		if (situacao) {
			setVisualizarRomaneioBarrasAtivas(situacao);
			setImprimirItemRomaneioBarrasAtivas(situacao);
		} else {
			setImprimirItemRomaneioBarrasAtivas(situacao);
		}
	};

	const controleAcessoTotalRomaneioBarrasAtivas = (situacao: boolean) => {
		if (situacao) {
			setVisualizarRomaneioBarrasAtivas(true);
			setCriarRomaneioBarrasAtivas(true);
			setImprimirTodasBarrasRomaneioBarrasAtivas(true);
			setGerarExcelRomaneioBarrasAtivas(true);
			setVerTodasRomaneioBarrasAtivas(true);
			setGerarExcelDetalhesRomaneioBarrasAtivas(true);
			setEditarRomaneioBarrasAtivas(true);
			setExcluirRomaneioBarrasAtivas(true);
			setImprimirItemRomaneioBarrasAtivas(true);
			setAcessoTotalRomaneioBarrasAtivas(true);
		} else {
			setVisualizarRomaneioBarrasAtivas(false);
			setCriarRomaneioBarrasAtivas(false);
			setImprimirTodasBarrasRomaneioBarrasAtivas(false);
			setGerarExcelRomaneioBarrasAtivas(false);
			setVerTodasRomaneioBarrasAtivas(false);
			setGerarExcelDetalhesRomaneioBarrasAtivas(false);
			setEditarRomaneioBarrasAtivas(false);
			setExcluirRomaneioBarrasAtivas(false);
			setImprimirItemRomaneioBarrasAtivas(false);
			setAcessoTotalRomaneioBarrasAtivas(false);
		}
	};

	// verificar se todas as opções estão marcadas (visualizarRomaneioBarrasAtivas, criarRomaneioBarrasAtivas, imprimirTodasBarrasRomaneioBarrasAtivas, gerarExcelRomaneioBarrasAtivas,
	// verTodasRomaneioBarrasAtivas, gerarExcelDetalhesRomaneioBarrasAtivas, editarRomaneioBarrasAtivas, excluirRomaneioBarrasAtivas, imprimirItemRomaneioBarrasAtivas)
	// se tiverem marca automaticamente o acesso total, caso alguma seja desativada o acesso total é desativado
	useEffect(() => {
		if (
			visualizarRomaneioBarrasAtivas &&
			criarRomaneioBarrasAtivas &&
			imprimirTodasBarrasRomaneioBarrasAtivas &&
			gerarExcelRomaneioBarrasAtivas &&
			verTodasRomaneioBarrasAtivas &&
			gerarExcelDetalhesRomaneioBarrasAtivas &&
			editarRomaneioBarrasAtivas &&
			excluirRomaneioBarrasAtivas &&
			imprimirItemRomaneioBarrasAtivas
		) {
			setAcessoTotalRomaneioBarrasAtivas(true);
		} else {
			setAcessoTotalRomaneioBarrasAtivas(false);
		}
	}, [
		visualizarRomaneioBarrasAtivas,
		criarRomaneioBarrasAtivas,
		imprimirTodasBarrasRomaneioBarrasAtivas,
		gerarExcelRomaneioBarrasAtivas,
		verTodasRomaneioBarrasAtivas,
		gerarExcelDetalhesRomaneioBarrasAtivas,
		editarRomaneioBarrasAtivas,
		excluirRomaneioBarrasAtivas,
		imprimirItemRomaneioBarrasAtivas,
	]);

	//#endregion

	//#region Estoque -> U.A. Vazio/Saída e Sem Uso -> U.A. Vazio/Saída e Sem Uso
	const controleAcessoUAVazioSaidaSemUsoVisualizarVazio = (situacao: boolean) => {
		// Verifica se qualquer uma das permissões (excluir vazio, imprimir vazio) está ativa
		if (excluirUAVazioSaidaSemUsoVazio || imprimirUAVazioSaidaSemUsoVazio) {
			// Se qualquer uma estiver ativa, "Visualizar" deve sempre ser true
			setVisualizarUAVazioSaidaSemUsoVazio(true);
		} else {
			// Se todas estiverem desativadas, "Visualizar" pode ser controlado manualmente
			setVisualizarUAVazioSaidaSemUsoVazio(situacao);
		}
	};

	const controleAcessoUAVazioSaidaSemUsoExcluirVazio = (situacao: boolean) => {
		if (situacao) {
			setVisualizarUAVazioSaidaSemUsoVazio(situacao);
			setExcluirUAVazioSaidaSemUsoVazio(situacao);
		} else {
			setExcluirUAVazioSaidaSemUsoVazio(situacao);
		}
	};

	const controleAcessoUAVazioSaidaSemUsoImprimirVazio = (situacao: boolean) => {
		if (situacao) {
			setVisualizarUAVazioSaidaSemUsoVazio(situacao);
			setImprimirUAVazioSaidaSemUsoVazio(situacao);
		} else {
			setImprimirUAVazioSaidaSemUsoVazio(situacao);
		}
	};

	const controleAcessoUAVazioSaidaSemUsoVisualizarSaida = (situacao: boolean) => {
		// Verifica se qualquer uma das permissões (excluir saida, imprimir saida) está ativa
		if (excluirUAVazioSaidaSemUsoSaida || imprimirUAVazioSaidaSemUsoSaida) {
			// Se qualquer uma estiver ativa, "Visualizar" deve sempre ser true
			setVisualizarUAVazioSaidaSemUsoSaida(true);
		} else {
			// Se todas estiverem desativadas, "Visualizar" pode ser
			setVisualizarUAVazioSaidaSemUsoSaida(situacao);
		}
	};

	const controleAcessoUAVazioSaidaSemUsoExcluirSaida = (situacao: boolean) => {
		if (situacao) {
			setVisualizarUAVazioSaidaSemUsoSaida(situacao);
			setExcluirUAVazioSaidaSemUsoSaida(situacao);
		} else {
			setExcluirUAVazioSaidaSemUsoSaida(situacao);
		}
	};

	const controleAcessoUAVazioSaidaSemUsoImprimirSaida = (situacao: boolean) => {
		if (situacao) {
			setVisualizarUAVazioSaidaSemUsoSaida(situacao);
			setImprimirUAVazioSaidaSemUsoSaida(situacao);
		} else {
			setImprimirUAVazioSaidaSemUsoSaida(situacao);
		}
	};

	const controleAcessoTotalUAVazioSaidaSemUso = (situacao: boolean) => {
		if (situacao) {
			setVisualizarUAVazioSaidaSemUsoVazio(true);
			setExcluirUAVazioSaidaSemUsoVazio(true);
			setImprimirUAVazioSaidaSemUsoVazio(true);
			setVisualizarUAVazioSaidaSemUsoSaida(true);
			setExcluirUAVazioSaidaSemUsoSaida(true);
			setImprimirUAVazioSaidaSemUsoSaida(true);
			setAcessoTotalUAVazioSaidaSemUso(true);
		} else {
			setVisualizarUAVazioSaidaSemUsoVazio(false);
			setExcluirUAVazioSaidaSemUsoVazio(false);
			setImprimirUAVazioSaidaSemUsoVazio(false);
			setVisualizarUAVazioSaidaSemUsoSaida(false);
			setExcluirUAVazioSaidaSemUsoSaida(false);
			setImprimirUAVazioSaidaSemUsoSaida(false);
			setAcessoTotalUAVazioSaidaSemUso(false);
		}
	};

	// verificar se todas as opções estão marcadas (visualizarUAVazioSaidaSemUsoVazio, excluirUAVazioSaidaSemUsoVazio, imprimirUAVazioSaidaSemUsoVazio,
	// visualizarUAVazioSaidaSemUsoSaida, excluirUAVazioSaidaSemUsoSaida, imprimirUAVazioSaidaSemUsoSaida)  se tiverem marca automaticamente o acesso total,
	// caso alguma seja desativada o acesso total é desativado
	useEffect(() => {
		if (
			visualizarUAVazioSaidaSemUsoVazio &&
			excluirUAVazioSaidaSemUsoVazio &&
			imprimirUAVazioSaidaSemUsoVazio &&
			visualizarUAVazioSaidaSemUsoSaida &&
			excluirUAVazioSaidaSemUsoSaida &&
			imprimirUAVazioSaidaSemUsoSaida
		) {
			setAcessoTotalUAVazioSaidaSemUso(true);
		} else {
			setAcessoTotalUAVazioSaidaSemUso(false);
		}
	}, [
		visualizarUAVazioSaidaSemUsoVazio,
		excluirUAVazioSaidaSemUsoVazio,
		imprimirUAVazioSaidaSemUsoVazio,
		visualizarUAVazioSaidaSemUsoSaida,
		excluirUAVazioSaidaSemUsoSaida,
		imprimirUAVazioSaidaSemUsoSaida,
	]);

	//#endregion

	//#region Operacional -> Impressão de Etiqueta -> Impressão de Etiqueta
	const controleAcessoImpressaoEtiquetaVisualizar = (situacao: boolean) => {
		// Verifica se qualquer uma das permissões (reimprimir ua, gerar ua vazio/saida, endereco, produto picking) está ativa
		if (
			visualizarImpEtqReimpressaoUA ||
			visualizarImpEtqGerarUAVazioSaida ||
			visualizarImpEtqImpressaoEndereco ||
			visualizarImpEtqImpressaoProdutoPicking
		) {
			// Se qualquer uma estiver ativa, "Visualizar" deve sempre ser true
			setVisualizarImpressaoEtiquetas(true);
		} else {
			// Se todas estiverem desativadas, "Visualizar" pode ser controlado manualmente
			setVisualizarImpressaoEtiquetas(situacao);
		}
	};

	const controleAcessoImpressaoEtiquetaReimprimirUA = (situacao: boolean) => {
		if (situacao) {
			setVisualizarImpressaoEtiquetas(situacao);
			setVisualizarImpEtqReimpressaoUA(situacao);
		} else {
			setVisualizarImpEtqReimpressaoUA(situacao);
		}
	};

	const controleAcessoImpressaoEtiquetaGerarUAVazioSaida = (situacao: boolean) => {
		if (situacao) {
			setVisualizarImpressaoEtiquetas(situacao);
			setVisualizarImpEtqGerarUAVazioSaida(situacao);
		} else {
			setVisualizarImpEtqGerarUAVazioSaida(situacao);
		}
	};

	const controleAcessoImpressaoEtiquetaImpressaoEndereco = (situacao: boolean) => {
		if (situacao) {
			setVisualizarImpressaoEtiquetas(situacao);
			setVisualizarImpEtqImpressaoEndereco(situacao);
		} else {
			setVisualizarImpEtqImpressaoEndereco(situacao);
		}
	};

	const controleAcessoImpressaoEtiquetaImpressaoProdutoPicking = (situacao: boolean) => {
		if (situacao) {
			setVisualizarImpressaoEtiquetas(situacao);
			setVisualizarImpEtqImpressaoProdutoPicking(situacao);
		} else {
			setVisualizarImpEtqImpressaoProdutoPicking(situacao);
		}
	};

	const controleAcessoTotalImpressaoEtiqueta = (situacao: boolean) => {
		if (situacao) {
			setVisualizarImpressaoEtiquetas(true);
			setVisualizarImpEtqReimpressaoUA(true);
			setVisualizarImpEtqGerarUAVazioSaida(true);
			setVisualizarImpEtqImpressaoEndereco(true);
			setVisualizarImpEtqImpressaoProdutoPicking(true);
			setAcessoTotalImpressaoEtiquetas(true);
		} else {
			setVisualizarImpressaoEtiquetas(false);
			setVisualizarImpEtqReimpressaoUA(false);
			setVisualizarImpEtqGerarUAVazioSaida(false);
			setVisualizarImpEtqImpressaoEndereco(false);
			setVisualizarImpEtqImpressaoProdutoPicking(false);
			setAcessoTotalImpressaoEtiquetas(false);
		}
	};

	// verificar se todas as opções estão marcadas (visualizarImpEtqReimpressaoUA, visualizarImpEtqGerarUAVazioSaida, visualizarImpEtqImpressaoEndereco, visualizarImpEtqImpressaoProdutoPicking)
	// se tiverem marca automaticamente o acesso total, caso alguma seja desativada o acesso total é desativado
	useEffect(() => {
		if (
			visualizarImpEtqReimpressaoUA &&
			visualizarImpEtqGerarUAVazioSaida &&
			visualizarImpEtqImpressaoEndereco &&
			visualizarImpEtqImpressaoProdutoPicking
		) {
			setAcessoTotalImpressaoEtiquetas(true);
		} else {
			setAcessoTotalImpressaoEtiquetas(false);
		}
	}, [
		visualizarImpEtqReimpressaoUA,
		visualizarImpEtqGerarUAVazioSaida,
		visualizarImpEtqImpressaoEndereco,
		visualizarImpEtqImpressaoProdutoPicking,
	]);

	//#endregion

	//#region Faturamento de Serviços -> Tarifas -> Cadastrar Tarifas
	const controleAcessoCadastrarTarifasVisualizar = (situacao: boolean) => {
		// Verifica se qualquer uma das permissões (criar tarifas, editar tarifas, excluir tarifas) está ativa
		if (criarCadastrarTarifas || editarCadastrarTarifas || excluirCadastrarTarifas) {
			// Se qualquer uma estiver ativa, "Visualizar" deve sempre ser true
			setVisualizarCadastrarTarifas(true);
		} else {
			// Se todas estiverem desativadas, "Visualizar" pode ser controlado manualmente
			setVisualizarCadastrarTarifas(situacao);
		}
	};

	const controleAcessoCadastrarTarifasCriar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarCadastrarTarifas(situacao);
			setCriarCadastrarTarifas(situacao);
		} else {
			setCriarCadastrarTarifas(situacao);
		}
	};

	const controleAcessoCadastrarTarifasEditar = (situacao: boolean) => {
		if (situacao) {
			setVisualizarCadastrarTarifas(situacao);
			setEditarCadastrarTarifas(situacao);
		} else {
			setEditarCadastrarTarifas(situacao);
		}
	};

	const controleAcessoCadastrarTarifasExcluir = (situacao: boolean) => {
		if (situacao) {
			setVisualizarCadastrarTarifas(situacao);
			setExcluirCadastrarTarifas(situacao);
		} else {
			setExcluirCadastrarTarifas(situacao);
		}
	};

	const controleAcessoTotalCadastrarTarifas = (situacao: boolean) => {
		if (situacao) {
			setVisualizarCadastrarTarifas(true);
			setCriarCadastrarTarifas(true);
			setEditarCadastrarTarifas(true);
			setExcluirCadastrarTarifas(true);
			setAcessoTotalCadastrarTarifas(true);
		} else {
			setVisualizarCadastrarTarifas(false);
			setCriarCadastrarTarifas(false);
			setEditarCadastrarTarifas(false);
			setExcluirCadastrarTarifas(false);
			setAcessoTotalCadastrarTarifas(false);
		}
	};

	// verificar se todas as opções estão marcadas (criarCadastrarTarifas, editarCadastrarTarifas, excluirCadastrarTarifas)  se tiverem marca automaticamente o acesso total,
	// caso alguma seja desativada o acesso total é desativado
	useEffect(() => {
		if (visualizarCadastrarTarifas && criarCadastrarTarifas && editarCadastrarTarifas && excluirCadastrarTarifas) {
			setAcessoTotalCadastrarTarifas(true);
		} else {
			setAcessoTotalCadastrarTarifas(false);
		}
	}, [visualizarCadastrarTarifas, criarCadastrarTarifas, editarCadastrarTarifas, excluirCadastrarTarifas]);

	//#endregion

	return (
		<>
			<div className="main">
				<div className="tituloDaPagina mt-2 ms-1">
					<h4>{params.acao === 'criar' ? 'Criar novo Perfil' : params.acao === 'editar' ? 'Editar Perfil' : 'Visualizar Perfil'}</h4>
				</div>
				<div className="card mt-2">
					<div className="card-body">
						<ul className="nav nav-pills bg-nav-pills nav-justified mb-3">
							<li className="nav-item" role="presentation">
								<button
									className="nav-link active"
									id="pills-home-tab"
									data-bs-toggle="pill"
									data-bs-target="#pills-home"
									type="button"
									role="tab"
									aria-controls="pills-home"
									aria-selected="true"
								>
									Perfil
								</button>
							</li>
							<li className="nav-item" role="presentation">
								<button
									className="nav-link"
									id="pills-profile-tab"
									data-bs-toggle="pill"
									data-bs-target="#pills-profile"
									type="button"
									role="tab"
									aria-controls="pills-profile"
									aria-selected="false"
								>
									Permissões Básicas
								</button>
							</li>
							<li className="nav-item" role="presentation">
								<button
									className="nav-link"
									id="pills-permissoesespeciais-tab"
									data-bs-toggle="pill"
									data-bs-target="#pills-especiais"
									type="button"
									role="tab"
									aria-controls="pills-especiais"
									aria-selected="false"
								>
									Permissões Especiais
								</button>
							</li>
						</ul>
						<form
							onSubmit={function (e) {
								e.preventDefault();

								if (params.acao === 'criar' && params.id === 'novo' && nivelPerfil === '0') {
									setModalConfirmacao(true);
								} else if (params.acao === 'criar' && params.id === 'novo') {
									CriarPerfil(id_filial);
								} else if (params.acao === 'editar' && params.id !== 'novo') {
									AtualizarPerfil(params.id!, params.id_filial!);
								}
							}}
						>
							<div className="tab-content" id="pills-tabContent">
								<div
									className="tab-pane fade show active"
									id="pills-home"
									role="tabpanel"
									aria-labelledby="pills-home-tab"
									tabIndex={0}
								>
									<div className="container p-0 m-0">
										<div className="row">
											<div className="col-sm col-md-6 col-lg-5">
												<div className="form-floating">
													<input
														value={nomePerfil}
														onChange={(e) => setNomePerfil(e.target.value)}
														readOnly={params.acao === 'visualizar' ? true : false}
														required
														type="text"
														name="nomePerfil"
														className="form-control bordasInferiorInput text-black text-capitalize"
														placeholder=" "
													/>
													<label>Nome do Perfil</label>
												</div>
											</div>
											<div className="col-sm col-md-6 col-lg-3">
												<InputSelect
													label="Nível"
													dados={ListaNiveis}
													value={nivel}
													onChange={setNivel}
													disabled={params.acao === 'visualizar' ? true : false}
													onClick={() => { }}
													required
													textoSelecao="Selecione ..."
												/>
											</div>
											<div className="col-sm col-md-6 col-lg-2">
												<CheckBox
													checked={situacao}
													onChange={setSituacao}
													disabled={params.acao || ''}
													label="Ativo"
													name="situacao"
													className="form-check-input w-50"
												/>
											</div>
											{nivelPerfil === '0' && nivel === '0' && (
												<div className="col-sm col-md-6 col-lg-2">
													<CheckBox
														checked={isMaster}
														onChange={setIsMaster}
														disabled={params.acao || ''}
														label="Master"
														name="isMaster"
														className="form-check-input w-50"
													/>
												</div>
											)}
										</div>
									</div>
								</div>
								<div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabIndex={0}>
									<div className="accordion bg-primary text-white">
										<div className="accordion-item">
											<h2 className="accordion-header">
												<button
													className="accordion-button bg-primary  text-white"
													type="button"
													data-bs-toggle="collapse"
													data-bs-target="#collapseOne"
													aria-expanded="true"
													aria-controls="collapseOne"
												>
													Administrativo
												</button>
											</h2>
											<div id="collapseOne" className="accordion-collapse collapse bg-white text-primary">
												<div className="accordion-body">
													<div className="accordion  bg-primary text-white">
														<div className="accordion-item">
															<h2 className="accordion-header">
																<button
																	className="accordion-button  bg-primary text-white"
																	type="button"
																	data-bs-toggle="collapse"
																	data-bs-target="#collapse2"
																	aria-expanded="true"
																	aria-controls="collapse2"
																>
																	Controle de Acesso
																</button>
															</h2>
															<div id="collapse2" className="accordion-collapse collapse bg-white text-black">
																<div className="accordion-body">
																	<div className="row">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>Menu</b>
																			</p>
																			<p>Usuários</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center justify-content-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarUsuario}
																				onChange={controleAcessoUsuarioVisualizar}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarUsuario"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		{/* </div> */}
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Criar</p>
																			<CheckBox
																				checked={criarUsuario}
																				onChange={controleAcessoUsuarioCriar}
																				disabled={params.acao || ''}
																				label=""
																				name="criarUsuario"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>

																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Editar</p>
																			<CheckBox
																				checked={editarUsuario}
																				onChange={controleAcessoUsuarioEditar}
																				disabled={params.acao || ''}
																				label=""
																				name="editarUsuario"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Excluir</p>
																			<CheckBox
																				checked={excluirUsuario}
																				onChange={controleAcessoUsuarioExcluir}
																				disabled={params.acao || ''}
																				label=""
																				name="excluirUsuario"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<strong>Acesso Total</strong>
																			</p>
																			<CheckBox
																				checked={acessoTotalUsuario}
																				onChange={controleAcessoTotalUsuario}
																				disabled={params.acao || ''}
																				label=""
																				name="acessoTotalUsuario"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>

																	<div className="row border-1 border-top border-primary">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>
																					<br />
																				</b>
																			</p>
																			<p>Perfil/Permissão</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarPerfilPermissao}
																				onChange={controleAcessoPerfilPermissaoVisualizar}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarPerfilPermissao"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Criar</p>
																			<CheckBox
																				checked={criarPerfilPermissao}
																				onChange={controleAcessoPerfilPermissaoCriar}
																				disabled={params.acao || ''}
																				label=""
																				name="criarPerfilPermissao"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Editar</p>
																			<CheckBox
																				checked={editarPerfilPermissao}
																				onChange={controleAcessoPerfilPermissaoEditar}
																				disabled={params.acao || ''}
																				label=""
																				name="editarPerfilPermissao"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Excluir</p>
																			<CheckBox
																				checked={excluirPerfilPermissao}
																				onChange={controleAcessoPerfilPermissaoExcluir}
																				disabled={params.acao || ''}
																				label=""
																				name="excluirPerfilPermissao"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<strong>Acesso Total</strong>
																			</p>
																			<CheckBox
																				checked={acessoTotalPerfilPermissao}
																				onChange={controleAcessoTotalPerfilPermissao}
																				disabled={params.acao || ''}
																				label=""
																				name="acessoTotalPerfilPermissao"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>

																	<div className="row border-1 border-top border-primary">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>
																					<br />
																				</b>
																			</p>
																			<p>Usuários Filial</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarUsuariosFilial}
																				onChange={setVisualizarUsuariosFilial}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarUsuariosFilial"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>

																	<div className="row border-1 border-top border-primary">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>
																					<br />
																				</b>
																			</p>
																			<p>Atividades Usuários</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarAtividadesUsuario}
																				onChange={setVisualizarAtividadesUsuario}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarAtividadesUsuario"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>

																	<div className="row border-1 border-top border-primary">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>
																					<br />
																				</b>
																			</p>
																			<p>Painel Monitoramento</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarPainelMonitoramento}
																				onChange={setVisualizarPainelMonitoramento}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarPainelMonitoramento"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="accordion-body">
													<div className="accordion  bg-primary text-white">
														<div className="accordion-item">
															<h2 className="accordion-header">
																<button
																	className="accordion-button  bg-primary text-white"
																	type="button"
																	data-bs-toggle="collapse"
																	data-bs-target="#collapse3"
																	aria-expanded="true"
																	aria-controls="collapse3"
																>
																	Códigos do Sistema
																</button>
															</h2>
															<div
																id="collapse3"
																className="accordion-collapse collapse bg-white text-black"
																data-bs-parent="#CodigosSistema"
															>
																<div className="accordion-body">
																	<div className="row">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>Menu</b>
																			</p>
																			<p>Códigos do Sistema</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarCodSistema}
																				onChange={controleAcessoCodSistemaVisualizar}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarCodSistema"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Criar</p>
																			<CheckBox
																				checked={criarCodSistema}
																				onChange={controleAcessoCodSistemaCriar}
																				disabled={params.acao || ''}
																				label=""
																				name="criarCodSistema"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Editar</p>
																			<CheckBox
																				checked={editarCodSistema}
																				onChange={controleAcessoCodSistemaEditar}
																				disabled={params.acao || ''}
																				label=""
																				name="editarCodSistema"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Excluir</p>
																			<CheckBox
																				checked={excluirCodSistema}
																				onChange={controleAcessoCodSistemaExcluir}
																				disabled={params.acao || ''}
																				label=""
																				name="excluirCodSistema"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<strong>Acesso Total</strong>
																			</p>
																			<CheckBox
																				checked={acessoTotalCodigosSistema}
																				onChange={controleAcessoTotalCodSistema}
																				disabled={params.acao || ''}
																				label=""
																				name="acessoTotalCodigosSistema"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="accordion-body">
													<div className="accordion  bg-primary text-white">
														<div className="accordion-item">
															<h2 className="accordion-header">
																<button
																	className="accordion-button  bg-primary text-white"
																	type="button"
																	data-bs-toggle="collapse"
																	data-bs-target="#collapse4"
																	aria-expanded="true"
																	aria-controls="collapse4"
																>
																	Cadastro Administrativo
																</button>
															</h2>
															<div
																id="collapse4"
																className="accordion-collapse collapse bg-white text-black"
																data-bs-parent="#CadastroAdministrativo"
															>
																<div className="accordion-body">
																	<div className="row">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>Menu</b>
																			</p>
																			<p>Cadastro de Filial</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarCadastroFilial}
																				onChange={controleAcessoCadastroFilialVisualizar}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarCadastroFilial"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Criar</p>
																			<CheckBox
																				checked={criarCadastroFilial}
																				onChange={controleAcessoCadastroFilialCriar}
																				disabled={params.acao || ''}
																				label=""
																				name="criarCadastroFilial"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Editar</p>
																			<CheckBox
																				checked={editarCadastroFilial}
																				onChange={controleAcessoCadastroFilialEditar}
																				disabled={params.acao || ''}
																				label=""
																				name="editarCadastroFilial"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Excluir</p>
																			<CheckBox
																				checked={excluirCadastroFilial}
																				onChange={controleAcessoCadastroFilialExcluir}
																				disabled={params.acao || ''}
																				label=""
																				name="excluirCadastroFilial"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<strong>Acesso Total</strong>
																			</p>
																			<CheckBox
																				checked={acessoTotalCadastroFilial}
																				onChange={controleAcessoTotalCadastroFilial}
																				disabled={params.acao || ''}
																				label=""
																				name="acessoTotalCadastroFilial"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																	<div className="row border-1 border-top border-primary">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>
																					<br />
																				</b>
																			</p>
																			<p>Controle de Numeração</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarControleNumeracao}
																				onChange={controleAcessoControleNumeracaoVisualizar}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarControleNumeracao"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Criar</p>
																			<CheckBox
																				checked={criarControleNumeracao}
																				onChange={controleAcessoControleNumeracaoCriar}
																				disabled={params.acao || ''}
																				label=""
																				name="criarControleNumeracao"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Editar</p>
																			<CheckBox
																				checked={editarControleNumeracao}
																				onChange={controleAcessoControleNumeracaoEditar}
																				disabled={params.acao || ''}
																				label=""
																				name="editarControleNumeracao"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Excluir</p>
																			<CheckBox
																				checked={excluirControleNumeracao}
																				onChange={controleAcessoControleNumeracaoExcluir}
																				disabled={params.acao || ''}
																				label=""
																				name="excluirControleNumeracao"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<strong>Acesso Total</strong>
																			</p>
																			<CheckBox
																				checked={acessoTotalControleNumeracao}
																				onChange={controleAcessoTotalControleNumeracao}
																				disabled={params.acao || ''}
																				label=""
																				name="acessoTotalControleNumeracao"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<br />
									<div className="accordion" id="accordionExample2">
										<div className="accordion-item bg-primary text-white">
											<h2 className="accordion-header">
												<button
													className="accordion-button bg-primary text-white"
													type="button"
													data-bs-toggle="collapse"
													data-bs-target="#collapseCadastros"
													aria-expanded="true"
													aria-controls="collapseCadastros"
												>
													Cadastros
												</button>
											</h2>
											<div
												id="collapseCadastros"
												className="accordion-collapse collapse bg-white text-black"
												data-bs-parent="#accordionExample2"
											>
												<div className="accordion-body">
													<div className="accordion" id="accordionExample2">
														<div className="accordion-item bg-primary text-white">
															<h2 className="accordion-header">
																<button
																	className="accordion-button bg-primary text-white"
																	type="button"
																	data-bs-toggle="collapse"
																	data-bs-target="#cadastroEntidade"
																	aria-expanded="true"
																	aria-controls="cadastroEntidade"
																>
																	Entidade
																</button>
															</h2>
															<div
																id="cadastroEntidade"
																className="accordion-collapse collapse bg-white text-black"
																data-bs-parent="#cadastros"
															>
																<div className="accordion-body">
																	<div className="row">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>Menu</b>
																			</p>
																			<p>Entidade</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarEntidade}
																				onChange={controleAcessoEntidadeVisualizar}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarEntidade"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Criar</p>
																			<CheckBox
																				checked={criarEntidade}
																				onChange={controleAcessoEntidadeCriar}
																				disabled={params.acao || ''}
																				label=""
																				name="criarEntidade"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Editar</p>
																			<CheckBox
																				checked={editarEntidade}
																				onChange={controleAcessoEntidadeEditar}
																				disabled={params.acao || ''}
																				label=""
																				name="editarEntidade"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Excluir</p>
																			<CheckBox
																				checked={excluirEntidade}
																				onChange={controleAcessoEntidadeExcluir}
																				disabled={params.acao || ''}
																				label=""
																				name="excluirEntidade"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<strong>Acesso Total</strong>
																			</p>
																			<CheckBox
																				checked={acessoTotalEntidade}
																				onChange={controleAcessoTotalEntidade}
																				disabled={params.acao || ''}
																				label=""
																				name="acessoTotalEntidade"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>

																	<div className="row border-1 border-top border-primary">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>
																					<br />
																				</b>
																			</p>
																			<p>Transportadora</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarTransportadora}
																				onChange={controleAcessoTransportadoraVisualizar}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarTransportadora"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Vincular Depositante</p>
																			<CheckBox
																				checked={vincularTransportadoraDepositante}
																				onChange={controleAcessoTransportadoraDepositanteVincular}
																				disabled={params.acao || ''}
																				label=""
																				name="vincularTransportadora"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>

																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<strong>Acesso Total</strong>
																			</p>
																			<CheckBox
																				checked={acessoTotalTransportadora}
																				onChange={controleAcessoTotalTransportadora}
																				disabled={params.acao || ''}
																				label=""
																				name="acessoTotalTransportadora"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div className="accordion-item bg-primary text-white">
															<h2 className="accordion-header">
																<button
																	className="accordion-button bg-primary text-white"
																	type="button"
																	data-bs-toggle="collapse"
																	data-bs-target="#cadastroDepositante"
																	aria-expanded="true"
																	aria-controls="cadastroDepositante"
																>
																	Depositante
																</button>
															</h2>
															<div
																id="cadastroDepositante"
																className="accordion-collapse collapse bg-white text-black"
																data-bs-parent="#cadastros"
															>
																<div className="accordion-body">
																	<div className="row">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>Menu</b>
																			</p>
																			<p>Depositante</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarDepositante}
																				onChange={controleAcessoDepositanteVisualizar}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarDepositante"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Criar</p>
																			<CheckBox
																				checked={criarDepositante}
																				onChange={controleAcessoDepositanteCriar}
																				disabled={params.acao || ''}
																				label=""
																				name="criarDepositante"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Editar</p>
																			<CheckBox
																				checked={editarDepositante}
																				onChange={controleAcessoDepositanteEditar}
																				disabled={params.acao || ''}
																				label=""
																				name="editarDepositante"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Excluir</p>
																			<CheckBox
																				checked={excluirDepositante}
																				onChange={controleAcessoDepositanteExcluir}
																				disabled={params.acao || ''}
																				label=""
																				name="excluirDepositante"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<strong>Acesso Total</strong>
																			</p>
																			<CheckBox
																				checked={acessoTotalDepositante}
																				onChange={controleAcessoTotalDepositante}
																				disabled={params.acao || ''}
																				label=""
																				name="acessoTotalDepositante"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div className="accordion-item bg-primary text-white">
															<h2 className="accordion-header">
																<button
																	className="accordion-button bg-primary text-white"
																	type="button"
																	data-bs-toggle="collapse"
																	data-bs-target="#cadastroProduto"
																	aria-expanded="true"
																	aria-controls="cadastroProduto"
																>
																	Produto
																</button>
															</h2>
															<div
																id="cadastroProduto"
																className="accordion-collapse collapse bg-white text-black"
																data-bs-parent="#cadastros"
															>
																<div className="accordion-body">
																	<div className="row">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>Menu</b>
																			</p>
																			<p>Produto de Armazenagem</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarProdutosArmazenagem}
																				onChange={controleAcessoProdutosArmazenagemVisualizar}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarProdutosArmazenagem"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Criar</p>
																			<CheckBox
																				checked={criarProdutosArmazenagem}
																				onChange={controleAcessoProdutosArmazenagemCriar}
																				disabled={params.acao || ''}
																				label=""
																				name="criarProdutosArmazenagem"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Editar</p>
																			<CheckBox
																				checked={editarProdutosArmazenagem}
																				onChange={controleAcessoProdutosArmazenagemEditar}
																				disabled={params.acao || ''}
																				label=""
																				name="editarProdutosArmazenagem"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Excluir</p>
																			<CheckBox
																				checked={excluirProdutosArmazenagem}
																				onChange={controleAcessoProdutosArmazenagemExcluir}
																				disabled={params.acao || ''}
																				label=""
																				name="excluirProdutosArmazenagem"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<strong>Acesso Total</strong>
																			</p>
																			<CheckBox
																				checked={acessoTotalProdutosArmazenagem}
																				onChange={controleAcessoTotalProdutosArmazenagem}
																				disabled={params.acao || ''}
																				label=""
																				name="acessoTotalProdutosArmazenagem"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																	<div className="row border-1 border-top border-primary">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>
																					<br />
																				</b>
																			</p>
																			<p>Produto de Fornecedor</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarProdutosFornecedor}
																				onChange={controleAcessoProdutosFornecedorVisualizar}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarProdutosFornecedor"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Criar</p>
																			<CheckBox
																				checked={criarProdutosFornecedor}
																				onChange={controleAcessoProdutosFornecedorCriar}
																				disabled={params.acao || ''}
																				label=""
																				name="criarProdutosFornecedor"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Editar</p>
																			<CheckBox
																				checked={editarProdutosFornecedor}
																				onChange={controleAcessoProdutosFornecedorEditar}
																				disabled={params.acao || ''}
																				label=""
																				name="editarProdutosFornecedor"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Excluir</p>
																			<CheckBox
																				checked={excluirProdutosFornecedor}
																				onChange={controleAcessoProdutosFornecedorExcluir}
																				disabled={params.acao || ''}
																				label=""
																				name="excluirProdutosFornecedor"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<strong>Acesso Total</strong>
																			</p>
																			<CheckBox
																				checked={acessoTotalProdutosFornecedor}
																				onChange={controleAcessoTotalProdutosFornecedor}
																				disabled={params.acao || ''}
																				label=""
																				name="acessoTotalProdutosFornecedor"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																	<div className="row border-1 border-top border-primary">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>
																					<br />
																				</b>
																			</p>
																			<p>Produto de Cross</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarProdutosCross}
																				onChange={controleAcessoProdutosCrossVisualizar}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarProdutosCross"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Criar</p>
																			<CheckBox
																				checked={criarProdutosCross}
																				onChange={controleAcessoProdutosCrossCriar}
																				disabled={params.acao || ''}
																				label=""
																				name="criarProdutosCross"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Editar</p>
																			<CheckBox
																				checked={editarProdutosCross}
																				onChange={controleAcessoProdutosCrossEditar}
																				disabled={params.acao || ''}
																				label=""
																				name="editarProdutosCross"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Excluir</p>
																			<CheckBox
																				checked={excluirProdutosCross}
																				onChange={controleAcessoProdutosCrossExcluir}
																				disabled={params.acao || ''}
																				label=""
																				name="excluirProdutosCross"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<strong>Acesso Total</strong>
																			</p>
																			<CheckBox
																				checked={acessoTotalProdutosCross}
																				onChange={controleAcessoTotalProdutosCross}
																				disabled={params.acao || ''}
																				label=""
																				name="acessoTotalProdutosCross"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																	<div className="row border-1 border-top border-primary">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>
																					<br />
																				</b>
																			</p>
																			<p>Produto de Picking</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarProdutosPicking}
																				onChange={controleAcessoProdutosPickingVisualizar}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarProdutosPicking"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Criar</p>
																			<CheckBox
																				checked={criarProdutosPicking}
																				onChange={controleAcessoProdutosPickingCriar}
																				disabled={params.acao || ''}
																				label=""
																				name="criarProdutosPicking"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Editar</p>
																			<CheckBox
																				checked={editarProdutosPicking}
																				onChange={controleAcessoProdutosPickingEditar}
																				disabled={params.acao || ''}
																				label=""
																				name="editarProdutosPicking"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Excluir</p>
																			<CheckBox
																				checked={excluirProdutosPicking}
																				onChange={controleAcessoProdutosPickingExcluir}
																				disabled={params.acao || ''}
																				label=""
																				name="excluirProdutosPicking"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<strong>Acesso Total</strong>
																			</p>
																			<CheckBox
																				checked={acessoTotalProdutosPicking}
																				onChange={controleAcessoTotalProdutosPicking}
																				disabled={params.acao || ''}
																				label=""
																				name="acessoTotalProdutosPicking"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div className="accordion-item bg-primary text-white">
															<h2 className="accordion-header">
																<button
																	className="accordion-button bg-primary text-white"
																	type="button"
																	data-bs-toggle="collapse"
																	data-bs-target="#cadastroEndereco"
																	aria-expanded="true"
																	aria-controls="cadastroEndereco"
																>
																	Endereço
																</button>
															</h2>
															<div
																id="cadastroEndereco"
																className="accordion-collapse collapse bg-white text-black"
																data-bs-parent="#cadastros"
															>
																<div className="accordion-body">
																	<div className="row">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>Menu</b>
																			</p>
																			<p>Endereço de Armazém</p>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarEnderecosArmz}
																				onChange={controleAcessoEnderecosArmzVisualizar}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarEnderecosArmz"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">Editar Temperatura</p>
																			<CheckBox
																				checked={editarTempEnderecosArmz}
																				onChange={controleAcessoEnderecosArmzEditarTemp}
																				disabled={params.acao || ''}
																				label=""
																				name="editarTempEnderecosArmz"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">Criar</p>
																			<CheckBox
																				checked={criarEnderecosArmz}
																				onChange={controleAcessoEnderecosArmzCriar}
																				disabled={params.acao || ''}
																				label=""
																				name="criarEnderecosArmz"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">Editar</p>
																			<CheckBox
																				checked={editarEnderecosArmz}
																				onChange={controleAcessoEnderecosArmzEditar}
																				disabled={params.acao || ''}
																				label=""
																				name="editarEnderecosArmz"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">Excluir</p>
																			<CheckBox
																				checked={excluirEnderecosArmz}
																				onChange={controleAcessoEnderecosArmzExcluir}
																				disabled={params.acao || ''}
																				label=""
																				name="excluirEnderecosArmz"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">
																				<strong>Acesso Total</strong>
																			</p>
																			<CheckBox
																				checked={acessoTotalEnderecosArmz}
																				onChange={controleAcessoTotalEnderecosArmz}
																				disabled={params.acao || ''}
																				label=""
																				name="acessoTotalEnderecosArmz"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div className="accordion-item bg-primary text-white">
															<h2 className="accordion-header">
																<button
																	className="accordion-button bg-primary text-white"
																	type="button"
																	data-bs-toggle="collapse"
																	data-bs-target="#cadastroVeiculo"
																	aria-expanded="true"
																	aria-controls="cadastroVeiculo"
																>
																	Veículo
																</button>
															</h2>
															<div
																id="cadastroVeiculo"
																className="accordion-collapse collapse bg-white text-black"
																data-bs-parent="#cadastros"
															>
																<div className="accordion-body">
																	<div className="row">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>Menu</b>
																			</p>
																			<p>Veículo</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarVeiculos}
																				onChange={controleAcessoVeiculosVisualizar}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarVeiculos"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Criar</p>
																			<CheckBox
																				checked={criarVeiculos}
																				onChange={controleAcessoVeiculosCriar}
																				disabled={params.acao || ''}
																				label=""
																				name="criarVeiculos"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Editar</p>
																			<CheckBox
																				checked={editarVeiculos}
																				onChange={controleAcessoVeiculosEditar}
																				disabled={params.acao || ''}
																				label=""
																				name="editarVeiculos"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Excluir</p>
																			<CheckBox
																				checked={excluirVeiculos}
																				onChange={controleAcessoVeiculosExcluir}
																				disabled={params.acao || ''}
																				label=""
																				name="excluirVeiculos"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<strong>Acesso Total</strong>
																			</p>
																			<CheckBox
																				checked={acessoTotalVeiculos}
																				onChange={controleAcessoTotalVeiculos}
																				disabled={params.acao || ''}
																				label=""
																				name="acessoTotalVeiculos"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																	<div className="row border-1 border-top border-primary">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>
																					<br />
																				</b>
																			</p>
																			<p>Motorista</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarMotoristas}
																				onChange={controleAcessoMotoristasVisualizar}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarMotoristas"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Criar</p>
																			<CheckBox
																				checked={criarMotoristas}
																				onChange={controleAcessoMotoristasCriar}
																				disabled={params.acao || ''}
																				label=""
																				name="criarMotoristas"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Editar</p>
																			<CheckBox
																				checked={editarMotoristas}
																				onChange={controleAcessoMotoristasEditar}
																				disabled={params.acao || ''}
																				label=""
																				name="editarMotoristas"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Excluir</p>
																			<CheckBox
																				checked={excluirMotoristas}
																				onChange={controleAcessoMotoristasExcluir}
																				disabled={params.acao || ''}
																				label=""
																				name="excluirMotoristas"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<strong>Acesso Total</strong>
																			</p>
																			<CheckBox
																				checked={acessoTotalMotoristas}
																				onChange={controleAcessoTotalMotoristas}
																				disabled={params.acao || ''}
																				label=""
																				name="acessoTotalMotoristas"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div className="accordion-item bg-primary text-white">
															<h2 className="accordion-header">
																<button
																	className="accordion-button bg-primary text-white"
																	type="button"
																	data-bs-toggle="collapse"
																	data-bs-target="#cadastroEquipamento"
																	aria-expanded="true"
																	aria-controls="cadastroEquipamento"
																>
																	Equipamento
																</button>
															</h2>
															<div
																id="cadastroEquipamento"
																className="accordion-collapse collapse bg-white text-black"
																data-bs-parent="#cadastros"
															>
																<div className="accordion-body">
																	<div className="row">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>Menu</b>
																			</p>
																			<p>Equipamento</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarEquipamento}
																				onChange={controleAcessoEquipamentoVisualizar}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarEquipamento"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Criar</p>
																			<CheckBox
																				checked={criarEquipamento}
																				onChange={controleAcessoEquipamentoCriar}
																				disabled={params.acao || ''}
																				label=""
																				name="criarEquipamento"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Editar</p>
																			<CheckBox
																				checked={editarEquipamento}
																				onChange={controleAcessoEquipamentoEditar}
																				disabled={params.acao || ''}
																				label=""
																				name="editarEquipamento"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Excluir</p>
																			<CheckBox
																				checked={excluirEquipamento}
																				onChange={controleAcessoEquipamentoExcluir}
																				disabled={params.acao || ''}
																				label=""
																				name="excluirEquipamento"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<strong>Acesso Total</strong>
																			</p>
																			<CheckBox
																				checked={acessoTotalEquipamento}
																				onChange={controleAcessoTotalEquipamento}
																				disabled={params.acao || ''}
																				label=""
																				name="acessoTotalEquipamento"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div className="accordion-item bg-primary text-white">
															<h2 className="accordion-header">
																<button
																	className="accordion-button bg-primary text-white"
																	type="button"
																	data-bs-toggle="collapse"
																	data-bs-target="#cadastroColaborador"
																	aria-expanded="true"
																	aria-controls="cadastroColaborador"
																>
																	Colaborador
																</button>
															</h2>
															<div
																id="cadastroColaborador"
																className="accordion-collapse collapse bg-white text-black"
																data-bs-parent="#cadastros"
															>
																<div className="accordion-body">
																	<div className="row">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>Menu</b>
																			</p>
																			<p>Colaborador</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarColaborador}
																				onChange={controleAcessoColaboradorVisualizar}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarColaborador"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Criar</p>
																			<CheckBox
																				checked={criarColaborador}
																				onChange={controleAcessoColaboradorCriar}
																				disabled={params.acao || ''}
																				label=""
																				name="criarColaborador"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Editar</p>
																			<CheckBox
																				checked={editarColaborador}
																				onChange={controleAcessoColaboradorEditar}
																				disabled={params.acao || ''}
																				label=""
																				name="editarColaborador"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Excluir</p>
																			<CheckBox
																				checked={excluirColaborador}
																				onChange={controleAcessoColaboradorExcluir}
																				disabled={params.acao || ''}
																				label=""
																				name="excluirColaborador"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<strong>Acesso Total</strong>
																			</p>
																			<CheckBox
																				checked={acessoTotalColaborador}
																				onChange={controleAcessoTotalColaborador}
																				disabled={params.acao || ''}
																				label=""
																				name="acessoTotalColaborador"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div className="accordion-item bg-primary text-white">
															<h2 className="accordion-header">
																<button
																	className="accordion-button bg-primary text-white"
																	type="button"
																	data-bs-toggle="collapse"
																	data-bs-target="#cadastroFiscal"
																	aria-expanded="true"
																	aria-controls="cadastroFiscal"
																>
																	Fiscal
																</button>
															</h2>
															<div
																id="cadastroFiscal"
																className="accordion-collapse collapse bg-white text-black"
																data-bs-parent="#cadastros"
															>
																<div className="accordion-body">
																	<div className="row">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>Menu</b>
																			</p>
																			<p>CFOP</p>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarCFOP}
																				onChange={controleAcessoCFOPVisualizar}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarCFOP"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">Importar Arq. CFOP</p>
																			<CheckBox
																				checked={importarArqCFOP}
																				onChange={controleAcessoCFOPImpArq}
																				disabled={params.acao || ''}
																				label=""
																				name="importarArqCFOP"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">Baixar Modelo CFOP</p>
																			<CheckBox
																				checked={baixarModeloCFOP}
																				onChange={controleAcessoCFOPBaixarModelo}
																				disabled={params.acao || ''}
																				label=""
																				name="baixarModeloCFOP"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">Criar</p>
																			<CheckBox
																				checked={criarCFOP}
																				onChange={controleAcessoCFOPCriar}
																				disabled={params.acao || ''}
																				label=""
																				name="criarCFOP"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">Editar</p>
																			<CheckBox
																				checked={editarCFOP}
																				onChange={controleAcessoCFOPEditar}
																				disabled={params.acao || ''}
																				label=""
																				name="editarCFOP"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">Excluir</p>
																			<CheckBox
																				checked={excluirCFOP}
																				onChange={controleAcessoCFOPExcluir}
																				disabled={params.acao || ''}
																				label=""
																				name="excluirCFOP"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">
																				<strong>Acesso Total</strong>
																			</p>
																			<CheckBox
																				checked={acessoTotalCFOP}
																				onChange={controleAcessoTotalCFOP}
																				disabled={params.acao || ''}
																				label=""
																				name="acessoTotalCFOP"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																	<div className="row border-1 border-top border-primary">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>
																					<br />
																				</b>
																			</p>
																			<p>CFOP Entrada x Saída</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarCFOPEntradaSaida}
																				onChange={controleAcessoCFOPEntradaSaidaVisualizar}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarCFOPEntradaSaida"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Criar</p>
																			<CheckBox
																				checked={criarCFOPEntradaSaida}
																				onChange={controleAcessoCFOPEntradaSaidaCriar}
																				disabled={params.acao || ''}
																				label=""
																				name="criarCFOPEntradaSaida"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Editar</p>
																			<CheckBox
																				checked={editarCFOPEntradaSaida}
																				onChange={controleAcessoCFOPEntradaSaidaEditar}
																				disabled={params.acao || ''}
																				label=""
																				name="editarCFOPEntradaSaida"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Excluir</p>
																			<CheckBox
																				checked={excluirCFOPEntradaSaida}
																				onChange={controleAcessoCFOPEntradaSaidaExcluir}
																				disabled={params.acao || ''}
																				label=""
																				name="excluirCFOPEntradaSaida"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<strong>Acesso Total</strong>
																			</p>
																			<CheckBox
																				checked={acessoTotalCFOPEntradaSaida}
																				onChange={controleAcessoTotalCFOPEntradaSaida}
																				disabled={params.acao || ''}
																				label=""
																				name="acessoTotalCFOPEntradaSaida"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																	<div className="row border-1 border-top border-primary">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>
																					<br />
																				</b>
																			</p>
																			<p>Grupo Tributário</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarGrupoTributario}
																				onChange={controleAcessoGrupoTributarioVisualizar}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarGrupoTributario"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Criar</p>
																			<CheckBox
																				checked={criarGrupoTributario}
																				onChange={controleAcessoGrupoTributarioCriar}
																				disabled={params.acao || ''}
																				label=""
																				name="criarGrupoTributario"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Editar</p>
																			<CheckBox
																				checked={editarGrupoTributario}
																				onChange={controleAcessoGrupoTributarioEditar}
																				disabled={params.acao || ''}
																				label=""
																				name="editarGrupoTributario"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Excluir</p>
																			<CheckBox
																				checked={excluirGrupoTributario}
																				onChange={controleAcessoGrupoTributarioExcluir}
																				disabled={params.acao || ''}
																				label=""
																				name="excluirGrupoTributario"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<strong>Acesso Total</strong>
																			</p>
																			<CheckBox
																				checked={acessoTotalGrupoTributario}
																				onChange={controleAcessoTotalGrupoTributario}
																				disabled={params.acao || ''}
																				label=""
																				name="acessoTotalGrupoTributario"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<br />
									<div className="accordion bg-primary text-white">
										<div className="accordion-item">
											<h2 className="accordion-header">
												<button
													className="accordion-button bg-primary  text-white"
													type="button"
													data-bs-toggle="collapse"
													data-bs-target="#AdminOperacional"
													aria-expanded="true"
													aria-controls="AdminOperacional"
												>
													Adm. Operacional
												</button>
											</h2>
											<div id="AdminOperacional" className="accordion-collapse collapse bg-white text-primary">
												<div className="accordion-body">
													<div className="accordion  bg-primary text-white">
														<div className="accordion-item">
															<h2 className="accordion-header">
																<button
																	className="accordion-button  bg-primary text-white"
																	type="button"
																	data-bs-toggle="collapse"
																	data-bs-target="#AdmOperFiscalEntrada"
																	aria-expanded="true"
																	aria-controls="AdmOperFiscalEntrada"
																>
																	Fiscal Entrada
																</button>
															</h2>
															<div
																id="AdmOperFiscalEntrada"
																className="accordion-collapse collapse bg-white text-black"
															>
																<div className="accordion-body">
																	<div className="row">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>Menu</b>
																			</p>
																			<p>NF-e Entrada Armazenagem</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarNfeEntradasArmz}
																				onChange={controleAcessoNfeEntradasArmzVisualizar}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarNfeEntradasArmz"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Detalhar</p>
																			<CheckBox
																				checked={detalharNfeEntradaArmz}
																				onChange={controleAcessoNfeEntradasArmzDetalhar}
																				disabled={params.acao || ''}
																				label=""
																				name="detalharNfeEntradaArmz"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Importar</p>
																			<CheckBox
																				checked={importarNfeEntradasArmz}
																				onChange={controleAcessoNfeEntradasArmzImportar}
																				disabled={params.acao || ''}
																				label=""
																				name="importarNfeEntradasArmz"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Excluir</p>
																			<CheckBox
																				checked={excluirNfeEntradasArmz}
																				onChange={controleAcessoNfeEntradasArmzExcluir}
																				disabled={params.acao || ''}
																				label=""
																				name="excluirNfeEntradasArmz"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<strong>Acesso Total</strong>
																			</p>
																			<CheckBox
																				checked={acessoTotalNfeEntradasArmz}
																				onChange={controleAcessoTotalNfeEntradasArmz}
																				disabled={params.acao || ''}
																				label=""
																				name="acessoTotalNfeEntradasArmz"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																	<div className="row  border-1 border-top border-primary">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>
																					<br />
																				</b>
																			</p>
																			<p>NF-e Entrada CrossDocking</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarNfeEntradaCrossDocking}
																				onChange={controleAcessoNfeEntradasCrossDockingVisualizar}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarEntradaCrossDocking"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Detalhar</p>
																			<CheckBox
																				checked={detalharNfeEntradaCrossDocking}
																				onChange={controleAcessoNfeEntradasCrossDockingDetalhar}
																				disabled={params.acao || ''}
																				label=""
																				name="detalharNfeEntradaCrossDocking"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<strong>Acesso Total</strong>
																			</p>
																			<CheckBox
																				checked={acessoTotalNfeEntradaCrossDocking}
																				onChange={controleAcessoTotalNfeEntradasCrossDocking}
																				disabled={params.acao || ''}
																				label=""
																				name="acessoTotalNfeEntradaCrossDocking"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																	<div className="row  border-1 border-top border-primary">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>
																					<br />
																				</b>
																			</p>
																			<p>NF-e Pedido</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarNfePedido}
																				onChange={controleAcessoNfePedidoVisualizar}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarNfePedido"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Detalhar</p>
																			<CheckBox
																				checked={detalharNfePedido}
																				onChange={controleAcessoNfePedidoDetalhar}
																				disabled={params.acao || ''}
																				label=""
																				name="detalharNfePedido"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Excluir</p>
																			<CheckBox
																				checked={excluirNfePedido}
																				onChange={controleAcessoNfePedidoExcluir}
																				disabled={params.acao || ''}
																				label=""
																				name="excluirNfePedido"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<strong>Acesso Total</strong>
																			</p>
																			<CheckBox
																				checked={acessoTotalNfePedido}
																				onChange={controleAcessoTotalNfesPedido}
																				disabled={params.acao || ''}
																				label=""
																				name="acessoTotalNfePedido"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className="accordion  bg-primary text-white mt-2">
														<div className="accordion-item">
															<h2 className="accordion-header">
																<button
																	className="accordion-button  bg-primary text-white"
																	type="button"
																	data-bs-toggle="collapse"
																	data-bs-target="#AdmOperFiscalSaida"
																	aria-expanded="true"
																	aria-controls="AdmOperFiscalSaida"
																>
																	Fiscal Saída
																</button>
															</h2>
															<div id="AdmOperFiscalSaida" className="accordion-collapse collapse bg-white text-black">
																<div className="accordion-body">
																	<div className="row">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>Menu</b>
																			</p>
																			<p>Saldo Fiscal</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarSaldoFiscal}
																				onChange={setVisualizarSaldoFiscal}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarSaldoFiscal"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																	<div className="row  border-1 border-top border-primary">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>
																					<br />
																				</b>
																			</p>
																			<p>NF-e Emitido</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarNfeEmitido}
																				onChange={controleAcessoNfeEmitidoVisualizar}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarNfeEmitido"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Detalhar</p>
																			<CheckBox
																				checked={detalharNfeEmitido}
																				onChange={controleAcessoNfeEmitidoDetalhar}
																				disabled={params.acao || ''}
																				label=""
																				name="detalharNfeEmitido"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<strong>Acesso Total</strong>
																			</p>
																			<CheckBox
																				checked={acessoTotalNfeEmitido}
																				onChange={controleAcessoTotalNfesEmitido}
																				disabled={params.acao || ''}
																				label=""
																				name="acessoTotalNfeEmitido"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																	<div className="row  border-1 border-top border-primary">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>
																					<br />
																				</b>
																			</p>
																			<p>Emissão NF-e</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarEmissaoNfe}
																				onChange={controleAcessoEmissaoNfeVisualizar}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarEmissaoNfe"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Criar</p>
																			<CheckBox
																				checked={criarEmissaoNfe}
																				onChange={controleAcessoEmissaoNfeCriar}
																				disabled={params.acao || ''}
																				label=""
																				name="criarEmissaoNfe"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Editar</p>
																			<CheckBox
																				checked={editarEmissaoNfe}
																				onChange={controleAcessoEmissaoNfeEditar}
																				disabled={params.acao || ''}
																				label=""
																				name="editarEmissaoNfe"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Excluir</p>
																			<CheckBox
																				checked={excluirEmissaoNfe}
																				onChange={controleAcessoEmissaoNfeExcluir}
																				disabled={params.acao || ''}
																				label=""
																				name="excluirEmissaoNfe"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<strong>Acesso Total</strong>
																			</p>
																			<CheckBox
																				checked={acessoTotalEmissaoNfe}
																				onChange={controleAcessoTotalEmissaoNfe}
																				disabled={params.acao || ''}
																				label=""
																				name="acessoTotalEmissaoNfe"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																	<div className="row  border-1 border-top border-primary">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>
																					<br />
																				</b>
																			</p>
																			<p>Manifestar NF-e</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarManifestarNfe}
																				onChange={controleAcessoManifestarNfeVisualizar}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarManifestarNfe"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Manifestar</p>
																			<CheckBox
																				checked={manifestarNfe}
																				onChange={controleAcessoManifestarNfeManifestar}
																				disabled={params.acao || ''}
																				label=""
																				name="manifestarNfe"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>

																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<strong>Acesso Total</strong>
																			</p>
																			<CheckBox
																				checked={acessoTotalManifestarNfe}
																				onChange={controleAcessoTotalManifestarNFe}
																				disabled={params.acao || ''}
																				label=""
																				name="acessoTotalManifestarNfe"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className="accordion  bg-primary text-white mt-2">
														<div className="accordion-item">
															<h2 className="accordion-header">
																<button
																	className="accordion-button  bg-primary text-white"
																	type="button"
																	data-bs-toggle="collapse"
																	data-bs-target="#AdmOperContProcesso"
																	aria-expanded="true"
																	aria-controls="AdmOperContProcesso"
																>
																	Controle de Processo
																</button>
															</h2>
															<div id="AdmOperContProcesso" className="accordion-collapse collapse bg-white text-black">
																<div className="accordion-body">
																	<div className="row">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>Menu</b>
																			</p>
																			<p>Processo de Entrada</p>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarProcEntrada}
																				onChange={controleAcessoProcessoEntradaVisualizar}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarProcEntrada"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">Criar</p>
																			<CheckBox
																				checked={criarProcEntrada}
																				onChange={controleAcessoProcessoEntradaCriar}
																				disabled={params.acao || ''}
																				label=""
																				name="criarProcEntrada"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">Editar</p>
																			<CheckBox
																				checked={editarProcEntrada}
																				onChange={controleAcessoProcessoEntradaEditar}
																				disabled={params.acao || ''}
																				label=""
																				name="editarProcEntrada"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">Cancelar</p>
																			<CheckBox
																				checked={cancelarProcEntrada}
																				onChange={controleAcessoProcessoEntradaCancelar}
																				disabled={params.acao || ''}
																				label=""
																				name="cancelarProcEntrada"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">
																				<strong>Acesso Total</strong>
																			</p>
																			<CheckBox
																				checked={acessoTotalProcEntrada}
																				onChange={controleAcessoTotalProcessoEntrada}
																				disabled={params.acao || ''}
																				label=""
																				name="acessoTotalProcEntrada"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																	<div className="row border-1 border-top border-primary">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>
																					<br />
																				</b>
																			</p>
																			<p>Processo de Saída</p>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarProcSaida}
																				onChange={controleAcessoProcessoSaidaVisualizar}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarProcSaida"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">Criar</p>
																			<CheckBox
																				checked={criarProcSaida}
																				onChange={controleAcessoProcessoSaidaCriar}
																				disabled={params.acao || ''}
																				label=""
																				name="criarProcSaida"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">Editar</p>
																			<CheckBox
																				checked={editarProcSaida}
																				onChange={controleAcessoProcessoSaidaEditar}
																				disabled={params.acao || ''}
																				label=""
																				name="editarProcSaida"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">Cancelar</p>
																			<CheckBox
																				checked={cancelarProcSaida}
																				onChange={controleAcessoProcessoSaidaCancelar}
																				disabled={params.acao || ''}
																				label=""
																				name="cancelarProcSaida"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">
																				<strong>Acesso Total</strong>
																			</p>
																			<CheckBox
																				checked={acessoTotalProcSaida}
																				onChange={controleAcessoTotalProcessoSaida}
																				disabled={params.acao || ''}
																				label=""
																				name="acessoTotalProcSaida"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className="accordion  bg-primary text-white mt-2">
														<div className="accordion-item">
															<h2 className="accordion-header">
																<button
																	className="accordion-button  bg-primary text-white"
																	type="button"
																	data-bs-toggle="collapse"
																	data-bs-target="#AdmOperPedido"
																	aria-expanded="true"
																	aria-controls="AdmOperPedido"
																>
																	Pedidos
																</button>
															</h2>
															<div id="AdmOperPedido" className="accordion-collapse collapse bg-white text-black">
																<div className="accordion-body">
																	<div className="row">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>Menu</b>
																			</p>
																			<p>Criação de Pedidos - Imp. de Arquivos</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarImportacaoArquivo}
																				onChange={controleAcessoImportacaoArquivoVisualizar}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarImportacaoArquivo"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Importar Arquivo</p>
																			<CheckBox
																				checked={importarArquivo}
																				onChange={controleAcessoImportacaoArquivoImportar}
																				disabled={params.acao || ''}
																				label=""
																				name="importarArquivo"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<strong>Acesso Total</strong>
																			</p>
																			<CheckBox
																				checked={acessoTotalImportacaoArquivo}
																				onChange={controleAcessoTotalImportacaoArquivo}
																				disabled={params.acao || ''}
																				label=""
																				name="acessoTotalImportacaoArquivo"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																	<div className="row border-1 border-top border-primary">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>
																					<br />
																				</b>
																			</p>
																			<p>Criação de Pedidos - NF-e p/ Pedido</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarNfeParaPedido}
																				onChange={controleAcessoNfeParaPedidoVisualizar}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarNfeParaPedido"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Importar Pedido</p>
																			<CheckBox
																				checked={importarNfeParaPedido}
																				onChange={controleAcessoNfeParaPedidoImportar}
																				disabled={params.acao || ''}
																				label=""
																				name="importarNfeParaPedido"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<strong>Acesso Total</strong>
																			</p>
																			<CheckBox
																				checked={acessoTotalNfeParaPedido}
																				onChange={controleAcessoTotalNfeParaPedido}
																				disabled={params.acao || ''}
																				label=""
																				name="acessoTotalNfeParaPedido"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																	<div className="row border-1 border-top border-primary">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>
																					<br />
																				</b>
																			</p>
																			<p>Criação de Pedidos - Pedido Manual</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarPedidoManual}
																				onChange={controleAcessoPedidoManualVisualizar}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarPedidoManual"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Criar Pedido</p>
																			<CheckBox
																				checked={criarPedidoManual}
																				onChange={controleAcessoPedidoManualCriar}
																				disabled={params.acao || ''}
																				label=""
																				name="criarPedidoManual"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Adicionar Item</p>
																			<CheckBox
																				checked={adicionarItemPedidoManual}
																				onChange={controleAcessoPedidoManualAdicionarItem}
																				disabled={params.acao || ''}
																				label=""
																				name="adicionarItemPedidoManual"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Excluir Item</p>
																			<CheckBox
																				checked={excluirItemPedidoManual}
																				onChange={controleAcessoPedidoManualExcluirItem}
																				disabled={params.acao || ''}
																				label=""
																				name="excluirItemPedidoManual"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<strong>Acesso Total</strong>
																			</p>
																			<CheckBox
																				checked={acessoTotalPedidoManual}
																				onChange={controleAcessoTotalPedidoManual}
																				disabled={params.acao || ''}
																				label=""
																				name="acessoTotalPedidoManual"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																	<div className="row border-1 border-top border-primary">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>
																					<br />
																				</b>
																			</p>
																			<p>Pedidos Pendentes</p>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarPedidosPendentes}
																				onChange={controleAcessoPedidoPendenteVisualizar}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarPedidosPendentes"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">Quebrar Pedido</p>
																			<CheckBox
																				checked={quebrarPedidoTarefas}
																				onChange={controleAcessoPedidoPendenteQuebrarPedido}
																				disabled={params.acao || ''}
																				label=""
																				name="quebrarPedidoTarefas"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">Liberar Pedido</p>
																			<CheckBox
																				checked={liberarPedidoParaPicking}
																				onChange={controleAcessoPedidoPendenteLiberarPicking}
																				disabled={params.acao || ''}
																				label=""
																				name="liberarPedidoParaPicking"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">Editar Pedido</p>
																			<CheckBox
																				checked={editarPedidoPendente}
																				onChange={controleAcessoPedidoPendenteEditar}
																				disabled={params.acao || ''}
																				label=""
																				name="editarPedidoPendente"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">Excluir Pedido</p>
																			<CheckBox
																				checked={excluirPedidoPendente}
																				onChange={controleAcessoPedidoPendenteExcluir}
																				disabled={params.acao || ''}
																				label=""
																				name="excluirPedidoPendente"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">Editar Item</p>
																			<CheckBox
																				checked={edtiarItemPedidoPendente}
																				onChange={controleAcessoPedidoPendenteEditarItem}
																				disabled={params.acao || ''}
																				label=""
																				name="edtiarItemPedidoPendente"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">Excluir Item</p>
																			<CheckBox
																				checked={excluirItemPedidoPendente}
																				onChange={controleAcessoPedidoPendenteExcluirItem}
																				disabled={params.acao || ''}
																				label=""
																				name="excluirItemPedidoPendente"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">
																				<strong>Acesso Total</strong>
																			</p>
																			<CheckBox
																				checked={acessoTotalPedidosPendentes}
																				onChange={controleAcessoTotalPedidoPendente}
																				disabled={params.acao || ''}
																				label=""
																				name="acessoTotalPedidosPendentes"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																	<div className="row border-1 border-top border-primary">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>
																					<br />
																				</b>
																			</p>
																			<p>Pedidos em Separação</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarPedidosSep}
																				onChange={setVisualizarPedidosSep}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarPedidosSep"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className="accordion  bg-primary text-white mt-2">
														<div className="accordion-item">
															<h2 className="accordion-header">
																<button
																	className="accordion-button  bg-primary text-white"
																	type="button"
																	data-bs-toggle="collapse"
																	data-bs-target="#AdmOperInventario"
																	aria-expanded="true"
																	aria-controls="AdmOperInventario"
																>
																	Inventário
																</button>
															</h2>
															<div id="AdmOperInventario" className="accordion-collapse collapse bg-white text-black">
																<div className="accordion-body">
																	<div className="row">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>Menu</b>
																			</p>
																			<p>Inventário</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarInventario}
																				onChange={setVisualizarInventario}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarInventario"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className="accordion  bg-primary text-white mt-2">
														<div className="accordion-item">
															<h2 className="accordion-header">
																<button
																	className="accordion-button  bg-primary text-white"
																	type="button"
																	data-bs-toggle="collapse"
																	data-bs-target="#AdmOperAjustes"
																	aria-expanded="true"
																	aria-controls="AdmOperAjustes"
																>
																	Ajustes
																</button>
															</h2>
															<div id="AdmOperAjustes" className="accordion-collapse collapse bg-white text-black">
																<div className="accordion-body">
																	<div className="row">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>Menu</b>
																			</p>
																			<p>Ajuste de Estoque</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarAjusteEstoque}
																				onChange={controleAcessoAjusteEstoqueVisualizar}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarAjusteEstoque"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Detalhar</p>
																			<CheckBox
																				checked={detalharAjusteEstoque}
																				onChange={controleAcessoAjusteEstoqueDetalhar}
																				disabled={params.acao || ''}
																				label=""
																				name="detalharAjusteEstoque"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<strong>Acesso Total</strong>
																			</p>
																			<CheckBox
																				checked={acessoTotalAjusteEstoque}
																				onChange={controleAcessoTotalAjusteEstoque}
																				disabled={params.acao || ''}
																				label=""
																				name="acessoTotalAjusteEstoque"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																	<div className="row border-1 border-top border-primary">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>
																					<br />
																				</b>
																			</p>
																			<p>Ajustes em Recebimento</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarAjusteRecebimento}
																				onChange={setVisualizarAjusteRecebimento}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarAjusteRecebimento"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																	<div className="row border-1 border-top border-primary">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>
																					<br />
																				</b>
																			</p>
																			<p>Ajustes de Movimento</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarAjusteMovimento}
																				onChange={controleAcessoAjusteMovimentoVisualizar}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarAjusteMovimento"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Detalhar</p>
																			<CheckBox
																				checked={detalharAjusteMovimento}
																				onChange={controleAcessoAjusteMovimentoDetalhar}
																				disabled={params.acao || ''}
																				label=""
																				name="detalharAjusteMovimento"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Excluir Item</p>
																			<CheckBox
																				checked={excluirItemAjusteMovimento}
																				onChange={controleAcessoAjusteMovimentoExcluirItem}
																				disabled={params.acao || ''}
																				label=""
																				name="excluirItemAjusteMovimento"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<strong>Acesso Total</strong>
																			</p>
																			<CheckBox
																				checked={acessoTotalAjusteMovimento}
																				onChange={controleAcessoTotalAjusteMovimento}
																				disabled={params.acao || ''}
																				label=""
																				name="acessoTotalAjusteMovimento"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="accordion mt-2 bg-primary text-white">
										<div className="accordion-item">
											<h2 className="accordion-header">
												<button
													className="accordion-button bg-primary  text-white"
													type="button"
													data-bs-toggle="collapse"
													data-bs-target="#MenuEstoque"
													aria-expanded="true"
													aria-controls="MenuEstoque"
												>
													Estoque
												</button>
											</h2>
											<div id="MenuEstoque" className="accordion-collapse collapse bg-white text-primary">
												<div className="accordion-body">
													<div className="accordion  bg-primary text-white">
														<div className="accordion-item">
															<h2 className="accordion-header">
																<button
																	className="accordion-button  bg-primary text-white"
																	type="button"
																	data-bs-toggle="collapse"
																	data-bs-target="#MenuEstoqueGestaoEstoque"
																	aria-expanded="true"
																	aria-controls="MenuEstoqueGestaoEstoque"
																>
																	Gestão de Estoque
																</button>
															</h2>

															<div
																id="MenuEstoqueGestaoEstoque"
																className="accordion-collapse collapse bg-white text-black"
															>
																<div className="accordion-body">
																	<div className="row bg-white text-black">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>Menu</b>
																			</p>
																			<p>Estoque Detalhado</p>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarEstoqueDetalhado}
																				onChange={controleAcessoEstoqueDetalhadoVisualizar}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarEstoqueDetalhado"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">Detalhar Por U.A.</p>
																			<CheckBox
																				checked={detalharPorUAEstoqueDetalhado}
																				onChange={controleAcessoEstoqueDetalhadoDetalharPorUA}
																				disabled={params.acao || ''}
																				label=""
																				name="detalharPorUAEstoqueDetalhado"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">Gerar Excel - Detalhe U.A.</p>
																			<CheckBox
																				checked={gerarExcelDetalharPorUAEstoqueDetalhado}
																				onChange={controleAcessoEstoqueDetalhadoGerarExcelDetalharPorUA}
																				disabled={params.acao || ''}
																				label=""
																				name="gerarExcelDetalharPorUAEstoqueDetalhado"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">Gerar Resumo Val. - Detalhe U.A.</p>
																			<CheckBox
																				checked={gerarResumoValidadeEstoqueDetalhado}
																				onChange={controleAcessoEstoqueDetalhadoGerarResumoValidade}
																				disabled={params.acao || ''}
																				label=""
																				name="gerarResumoValidadeEstoqueDetalhado"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																	<div className="row bg-white text-black">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b></b>
																			</p>
																			<p></p>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">Gerar EXCEL Resumo Val. - Detalhe U.A.</p>
																			<CheckBox
																				checked={gerarExcelResumoValidadeEstoqueDetalhado}
																				onChange={controleAcessoEstoqueDetalhadoGerarExcelResumoValidade}
																				disabled={params.acao || ''}
																				label=""
																				name="gerarExcelResumoValidadeEstoqueDetalhado"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">Gerar PDF Resumo Val. - Detalhe U.A.</p>
																			<CheckBox
																				checked={gerarPDFResumoValidadeEstoqueDetalhado}
																				onChange={controleAcessoEstoqueDetalhadoGerarPDFResumoValidade}
																				disabled={params.acao || ''}
																				label=""
																				name="gerarPDFResumoValidadeEstoqueDetalhado"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">Enviar E-mail</p>
																			<CheckBox
																				checked={enviarEstoqueEmailEstoqueDetalhado}
																				onChange={controleAcessoEstoqueDetalhadoEnviarEmail}
																				disabled={params.acao || ''}
																				label=""
																				name="enviarEstoqueEmailEstoqueDetalhado"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">Bloquear/Desbloquear Item</p>
																			<CheckBox
																				checked={bloquearDesbloquearItemEstoqueDetalhado}
																				onChange={controleAcessoEstoqueDetalhadoBloquearDesbloquearItem}
																				disabled={params.acao || ''}
																				label=""
																				name="bloquearDesbloquearItemEstoqueDetalhado"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																	<div className="row bg-white text-black">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b></b>
																			</p>
																			<p></p>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">Visualizar Empenho</p>
																			<CheckBox
																				checked={visualizarEmpenhoEstoqueDetalhado}
																				onChange={controleAcessoEstoqueDetalhadoVisualizarEmpenho}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarEmpenhoEstoqueDetalhado"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">Visualizar Barras UA</p>
																			<CheckBox
																				checked={visualizarBarrasUAEstoqueDetalhado}
																				onChange={controleAcessoEstoqueDetalhadoVisualizarBarrasUA}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarBarrasUAEstoqueDetalhado"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">Adicionar Barras U.A.</p>
																			<CheckBox
																				checked={adicionarBarrasUAEstoqueDetalhado}
																				onChange={controleAcessoEstoqueDetalhadoAdicionarBarrasUA}
																				disabled={params.acao || ''}
																				label=""
																				name="adicionarBarrasUAEstoqueDetalhado"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">Editar Barras U.A.</p>
																			<CheckBox
																				checked={editarBarrasUAEstoqueDetalhado}
																				onChange={controleAcessoEstoqueDetalhadoEditarBarrasUA}
																				disabled={params.acao || ''}
																				label=""
																				name="editarBarrasUAEstoqueDetalhado"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">Excluir Barras U.A.</p>
																			<CheckBox
																				checked={excluirBarrasUAEstoqueDetalhado}
																				onChange={controleAcessoEstoqueDetalhadoExcluirBarrasUA}
																				disabled={params.acao || ''}
																				label=""
																				name="excluirBarrasUAEstoqueDetalhado"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">
																				<strong>Acesso Total</strong>
																			</p>
																			<CheckBox
																				checked={acessoTotalEstoqueDetalhado}
																				onChange={controleAcessoTotalEstoqueDetalhado}
																				disabled={params.acao || ''}
																				label=""
																				name="acessoTotalEstoqueDetalhado"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																	<div className="row bg-white text-black border-1 border-top border-primary">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>
																					<br />
																				</b>
																			</p>
																			<p>Estoque Sintético</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarEstoqueSintetico}
																				onChange={controleAcessoEstoqueSinteticoVisualizar}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarEstoqueSintetico"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Gerar Excel</p>
																			<CheckBox
																				checked={gerarExcelEstoqueSintetico}
																				onChange={controleAcessoEstoqueSinteticoGerarExcel}
																				disabled={params.acao || ''}
																				label=""
																				name="gerarExcelEstoqueSintetico"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<strong>Acesso Total</strong>
																			</p>
																			<CheckBox
																				checked={acessoTotalEstoqueSintetico}
																				onChange={controleAcessoTotalEstoqueSintetico}
																				disabled={params.acao || ''}
																				label=""
																				name="acessoTotalEstoqueSintetico"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																	<div className="row bg-white text-black border-1 border-top border-primary">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>
																					<br />
																				</b>
																			</p>
																			<p>Romaneio de Barras Ativas</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarRomaneioBarrasAtivas}
																				onChange={controleAcessoRomaneioBarrasAtivasVisualizar}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarRomaneioBarrasAtivas"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Criar</p>
																			<CheckBox
																				checked={criarRomaneioBarrasAtivas}
																				onChange={controleAcessoRomaneioBarrasAtivasCriar}
																				disabled={params.acao || ''}
																				label=""
																				name="criarRomaneioBarrasAtivas"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Imprimir Todos</p>
																			<CheckBox
																				checked={imprimirTodasBarrasRomaneioBarrasAtivas}
																				onChange={controleAcessoRomaneioBarrasAtivasImprimirTodasBarras}
																				disabled={params.acao || ''}
																				label=""
																				name="imprimirTodasBarrasRomaneioBarrasAtivas"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Gerar Excel</p>
																			<CheckBox
																				checked={gerarExcelRomaneioBarrasAtivas}
																				onChange={controleAcessoRomaneioBarrasAtivasGerarExcel}
																				disabled={params.acao || ''}
																				label=""
																				name="gerarExcelRomaneioBarrasAtivas"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Ver Todas</p>
																			<CheckBox
																				checked={verTodasRomaneioBarrasAtivas}
																				onChange={controleAcessoRomaneioBarrasAtivasVerTodas}
																				disabled={params.acao || ''}
																				label=""
																				name="verTodasRomaneioBarrasAtivas"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																	<div className="row bg-white text-black">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b></b>
																			</p>
																			<p></p>
																		</div>

																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Gerar Excel - Detalhe</p>
																			<CheckBox
																				checked={gerarExcelDetalhesRomaneioBarrasAtivas}
																				onChange={controleAcessoRomaneioBarrasAtivasGerarExcelDetalhes}
																				disabled={params.acao || ''}
																				label=""
																				name="gerarExcelDetalhesRomaneioBarrasAtivas"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Editar</p>
																			<CheckBox
																				checked={editarRomaneioBarrasAtivas}
																				onChange={controleAcessoRomaneioBarrasAtivasEditar}
																				disabled={params.acao || ''}
																				label=""
																				name="editarRomaneioBarrasAtivas"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Excluir</p>
																			<CheckBox
																				checked={excluirRomaneioBarrasAtivas}
																				onChange={controleAcessoRomaneioBarrasAtivasExcluir}
																				disabled={params.acao || ''}
																				label=""
																				name="excluirRomaneioBarrasAtivas"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Imprimir Etq. Item</p>
																			<CheckBox
																				checked={imprimirItemRomaneioBarrasAtivas}
																				onChange={controleAcessoRomaneioBarrasAtivasImprimirItem}
																				disabled={params.acao || ''}
																				label=""
																				name="imprimirItemRomaneioBarrasAtivas"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<strong>Acesso Total</strong>
																			</p>
																			<CheckBox
																				checked={acessoTotalRomaneioBarrasAtivas}
																				onChange={controleAcessoTotalRomaneioBarrasAtivas}
																				disabled={params.acao || ''}
																				label=""
																				name="acessoTotalRomaneioBarrasAtivas"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className="accordion  bg-primary text-white mt-2">
														<div className="accordion-item">
															<h2 className="accordion-header">
																<button
																	className="accordion-button  bg-primary text-white"
																	type="button"
																	data-bs-toggle="collapse"
																	data-bs-target="#MenuEstoqueUaVaziaSaida"
																	aria-expanded="true"
																	aria-controls="MenuEstoqueUaVaziaSaida"
																>
																	U.A. Vazio/Saída e Sem Uso
																</button>
															</h2>

															<div
																id="MenuEstoqueUaVaziaSaida"
																className="accordion-collapse collapse bg-white text-black"
															>
																<div className="accordion-body">
																	<div className="row">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>Menu</b>
																			</p>
																			<p>U.A. Vazio/Saída e Sem Uso</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar - Vazio</p>
																			<CheckBox
																				checked={visualizarUAVazioSaidaSemUsoVazio}
																				onChange={controleAcessoUAVazioSaidaSemUsoVisualizarVazio}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarUAVazioSaidaSemUsoVazio"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Excluir - Vazio</p>
																			<CheckBox
																				checked={excluirUAVazioSaidaSemUsoVazio}
																				onChange={controleAcessoUAVazioSaidaSemUsoExcluirVazio}
																				disabled={params.acao || ''}
																				label=""
																				name="excluirUAVazioSaidaSemUsoVazio"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Imprimir - Vazio</p>
																			<CheckBox
																				checked={imprimirUAVazioSaidaSemUsoVazio}
																				onChange={controleAcessoUAVazioSaidaSemUsoImprimirVazio}
																				disabled={params.acao || ''}
																				label=""
																				name="imprimirUAVazioSaidaSemUsoVazio"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																	<div className="row">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b></b>
																			</p>
																			<p></p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar - Saída</p>
																			<CheckBox
																				checked={visualizarUAVazioSaidaSemUsoSaida}
																				onChange={controleAcessoUAVazioSaidaSemUsoVisualizarSaida}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarUAVazioSaidaSemUsoSaida"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Excluir - Saída</p>
																			<CheckBox
																				checked={excluirUAVazioSaidaSemUsoSaida}
																				onChange={controleAcessoUAVazioSaidaSemUsoExcluirSaida}
																				disabled={params.acao || ''}
																				label=""
																				name="excluirUAVazioSaidaSemUsoSaida"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Imprimir - Saída</p>
																			<CheckBox
																				checked={imprimirUAVazioSaidaSemUsoSaida}
																				onChange={controleAcessoUAVazioSaidaSemUsoImprimirSaida}
																				disabled={params.acao || ''}
																				label=""
																				name="imprimirUAVazioSaidaSemUsoSaida"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<strong>Acesso Total</strong>
																			</p>
																			<CheckBox
																				checked={acessoTotalUAVazioSaidaSemUso}
																				onChange={controleAcessoTotalUAVazioSaidaSemUso}
																				disabled={params.acao || ''}
																				label=""
																				name="acessoTotalUAVazioSaidaSemUso"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="accordion mt-2 bg-primary text-white">
										<div className="accordion-item">
											<h2 className="accordion-header">
												<button
													className="accordion-button bg-primary  text-white"
													type="button"
													data-bs-toggle="collapse"
													data-bs-target="#MenuOperacional"
													aria-expanded="true"
													aria-controls="MenuOperacional"
												>
													Operacional
												</button>
											</h2>
											<div id="MenuOperacional" className="accordion-collapse collapse bg-white text-primary">
												<div className="accordion-body">
													<div className="accordion  bg-primary text-white">
														<div className="accordion-item">
															<h2 className="accordion-header">
																<button
																	className="accordion-button  bg-primary text-white"
																	type="button"
																	data-bs-toggle="collapse"
																	data-bs-target="#MenuOperacionalConfEntrada"
																	aria-expanded="true"
																	aria-controls="MenuOperacionalConfEntrada"
																>
																	Conferência de Entrada
																</button>
															</h2>

															<div
																id="MenuOperacionalConfEntrada"
																className="accordion-collapse collapse bg-white text-black"
															>
																<div className="accordion-body">
																	<div className="row">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>Menu</b>
																			</p>
																			<p>Conferir Recebimento</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarConferirRecebimento}
																				onChange={setVisualizarConferirRecebimento}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarConferirRecebimento"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className="accordion  bg-primary text-white mt-2">
														<div className="accordion-item">
															<h2 className="accordion-header">
																<button
																	className="accordion-button  bg-primary text-white"
																	type="button"
																	data-bs-toggle="collapse"
																	data-bs-target="#MenuOperacionalConfSaida"
																	aria-expanded="true"
																	aria-controls="MenuOperacionalConfSaida"
																>
																	Conferência de Saída
																</button>
															</h2>

															<div
																id="MenuOperacionalConfSaida"
																className="accordion-collapse collapse bg-white text-black"
															>
																<div className="accordion-body">
																	<div className="row">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>Menu</b>
																			</p>
																			<p>Conferir Carregamento</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarConferirCarregamento}
																				onChange={setVisualizarConferirCarregamento}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarConferirCarregamento"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className="accordion  bg-primary text-white mt-2">
														<div className="accordion-item">
															<h2 className="accordion-header">
																<button
																	className="accordion-button  bg-primary text-white"
																	type="button"
																	data-bs-toggle="collapse"
																	data-bs-target="#MenuOperacionalEnderecar"
																	aria-expanded="true"
																	aria-controls="MenuOperacionalEnderecar"
																>
																	Endereçar
																</button>
															</h2>

															<div
																id="MenuOperacionalEnderecar"
																className="accordion-collapse collapse bg-white text-black"
															>
																<div className="accordion-body">
																	<div className="row">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>Menu</b>
																			</p>
																			<p>Endereçar U.A.</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarEnderecarUA}
																				onChange={setVisualizarEnderecarUA}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarEnderecarUA"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className="accordion  bg-primary text-white mt-2">
														<div className="accordion-item">
															<h2 className="accordion-header">
																<button
																	className="accordion-button  bg-primary text-white"
																	type="button"
																	data-bs-toggle="collapse"
																	data-bs-target="#MenuOperacionalDesfragmentacao"
																	aria-expanded="true"
																	aria-controls="MenuOperacionalDesfragmentacao"
																>
																	Desfragmentação
																</button>
															</h2>

															<div
																id="MenuOperacionalDesfragmentacao"
																className="accordion-collapse collapse bg-white text-black"
															>
																<div className="accordion-body">
																	<div className="row">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>Menu</b>
																			</p>
																			<p>Desfragmentar U.A.</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarDesfragmentarUA}
																				onChange={setVisualizarDesfragmentarUA}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarDesfragmentarUA"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																	<div className="row border-1 border-top border-primary">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>
																					<br />
																				</b>
																			</p>
																			<p>Análise de Otimização</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarAnaliseOtimizacao}
																				onChange={setVisualizarAnaliseOtimizacao}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarAnaliseOtimizacao"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className="accordion  bg-primary text-white mt-2">
														<div className="accordion-item">
															<h2 className="accordion-header">
																<button
																	className="accordion-button  bg-primary text-white"
																	type="button"
																	data-bs-toggle="collapse"
																	data-bs-target="#MenuReposicaoPicking"
																	aria-expanded="true"
																	aria-controls="MenuReposicaoPicking"
																>
																	Reposição de Picking
																</button>
															</h2>

															<div
																id="MenuReposicaoPicking"
																className="accordion-collapse collapse bg-white text-black"
															>
																<div className="accordion-body">
																	<div className="row">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>Menu</b>
																			</p>
																			<p>Reposição Primária</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarReposicaoPrimaria}
																				onChange={setVisualizarReposicaoPrimaria}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarReposicaoPrimaria"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																	<div className="row border-1 border-top border-primary">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>
																					<br />
																				</b>
																			</p>
																			<p>Reposição Complementar</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarReposicaoComplementar}
																				onChange={setVisualizarReposicaoComplementar}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarReposicaoComplementar"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className="accordion  bg-primary text-white mt-2">
														<div className="accordion-item">
															<h2 className="accordion-header">
																<button
																	className="accordion-button  bg-primary text-white"
																	type="button"
																	data-bs-toggle="collapse"
																	data-bs-target="#MenuImpressaoEtiqueta"
																	aria-expanded="true"
																	aria-controls="MenuImpressaoEtiqueta"
																>
																	Impressão de Etiquetas
																</button>
															</h2>

															<div
																id="MenuImpressaoEtiqueta"
																className="accordion-collapse collapse bg-white text-black"
															>
																<div className="accordion-body">
																	<div className="row">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>Menu</b>
																			</p>
																			<p>Impressão de Etiqueta</p>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarImpressaoEtiquetas}
																				onChange={controleAcessoImpressaoEtiquetaVisualizar}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarImpressaoEtiquetas"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">Reimpressão U.A.</p>
																			<CheckBox
																				checked={visualizarImpEtqReimpressaoUA}
																				onChange={controleAcessoImpressaoEtiquetaReimprimirUA}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarImpEtqReimpressaoUA"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">Gerar U.A. Vazio/Saída</p>
																			<CheckBox
																				checked={visualizarImpEtqGerarUAVazioSaida}
																				onChange={controleAcessoImpressaoEtiquetaGerarUAVazioSaida}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarImpEtqGerarUAVazioSaida"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">Endereço</p>
																			<CheckBox
																				checked={visualizarImpEtqImpressaoEndereco}
																				onChange={controleAcessoImpressaoEtiquetaImpressaoEndereco}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarImpEtqImpressaoEndereco"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">Produto Picking</p>
																			<CheckBox
																				checked={visualizarImpEtqImpressaoProdutoPicking}
																				onChange={controleAcessoImpressaoEtiquetaImpressaoProdutoPicking}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarImpEtqImpressaoProdutoPicking"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg text-center">
																			<p className="mb-0 pb-0">
																				<strong>Acesso Total</strong>
																			</p>
																			<CheckBox
																				checked={acessoTotalImpressaoEtiquetas}
																				onChange={controleAcessoTotalImpressaoEtiqueta}
																				disabled={params.acao || ''}
																				label=""
																				name="acessoTotalImpressaoEtiquetas"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="accordion mt-2 bg-primary text-white">
										<div className="accordion-item">
											<h2 className="accordion-header">
												<button
													className="accordion-button bg-primary  text-white"
													type="button"
													data-bs-toggle="collapse"
													data-bs-target="#MenuGerencial"
													aria-expanded="true"
													aria-controls="MenuGerencial"
												>
													Gerencial
												</button>
											</h2>
											<div id="MenuGerencial" className="accordion-collapse collapse bg-white text-primary">
												<div className="accordion-body">
													<div className="accordion  bg-primary text-white">
														<div className="accordion-item">
															<h2 className="accordion-header">
																<button
																	className="accordion-button  bg-primary text-white"
																	type="button"
																	data-bs-toggle="collapse"
																	data-bs-target="#MenuGerencialExecao"
																	aria-expanded="true"
																	aria-controls="MenuGerencialExecao"
																>
																	Exceção
																</button>
															</h2>

															<div id="MenuGerencialExecao" className="accordion-collapse collapse bg-white text-black">
																<div className="accordion-body">
																	<div className="row">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>Menu</b>
																			</p>
																			<p>Quebra-FEFO</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarExcecaoQuebraFefo}
																				onChange={setVisualizarExcecaoQuebraFefo}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarExcecaoQuebraFefo"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																	<div className="row border-1 border-top border-primary">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>
																					<br />
																				</b>
																			</p>
																			<p>Ler GTIN</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarExcecaoLerGtin}
																				onChange={setVisualizarExcecaoLerGtin}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarExcecaoLerGtin"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																	<div className="row border-1 border-top border-primary">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>
																					<br />
																				</b>
																			</p>
																			<p>Ler Volume</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarExcecaoLerVolume}
																				onChange={setVisualizarExcecaoLerVolume}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarExcecaoLerVolume"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																	<div className="row border-1 border-top border-primary">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>
																					<br />
																				</b>
																			</p>
																			<p>Endereco</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarExcecaoEndereco}
																				onChange={setVisualizarExcecaoEndereco}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarExcecaoEndereco"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																	<div className="row border-1 border-top border-primary">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>
																					<br />
																				</b>
																			</p>
																			<p>Zona de Endereçamento</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarExcecaoZonaEnderecamento}
																				onChange={setVisualizarExcecaoZonaEnderecamento}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarExcecaoZonaEnderecamento"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="accordion mt-2 bg-primary text-white">
										<div className="accordion-item">
											<h2 className="accordion-header">
												<button
													className="accordion-button bg-primary  text-white"
													type="button"
													data-bs-toggle="collapse"
													data-bs-target="#MenuRelatoros"
													aria-expanded="true"
													aria-controls="MenuRelatoros"
												>
													Relatórios
												</button>
											</h2>
											<div id="MenuRelatoros" className="accordion-collapse collapse bg-white text-primary">
												<div className="accordion-body">
													<div className="accordion  bg-primary text-white">
														<div className="accordion-item">
															<h2 className="accordion-header">
																<button
																	className="accordion-button  bg-primary text-white"
																	type="button"
																	data-bs-toggle="collapse"
																	data-bs-target="#MenuRelatoriosRastreabilidade"
																	aria-expanded="true"
																	aria-controls="MenuRelatoriosRastreabilidade"
																>
																	Rastreabilidade
																</button>
															</h2>

															<div
																id="MenuRelatoriosRastreabilidade"
																className="accordion-collapse collapse bg-white text-black"
															>
																<div className="accordion-body">
																	<div className="row">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>Menu</b>
																			</p>
																			<p>Rastreabilidade do Produto</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarRelRastreabilidadeProduto}
																				onChange={setVisualizarRelRastreabilidadeProduto}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarRelRastreabilidadeProduto"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className="accordion  bg-primary text-white mt-2">
														<div className="accordion-item">
															<h2 className="accordion-header">
																<button
																	className="accordion-button  bg-primary text-white"
																	type="button"
																	data-bs-toggle="collapse"
																	data-bs-target="#MenuRelatoriosPedidos"
																	aria-expanded="true"
																	aria-controls="MenuRelatoriosPedidos"
																>
																	Pedidos
																</button>
															</h2>

															<div
																id="MenuRelatoriosPedidos"
																className="accordion-collapse collapse bg-white text-black"
															>
																<div className="accordion-body">
																	<div className="row">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>Menu</b>
																			</p>
																			<p>Romaneio de Carga</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarRelRomaneioCarga}
																				onChange={setVisualizarRelRomaneioCarga}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarRelRomaneioCarga"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className="accordion  bg-primary text-white mt-2">
														<div className="accordion-item">
															<h2 className="accordion-header">
																<button
																	className="accordion-button  bg-primary text-white"
																	type="button"
																	data-bs-toggle="collapse"
																	data-bs-target="#MenuRelatoriosMovimentacao"
																	aria-expanded="true"
																	aria-controls="MenuRelatoriosMovimentacao"
																>
																	Movimentação
																</button>
															</h2>

															<div
																id="MenuRelatoriosMovimentacao"
																className="accordion-collapse collapse bg-white text-black"
															>
																<div className="accordion-body">
																	<div className="row">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>Menu</b>
																			</p>
																			<p>Movimentação Período</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarRelMovimentacaoPeriodo}
																				onChange={setVisualizarRelMovimentacaoPeriodo}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarRelMovimentacaoPeriodo"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																	<div className="row border-1 border-top border-primary">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>
																					<br />
																				</b>
																			</p>
																			<p>Curva ABC</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarRelCurvaABC}
																				onChange={setVisualizarRelCurvaABC}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarRelCurvaABC"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																	<div className="row border-1 border-top border-primary">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>
																					<br />
																				</b>
																			</p>
																			<p>Histórico de Endereçamento</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarRelHistoricoEnderecamento}
																				onChange={setVisualizarRelHistoricoEnderecamento}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarRelHistoricoEnderecamento"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="accordion mt-2 bg-primary text-white">
										<div className="accordion-item">
											<h2 className="accordion-header">
												<button
													className="accordion-button bg-primary  text-white"
													type="button"
													data-bs-toggle="collapse"
													data-bs-target="#MenuFaturamentoServicos"
													aria-expanded="true"
													aria-controls="MenuFaturamentoServicos"
												>
													Faturamento de Serviços
												</button>
											</h2>
											<div id="MenuFaturamentoServicos" className="accordion-collapse collapse bg-white text-primary">
												<div className="accordion-body">
													<div className="accordion  bg-primary text-white">
														<div className="accordion-item">
															<h2 className="accordion-header">
																<button
																	className="accordion-button  bg-primary text-white"
																	type="button"
																	data-bs-toggle="collapse"
																	data-bs-target="#MenuFaturamentoServicosTarifas"
																	aria-expanded="true"
																	aria-controls="MenuFaturamentoServicosTarifas"
																>
																	Tarifas
																</button>
															</h2>

															<div
																id="MenuFaturamentoServicosTarifas"
																className="accordion-collapse collapse bg-white text-black"
															>
																<div className="accordion-body">
																	<div className="row">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>Menu</b>
																			</p>
																			<p>Cadastrar Tarifas</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarCadastrarTarifas}
																				onChange={controleAcessoCadastrarTarifasVisualizar}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarCadastrarTarifas"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Criar</p>
																			<CheckBox
																				checked={criarCadastrarTarifas}
																				onChange={controleAcessoCadastrarTarifasCriar}
																				disabled={params.acao || ''}
																				label=""
																				name="criarCadastrarTarifas"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Editar</p>
																			<CheckBox
																				checked={editarCadastrarTarifas}
																				onChange={controleAcessoCadastrarTarifasEditar}
																				disabled={params.acao || ''}
																				label=""
																				name="editarCadastrarTarifas"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Excluir</p>
																			<CheckBox
																				checked={excluirCadastrarTarifas}
																				onChange={controleAcessoCadastrarTarifasExcluir}
																				disabled={params.acao || ''}
																				label=""
																				name="excluirCadastrarTarifas"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<strong>Acesso Total</strong>
																			</p>
																			<CheckBox
																				checked={acessoTotalCadastrarTarifas}
																				onChange={controleAcessoTotalCadastrarTarifas}
																				disabled={params.acao || ''}
																				label=""
																				name="acessoTotalCadastrarTarifas"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className="accordion  bg-primary text-white mt-2">
														<div className="accordion-item">
															<h2 className="accordion-header">
																<button
																	className="accordion-button  bg-primary text-white"
																	type="button"
																	data-bs-toggle="collapse"
																	data-bs-target="#MenuFaturamentoServicosCortePeriodo"
																	aria-expanded="true"
																	aria-controls="MenuFaturamentoServicosCortePeriodo"
																>
																	Corte Período
																</button>
															</h2>

															<div
																id="MenuFaturamentoServicosCortePeriodo"
																className="accordion-collapse collapse bg-white text-black"
															>
																<div className="accordion-body">
																	<div className="row">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>Menu</b>
																			</p>
																			<p>Gerar Corte</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarGerarCorte}
																				onChange={setVisualizarGerarCorte}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarGerarCorte"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																	<div className="row border-1 border-top border-primary">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>
																					<br />
																				</b>
																			</p>
																			<p>Estornar Corte</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarEstornarCorte}
																				onChange={setVisualizarEstornarCorte}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarEstornarCorte"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className="accordion  bg-primary text-white mt-2">
														<div className="accordion-item">
															<h2 className="accordion-header">
																<button
																	className="accordion-button  bg-primary text-white"
																	type="button"
																	data-bs-toggle="collapse"
																	data-bs-target="#MenuFaturamentoServicosEmissaoFaturas"
																	aria-expanded="true"
																	aria-controls="MenuFaturamentoServicosEmissaoFaturas"
																>
																	Emissão de Faturas
																</button>
															</h2>

															<div
																id="MenuFaturamentoServicosEmissaoFaturas"
																className="accordion-collapse collapse bg-white text-black"
															>
																<div className="accordion-body">
																	<div className="row">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>Menu</b>
																			</p>
																			<p>Emissão de Fatura</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarEmissaoFatura}
																				onChange={setVisualizarEmissaoFatura}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarEmissaoFatura"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className="accordion  bg-primary text-white mt-2">
														<div className="accordion-item">
															<h2 className="accordion-header">
																<button
																	className="accordion-button  bg-primary text-white"
																	type="button"
																	data-bs-toggle="collapse"
																	data-bs-target="#MenuFaturamentoServicosServicoExtra"
																	aria-expanded="true"
																	aria-controls="MenuFaturamentoServicosServicoExtra"
																>
																	Serviço Extra
																</button>
															</h2>

															<div
																id="MenuFaturamentoServicosServicoExtra"
																className="accordion-collapse collapse bg-white text-black"
															>
																<div className="accordion-body">
																	<div className="row">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>Menu</b>
																			</p>
																			<p>Lançar Serviço Extra</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarLancarServicoExtra}
																				onChange={setVisualizarLancarServicoExtra}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarLancarServicoExtra"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className="accordion  bg-primary text-white mt-2">
														<div className="accordion-item">
															<h2 className="accordion-header">
																<button
																	className="accordion-button  bg-primary text-white"
																	type="button"
																	data-bs-toggle="collapse"
																	data-bs-target="#MenuFaturamentoServicosLiberarFaturamento"
																	aria-expanded="true"
																	aria-controls="MenuFaturamentoServicosLiberarFaturamento"
																>
																	Liberar Faturamento
																</button>
															</h2>

															<div
																id="MenuFaturamentoServicosLiberarFaturamento"
																className="accordion-collapse collapse bg-white text-black"
															>
																<div className="accordion-body">
																	<div className="row">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>Menu</b>
																			</p>
																			<p>Bonificar Faturamento</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarBonificarFaturamento}
																				onChange={setVisualizarBonificarFaturamento}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarBonificarFaturamento"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="accordion mt-2 bg-primary text-white">
										<div className="accordion-item">
											<h2 className="accordion-header">
												<button
													className="accordion-button bg-primary  text-white"
													type="button"
													data-bs-toggle="collapse"
													data-bs-target="#MenuIndicadores"
													aria-expanded="true"
													aria-controls="MenuIndicadores"
												>
													Indicadores
												</button>
											</h2>
											<div id="MenuIndicadores" className="accordion-collapse collapse bg-white text-primary">
												<div className="accordion-body">
													<div className="row bg-white text-black">
														<div className="col-sm col-md col-lg-2 text-center">
															<p className="mb-0 pb-0">
																<b>Menu</b>
															</p>
															<p>Movimentação</p>
														</div>
														<div className="col-sm col-md col-lg-2 text-center">
															<p className="mb-0 pb-0">Visualizar</p>
															<CheckBox
																checked={visualizarIndicadoresMovimentacao}
																onChange={setVisualizarIndicadoresMovimentacao}
																disabled={params.acao || ''}
																label=""
																name="visualizarIndicadoresMovimentacao"
																className="form-check-input w-50"
																classNameDiv="form-check form-switch justify-content-center d-flex"
															/>
														</div>
													</div>

													<div className="row bg-white text-black border-1 border-top border-primary">
														<div className="col-sm col-md col-lg-2 text-center">
															<p className="mb-0 pb-0">
																<b>
																	<br />
																</b>
															</p>
															<p>Produtividade</p>
														</div>
														<div className="col-sm col-md col-lg-2 text-center">
															<p className="mb-0 pb-0">Visualizar</p>
															<CheckBox
																checked={visualizarIndicadoresProdutividade}
																onChange={setVisualizarIndicadoresProdutividade}
																disabled={params.acao || ''}
																label=""
																name="visualizarIndicadoresProdutividade"
																className="form-check-input w-50"
																classNameDiv="form-check form-switch justify-content-center d-flex"
															/>
														</div>
													</div>
													<div className="row bg-white text-black border-1 border-top border-primary">
														<div className="col-sm col-md col-lg-2 text-center">
															<p className="mb-0 pb-0">
																<b>
																	<br />
																</b>
															</p>
															<p>Acuracidade</p>
														</div>
														<div className="col-sm col-md col-lg-2 text-center">
															<p className="mb-0 pb-0">Visualizar</p>
															<CheckBox
																checked={visualizarIndicadoresAcuracidade}
																onChange={setVisualizarIndicadoresAcuracidade}
																disabled={params.acao || ''}
																label=""
																name="visualizarIndicadoresAcuracidade"
																className="form-check-input w-50"
																classNameDiv="form-check form-switch justify-content-center d-flex"
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="accordion mt-2 bg-primary text-white">
										<div className="accordion-item">
											<h2 className="accordion-header">
												<button
													className="accordion-button bg-primary  text-white"
													type="button"
													data-bs-toggle="collapse"
													data-bs-target="#MenuLogsSistema"
													aria-expanded="true"
													aria-controls="MenuLogsSistema"
												>
													Log's do Sistema
												</button>
											</h2>
											<div id="MenuLogsSistema" className="accordion-collapse collapse bg-white text-primary">
												<div className="accordion-body">
													<div className="accordion  bg-primary text-white">
														<div className="accordion-item">
															<h2 className="accordion-header">
																<button
																	className="accordion-button  bg-primary text-white"
																	type="button"
																	data-bs-toggle="collapse"
																	data-bs-target="#MenuLogsSistemaAcaoUsuario"
																	aria-expanded="true"
																	aria-controls="MenuLogsSistemaAcaoUsuario"
																>
																	Log's Ação do Usuário
																</button>
															</h2>

															<div
																id="MenuLogsSistemaAcaoUsuario"
																className="accordion-collapse collapse bg-white text-black"
															>
																<div className="accordion-body">
																	<div className="row bg-white text-black">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>Menu</b>
																			</p>
																			<p>Log de Ação do Usuário</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarLogAcaoUsuario}
																				onChange={setVisualizarLogAcaoUsuario}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarLogAcaoUsuario"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className="accordion  bg-primary text-white mt-2">
														<div className="accordion-item">
															<h2 className="accordion-header">
																<button
																	className="accordion-button  bg-primary text-white"
																	type="button"
																	data-bs-toggle="collapse"
																	data-bs-target="#MenuLogsSistemaIntegracao"
																	aria-expanded="true"
																	aria-controls="MenuLogsSistemaIntegracao"
																>
																	Log's de Integração
																</button>
															</h2>

															<div
																id="MenuLogsSistemaIntegracao"
																className="accordion-collapse collapse bg-white text-black"
															>
																<div className="accordion-body">
																	<div className="row bg-white text-black border-1 border-top border-primary">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>Menu</b>
																			</p>
																			<p>Log API Pedidos</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarLogApiPedidos}
																				onChange={setVisualizarLogApiPedidos}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarLogApiPedidos"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																	<div className="row bg-white text-black border-1 border-top border-primary">
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">
																				<b>
																					<br />
																				</b>
																			</p>
																			<p>Log Integração</p>
																		</div>
																		<div className="col-sm col-md col-lg-2 text-center">
																			<p className="mb-0 pb-0">Visualizar</p>
																			<CheckBox
																				checked={visualizarLogIntegracao}
																				onChange={setVisualizarLogIntegracao}
																				disabled={params.acao || ''}
																				label=""
																				name="visualizarLogIntegracao"
																				className="form-check-input w-50"
																				classNameDiv="form-check form-switch justify-content-center d-flex"
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div
									className="tab-pane fade"
									id="pills-especiais"
									role="tabpanel"
									aria-labelledby="pills-permissoesespeciais-tab"
									tabIndex={0}
								>
									<div className="accordion bg-primary text-white">
										<div className="accordion-item ">
											<h2 className="accordion-header">
												<button
													className="accordion-button bg-primary text-white"
													type="button"
													data-bs-toggle="collapse"
													data-bs-target="#permEspeciaisProcEntrada"
													aria-expanded="true"
													aria-controls="permEspeciaisProcEntrada"
												>
													Processo Entrada
												</button>
											</h2>
											<div id="permEspeciaisProcEntrada" className="accordion-collapse collapse bg-white text-black">
												<div className="row mb-1 m-1">
													<div className="col-sm-6">
														<div className="form-floating mb-1 m-1">
															<CheckBox
																label="Aprova Divergência Quantidade no Recebimento"
																name="procEntradaAprovaDivQtdeReceb"
																checked={procEntradaAprovaDivQtdeReceb}
																onChange={setProcEntradaAprovaDivQtdeReceb}
																disabled={params.acao || ''}
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="accordion mt-1 bg-primary text-white">
										<div className="accordion-item ">
											<h2 className="accordion-header">
												<button
													className="accordion-button bg-primary text-white"
													type="button"
													data-bs-toggle="collapse"
													data-bs-target="#permEspeciaisEnderecamento"
													aria-expanded="true"
													aria-controls="permEspeciaisEnderecamento"
												>
													Endereçamento
												</button>
											</h2>
											<div id="permEspeciaisEnderecamento" className="accordion-collapse collapse bg-white text-black">
												<div className="row mb-1 m-1">
													<div className="col-sm-6">
														<div className="form-floating mb-1 m-1">
															<CheckBox
																label="Exceção de Endereço"
																name="endExcecaoEndereco"
																checked={endExcecaoEndereco}
																onChange={setEndExcecaoEndereco}
																disabled={params.acao || ''}
															/>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-floating mb-1 m-1">
															<CheckBox
																label="Exceção de Zona de Endereçamento"
																name="endExcecaoZonaEnderecamento"
																checked={endExcecaoZonaEnderecamento}
																onChange={setEndExcecaoZonaEnderecamento}
																disabled={params.acao || ''}
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="accordion mt-1 bg-primary text-white">
										<div className="accordion-item ">
											<h2 className="accordion-header">
												<button
													className="accordion-button bg-primary text-white"
													type="button"
													data-bs-toggle="collapse"
													data-bs-target="#permEspeciaisPedidos"
													aria-expanded="true"
													aria-controls="permEspeciaisPedidos"
												>
													Pedidos
												</button>
											</h2>
											<div id="permEspeciaisPedidos" className="accordion-collapse collapse bg-white text-black">
												<div className="row mb-1 m-1">
													<div className="col-sm-6">
														<div className="form-floating mb-1 m-1">
															<CheckBox
																label="Manutenção de Pedidos"
																name="pedidoManutencaoPedido"
																checked={pedidoManutencaoPedido}
																onChange={setPedidoManutencaoPedido}
																disabled={params.acao || ''}
															/>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-floating mb-1 m-1">
															<CheckBox
																label="Autoriza de Quebra de FEFO Picking"
																name="pedidoAutorizaQuebraFefoPicking"
																checked={pedidoAutorizaQuebraFefoPicking}
																onChange={setPedidoAutorizaQuebraFefoPicking}
																disabled={params.acao || ''}
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="accordion mt-1 bg-primary text-white">
										<div className="accordion-item ">
											<h2 className="accordion-header">
												<button
													className="accordion-button bg-primary text-white"
													type="button"
													data-bs-toggle="collapse"
													data-bs-target="#permEspeciaisConfCarga"
													aria-expanded="true"
													aria-controls="permEspeciaisConfCarga"
												>
													Conferência de Carga
												</button>
											</h2>
											<div id="permEspeciaisConfCarga" className="accordion-collapse collapse bg-white text-black">
												<div className="row mb-1 m-1">
													<div className="col-sm-6">
														<div className="form-floating mb-1 m-1">
															<CheckBox
																label="Exceção de Leitura de Gtin"
																name="confCargaExcLeituraGtin"
																checked={confCargaExcLeituraGtin}
																onChange={setConfCargaExcLeituraGtin}
																disabled={params.acao || ''}
															/>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-floating mb-1 m-1">
															<CheckBox
																label="Exceção de Leitura de Volume"
																name="confCargaExcLeituraVolume"
																checked={confCargaExcLeituraVolume}
																onChange={setConfCargaExcLeituraVolume}
																disabled={params.acao || ''}
															/>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-floating mb-1 m-1">
															<CheckBox
																label="Aprova Validade Vencida"
																name="confCargaAprovaValVencida"
																checked={aprovaValidadeVencida}
																onChange={setAprovaValidadeVencida}
																disabled={params.acao || ''}
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="accordion mt-1 bg-primary text-white">
										<div className="accordion-item ">
											<h2 className="accordion-header">
												<button
													className="accordion-button bg-primary text-white"
													type="button"
													data-bs-toggle="collapse"
													data-bs-target="#permEspeciaisPesagem"
													aria-expanded="true"
													aria-controls="permEspeciaisPesagem"
												>
													Pesagem
												</button>
											</h2>
											<div id="permEspeciaisPesagem" className="accordion-collapse collapse bg-white text-black">
												<div className="row mb-1 m-1">
													<div className="col-sm-6">
														<div className="form-floating mb-1 m-1">
															<CheckBox
																label="Aprova Divergência de Balança Rodoviária"
																name="pesagemAprovaDivBalanRodviaria"
																checked={pesagemAprovaDivBalanRodviaria}
																onChange={setPesagemAprovaDivBalanRodviaria}
																disabled={params.acao || ''}
															/>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-floating mb-1 m-1">
															<CheckBox
																label="Aprova Divergência de Balança de O.S"
																name="pesagemAprovaDivBalancaOS"
																checked={pesagemAprovaDivBalancaOS}
																onChange={setPesagemAprovaDivBalancaOS}
																disabled={params.acao || ''}
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="accordion mt-1 bg-primary text-white">
										<div className="accordion-item ">
											<h2 className="accordion-header">
												<button
													className="accordion-button bg-primary text-white"
													type="button"
													data-bs-toggle="collapse"
													data-bs-target="#permEspeciaisEstoque"
													aria-expanded="true"
													aria-controls="permEspeciaisEstoque"
												>
													Estoque
												</button>
											</h2>
											<div id="permEspeciaisEstoque" className="accordion-collapse collapse bg-white text-black">
												<div className="row mb-1 m-1">
													<div className="col-sm-6">
														<div className="form-floating mb-1 m-1">
															<CheckBox
																label="Realiza Ajustes Qualitativos"
																name="estoqueRealizaAjusteQualitativo"
																checked={estoqueRealizaAjusteQualitativo}
																onChange={setEstoqueRealizaAjusteQualitativo}
																disabled={params.acao || ''}
															/>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-floating mb-1 m-1">
															<CheckBox
																label="Realiza Ajustes Quantitativos"
																name="estoqueRealizaAjusteQuantitativo"
																checked={estoqueRealizaAjusteQuantitativo}
																onChange={setEstoqueRealizaAjusteQuantitativo}
																disabled={params.acao || ''}
															/>
														</div>
													</div>
												</div>
												<div className="row mb-1 m-1">
													<div className="col-sm-6">
														<div className="form-floating mb-1 m-1">
															<CheckBox
																label="Realiza Desfragmentação de Estoque"
																name="estoqueRealizaDesfragEstoque"
																checked={estoqueRealizaDesfragEstoque}
																onChange={setEstoqueRealizaDesfragEstoque}
																disabled={params.acao || ''}
															/>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-floating mb-1 m-1">
															<CheckBox
																label="Altera Dados de Barra"
																name="estoqueAlteraDadosBarra"
																checked={estoqueAlteraDadosBarra}
																onChange={setEstoqueAlteraDadosBarra}
																disabled={params.acao || ''}
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="accordion mt-1 bg-primary text-white">
										<div className="accordion-item ">
											<h2 className="accordion-header">
												<button
													className="accordion-button bg-primary text-white"
													type="button"
													data-bs-toggle="collapse"
													data-bs-target="#permEspeciaisInventario"
													aria-expanded="true"
													aria-controls="permEspeciaisInventario"
												>
													Inventário
												</button>
											</h2>
											<div id="permEspeciaisInventario" className="accordion-collapse collapse bg-white text-black">
												<div className="row mb-1 m-1">
													<div className="col-sm-6">
														<div className="form-floating mb-1 m-1">
															<CheckBox
																label="Define Tolerância de Quebra de Inventário"
																name="inventarioDefineToleranciaQuebraInv"
																checked={inventarioDefineToleranciaQuebraInv}
																onChange={setInventarioDefineToleranciaQuebraInv}
																disabled={params.acao || ''}
															/>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-floating mb-1 m-1">
															<CheckBox
																label="Aprova Divergência de Inventário Acima da Tolerância"
																name="inventarioAprovaDivergenciaInventarioAcimaTolerancia"
																checked={inventarioAprovaDivergenciaInventarioAcimaTolerancia}
																onChange={setInventarioAprovaDivergenciaInventarioAcimaTolerancia}
																disabled={params.acao || ''}
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="accordion mt-1 bg-primary text-white">
										<div className="accordion-item ">
											<h2 className="accordion-header">
												<button
													className="accordion-button bg-primary text-white"
													type="button"
													data-bs-toggle="collapse"
													data-bs-target="#permEspeciaisAjustes"
													aria-expanded="true"
													aria-controls="permEspeciaisAjustes"
												>
													Ajustes
												</button>
											</h2>
											<div id="permEspeciaisAjustes" className="accordion-collapse collapse bg-white text-black">
												<div className="row mb-1 m-1">
													<div className="col-sm-6">
														<div className="form-floating mb-1 m-1">
															<CheckBox
																label="Ajuste Estoque"
																name="ajusteEstoque"
																checked={ajusteEstoque}
																onChange={setAjusteEstoque}
																disabled={params.acao || ''}
															/>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-floating mb-1 m-1">
															<CheckBox
																label=" Ajuste Entrada"
																name="ajusteEntrada"
																checked={ajusteEntrada}
																onChange={setAjusteEntrada}
																disabled={params.acao || ''}
															/>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="form-floating mb-1 m-1">
															<CheckBox
																label="Ajuste Movimentação"
																name="ajusteMovimentacao"
																checked={ajusteMovimentacao}
																onChange={setAjusteMovimentacao}
																disabled={params.acao || ''}
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="card-footer mt-2">
								<div className="col-sm">
									<div className="text-sm-end">
										<BotaoCustomizado
											type="button"
											className="btn btn-light text-danger bg-white border-0"
											descricaoBotao="Voltar"
											icone={mdiKeyboardReturn}
											onclick={() => {
												navigate(-1);
											}}
										/>
										<BotaoCustomizado
											type="submit"
											className="btn btn-light text-info-emphasis bg-white border-0"
											descricaoBotao="Salvar"
											icone={mdiCheckCircle}
											disabled={params.acao === 'criar' ? false : params.acao === 'editar' ? false : true}
										/>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			<ModalCarregando mensagem="Carregando..." mostrar={showModalCarregando} />
			<ModalConfirmacao
				msgPrincipal="Deseja criar esse perfil para todas as filiais ou apenas para filial Logada ?"
				msgAcao1="Todas Filiais"
				msgAcao2="Filial Logada"
				Acao1={function () {
					CriarPerfil('0');
				}}
				Acao2={function () {
					CriarPerfil(id_filial);
				}}
				mostrar={modalConfirmacao}
			/>
		</>
	);
};

export { PerfilPermissaoForm };
