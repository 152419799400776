import { Routes, Route, Navigate } from 'react-router-dom';
import { MainPage } from './components/mainPage';
import { Login } from './pages/login/login';
import { AtividadeUsuarios } from './pages/administrativo/controleDeAcessos/atividadeUsuarios/atividadeUsuarios';
import { NovoUsuario } from './pages/administrativo/controleDeAcessos/usuarios/novoUsuario';
import { Usuarios } from './pages/administrativo/controleDeAcessos/usuarios/usuarios';
import { PerfilPermissaoForm } from './pages/administrativo/controleDeAcessos/PerfilPermissao/perfilPermissaoForm';
import { PerfilPermissoes } from './pages/administrativo/controleDeAcessos/PerfilPermissao/perfilPermissoes';
import { Integracao } from './pages/configuracoes/cadastro/integracao/integracao';

import { NovosUtilitarios } from './pages/configuracoes/cadastro/utilitarios/novosUtilitarios';
import { Utilitarios } from './pages/configuracoes/cadastro/utilitarios/utilitarios';
import { Colaborador } from './pages/configuracoes/cadastro/colaborador/colaborador';
import { NovoColaborador } from './pages/configuracoes/cadastro/colaborador/novoColaborador';
import { Depositante } from './pages/cadastros/Depositante/depositante';
import { NovoDepositante } from './pages/cadastros/Depositante/novoDepositante';
import { Embalagem } from './pages/configuracoes/cadastro/embalagem/embalagem';
import { NovaEmbalagem } from './pages/configuracoes/cadastro/embalagem/novaEmbalagem';
import { Endereco } from './pages/configuracoes/cadastro/endereco/endereco';
import { NovoEndereco } from './pages/configuracoes/cadastro/endereco/novoEndereco';
import { VisualizarEndereco } from './pages/configuracoes/cadastro/endereco/visualizarEndereço';
import { EditarEndereco } from './pages/configuracoes/cadastro/endereco/editarEndereco';
import { Entidade } from './pages/cadastros/Entidade/entidade/entidade';
import { NovaEntidade } from './pages/cadastros/Entidade/entidade/novaEntidade';
import { Filial } from './pages/administrativo/CadAdministrativo/filial/filial';
import { NovaFilial } from './pages/administrativo/CadAdministrativo/filial/novaFilial';
import { Transportadora } from './pages/cadastros/Entidade/Transportadora/transportadora';
// import { NovoVeiculo } from './pages/configuracoes/cadastro/veiculo/novoVeiculo';
// import { Veiculo } from './pages/configuracoes/cadastro/veiculo/veiculo';
//import { ImportacaoDeNotasFiscais } from './pages/pco/controleFiscal/controleNotasFiscais/importacaoDeNotasFiscais/importacaoDeNotasFiscais';
import { ImportNotasFiscaisF } from './pages/admOperacional/FiscalEntrada/nfeEntradaArmz/importacaoNotasFiscais';
import { NotasFiscaisArmazenagem } from './pages/pco/controleFiscal/controleNotasFiscais/notasFiscaisArmazenagem/notasFiscaisArmazenagem';
import { NovasNotasFiscaisArmazenagem } from './pages/pco/controleFiscal/controleNotasFiscais/notasFiscaisArmazenagem/novasNotasFiscaisArmazenagem';
import { NotasFiscaisCrossDocking } from './pages/pco/controleFiscal/controleNotasFiscais/notasFiscaisCrossDocking/notasFiscaisCrossDocking';
import { NovasNotasFiscaisCrossDocking } from './pages/pco/controleFiscal/controleNotasFiscais/notasFiscaisCrossDocking/novasNotasFiscaisCrossDocking';
import { ProcessoEntrada } from './pages/pco/processosVeiculos/processoEntrada/processoEntrada';
import { ProcessoSaida } from './pages/pco/processosVeiculos/processoSaida/processoSaida';
import { NovoProcessoSaida } from './pages/pco/processosVeiculos/processoSaida/novoProcessoSaida';
import { Ocorrencia } from './pages/pco/processosVeiculos/ocorrencias/ocorrencias';
import { NovasOcorrencias } from './pages/pco/processosVeiculos/ocorrencias/novasOcorrencias';
import { MovimentoSaidaNF } from './pages/pco/processosVeiculos/movimentoSaidaNF/movimentoSaidaNF';
import { ListagemDosPedidos } from './pages/pco/gestaoDePedidos/listagemDosPedidos/listagemDosPedidos';
import { NovaListagemDosPedidos } from './pages/pco/gestaoDePedidos/listagemDosPedidos/novaListagemDosPedidos';
import { VisualizarPedido } from './pages/pco/gestaoDePedidos/listagemDosPedidos/visualizarPedido';
import { EditarPedido } from './pages/pco/gestaoDePedidos/listagemDosPedidos/editarPedido';
import { OSProgramada } from './pages/pco/gestaoDePedidos/listagemDosPedidos/OSProgramada';
import { IntegracaoSaida } from './pages/pco/gestaoDePedidos/integracaoSaida/integracaoSaida';
import { NovaIntegracaoSaida } from './pages/pco/gestaoDePedidos/integracaoSaida/novaIntegracaoSaida';
import { RomaneioSaida } from './pages/pco/gestaoDePedidos/romaneioSaida/romaneioSaida';
import EstoqueDetalhado from './pages/estoque/estoqueDetalhado/estoqueDetalhado';
import EstoqueSintetico from './pages/estoque/estoqueSintetico/estoqueSintetico';
import { ConsultaCodigoBarras } from './pages/gestaoEstoque/codigoBarras/consultaCodigoBarras';

import { ConferenciaEntrada } from './pages/operacao/conferenciaEntrada/conferenciaEntrada/conferenciaEntrada';
import { NovaConferenciaEntrada } from './pages/operacao/conferenciaEntrada/conferenciaEntrada/novaConferenciaEntrada';
import { EmbalagemSemTexto } from './pages/operacao/conferenciaEntrada/embalagemSemTexto/embalagemSemTexto';
import { NovaEmbalagemSemTexto } from './pages/operacao/conferenciaEntrada/embalagemSemTexto/novaEmbalagemSemTexto';
import { ConferenciaSaida } from './pages/operacao/conferenciaSaida/conferenciaSaida';
import { NovaConferenciaSaida } from './pages/operacao/conferenciaSaida/novaConferenciaSaida';
import { Enderecamento } from './pages/operacao/enderecamento/enderecamento';
import { DesfragmentacaoEstoque } from './pages/operacao/desfragmentacaoEstoque/desfragmentacaoEstoque';
import { NovaDesfragmentacaoEstoque } from './pages/operacao/desfragmentacaoEstoque/novaDesfragmentacaoEstoque';
import { HistoricoEnderecamento } from './pages/relatorios/movimentacao/historicoEnderecamento/historicoEnderecamento';
import { PalletSemEnderecamento } from './pages/gerenciamento/enderecamento/palletSemEnderecamento/palletSemEnderecamento';
import { NovoPalletSemEnderecamento } from './pages/gerenciamento/enderecamento/palletSemEnderecamento/novoPalletSemEnderecamento';
import { AutorizacaoEntrada } from './pages/gerenciamento/processos/autorizacaoEntrada/autorizacaoEntrada';
import { NovaAutorizacaoEntrada } from './pages/gerenciamento/processos/autorizacaoEntrada/novaAutorizacaoEntrada';
import { BarraPallet } from './pages/gerenciamento/estoque/barraPallet/barraPallet';
import { NovaBarraPallet } from './pages/gerenciamento/estoque/barraPallet/novaBarraPallet';
import { CadastroTarifas } from './pages/faturamento/manutencao/cadastroTarifas';
import { NovoCadastroTarifas } from './pages/faturamento/manutencao/novoCadastroTarifas';
import MovimentacaoPeriodo from './pages/relatorios/movimentacao/movimentacaoPeriodo/movimentacaoPeriodo';
import { EditarEmbalagem } from './pages/configuracoes/cadastro/embalagem/editarEmbalagem';
import { VisualizarEmbalagem } from './pages/configuracoes/cadastro/embalagem/visualizarEmbalagem';
import { EditarProduto } from './pages/configuracoes/cadastro/produto/editarProduto';
import { VisualizarProduto } from './pages/configuracoes/cadastro/produto/visualizarProduto';
import { EditarColaborador } from './pages/configuracoes/cadastro/colaborador/editarColaborador';
import { VisualizarColaborador } from './pages/configuracoes/cadastro/colaborador/visualizarColaborador';
import { ProcessoEntradaIncluir } from './pages/pco/processosVeiculos/processoEntrada/processoEntradaIncluir';
import { Pedidos } from './pages/pco/gestaoDePedidos/pedidos/Pedidos';
import { PedidosNovo } from './pages/pco/gestaoDePedidos/pedidos/PedidosNovo';
import { PedidosEditar } from './pages/pco/gestaoDePedidos/pedidos/PedidosEditar';
import { PedidosVisualizar } from './pages/pco/gestaoDePedidos/pedidos/PedidosVisualizar';
import { ProcessoEntradaEditar } from './pages/pco/processosVeiculos/processoEntrada/processoEntradaEditar';
import { DetalharNotaFiscal } from './pages/pco/controleFiscal/controleNotasFiscais/importacaoDeNotasFiscais/importacaodeNotasFiscaisDetalhar';
import { VerificaEstoque } from './pages/pco/gestaoDePedidos/pedidos/Programacao/VerificaEstoque';
import { ProgramarManual } from './pages/pco/gestaoDePedidos/pedidos/Programacao/ProgramarManual';
import { CadastroCFOP } from './pages/pco/controleFiscal/cadastroCFOP/cadastroCFOP';
import { CadastroCFOPVisualizar } from './pages/pco/controleFiscal/cadastroCFOP/cadastroCFOPVisualizar';
import { CadastroCFOPEditar } from './pages/pco/controleFiscal/cadastroCFOP/cadatroCFOPEditar';
import { CadastroCFOPIncluir } from './pages/pco/controleFiscal/cadastroCFOP/cadastroCFOPIncluir';
import { CadastroCFOPEntradaXsaida } from './pages/pco/controleFiscal/CFOP Entrada X Saida/cadastrosCFOP';
import { CadastroCFOPIncluirEntradaXSaida } from './pages/pco/controleFiscal/CFOP Entrada X Saida/cadastroCFOPEntradaXSaida';
import { CadastroCFOPxSaidaVisualizar } from './pages/pco/controleFiscal/CFOP Entrada X Saida/visualizarCfopXsaida';
import { EditarCFOPEntradaXSaida } from './pages/pco/controleFiscal/CFOP Entrada X Saida/editarCfopxSaida';
import GruposTributarios from './pages/pco/controleFiscal/GrupoTributário/gruposTributarios';
import NovoGrupo from './pages/pco/controleFiscal/GrupoTributário/novoGrupo';
import EditarGrupo from './pages/pco/controleFiscal/GrupoTributário/editarGrupo';
import VerGrupo from './pages/pco/controleFiscal/GrupoTributário/verGrupo';
import { Veiculos } from './pages/cadastros/veiculo/veiculo/veiculos';
import ListaFiliais from './pages/administrativo/controleDeAcessos/usuariosFilial/ListaFiliais';
import { NovoVeiculo } from './pages/cadastros/veiculo/veiculo/novoVeiculo';
import { Motoristas } from './pages/cadastros/veiculo/motorista/motoristas';
import { NovoMotorista } from './pages/cadastros/veiculo/motorista/novoMotorista';
import { UsuariosFilial } from './pages/administrativo/controleDeAcessos/usuariosFilial/usuariosFilial';
import { ProdutosdeArmazenagem } from './pages/cadastros/produtos/produtosDeArmazenagem/produtosDeArmazenagem';
import { NovoProdutosdeArmazenagem } from './pages/cadastros/produtos/produtosDeArmazenagem/novoProdutodeArmazenagem';
import VerDetalhesNota from './pages/admOperacional/FiscalEntrada/nfeEntradaArmz/verDetalhesNota';
import ProcessosDeEntrada from './pages/admOperacional/FiscalEntrada/controleProcesso/processoEntrada/ProcessosEntrada';
import ProcessoEntradaForm from './pages/admOperacional/FiscalEntrada/controleProcesso/processoEntrada/ProcessoEntradaForm';
import { ControleNumeracao } from './pages/administrativo/CadAdministrativo/controleNumeracao/controleNumeracao';
import { Equipamento } from './pages/cadastros/equipamento/equipamento/equipamento';
import { NovoEquipamento } from './pages/cadastros/equipamento/equipamento/novoEquipamento';
import { CodigosDoSistema } from './pages/administrativo/codigosDoSistema/CodigosDoSistema/codigosDoSistema';

import { NovoControleDeNumeracao } from './pages/administrativo/CadAdministrativo/controleNumeracao/novoControleNumeracao';
import { NovoCodigosDoSistema } from './pages/administrativo/codigosDoSistema/CodigosDoSistema/novoCodigosDoSistema';
import { ProdutosDeFornecedor } from './pages/cadastros/produtos/produtosDeFornecedor/produtosDeFornecedor';
import { NovoProdutoDeFornecedor } from './pages/cadastros/produtos/produtosDeFornecedor/novoProdutodeFornecedor';
import { EnderecoArmazem } from './pages/cadastros/Endereco/enderecoArmazem/enderecoArmazem';
import { NovoEnderecoArmazem } from './pages/cadastros/Endereco/enderecoArmazem/novoEnderecoArmazem';
import { CFOPs } from './pages/cadastros/fiscal/cfop/cfop';
import { CFOPEntradaSaida } from './pages/cadastros/fiscal/cfopentradaxsaida/cfopentradaxsaida';
import { NovoCFOP } from './pages/cadastros/fiscal/cfop/novoCFOP';
import { NovoCFOPEntradaxSaida } from './pages/cadastros/fiscal/cfopentradaxsaida/novoCFOPEntradaxSaida';
import { ProdutosDePicking } from './pages/cadastros/produtos/produtosDePicking/produtosDePicking';
import { NovoProdutoDePicking } from './pages/cadastros/produtos/produtosDePicking/novoProdutodePicking';
import CriacaoDePedidos from './pages/admOperacional/Pedidos/CriacaoDePedidos/criacaoDePedidos';
import PedidosPendentes from './pages/admOperacional/Pedidos/pedidosPendentes/pedidosPendentes';
import PedidosSeparacao from './pages/admOperacional/Pedidos/pedidosSeparacao/pedidosSeparacao';
import { ExecaoQuebraFEFO } from './pages/gerencial/excecao/quebraFefo/quebraFefo';
import { ExcecaoLerGtinCarga } from './pages/gerencial/excecao/lerGtinCarga/lerGtinCarga';
import { ExcecaoLerVolumeCarga } from './pages/gerencial/excecao/lerVolumeCarga/lerVolumeCarga';
import { ExcecaoEndereco } from './pages/gerencial/excecao/endereco/endereco';
import { ExcecaoZona } from './pages/gerencial/excecao/zona/zona';
import AjusteEstoque from './pages/admOperacional/ajustes/ajusteDeEstoque/ajusteEstoque';
import { RomaneioBarrasAtivas } from './pages/estoque/romaneioBarrasAtivas/romaneioBarrasAtivas';
import { ImpressaoEtiqueta } from './pages/operacional/impressaoEtiqueta/impressaoEtiqueta';
import AjusteDeMovimento from './pages/admOperacional/ajustes/ajusteDeMovimentacao/ajusteDeMovimento';
import { UASemUso } from './pages/estoque/UASemUso/uaSemUso';
import { AdmOperacionalFiscalEntradaNFeEntradaCrossDocking } from './pages/admOperacional/FiscalEntrada/nfeEntradaCrossDocking/nfeEntradaCrossDocking';
import AdmOperacionalFiscalEntradaNFeEntradaCrossDockingItem from './pages/admOperacional/FiscalEntrada/nfeEntradaCrossDocking/nfeEntradaCrossDockingItem';
import { AdmOperacionalFiscalEntradaNFePedido } from './pages/admOperacional/FiscalEntrada/nfePedido/nfePedido';
import AdmOperacionalFiscalEntradaNFePedidoItem from './pages/admOperacional/FiscalEntrada/nfePedido/nfePedidoItem';
import { LogSistemaIntegracao } from './pages/logSistema/logIntegracao/logIntegracao';
import { LogSistemaAcaoUsuario } from './pages/logSistema/logAcaoUsuario/logAcaoUsuario';
import { LogSistemaIntegracaoAPIPedidos } from './pages/logSistema/logIntegracaoApiPedidos/logIntegracaoApiPedidos';
import TarefasList from './pages/admOperacional/Pedidos/tarefas/tarefasList';
import OSpendetenEmbarque from './pages/admOperacional/Pedidos/osPendenteEmbarque/osPendenteEmbarque';
import ReposicaoPrimaria from './pages/operacional/reposicaoPicking/reposicaoPrimaria';



function App() {
	return (
		<div className="App">
			<div className="wrapper">
				<Routes>
					<Route path="/login" element={<Login />} />

					<Route path="/" element={<MainPage />}>
						{/* SEGURANÇA */}

						<Route path="config/segur/cadastroDePermissoes" element={<PerfilPermissoes />} />
						<Route path="config/segur/cadastroDePermissoes/:acao/:id/:id_filial" element={<PerfilPermissaoForm />} />

						<Route path="config/segur/usuarios" element={<Usuarios />} />
						<Route path="config/segur/usuarios/:acao/:id" element={<NovoUsuario />} />


						<Route path="config/segur/atividadeUsuarios" element={<AtividadeUsuarios />} />

						{/* ADMINISTRATICO -> CODIGOS DO SISTEMA */}
						<Route path="admin/codsis/codsis" element={<CodigosDoSistema />} />
						<Route path="admin/codsis/codsis/:id/:acao" element={<NovoCodigosDoSistema />} />
						{/* CADASTRO */}
						<Route path="config/cadast/filial" element={<Filial />} />
						<Route path="config/cadast/filial/:id/:acao" element={<NovaFilial />} />
						{/* ADMINISTRATIVO -> CAD. ADMINISTRATIVO -> CONTROLE NUMERACAO*/}
						<Route path="config/cadast/controlenumeracao" element={<ControleNumeracao />} />
						<Route path="config/cadast/controlenumeracao/:id/:acao" element={<NovoControleDeNumeracao />} />

						<Route path="config/cadast/depositante" element={<Depositante />} />
						<Route path="config/cadast/depositante/:id/:acao" element={<NovoDepositante />} />

						<Route path="config/cadast/entidade" element={<Entidade />} />
						<Route path="config/cadast/entidade/:id/:acao" element={<NovaEntidade />} />

						<Route path="config/cadast/transportadora" element={<Transportadora />} />

						<Route path="cadast/veiculo/veiculo" element={<Veiculos />} />
						<Route path="cadast/veiculo/veiculo/:id/:acao" element={<NovoVeiculo />} />

						<Route path="cadast/veiculo/motorista" element={<Motoristas />} />
						<Route path="cadast/veiculo/motorista/:id/:acao" element={<NovoMotorista />} />

						<Route path="cadast/equipamento/equipamento" element={<Equipamento />} />
						<Route path="cadast/equipamento/equipamento/:id/:acao" element={<NovoEquipamento />} />

						<Route path="config/cadast/embalagem" element={<Embalagem />} />
						<Route path="config/cadast/embalagem/:id" element={<NovaEmbalagem />} />
						<Route path="config/cadast/embalagem/editar/:id" element={<EditarEmbalagem />} />
						<Route path="config/cadast/embalagem/visu/:id" element={<VisualizarEmbalagem />} />

						<Route path="cadast/produto/prodarm" element={<ProdutosdeArmazenagem />} />
						<Route path="cadast/produto/prodarm/:id/:acao" element={<NovoProdutosdeArmazenagem />} />

						<Route path="cadast/produto/prodforn" element={<ProdutosDeFornecedor />} />
						<Route path="cadast/produto/prodforn/:id/:acao" element={<NovoProdutoDeFornecedor />} />

						<Route path="cadast/produto/prodpick" element={<ProdutosDePicking />} />
						<Route path="cadast/produto/prodpick/:id/:acao" element={<NovoProdutoDePicking />} />

						{/* CADASTRO -> ENDERECOS -> ENDERECO DE ARMAZEM */}
						<Route path="cadast/endereco/enderecoarm" element={<EnderecoArmazem />} />
						<Route path="cadast/endereco/enderecoarm/:id/:acao" element={<NovoEnderecoArmazem />} />

						{/* <Route path="itemAlternativo" element={<ItemAlternativo />} />
							<Route path="itemAlternativo/:id" element={<NovoItemAlternativo />} /> */}
						{/* </Route> */}

						<Route path="config/cadast/endereco" element={<Endereco />} />
						<Route path="config/cadast/endereco/:id" element={<NovoEndereco />} />
						<Route path="config/cadast/endereco/visu/:endereco/:filial" element={<VisualizarEndereco />} />
						<Route path="config/cadast/editar-endereco/:endereco/:filial" element={<EditarEndereco />} />

						<Route path="config/cadast/colaborador" element={<Colaborador />} />
						<Route path="config/cadast/colaborador/:id" element={<NovoColaborador />} />
						<Route path="config/cadast/colaborador/editar/:id" element={<EditarColaborador />} />
						<Route path="config/cadast/colaborador/visu/:id" element={<VisualizarColaborador />} />

						<Route path="config/cadast/utilitarios" element={<Utilitarios />} />
						<Route path="config/cadast/utilitarios/:id" element={<NovosUtilitarios />} />

						{/* <Route path="config/cadast/codigosDoSistema" element={<CodigosDoSistema />} />
						<Route path="config/cadast/codigosDoSistema/:id" element={<NovosCodigosDoSistema />} />
						<Route path="config/cadast/codigosDoSistema/visu/:id" element={<VisualizarCodigosDoSistema />} />
						<Route path="config/cadast/codigosDoSistema/editar/:id" element={<EditarCodigosDoSistema />} /> */}

						{/* <Route path="config/cadast/controleNumeracao" element={<ControleNumeracao />} />
						<Route path="config/cadast/controleNumeracao/:id" element={<NovoControleNumeracao />} />
						<Route path="config/cadast/controleNumeracao/visu/:id" element={<VisualizarControleNumeracao />} />
						<Route path="config/cadast/controleNumeracao/editar/:id" element={<EditarControleNumeracao />} /> */}

						{/* CADASTRO -> FISCAL -> CFOP */}
						<Route path="cadast/fiscal/cfop" element={<CFOPs />} />
						<Route path="cadast/fiscal/cfop/:id/:acao" element={<NovoCFOP />} />

						{/* CADASTRO -> FISCAL -> CFOP ENTRADA X SAIDA */}
						<Route path="cadast/fiscal/cfopentradasaida" element={<CFOPEntradaSaida />} />
						<Route path="cadast/fiscal/cfopentradasaida/:id/:acao" element={<NovoCFOPEntradaxSaida />} />

						<Route path="config/cadast/integracao" element={<Integracao />} />

						{/* PCO */}
						{/* CONTROLE FISCAL */}
						{/* CONTROLE NOTAS FISCAIS */}
						<Route path="pco/contrFisc/contrNotFisc/impNotFisc" element={<ImportNotasFiscaisF />} />
						<Route path="nf/detalhe/:acao/:id" element={<VerDetalhesNota />} />

						{/* CADASTRO DE CFOP's */}
						<Route path="pco/contrFisc/contrNotFisc/cfop" element={<CadastroCFOP />} />
						<Route path="pco/contrFisc/contrNotFisc/cfop/:id" element={<CadastroCFOPIncluir />} />
						<Route path="pco/contrFisc/contrNotFisc/cfop/visu/:id" element={<CadastroCFOPVisualizar />} />
						<Route path="pco/contrFisc/contrNotFisc/cfop/editar/:id" element={<CadastroCFOPEditar />} />

						{/*CADASTRO DE CFOP ENTRADA X SAIDA}*/}
						<Route path="pco/contrFisc/contrNotFisc/cfopEntradaXsaida" element={<CadastroCFOPEntradaXsaida />} />
						<Route path="pco/contrFisc/contrNotFisc/cfopEntradaXsaida/novo" element={<CadastroCFOPIncluirEntradaXSaida />} />
						<Route path="pco/contrFisc/contrNotFisc/cfopEntradaXsaida/visualizar/:id" element={<CadastroCFOPxSaidaVisualizar />} />
						<Route path="pco/contrFisc/contrNotFisc/cfopEntradaXsaida/editar/:id" element={<EditarCFOPEntradaXSaida />} />

						{/*Notas Fiscais */}
						<Route path="pco/contrFisc/contrNotFisc/notasFiscaisArmazenagem" element={<NotasFiscaisArmazenagem />} />
						<Route path="pco/contrFisc/contrNotFisc/notasFiscaisArmazenagem/:id" element={<NovasNotasFiscaisArmazenagem />} />

						{/* Nota Fiscal de Entrada - Cross Docking */}
						<Route path="pco/contrFisc/contrNotFisc/notasFiscaisCrossDocking" element={<AdmOperacionalFiscalEntradaNFeEntradaCrossDocking />} />
						<Route path="pco/contrFisc/contrNotFisc/notasFiscaisCrossDocking/:id/:acao" element={<AdmOperacionalFiscalEntradaNFeEntradaCrossDockingItem />} />

						{/* Nota Fiscal - Pedido*/}
						<Route path="pco/contrFisc/contrNotFisc/notasFiscaisPedido" element={<AdmOperacionalFiscalEntradaNFePedido />} />
						<Route path="pco/contrFisc/contrNotFisc/notasFiscaisPedido/:id/:acao" element={<AdmOperacionalFiscalEntradaNFePedidoItem />} />

						{/*Grupo Tributário - GruposTributarios*/}
						<Route path="/pco/contrFisc/gruposTributarios" element={<GruposTributarios />} />
						<Route path="/pco/contrFisc/gruposTributarios/criar" element={<NovoGrupo />} />
						<Route path="/pco/contrFisc/gruposTributarios/editar/:id" element={<EditarGrupo />} />
						<Route path="/pco/contrFisc/gruposTributarios/ver/:id" element={<VerGrupo />} />

						{/* Processos E Veiculos */}
						<Route path="controle/processo/entrada/lista" element={<ProcessosDeEntrada />} />
						<Route path="controle/processo/entrada/:acao/:id_processo" element={<ProcessoEntradaForm />} />
						{/*
					<Route path="pco/contrFisc/procEVeiculos/processoEntrada/:id" element={<ProcessoEntradaIncluir />} />
						<Route path="pco/contrFisc/procEVeiculos/processoEntrada/editar/:id" element={<ProcessoEntradaEditar />} />
							*/}

						<Route path="pco/contrFisc/procEVeiculos/processoSaida" element={<ProcessoSaida />} />
						<Route path="pco/contrFisc/procEVeiculos/processoSaida/:id" element={<NovoProcessoSaida />} />

						<Route path="pco/contrFisc/procEVeiculos/ocorrencia" element={<Ocorrencia />} />
						<Route path="pco/contrFisc/procEVeiculos/ocorrencia/:id" element={<NovasOcorrencias />} />

						<Route path="pco/contrFisc/procEVeiculos/movimentoSaidaNF" element={<MovimentoSaidaNF />} />

						{/* Gestão de Pedidos */}
						<Route path="pco/contrFisc/gestPedidos/listagemDosPedidos" element={<ListagemDosPedidos />} />
						<Route path="pco/contrFisc/gestPedidos/listagemDosPedidos/:id" element={<NovaListagemDosPedidos />} />
						<Route path="pco/contrFisc/gestPedidos/listagemDosPedidos/visualizar/:id" element={<VisualizarPedido />} />
						<Route path="pco/contrFisc/gestPedidos/listagemDosPedidos/editar/:id" element={<EditarPedido />} />
						<Route path="pco/contrFisc/gestPedidos/OSPRogramados" element={<OSProgramada />} />

						<Route path="pco/contrFisc/gestPedidos/integracaoSaida" element={<IntegracaoSaida />} />
						<Route path="pco/contrFisc/gestPedidos/integracaoSaida/:id" element={<NovaIntegracaoSaida />} />

						<Route path="pco/contrFisc/gestPedidos/romaneioSaida" element={<RomaneioSaida />} />

						{/* ADM OPERACIONAL -> PEDIDOS */}
						<Route path="admoperacional/pedido/criacao" element={<CriacaoDePedidos />} />
						<Route path="admoperacional/pedido/pendente" element={<PedidosPendentes />} />
						<Route path="admoperacional/pedido/separacao" element={<PedidosSeparacao />} />
						{/*
						<Route path="admOperacional/gestaopedidos/pedidos" element={<GestaoPedidos />} />
						<Route path="pco/gestaopedidos/pedidos/:id" element={<PedidosNovo />} />
						<Route path="pco/gestaopedidos/pedidos/editar/:id" element={<PedidosEditar />} />
						<Route path="pco/gestaopedidos/pedidos/visualizar/:id" element={<PedidosVisualizar />} />
						<Route path="pco/gestaopedidos/pedidos/pgr/verificaestoque" element={<VerificaEstoque />} />
						<Route path="pco/gestaopedidos/pedidos/pgr/programarmanual" element={<ProgramarManual />} />
						*/}

						{/* Gestão de Estoque */}
						<Route path="gestEstoque/estoqueDetalhado" element={<EstoqueDetalhado />} />

						<Route path="gestEstoque/estoqueSintetico" element={<EstoqueSintetico />} />

						<Route path="gestEstoque/consultaCodigoBarras" element={<ConsultaCodigoBarras />} />

						<Route path="gestEstoque/uasemuso" element={<UASemUso />} />

						<Route path="gestEstoque/romaneioBarrasAtivas" element={<RomaneioBarrasAtivas />} />

						{/*Ajuste de estoque */}
						<Route path='ajuste/estoque' element={<AjusteEstoque />}></Route>
						<Route path='ajuste/estoque/movimento' element={<AjusteDeMovimento />}></Route>

						{/* Gerencial */}
						{/* Gerencial -> Exceção -> Quebra-FEFO Picking */}
						<Route path="gerencial/excecao/quebrafefo" element={<ExecaoQuebraFEFO />} />
						{/* Gerencial -> Exceção -> Ler GTIN Carga */}
						<Route path="gerencial/excecao/lergtincarga" element={<ExcecaoLerGtinCarga />} />
						{/* Gerencial -> Exceção -> Ler Volume Carga */}
						<Route path="gerencial/excecao/lervolumecarga" element={<ExcecaoLerVolumeCarga />} />
						{/* Gerencial -> Exceção -> Endereço */}
						<Route path="gerencial/excecao/endereco" element={<ExcecaoEndereco />} />
						{/* Gerencial -> Exceção -> Zona */}
						<Route path="gerencial/excecao/zona" element={<ExcecaoZona />} />

						{/*Operacional */}
						{/*Operacional -> Impressão de Etiquetas*/}
						<Route path='operacional/impressaoetiqueta' element={<ImpressaoEtiqueta />} />

						{/* Operação */}
						{/* Conferencia Entrada */}
						<Route path="operacao/confEntrada/confEntrada" element={<ConferenciaEntrada />} />
						<Route path="operacao/confEntrada/confEntrada/:id" element={<NovaConferenciaEntrada />} />

						<Route path="operacao/confEntrada/embalagemSemTexto" element={<EmbalagemSemTexto />} />
						<Route path="operacao/confEntrada/embalagemSemTexto/:id" element={<NovaEmbalagemSemTexto />} />

						<Route path="operacao/confSaida" element={<ConferenciaSaida />} />
						<Route path="operacao/confSaida/:id" element={<NovaConferenciaSaida />} />

						<Route path="operacao/enderecamento" element={<Enderecamento />} />

						<Route path="operacao/desfragmentacaoEstoque" element={<DesfragmentacaoEstoque />} />
						<Route path="operacao/desfragmentacaoEstoque/:id" element={<NovaDesfragmentacaoEstoque />} />

						{/* Gerenciamento */}
						{/* Endereçamento */}
						<Route path="gerenciamento/enderecamento/historicoEnderecamento" element={<HistoricoEnderecamento />} />
						<Route path="relatorios/movimentacao/movimentacaoperiodo" element={<MovimentacaoPeriodo />} />
						<Route path="gerenciamento/enderecamento/palletSemEnderecamento" element={<PalletSemEnderecamento />} />
						<Route path="gerenciamento/enderecamento/palletSemEnderecamento/:id" element={<NovoPalletSemEnderecamento />} />

						{/* Processos */}
						<Route path="gerenciamento/processos/autorizacaoEntrada" element={<AutorizacaoEntrada />} />
						<Route path="gerenciamento/processos/autorizacaoEntrada/:id" element={<NovaAutorizacaoEntrada />} />

						{/* Estoque */}
						<Route path="gerenciamento/estoque/barraPallet" element={<BarraPallet />} />
						<Route path="gerenciamento/estoque/barraPallet/:id" element={<NovaBarraPallet />} />

						{/* Faturamento */}
						{/* Manuntenção */}
						<Route path="faturamento/manuntencao/cadastroTarifas" element={<CadastroTarifas />} />
						<Route path="faturamento/manuntencao/cadastroTarifas/:id" element={<NovoCadastroTarifas />} />

						{/*adm -> controle de acesso -> usuários filial */}

						<Route path="administrativo/filial/controleacesso/usuariosfilial" element={<ListaFiliais />} />
						<Route path="config/segur/usuarios/filial/:id_filial" element={<UsuariosFilial />} />

						{/*LOGS DO SISTEMA -> LOG DE INTEGRACAO */}
						<Route path="logsistema/logintegracao" element={<LogSistemaIntegracao />} />
						{/*LOGS DO SISTEMA -> LOG DE ACAO DO USUARIO */}
						<Route path="logsistema/logacaousuario" element={<LogSistemaAcaoUsuario />} />
						{/*LOGS DO SISTEMA -> LOG DE INTEGRACAO API PEDIDOS */}
						<Route path="logsistema/logintapipedido" element={<LogSistemaIntegracaoAPIPedidos />} />
						{/*adm-operacional -> pedidos -> tarefas */}
						<Route path="tarefa/programada/listagem" element={<TarefasList />} />
						{/*adm-operacional -> pedidos -> o.s pendente de embarque */}
						<Route path="os/pendente/embarque/listagem" element={<OSpendetenEmbarque />} />

						{/*Operacional -> Reposição de Picking -> Reposição primária*/}
						<Route path='operaciona/reposicaoPicking/reposicaoPrimaria' element={< ReposicaoPrimaria />} />
					</Route>
					<Route path="/" element={<Navigate to="/login" replace />} />

					{/* DEFAULT */}

					<Route path="*" element={<h1>Página não Localizada</h1>} />
				</Routes>
			</div>
		</div>
	);
}

export default App;
