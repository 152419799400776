import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Modal, Spinner } from 'react-bootstrap';
import { mdiPlusCircle } from '@mdi/js';
import Icon from '@mdi/react';
import { ITabela } from '../../../../components/Table/TabelaInterface';
import ClickableTable from '../../../../components/ClickableTable/ClickableTabl';
import ModalConfirmacao from '../../../../components/ModalDeConfirmacao/ModalConfirmacao';
import { toast } from 'react-toastify';
import { BuscarPermissoesUsuario } from '../../../../hooks/buscarPermissoesUsuario';

const Usuarios = (): JSX.Element => {
	const token = sessionStorage.getItem('IsFKLTOKEN')!;
	const id_filial = sessionStorage.getItem('IsIDFilialPadraoFKL')!;
	const id_perfil = sessionStorage.getItem('IsIdPerfilFKL')!;
	const nivelPerfil = sessionStorage.getItem('NivelPerfil')!;
	const navigate = useNavigate();

	// Usa o hook com os parâmetros de ID de filial e ID de perfil de permissão
	const { permissoes, erro } = BuscarPermissoesUsuario(id_filial, id_perfil);
	const [permissaoVisualizar, setPermissaoVisualizar] = useState(false);
	const [permissaoCriar, setPermissaoCriar] = useState<boolean>(false);
	const [permissaoEditar, setPermissaoEditar] = useState<boolean>(false);
	const [permissaoExcluir, setPermissaoExcluir] = useState<boolean>(false);

	// Utiliza o resultado da requisição dentro de um efeito ou qualquer lógica de negócio
	useEffect(() => {
		if (erro) {
			toast.error(`Falha ao buscar permissões do usuário. Motivo: ${erro}`);
		}
		if (permissoes) {
			if (!permissoes.perfilPermissao.visualizarUsuario) {
				toast.warning('Você não tem permissão para acessar Lista de Usuários');
				navigate('/');
			} else {
				setPermissaoVisualizar(permissoes.perfilPermissao.visualizarUsuario);
				setPermissaoCriar(permissoes.perfilPermissao.criarUsuario);
				setPermissaoEditar(permissoes.perfilPermissao.editarUsuario);
				setPermissaoExcluir(permissoes.perfilPermissao.excluirUsuario);
			}
		}
	}, [permissoes, erro, navigate]);

	//#region Modifica o status do Modal Carregando
	const [showCarregando, setShowCarregando] = useState(false);
	//#endregion
	const colunas: ITabela[] = [
		{
			titulo: 'ID Usuario',
			acesso: 'id_usuario',
		},
		{
			titulo: 'Nome',
			acesso: 'nome',
		},
		{
			titulo: 'Email',
			acesso: 'email',
		},
		{
			titulo: 'Perfil',
			acesso: 'nome_perfil',
		},
		{
			titulo: 'Status',
			acesso: 'situacao',
		},
	];

	const [itensPorPagina, setItensPorPagina] = useState(10);
	const [ListaDeUsuarios, setListaDeUsuarios] = useState([]);
	function carregarUsuarios(id_filial: any) {
		setShowCarregando(true);
		axios
			.get(`${process.env.REACT_APP_API_BASE_URL}/lista/usuarios/filial/${id_filial}/${nivelPerfil}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setShowCarregando(false);
				setListaDeUsuarios(resposta.data.usuarios);
			})
			.catch(function (erro) {
				setShowCarregando(false);
				alert(erro.response.data.message || erro.message);
			});
	}

	const [ModalExcluir, setModalExcluir] = useState(false);
	const [dadoUsuario, setDadoUsuario] = useState<any>([]);
	function AbriModalExcluir(dadoUsuario: any) {
		setDadoUsuario(dadoUsuario);
		setModalExcluir(true);
	}

	function FecharModalExcluir() {
		setModalExcluir(false);
	}

	function ExcluirUsuario() {
		setShowCarregando(true);

		const usuarioLogado = sessionStorage.getItem('IsUserFKL')!;

		axios
			.delete(`${process.env.REACT_APP_API_BASE_URL}/deletar/usuario/${dadoUsuario.id_usuario}/${dadoUsuario.cpf}/${usuarioLogado}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setShowCarregando(false);
				FecharModalExcluir();
				carregarUsuarios(id_filial);
				toast.success(resposta.data.message, {
					autoClose: 900,
				});
			})
			.catch(function (erro) {
				setShowCarregando(false);
				toast.error(erro.response.data.message || erro.message, {
					autoClose: 900,
				});
			});
	}

	useEffect(function () {
		carregarUsuarios(id_filial);
	}, []);
	return (
		<>
			<div className="main">
				<div className="tituloDaPagina mt-2 ms-1">
					<h4>Usuários</h4>
				</div>
				<div className="card mt-2 pb-3">
					<div className="card-body">
						{permissaoCriar && (
							<div className="container p-0 m-0">
								<div className="row">
									<div className="col-lg-10 col-sm">
										<Link className="btn btn-secondary w-25" to={`/config/segur/usuarios/criar/novo`}>
											<Icon path={mdiPlusCircle} size={1} className="me-2 rounded" />
											Novo Usuário
										</Link>
									</div>
								</div>
							</div>
						)}
						<ClickableTable
							coluna={colunas}
							itemsPerPage={itensPorPagina}
							data={ListaDeUsuarios}
							onRowClick={function () {}}
							acaoEditar={function (dado: any) {
								navigate(`/config/segur/usuarios/editar/${dado.id_usuario}`);
							}}
							acaoExcluir={AbriModalExcluir}
							acaoVisualizar={function (dado: any) {
								navigate(`/config/segur/usuarios/visualizar/${dado.id_usuario}`);
							}}
							usaAcoes={permissaoVisualizar}
							usaEditar={permissaoEditar}
							usaExcluir={permissaoExcluir}
							usaVisualizar={permissaoVisualizar}
							acessopesquisa="nome"
							labelpesquisa="Procurar por nome de usuario."
						/>
					</div>
					<div className="container">
						<div className="row">
							<div className="col-lg-2 m-auto text-center">
								<label>Itens por Página</label>
							</div>
							<div className="w-100"></div>
							<div className="col-lg-1 m-auto">
								<select
									value={itensPorPagina}
									onChange={function (e: any) {
										setItensPorPagina(e.target.value);
									}}
									className="form-select form-select-sm"
									aria-label=".form-select-sm example"
								>
									<option value={10}>10</option>
									<option value={20}>20</option>
									<option value={30}>30</option>
								</select>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/*MODAL CARREGANDO */}
			<Modal
				show={showCarregando}
				//onHide={fecharModalClassificaAR}
				backdrop="static"
				size="sm"
				//dialogClassName="modal-90w"
				keyboard={false}
				centered
			>
				<div className="row" style={{ textAlign: 'center' }}>
					<div className="col-sm">
						<Spinner animation="border" role="status" variant="dark" size="sm" />
						<Spinner animation="border" role="status" variant="dark" />
					</div>
				</div>
				<div className="row">
					<div className="col-sm" style={{ textAlign: 'center', color: 'black' }}>
						<label className="form-label" htmlFor="">
							Carregando...
						</label>
					</div>
				</div>
			</Modal>

			<ModalConfirmacao
				icone="bi bi-question-lg"
				msgPrincipal="Confimar exclusão de usuário ?"
				mostrar={ModalExcluir}
				Acao1={ExcluirUsuario}
				Acao2={FecharModalExcluir}
				msgAcao1="Confirmar"
				msgAcao2="Cancelar"
			/>
		</>
	);
};

export { Usuarios };
