import { useNavigate } from 'react-router-dom';

const NovoProcessoSaida = (): JSX.Element => {
	const navigate = useNavigate();

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box">
							<div className="page-title-right"></div>
							<h4 className="page-title">Processo Saída</h4>
						</div>
					</div>
				</div>
				<div className="card">
					<div className="card-body">
						<ul className="nav nav-pills bg-nav-pills nav-justified mb-2">
							<li className="nav-item">
								<a href="#tab-procSaid-process" data-bs-toggle="tab" aria-expanded="true" className="nav-link rounded-0 active">
									<i className="mdi mdi-home-variant d-md-none d-block"></i>
									<span className="d-none d-md-block">Processo</span>
								</a>
							</li>
							<li className="nav-item">
								<a href="#tab-procSaid-veic" data-bs-toggle="tab" aria-expanded="false" className="nav-link rounded-0">
									<i className="mdi mdi-home-variant d-md-none d-block"></i>
									<span className="d-none d-md-block">Veículo</span>
								</a>
							</li>
							<li className="nav-item">
								<a href="#tab-procSaid-OSVinc" data-bs-toggle="tab" aria-expanded="false" className="nav-link rounded-0">
									<i className="mdi mdi-settings-outline d-md-none d-block"></i>
									<span className="d-none d-md-block">OS Vinculadas</span>
								</a>
							</li>
							<li className="nav-item">
								<a href="#tab-procSaid-NFSCross" data-bs-toggle="tab" aria-expanded="false" className="nav-link rounded-0">
									<i className="mdi mdi-settings-outline d-md-none d-block"></i>
									<span className="d-none d-md-block">NFS Crossdocking</span>
								</a>
							</li>
							<li className="nav-item">
								<a href="#tab-procSaid-audit" data-bs-toggle="tab" aria-expanded="false" className="nav-link rounded-0">
									<i className="mdi mdi-settings-outline d-md-none d-block"></i>
									<span className="d-none d-md-block">Auditoria</span>
								</a>
							</li>
						</ul>
						<div className="tab-content">
							<div className="tab-pane show active" id="tab-procSaid-process">
								<div className="row mb-2">
									<div className="col">
										<button className="btn btn-info mr-2 rounded">Relatório de Carga</button>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<div className="form-floating">
											<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">Processo</label>
										</div>
										<div className="form-floating">
											<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">Tipo Operação</label>
										</div>

									</div>
									<div className="col">
										<div className="form-floating">
											<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">Data Chegada</label>
										</div>
										<div className="form-floating">
											<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">Status</label>
										</div>

									</div>
									<div className="col">
										<div className="form-floating">
											<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">Data Cadastro</label>
										</div>

									</div>
								</div>
								<div className="row">
									<div className="col-4 form-floating">
										<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
										<label className="form-label">Modal Operação</label>
									</div>

								</div>
								<div className="row">
									<div className="col-4">
										<div className="form-floating">

											<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder='' />
											<label className="form-label">Depositante</label>
										</div>
										<div className="form-floating">

											<input type="text" className="form-control bordasInferiorInput text-black text-capitalize" placeholder='' />
											<label className="form-label">Critério</label>
										</div>

									</div>
									<div className="col-4">
										<div className="form-floating">
											<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">CNPJ Principal</label>
										</div>
										<div className="form-floating">
											<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">Observações</label>
										</div>

									</div>
								</div>
							</div>
							<div className="tab-pane show" id="tab-procSaid-veic">
								<div className="row">
									<div className="col">
										<div className="form-floating">
											<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">Cavalo</label>
										</div>
										<div className="form-floating">
											<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">Motorista</label>
										</div>
										<div className="form-floating">
											<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">Telefone</label>
										</div>

									</div>
									<div className="col">
										<div className="form-floating">
											<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">Carreta</label>
										</div>
										<div className="form-floating">
											<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">RG</label>
										</div>
										<div className="form-floating">
											<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">Transportadora</label>
										</div>

									</div>
									<div className="col">
										<div className="form-floating">
											<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">Tipo Veículo</label>
										</div>
										<div className="form-floating">
											<input type="text" placeholder='' className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">CNH</label>
										</div>

									</div>
								</div>
							</div>
							<div className="tab-pane show" id="tab-procSaid-OSVinc">
								<div className="row mb-1">
									<div className="container-fluid">
										<div className="card">
											<div className="card-header">
												<div className="row">
													<div className="col-sm-2">
														<h5>OS Programadas</h5>
													</div>
													<div className="col-sm-10 text-right">
														<button
															className="btn btn-warning rounded mr-4 mx-1"
															data-bs-toggle="modal"
															data-bs-target="#modal-pco-contrFiscal-ProcEVeic-procSaida"
														>
															Vincular OS
														</button>
														<button className="btn btn-danger rounded">Remover Vinculo</button>
													</div>
												</div>
											</div>
											<div className="card-body">
												<div className="table-responsive">
													<table className="table table-centered w-100 dt-responsive nowrap" id="endereco-datatable">
														<thead className="table-light">
															<tr>
																<th>Vincular</th>
																<th>OS</th>
																<th>Cliente</th>
																<th>Pedido</th>
																<th>Data carga Prevista</th>
																<th>Roteiro</th>
																<th>Qtd. Manuseio</th>
																<th>Qtd. Fiscal</th>
																<th>Peso Bruto</th>
																<th>Peso Liq.</th>
																<th>Programação</th>
																<th>Critério</th>
																<th>CNPJ Cliente</th>
																<th>Baixa</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td>
																	<input type="checkbox" />
																</td>
																<td>61</td>
																<td>KPL</td>
																<td>123456</td>
																<td>29/11/2021 16:00:00</td>
																<td></td>
																<td>1200,00</td>
																<td>1200,00</td>
																<td>1200,00</td>
																<td>1100,00</td>
																<td>29/11/2021 16:00:00</td>
																<td>1234</td>
																<td>11321432000122</td>
																<td>0</td>
															</tr>
														</tbody>
													</table>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="tab-pane show" id="tab-procSaid-NFSCross">
								<div className="row">
									<div className="container-fluid">
										<div className="card">
											<div className="card-header">
												<div className="row">
													<div className="col-sm-2">
														<h5>NFS Crossdocking</h5>
													</div>
													<div className="col-sm-10 text-right">
														<button className="btn btn-success rounded mx-1 mr-4">Gerar O.S Crossdocking</button>
														<button className="btn btn-danger rounded">Remover Vinculo</button>
													</div>
												</div>
											</div>
											<div className="card-body">
												<div className="table-responsive">
													<table className="table table-centered w-100 dt-responsive nowrap" id="endereco-datatable">
														<thead className="table-light">
															<tr>
																<th>Vincular</th>
																<th>Cliente</th>
																<th>Nota Fiscal</th>
																<th>Série</th>
																<th>Data Cadastro</th>
																<th>UF</th>
																<th>Emitente</th>
																<th>Destinatário</th>
																<th>Volumes</th>
																<th>Vlr. Nota Fiscal</th>
																<th>Peso Bruto</th>
																<th>Peso Liq.</th>
																<th>CNPJ Cliente</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td>
																	<input type="checkbox" />
																</td>
																<td>KPL</td>
																<td>123456</td>
																<td>1</td>
																<td>29/11/2021 16:00:00</td>
																<td>SP</td>
																<td>Emitente Ltda</td>
																<td>Destinatário Ltda</td>
																<td>120,00</td>
																<td>1200,00</td>
																<td>1100,00</td>
																<td>1000,00</td>
																<td>11321432000122</td>
															</tr>
														</tbody>
													</table>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="tab-pane show" id="tab-procSaid-audit">
								<div className="row">
									<div className="col">
										<div className="mb-1 form-floating">
											<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">Conferido por</label>
										</div>
										<div className="mb-1 form-floating">
											<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">Tempo Inicio</label>
										</div>
										<div className="mb-1 form-floating">
											<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">Tempo Meio</label>
										</div>
										<div className="mb-1 form-floating">
											<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">Tempo Fim</label>
										</div>
										<div className="mb-1 form-floating">
											<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">Lacre</label>
										</div>
										<div className="mb-1 form-floating">
											<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">Tipo Carga</label>
										</div>

									</div>
									<div className="col">
										<div className="mb-1 form-floating">

											<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">Liberado por</label>
										</div>
										<div className="form-check mb-1">

											<input type="checkbox" className="form-check-input" />
											<label className="form-check-label">Email Enviado</label>
										</div>
									</div>
									<div className="col">
										<div className="mb-1 form-floating">
											<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">Aprovado por</label>
										</div>
										<div className="mb-1 form-floating">
											<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">Motivo Aprov Diverg</label>
										</div>
										<div className="form-check mb-1 mt-1">
											<input type="checkbox" className="form-check-input" />
											<label className="form-check-label">Divergência?</label>
										</div>
										<div className="mb-1 form-floating">
											<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">Peso Entrada</label>
										</div>
										<div className="mb-1 form-floating">
											<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">Peso Saída</label>
										</div>
										<div className="mb-1 form-floating">
											<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">Peso</label>
										</div>
										<div className="mb-1 form-floating">
											<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">Peso Téorico</label>
										</div>
										<div className="mb-1 form-floating">
											<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">Diverg Peso</label>
										</div>

									</div>
									<div className="col">
										<div className="mb-1 form-floating">
											<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">Autorizado Por</label>
										</div>
										<div className="mb-1 form-floating">
											<input type="text" placeholder="" className="form-control bordasInferiorInput text-black text-capitalize" />
											<label className="form-label">Data Auto Entrada</label>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="card-footer">
						<div className="col">
							<div className="text-sm-end">
								<button
									className="btn btn-danger mb-2 me-1 rounded"
									type="button"
									onClick={() => navigate('/pco/contrFisc/procEVeiculos/processoSaida')}
								>
									Cancelar
								</button>
							</div>
						</div>
					</div>
				</div>
			</div >
			<div
				className="modal fade"
				id="modal-processoSaida"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabIndex={-1}
				aria-labelledby="staticBackdropLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-full-width modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="staticBackdropLabel">
								Novo Processo
							</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
						</div>
						<div className="modal-body">
							<div className="card">
								<div className="card-body">
									<div className="row mb-1">
										<div className="col-3">
											<label className="form-label">Data Chegada</label>
											<input type="datetime" className="form-control" />
										</div>
										<div className="col-3">
											<label className="form-label">Critério</label>
											<input type="text" className="form-control" />
										</div>
									</div>
									<div className="row mb-1">
										<div className="col">
											<label className="form-label">Placa cavalo</label>
											<input type="text" className="form-control" />
										</div>
										<div className="col">
											<label className="form-label">Carreta</label>
											<input type="text" className="form-control" />
										</div>
										<div className="col">
											<label className="form-label">Tipo Veículo</label>
											<input type="text" className="form-control" />
										</div>
										<div className="col">
											<label className="form-label">Transportadora</label>
											<input type="text" className="form-control" />
										</div>
									</div>
									<div className="row mb-1">
										<div className="col-6">
											<label className="form-label">Motorista</label>
											<input type="text" className="form-control" />
										</div>
										<div className="col-2">
											<label className="form-label">RG</label>
											<input type="text" className="form-control" />
										</div>
										<div className="col-2">
											<label className="form-label">CNH</label>
											<input type="number" className="form-control" />
										</div>
										<div className="col-2">
											<label className="form-label">Telefone</label>
											<input type="text" className="form-control" placeholder="(##) ####-####" />
										</div>
									</div>
									<div className="row mb-1">
										<div className="col-3">
											<label className="form-label">Depositante</label>
											<select className="form-select">
												<option value="">---</option>
												<option value="1">Depositante 1</option>
											</select>
										</div>
										<div className="col-3">
											<label className="form-label">CNPJ Principal</label>
											<input type="text" className="form-control" />
										</div>
									</div>
									<div className="row mb-1">
										<div className="col-3">
											<label className="form-label">Tipo Operação</label>
											<select className="form-select">
												<option value="">---</option>
												<option value="1">Operação 1</option>
											</select>
										</div>
										<div className="col-3">
											<label className="form-label">Modalidade</label>
											<select className="form-select">
												<option value="">---</option>
												<option value="1">Modalidade 1</option>
											</select>
										</div>
									</div>
								</div>
								<div className="modal-footer text-sm-end">
									<button type="button" className="btn btn-danger" data-bs-dismiss="modal">
										Cancelar
									</button>
									<button type="button" className="btn btn-info" data-bs-dismiss="modal">
										Salvar
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="modal fade"
				id="modal-pco-contrFiscal-ProcEVeic-procSaida"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabIndex={-1}
				aria-labelledby="staticBackdropLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-full-width modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title rounded" id="staticBackdropLabel">
								Vincular OS
							</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
						</div>
						<div className="modal-body">
							<div className="card">
								<div className="card-body">
									<div className="table-responsive">
										<table className="table table-centered w-100 dt-responsive nowrap" id="endereco-datatable">
											<thead className="table-light">
												<tr>
													<th>Vincular</th>
													<th>OS</th>
													<th>Cliente</th>
													<th>Pedido</th>
													<th>Data carga Prevista</th>
													<th>Roteiro</th>
													<th>Qtd Manuseio</th>
													<th>Qtd Fiscal</th>
													<th>Peso Bruto</th>
													<th>Peso Liq</th>
													<th>Programação</th>
													<th>Critério</th>
													<th>CNPJ Cliente</th>
													<th>Baixa</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>
														<input type="checkbox" />
													</td>
													<td>61</td>
													<td>KPL</td>
													<td>123456</td>
													<td>29/11/2021 16:00:00</td>
													<td></td>
													<td>1200,00</td>
													<td>1200,00</td>
													<td>1200,00</td>
													<td>1100,00</td>
													<td>29/11/2021 16:00:00</td>
													<td>1234</td>
													<td>11321432000122</td>
													<td>0</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
								<div className="modal-footer">
									<div className="col-4 for-group text-sm-end">
										<button type="button" className="btn btn-danger" data-bs-dismiss="modal">
											Cancelar
										</button>
										<button type="button" className="btn btn-info" data-bs-dismiss="modal">
											Salvar
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export { NovoProcessoSaida };
