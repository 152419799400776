import axios from 'axios';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Modal, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ITabela } from '../../../../../components/Table/TabelaInterface';
import ClickableTable from '../../../../../components/ClickableTable/ClickableTabl';
import ModalConfirmacao from '../../../../../components/ModalDeConfirmacao/ModalConfirmacao';
import { BuscarPermissoesUsuario } from '../../../../../hooks/buscarPermissoesUsuario';

//Interface para receber as informações por parametros
interface ModalEditarPedEmSeparacaoProps {
	isOpen: boolean;
	fecharModal: () => void;
	token: string;
	idFilial: string;
	dataCarga: string;
	destinatario: string;
	descricao: string;
	idPedido: number;
}

//Criação do componente
const ModalEditarPedEmSeparacao: React.FC<ModalEditarPedEmSeparacaoProps> = ({ isOpen, fecharModal, token, idFilial, dataCarga, destinatario, descricao, idPedido }) => {
	const [data, setData] = useState([]);
	const [mostraModalConfirm, setMostraModalConfirm] = useState<boolean>(false);
	const [id, setId] = useState<string>();
	const [showModal, setShowModal] = useState<boolean>(isOpen);
	const [newDataCarga, setNewDataCarga] = useState<string>();
	const [newDestinatario, setNewDestinatario] = useState<string>();
	const [disabled, setDisabled] = useState<boolean>(true);
	const [codigos, setCodigos] = useState([]);
	const [codigoSelecionado, setCodigoSelecionado] = useState<string>();
	const [codigoInput, setCodigoInput] = useState<string>();
	const [unidEmbInput, setUnidEmbInput] = useState<string>();
	const [newDescricao, setNewDescricao] = useState<string>();
	const [idEmb, setIdEmb] = useState<number>();
	const [qtdeInserida, setQtdeInserida] = useState<number | string>();
	const [minValidade, setMinValidade] = useState<string>();
	const [lote, setLote] = useState<string>();
	const [controlaViewSelectCod, setControlaViewSelectCod] = useState<boolean>();
	const [controlaViewInputCod, setControlaViewInputCod] = useState<boolean>();
	const [unidades, setUnidades] = useState([]);
	const [unidadeSelecionada, setUnidadeSelecionada] = useState<string>();
	const [idWms, setIdWms] = useState<number>();
	const [idCliente, setIdCliente] = useState<number>();
	const [btnName, setBtnName] = useState<string>();

	const usuarioLogado = sessionStorage.getItem('IsUserFKL');
	const id_perfil = sessionStorage.getItem('IsIdPerfilFKL')!;

	const navigate = useNavigate();

	// Usa o hook com os parâmetros de ID de filial e ID de perfil de permissão
	const { permissoes, erro } = BuscarPermissoesUsuario(idFilial, id_perfil);

	const [permissaoEditarItemPedido, setPermissaoEditarItemPedido] = useState(false);
	const [permissaoExcluirItemPedido, setPermissaoExcluirItemPedido] = useState(false);

	// Utiliza o resultado da requisição dentro de um efeito ou qualquer lógica de negócio
	useEffect(() => {
		if (erro) {
			toast.error(`Falha ao buscar permissões do usuário. Motivo: ${erro}`);
		}
		if (permissoes) {
			setPermissaoEditarItemPedido(permissoes.perfilPermissao.edtiarItemPedidoPendente);
			setPermissaoExcluirItemPedido(permissoes.perfilPermissao.excluirItemPedidoPendente);
		}
	}, [permissoes, erro, navigate]);

	const excluirPedItem = (dados: any) => {
		setId(dados.id_wms);
		setShowModal(false);
		setMostraModalConfirm(true);
	};

	const colunas: ITabela[] = [
		{ titulo: 'Id', acesso: 'id_pedido' },
		{ titulo: 'Código', acesso: 'codigo' },
		{ titulo: 'Qtde', acesso: 'qtde' },
		{ titulo: 'Lote', acesso: 'restricao_lote' },
		{ titulo: 'Min. Validade', acesso: 'restricao_validade' },
		{ titulo: 'Und. Emb. Pedido', acesso: 'und_emb_pedido' },
	];

	function carregaGridModalEdit() {
		axios
			.get(`${process.env.REACT_APP_API_BASE_URL}/carrega/grid/pedEdit/${idFilial}/${idPedido}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				console.log(resposta.data)
				setData(resposta.data.data);
				setIdCliente(resposta.data.data[0].id_cliente);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				}
				else if (status == 406) {
					toast.error("Não é possível carregar tarefa de pedido sem item.")
					fecharModal()
				}
				else {
					console.info(erro.response.data.message);
					setData([]);
				}
			});
	}

	function rowClick(dados: any) {
		setCodigoInput(dados.codigo);
		setUnidEmbInput(dados.und_emb);
		setIdEmb(dados.id_emb);
		setQtdeInserida(dados.qtde);
		setMinValidade(dados.restricao_validade);
		setLote(dados.restricao_lote);
	}

	function excluirPedItens() {
		axios
			.delete(`${process.env.REACT_APP_API_BASE_URL}/delete/items/pedido/${idFilial}/${idPedido}/${id}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				toast.success(resposta.data.message);
				setMostraModalConfirm(false);
				setShowModal(true);
				carregaGridModalEdit();
			})
			.catch(function (erro) {
				setMostraModalConfirm(false);
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}

	function editarPedItens(dados: any) {
		setControlaViewInputCod(false);
		setControlaViewSelectCod(true);
		setCodigoInput(dados.codigo);
		setUnidEmbInput(dados.und_emb);
		setIdEmb(dados.id_emb);
		setQtdeInserida(dados.qtde);
		setMinValidade(dados.restricao_validade);
		setLote(dados.restricao_lote);
		setId(dados.id_wms);

		setBtnName('Gravar atualização');
	}

	function buscaCodigo() {
		axios
			.get(`${process.env.REACT_APP_API_BASE_URL}/busca/codigo/${idFilial}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setCodigos(resposta.data.data);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					console.info(erro.response.data.message);
				}
			});
	}

	function buscaInfosCodSelecionado(codigoSelecionado: string) {
		if (!codigoSelecionado) {
			return;
		}

		axios
			.get(`${process.env.REACT_APP_API_BASE_URL}/busca/infos/cod/${codigoSelecionado}/${idFilial}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				setNewDescricao(resposta.data.dataProd[0].descricao);
				setUnidades(resposta.data.dataEmb);
				setIdWms(resposta.data.dataProd[0].id_wms);
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else if (status === 406) {
					toast.error(erro.response.data.message);
					setUnidades([]);
				} else {
					toast.error(erro.response.data.message);
					setCodigos([]);
					setUnidades([]);
				}
			});
	}

	function inserirItem() {
		if (
			!codigoSelecionado ||
			codigoSelecionado === 'Selecione Código' ||
			!unidadeSelecionada ||
			unidadeSelecionada === 'Selecione Unidade' ||
			!qtdeInserida
		) {
			toast.info('Preencha todos os campos para prosseguir');
			return;
		}

		axios
			.post(
				`${process.env.REACT_APP_API_BASE_URL}/insere/novo/item`,
				{
					idFilial,
					codigoSelecionado,
					unidadeSelecionada,
					idEmb,
					qtdeInserida,
					minValidade,
					lote,
					idWms,
					idPedido,
					idCliente,
					usuarioLogado,
				},
				{
					headers: {
						authorization: token,
					},
				}
			)
			.then(function (resposta) {
				toast.success(resposta.data.message);
				fecharModal();
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}

	function gravarAtt() {
		if (!qtdeInserida) {
			toast.info('Preencha a quantidade para atualizar item');
			return;
		}

		axios
			.put(
				`${process.env.REACT_APP_API_BASE_URL}/editar/pedido/pendente`,
				{
					idFilial,
					id_wms: id,
					qtdeInserida,
					minValidade,
					lote,
					idPedido
				},
				{
					headers: {
						authorization: token,
					},
				}
			)
			.then(function (resposta) {
				toast.success(resposta.data.message);
				fecharModal();
			})
			.catch(function (erro) {
				const status = erro.response.status;
				if (status === 403 || status === 401) {
					toast.error('Acesso negado, faça seu login novamente');
					navigate('/login');
				} else {
					toast.error(erro.response.data.message);
				}
			});
	}

	const handleMinValidadeChange = (e: ChangeEvent<HTMLInputElement>) => {
		// Remove todos os caracteres não numéricos
		let input = e.target.value.replace(/\D/g, '');
		if (input.length > 8) {
			// Limita a entrada a 8 caracteres
			input = input.slice(0, 8);
		}

		if (input.length <= 2) {
			input = input;
		} else if (input.length <= 4) {
			input = `${input.slice(0, 2)}/${input.slice(2)}`;
		} else {
			input = `${input.slice(0, 2)}/${input.slice(2, 4)}/${input.slice(4)}`;
		}

		setMinValidade(input);
	};

	useEffect(() => {
		setShowModal(isOpen);
	}, [isOpen]);

	// useEffect(() => {
	//     setBtnName(disabled ? 'Novo' : 'Gravar')
	// }, [disabled])

	return (
		<>
			{/* Modal com a finalidade de apresentar os botões de navegação para o usuário */}
			<Modal
				className="mt-2 rounded "
				show={showModal}
				size="xl"
				onShow={() => {
					setControlaViewInputCod(false);
					setControlaViewSelectCod(true);
					setBtnName('Novo');
					setDisabled(true);
					setCodigos([]);

					carregaGridModalEdit();
					setNewDataCarga(dataCarga);
					setNewDestinatario(destinatario);
					setNewDescricao(descricao);
					setCodigoInput('');
					setUnidEmbInput('');
					setIdEmb(0);
					setQtdeInserida(0);
					setMinValidade('');
					setLote('');
				}}
			>
				<Modal.Header className="bg-white" style={{ justifyContent: 'center' }}>
					<Modal.Title style={{ flex: '1', textAlign: 'center', color: 'black' }}>{'Editar Tarefa do pedido'}</Modal.Title>
					<i
						className="bi bi-x-circle"
						style={{ fontSize: '2rem', cursor: 'pointer' }}
						onClick={() => {
							fecharModal();
						}}
					></i>
				</Modal.Header>
				<Modal.Body className="bg-white">
					<Row>
						<div className="col-sm-3 mt-2">
							<button
								type="button"
								className="rounded w-100 btn btn-success"
								onClick={() => {
									if (btnName === 'Gravar') {
										inserirItem();
									} else if (btnName === 'Novo') {
										setControlaViewInputCod(true);
										setControlaViewSelectCod(false);
										setBtnName('Gravar');
										setCodigoSelecionado('Selecione Código');
										setIdEmb(0);
										setUnidades([]);
										setCodigos([]);
										setQtdeInserida(0);
										setMinValidade('');
										setLote('');
										buscaCodigo();
									} else if (btnName === 'Gravar atualização') {
										gravarAtt();
									}
								}}
							>
								<i className="bi bi-plus-circle me-1"></i>
								{btnName}
							</button>
						</div>

						<div className="col-sm-4">
							<div className="form-floating mb-1">
								<input
									type="text"
									className="form-control bordasInferiorInput text-black "
									id="contato"
									name="contato"
									placeholder=""
									value={newDataCarga}
									disabled
								// onChange={handleDataCargaChange}
								/>
								<label className="form-label">Dt. Carga</label>
							</div>
						</div>

						<div className="col-sm-4">
							<div className="form-floating mb-1">
								<input
									type="text"
									className="form-control bordasInferiorInput text-black "
									id="contato"
									name="contato"
									placeholder=""
									disabled
									value={newDestinatario}
								/>
								<label className="form-label">Destinatário</label>
							</div>
						</div>
					</Row>

					<Row>
						<div className="col-sm-3 form-floating" hidden={controlaViewInputCod}>
							<input
								type="text"
								className="form-control bordasInferiorInput text-black "
								id="contato"
								name="contato"
								disabled
								value={codigoInput}
							/>
							<label className="form-label">Código</label>
						</div>

						<div className="col-sm-3 form-floating" hidden={controlaViewSelectCod}>
							<select
								className="form-select bordasInferiorInput text-black text-capitalize"
								id="cfopEntrada"
								name="modo_operacao"
								value={codigoSelecionado}
								onChange={(event) => {
									setCodigoSelecionado(event.target.value);

									buscaInfosCodSelecionado(event.target.value);
								}}
							>
								<option value="">Selecione Código</option>

								{codigos.map((cod: any, index: any) => (
									<option key={index} value={cod.codigo}>
										{cod.codigo}
									</option>
								))}
							</select>
							<label className="form-label" htmlFor="cfopEntrada">
								Código
							</label>
						</div>

						<div className="col-sm-3">
							<div className="form-floating mb-1">
								<input
									type="text"
									className="form-control bordasInferiorInput text-black "
									id="contato"
									name="contato"
									disabled
									value={newDescricao}
								/>
								<label className="form-label">Descrição</label>
							</div>
						</div>

						<div className="col-sm-3" hidden={controlaViewInputCod}>
							<div className="form-floating mb-1">
								<input
									type="text"
									className="form-control bordasInferiorInput text-black "
									id="contato"
									name="contato"
									value={unidEmbInput}
									disabled
									onChange={(e) => setUnidadeSelecionada(e.target.value)}
								/>
								<label className="form-label">Unid. Embalagem</label>
							</div>
						</div>

						<div className="col-sm-3 form-floating" hidden={controlaViewSelectCod}>
							<select
								className="form-select bordasInferiorInput text-black text-capitalize"
								id="cfopEntrada"
								name="modo_operacao"
								value={unidades ? unidadeSelecionada : unidEmbInput}
								onChange={(event) => {
									setUnidadeSelecionada(event.target.value);

									const und: any = unidades.find((unid: any) => unid.unid_manuseio === event.target.value);
									setIdEmb(und ? und.id_emb : 0);
								}}
							>
								<option value="">Selecione Unidade</option>

								{unidades.map((unid: any, index: any) => (
									<option key={index} value={unid.unid_manuseio}>
										{unid.unid_manuseio}
									</option>
								))}
							</select>
							<label className="form-label" htmlFor="cfopEntrada">
								Unid. Embalagem
							</label>
						</div>

						<div className="col-sm-3">
							<div className="form-floating mb-1">
								<input
									type="number"
									className="form-control bordasInferiorInput text-black "
									id="contato"
									name="contato"
									disabled
									value={idEmb}
								/>
								<label className="form-label">Id. Embal</label>
							</div>
						</div>
					</Row>

					<Row>
						<div className="col-sm-4">
							<div className="form-floating mb-1">
								<input
									type="number"
									className="form-control bordasInferiorInput text-black "
									id="contato"
									name="contato"
									value={qtdeInserida}
									onChange={(e) => setQtdeInserida(e.target.value !== '' ? parseInt(e.target.value) : '')}
								/>
								<label className="form-label">Qtde</label>
							</div>
						</div>

						<div className="col-sm-4">
							<div className="form-floating mb-1">
								<input
									type="text"
									className="form-control bordasInferiorInput text-black "
									id="contato"
									name="contato"
									disabled
									value={minValidade}
									onChange={handleMinValidadeChange}
								/>
								<label className="form-label">Mín. Validade</label>
							</div>
						</div>

						<div className="col-sm-4">
							<div className="form-floating mb-1">
								<input
									type="text"
									className="form-control bordasInferiorInput text-black "
									id="contato"
									name="contato"
									value={lote}
									disabled
									onChange={(e) => setLote(e.target.value)}
								/>
								<label className="form-label">Lote</label>
							</div>
						</div>
					</Row>

					<Row className="mt-4">
						<ClickableTable
							coluna={colunas}
							data={data}
							usaRowClick={true}
							onRowClick={rowClick}
							itemsPerPage={10}
							acaoEditar={editarPedItens}
							acaoExcluir={excluirPedItem}
							acaoVisualizar={function () { }}
							usaAcoes={permissaoEditarItemPedido || permissaoExcluirItemPedido}
							usaEditar={permissaoEditarItemPedido}
							usaExcluir={permissaoExcluirItemPedido}
							usaVisualizar={false}
						/>
					</Row>
				</Modal.Body>
			</Modal>

			<ModalConfirmacao
				mostrar={mostraModalConfirm}
				msgAcao1="Cancelar"
				msgAcao2="Confirmar"
				msgPrincipal="Tem certeza que deseja excluir o item?"
				Acao1={() => {
					setMostraModalConfirm(false);
					setShowModal(true);
				}}
				Acao2={() => {
					excluirPedItens();
					setShowModal(true);
				}}
			// setShowModal={setShowModal}
			/>
		</>
	);
};

export default ModalEditarPedEmSeparacao;
