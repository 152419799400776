import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import { BuscarPermissoesUsuario } from "../../../../hooks/buscarPermissoesUsuario"
import { toast } from "react-toastify";
import ModalCarregando from "../../../../components/ModalCarregando/ModalCarregando";
import SelecionarDepositantes from "../../../../components/ModalSelecionarDepositantes/selecionarDepositantes";
import ClickableTable from "../../../../components/ClickableTable/ClickableTabl";
import { ITabela } from "../../../../components/Table/TabelaInterface";
import Exceljs from "exceljs"
import FileSaver from "file-saver";
function MovimentacaoPeriodo() {
    const token = sessionStorage.getItem('IsFKLTOKEN')!
    const id_filial = sessionStorage.getItem('IsIDFilialPadraoFKL')!
    const id_perfil = sessionStorage.getItem('IsIdPerfilFKL')!
    const navigate = useNavigate()
    //verificando permissoes
    const { permissoes, erro } = BuscarPermissoesUsuario(id_filial, id_perfil)
    const [visualizar, setVisualizar] = useState(false)
    useEffect(function () {
        if (erro) {
            toast.warning('Você não tem permissão para acessar movimentação periodo.');
            navigate('/');
        }
        else if (permissoes) {
            setVisualizar(permissoes?.perfilPermissao.visualizarRelMovimentacaoPeriodo)
        }
    }, [permissoes, erro, navigate])
    const [inputsPesquisa, setInputsPesquisa] = useState({
        dataInicio: "",
        dataFim: "",
        depositante: []
    })
    function onChangeDataInicio(e: React.ChangeEvent<HTMLInputElement>) {
        setInputsPesquisa({
            ...inputsPesquisa,
            dataInicio: e.target.value
        })
    }
    function onChangeDataFim(e: React.ChangeEvent<HTMLInputElement>) {
        setInputsPesquisa({
            ...inputsPesquisa,
            dataFim: e.target.value
        })
    }
    function onChangeDepositante(e: []) {
        setInputsPesquisa({
            ...inputsPesquisa,
            depositante: e
        })
    }
    const [showModalListaDepositantes, setShowModalListaDepositantes] = useState(false)
    function mostrarModalListaDepositantes() {
        setShowModalListaDepositantes(!showModalListaDepositantes)
    }
    function DepositantesSelecionados(depositantes: []) {
        onChangeDepositante(depositantes)
    }
    const [showCarregando, setShowCarregando] = useState(false)
    const [ListaMovimentacaoPeriodo, setListaMovimentacaoPeriodo] = useState([])
    const [itensPorPagina, setItensPorPagina] = useState(10)
    const colunas: ITabela[] = [
        { titulo: "Fantasia", acesso: "fantasia" },
        { titulo: "Processo", acesso: "id_processo" },
        { titulo: "Tipo", acesso: "tipoProcesso" },
        { titulo: "WMS", acesso: "id_wms" },
        { titulo: "Cód.Produto", acesso: "codigo" },
        { titulo: "Und", acesso: "und" },
        { titulo: "Qtde", acesso: "qtde" },
        { titulo: "PL", acesso: "pl" },
        { titulo: "PB", acesso: "pb" },
        { titulo: "Menor Produção", acesso: "producao_menor" },
        { titulo: "Menor Validade", acesso: "validade_menor" },
        { titulo: "Lote", acesso: "lote" },
        { titulo: "Tipo do Peso", acesso: "tipo_peso" },
        { titulo: "Gera faturamento?", acesso: "gera_faturamento" },
        { titulo: "Usuário", acesso: "usuario" },
        { titulo: "Data da Movimentação", acesso: "cadastro" }
    ]
    function onSubmitMovimentacaoPeriodo(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()
        setShowCarregando(true)
        const dados = {
            depositantes: inputsPesquisa.depositante
        }
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/carregar/movimentacao/periodo/${id_filial}/${inputsPesquisa.dataInicio}/${inputsPesquisa.dataFim}`, dados, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {
            setListaMovimentacaoPeriodo(resposta.data.movimentacoes)
            setShowCarregando(false)
        }).catch(function (erro) {
            toast.error(erro.response.data.message || erro.message)
            setShowCarregando(false)
        })
    }
    async function exportarExcel() {
        try {
            setShowCarregando(true)
            const workbook = new Exceljs.Workbook()
            const worksheet = workbook.addWorksheet("Movimentação Periodo")
            //fazendo o cabecalho
            const alfabeto = [
                "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M",
                "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z",
                "AA", "BB", "CC", "DD", "EE"
            ]
            for (let i = 0; i < colunas.length; i = i + 1) {
                const cell = worksheet.getCell(`${alfabeto[i]}${1}`)
                cell.value = colunas[i].titulo
                cell.font = {
                    bold: true
                }
                const colum = worksheet.getColumn(i + 1)
                colum.width = 25
            }
            //montando o corpo
            for (let i = 0; i < ListaMovimentacaoPeriodo.length; i++) {
                const movimentacao: any = ListaMovimentacaoPeriodo[i]
                const cellA = worksheet.getCell(`A${i + 2}`)
                cellA.value = movimentacao.fantasia
                const cellB = worksheet.getCell(`B${i + 2}`)
                cellB.value = movimentacao.id_processo
                const cellC = worksheet.getCell(`C${i + 2}`)
                cellC.value = movimentacao.tipoProcesso
                const cellD = worksheet.getCell(`D${i + 2}`)
                cellD.value = movimentacao.id_wms
                const cellE = worksheet.getCell(`E${i + 2}`)
                cellE.value = movimentacao.codigo
                const cellF = worksheet.getCell(`F${i + 2}`)
                cellF.value = movimentacao.und
                const cellG = worksheet.getCell(`G${i + 2}`)
                cellG.value = movimentacao.qtde
                const cellH = worksheet.getCell(`H${i + 2}`)
                cellH.value = movimentacao.pl
                const cellI = worksheet.getCell(`I${i + 2}`)
                cellI.value = movimentacao.pb
                const cellJ = worksheet.getCell(`J${i + 2}`)
                cellJ.value = movimentacao.producao_menor
                const cellK = worksheet.getCell(`K${i + 2}`)
                cellK.value = movimentacao.validade_menor
                const cellL = worksheet.getCell(`L${i + 2}`)
                cellL.value = movimentacao.lote
                const cellM = worksheet.getCell(`M${i + 2}`)
                cellM.value = movimentacao.tipo_peso
                const cellN = worksheet.getCell(`N${i + 2}`)
                cellN.value = movimentacao.gera_faturamento === true ? "SIM" : "NÃO"
                const cellO = worksheet.getCell(`O${i + 2}`)
                cellO.value = movimentacao.usuario
                const cellP = worksheet.getCell(`P${i + 2}`)
                cellP.value = movimentacao.cadastro
            }
            //exportando
            const buffer = await workbook.xlsx.writeBuffer()
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
            FileSaver.saveAs(blob, "MovimentacaoPeriodo")
            setShowCarregando(false)
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <div className="main">
            <div className="col-sm col-md-12 col-lg-12 mt-2">
                <h4>Movimentação Período</h4>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={onSubmitMovimentacaoPeriodo}>
                                <div className="row">
                                    <div className="col-sm col-md-3 col-lg-3 pt-2">
                                        <div className="form-floating">
                                            <button onClick={function () {
                                                mostrarModalListaDepositantes()
                                                onChangeDepositante([])
                                            }} className="btn btn-primary w-100">Filtrar Depositantes
                                                <span className="badge bg-danger text-white ms-1">{inputsPesquisa.depositante.length}</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-sm col-md-2 col-lg-2">
                                        <div className="form-floating">
                                            <input
                                                value={inputsPesquisa.dataInicio}
                                                onChange={onChangeDataInicio}
                                                type="date"
                                                required
                                                className="form-control bordasInferiorInput text-black"
                                            />
                                            <label>Data Início</label>
                                        </div>
                                    </div>
                                    <div className="col-sm col-md-2 col-lg-2">
                                        <div className="form-floating">
                                            <input
                                                value={inputsPesquisa.dataFim}
                                                onChange={onChangeDataFim}
                                                type="date"
                                                required
                                                className="form-control bordasInferiorInput text-black"
                                            />
                                            <label>Data Fim</label>
                                        </div>
                                    </div>
                                    <div className="col-sm col-md-2 col-lg-3 pt-2">
                                        <button type="submit" className="btn btn-primary me-1">
                                            Filtrar
                                            <i className="bi bi-search ms-2"></i>
                                        </button>
                                        {
                                            ListaMovimentacaoPeriodo.length > 0 ?
                                                <button hidden={!visualizar} onClick={exportarExcel} type="button" className="btn btn-success">
                                                    Exportar .xlsx
                                                </button>
                                                :
                                                <></>
                                        }
                                    </div>
                                </div>
                                <ClickableTable
                                    coluna={colunas}
                                    itemsPerPage={itensPorPagina}
                                    data={ListaMovimentacaoPeriodo}
                                    usaRowClick={false}
                                    onRowClick={function () {

                                    }}
                                    usaAcoes={false}
                                    usaEditar={false}
                                    usaVisualizar={false}
                                    usaExcluir={false}
                                    usaCheckBox={false}
                                    acaoEditar={function () { }}
                                    acaoExcluir={function () { }}
                                    acaoVisualizar={function () { }}
                                    acaoCheckBox={function () { }}
                                    labelpesquisa="Procurar por Processo"
                                    acessopesquisa="id_processo"
                                />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ModalCarregando mostrar={showCarregando} mensagem="Carregando..." />
            <SelecionarDepositantes
                show={showModalListaDepositantes}
                textoHeader="Selecione os depositantes que deseja filtrar"
                funcaoFechar={mostrarModalListaDepositantes}
                receberDepositantesSelecionado={DepositantesSelecionados}
            />
        </div>
    )
}
export default MovimentacaoPeriodo