import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo4 from '../assets/img/logo4.png';
import logoSM from '../assets/img/logo_sm.png';
import { FaSearch } from 'react-icons/fa';
import ModalCarregando from './ModalCarregando/ModalCarregando';
import { toast } from 'react-toastify';

//Aqui um comentario

const LeftBar = (): JSX.Element => {
	const token = sessionStorage.getItem('IsFKLTOKEN')!;
	const id_perfil = sessionStorage.getItem('IsIdPerfilFKL')!;
	const id_filial = sessionStorage.getItem('IsIDFilialPadraoFKL')!;
	const NivelPerfil = sessionStorage.getItem('NivelPerfil')!;

	const [linkSWM, setLinkSWM] = useState<string>('')

	const navigate = useNavigate()

	const [permissoes, setPermissoes] = useState({
		isMaster: false,
		//Administrativo -> Controle de Acessos
		visualizarUsuario: false,
		visualizarPerfilPermissao: false,
		visualizarUsuariosFilial: false,
		visualizarAtividadesUsuario: false,
		//Administrativo -> Códigos do Sistema
		visualizarCodSistema: false,

		//Cadastros -> Entidade
		visualizarEntidade: false,
		visualizarTransportadora: false,

		//Cadastros -> Depositante
		visualizarDepositante: false,

		//Cadastros -> Produtos
		visualizarProdutosArmz: false,
		visualizarProdutosForn: false,
		visualizarProdutosCross: false,
		visualizarProdutosPicking: false,

		//Cadastros -> Endereços
		visualizarEnderecosArmz: false,

		//Cadastros -> Veículo
		visualizarVeiculo: false,
		visualizarMotorista: false,

		//Cadastros -> Equipamento
		visualizarEquipamento: false,

		//Cadastros -> Colaborador
		visualizarColaborador: false,

		//Cadastros -> Fiscal
		visualizarCFOP: false,
		visualizarCFOPEntradaSaida: false,
		visualizarGrupoTributario: false,

		//AdmOperacional -> Fiscal-Entrada
		visualizarNfeEntradasArmz: false,
		visualizarNfeEntradasCross: false,
		visualizarNfeEntradasPedido: false,
		//AdmOperacional -> Fiscal-Saída
		visualizarNfeSaida: false,
		visualizarSaldoFiscal: false,
		visualizarNfeEmitido: false,
		visualizarEmissaoNfe: false,
		visualizarManifestarNfe: false,
		//AdmOperacional -> Controle Processo
		visualizarProcEntrada: false,
		visualizarProcSaida: false,
		//AdmOperacional -> Pedidos
		visualizarImportacaoArquivo: false,
		visualizarNfeParaPedido: false,
		visualizarPedidoManual: false,
		visualizarPedidosPendentes: false,
		visualizarPedidosSeparacao: false,
		//AdmOperacional -> Inventário
		visualizarInventario: false,
		//AdmOperacional -> Ajustes
		visualizarAjusteEstoque: false,
		visualizarAjusteRecebimento: false,
		visualizarAjusteMovimento: false,

		//Estoque -> Gestao de Estoque
		visualizarEstoqueDetalhado: false,
		visualizarEstoqueSintetico: false,
		visualizarRomaneioBarrasAtivas: false,
		//Estoque -> Romaneio de Barras Ativas
		visualizarUAVazioSaidaSemUsoVazio: false,
		visualizarUAVazioSaidaSemUsoSaida: false,

		//Operacional -> Conferencia de Entrada
		visualizarConferirRecebimento: false,
		//Operacional -> Conferencia de Saida
		visualizarConferirCarregamento: false,
		//Operacional -> Endereçar U.A
		visualizarEnderecarUA: false,
		//Operacional -> Desfragmentação
		visualizarDesfragmentarUA: false,
		//Operacional -> Análise de Otimização
		visualizarAnaliseOtimizacao: false,
		//Operacional -> Reposição de Picking
		visualizarReposicaoPrimaria: false,
		//Operacional -> Reposição Complementar
		visualizarReposicaoComplementar: false,
		//Operacional -> Impressão de Etiquetas
		visualizarImpressaoEtiquetas: false,

		//Gerencial -> Exceção
		visualizarExcecaoQuebraFefo: false,
		visualizarExcecaoLerGtin: false,
		visualizarExcecaoLerVolume: false,
		visualizarExcecaoEndereco: false,
		visualizarExcecaoZonaEnderecamento: false,

		// Relatórios -> Rastreabilidade
		visualizarRelRastreabilidadeProduto: false,

		// Relatórios -> Pedidos
		visualizarRelRomaneioCarga: false,

		// Relatórios -> Movimentação
		visualizarRelMovimentacaoPeriodo: false,
		visualizarRelCurvaABC: false,
		visualizarRelHistoricoEnderecamento: false,

		// Faturamento de Serviços -> Tarifas
		visualizarCadastrarTarifas: false,

		// Faturamento de Serviços -> Corte Período
		visualizarGerarCorte: false,
		visualizarEstornarCorte: false,

		// Faturamento de Serviços -> Emissão de Fatura
		visualizarEmissaoFatura: false,

		// Faturamento de Serviços -> Serviço Extra
		visualizarLancarServicoExtra: false,

		// Faturamento de Serviços -> Liberar Faturamento
		visualizarBonificarFaturamento: false,

		// Indicadores
		visualizarIndicadoresMovimentacao: false,
		visualizarIndicadoresProdutividade: false,
		visualizarIndicadoresAcuracidade: false,

		// Logs do Sistema -> Log's de Acao do usuario
		visualizarLogAcaoUsuario: false,

		// Logs do Sistema -> Log's de Integração
		visualizarLogApiPedidos: false,
		visualizarLogIntegracao: false,
	});

	//left bar dinamica

	const [LeftBarInicial, setLeftBarInicial] = useState([
		{
			menu_principal: {
				href: '#Administrativo',
				aria_controls: 'Administrativo',
				data_icon: 'carbon:operations-record',
				menu: 'Administrativo',
				id: 'Administrativo',
				exibir: true,
				sub_menus: [
					{
						sub_menu: 'Controle de Acessos',
						href: '#ControleDeAcessos',
						menu_composto: 1,
						id_submenu: 'ControleDeAcessos',
						aria_controls: 'ControleDeAcessos',
						exibir: true,
						opcoes_submenu: [
							{
								sub_menu: 'Usuários',
								exibir_sub_menu: true,
								to: '/config/segur/usuarios',
							},
							{
								sub_menu: 'Perfil/Permissão',
								exibir_sub_menu: true,
								to: '/config/segur/cadastroDePermissoes',
							},
							{
								sub_menu: 'Usuários Filial',
								exibir_sub_menu: true,
								to: '/administrativo/filial/controleacesso/usuariosfilial',
							},
							{
								sub_menu: 'Atividades Usuários',
								exibir_sub_menu: true,
								to: '/config/segur/atividadeUsuarios',
							},
						],
					},
					{
						sub_menu: 'Códigos do Sistema',
						href: '#CódigosDoSisyema',
						menu_composto: 1,
						id_submenu: 'CódigosDoSisyema',
						aria_controls: 'CódigosDoSisyema',
						exibir: true,
						opcoes_submenu: [
							{
								sub_menu: 'Código do Sistema',
								exibir_sub_menu: true,
								to: '/admin/codsis/codsis',
							},
							{
								sub_menu: 'Controle de Numeração',
								exibir_sub_menu: true,
								to: '/config/cadast/controlenumeracao',
							},
						],
					},
					{
						sub_menu: 'Cad. Administrativo',
						href: '#CadastroAdmnistrativo',
						menu_composto: 1,
						id_submenu: 'CadastroAdmnistrativo',
						aria_controls: 'CadastroAdmnistrativo',
						exibir: true,
						opcoes_submenu: [
							{
								sub_menu: 'Cadastro de Filial',
								exibir_sub_menu: true,
								to: '/config/cadast/filial',
							},
						],
					},
				],
			},
		},
		{
			menu_principal: {
				href: '#Cadastros',
				aria_controls: 'Cadastros',
				data_icon: 'system-uicons:write',
				menu: 'Cadastros',
				id: 'Cadastros',
				exibir: true,
				sub_menus: [
					{
						sub_menu: 'Entidade',
						href: '#Entidade',
						menu_composto: 1,
						id_submenu: 'Entidade',
						aria_controls: 'Entidade',
						exibir: true,
						opcoes_submenu: [
							{
								sub_menu: 'Entidade',
								exibir_sub_menu: true,
								to: '/config/cadast/entidade',
							},
							{
								sub_menu: 'Transportadora',
								exibir_sub_menu: true,
								to: '/config/cadast/transportadora',
							},
						],
					},
					{
						sub_menu: 'Depositante',
						href: '#Depositante',
						menu_composto: 1,
						id_submenu: 'Depositante',
						aria_controls: 'Depositante',
						exibir: true,
						opcoes_submenu: [
							{
								sub_menu: 'Depositante',
								exibir_sub_menu: true,
								to: '/config/cadast/depositante',
							},
						],
					},
					{
						sub_menu: 'Produtos',
						href: '#Produtos',
						menu_composto: 1,
						id_submenu: 'Produtos',
						aria_controls: 'Produtos',
						exibir: true,
						opcoes_submenu: [
							{
								sub_menu: 'Produtos de Armazenagem',
								exibir_sub_menu: true,
								to: '/cadast/produto/prodarm',
							},
							{
								sub_menu: 'Produtos de Fonecedor',
								exibir_sub_menu: true,
								to: '/',
							},
							{
								sub_menu: 'Produtos de Cross',
								exibir_sub_menu: true,
								to: '/',
							},
							{
								sub_menu: 'Produto Picking',
								exibir_sub_menu: true,
								to: '/',
							},
						],
					},
					{
						sub_menu: 'Endereços',
						href: '#Endereços',
						menu_composto: 1,
						id_submenu: 'Endereços',
						aria_controls: 'Endereços',
						exibir: true,
						opcoes_submenu: [
							{
								sub_menu: 'Endereços de Armazém',
								exibir_sub_menu: true,
								to: '/config/cadast/enderecos',
							},
						],
					},
					{
						sub_menu: 'Veículo',
						href: '#Veículo',
						menu_composto: 1,
						id_submenu: 'Veículo',
						aria_controls: 'Veículo',
						exibir: true,
						opcoes_submenu: [
							{
								sub_menu: 'Veículo',
								exibir_sub_menu: true,
								to: '/cadast/veiculo/veiculo',
							},
							{
								sub_menu: 'Motorista',
								exibir_sub_menu: true,
								to: '/cadast/veiculo/motorista',
							},
						],
					},
					{
						sub_menu: 'Equipamento',
						href: '#Equipamento',
						menu_composto: 1,
						id_submenu: 'Equipamento',
						aria_controls: 'Equipamento',
						exibir: true,
						opcoes_submenu: [
							{
								sub_menu: 'Equipamento',
								exibir_sub_menu: true,
								to: '/cadast/equipamento/equipamento',
							},
						],
					},
					{
						sub_menu: 'Colaborador',
						href: '#Colaborador',
						menu_composto: 1,
						id_submenu: 'Colaborador',
						aria_controls: 'Colaborador',
						exibir: true,
						opcoes_submenu: [
							{
								sub_menu: 'Colaborador',
								exibir_sub_menu: true,
								to: '/config/cadast/colaborador',
							},
						],
					},
					{
						sub_menu: 'Fiscal',
						href: '#Fiscal',
						menu_composto: 1,
						id_submenu: 'Fiscal',
						aria_controls: 'Fiscal',
						exibir: true,
						opcoes_submenu: [
							{
								sub_menu: 'CFOP',
								exibir_sub_menu: true,
								to: '/pco/contrFisc/contrNotFisc/cfop',
							},
							{
								sub_menu: 'CFOP Entrada x Saída',
								exibir_sub_menu: true,
								to: '/pco/contrFisc/contrNotFisc/cfopEntradaXsaida',
							},
							{
								sub_menu: 'Grupo Tributario',
								exibir_sub_menu: true,
								to: '/pco/contrFisc/gruposTributarios',
							},
						],
					},
				],
			},
		},
		{
			menu_principal: {
				href: '#AdmOperacional',
				aria_controls: 'AdmOperacional',
				data_icon: 'clarity:administrator-line',
				menu: 'Adm.Operacional',
				id: 'AdmOperacional',
				exibir: true,
				sub_menus: [
					{
						sub_menu: 'Fiscal-Entrada',
						href: '#FiscalEntrada',
						menu_composto: 1,
						id_submenu: 'FiscalEntrada',
						aria_controls: 'FiscalEntrada',
						exibir: true,
						opcoes_submenu: [
							{
								sub_menu: 'NFe-Entrada-Armazenagem',
								exibir_sub_menu: true,
								to: '/pco/contrFisc/contrNotFisc/impNotFisc',
							},
							/*
							{
								sub_menu: "NFe-Entrada-Armazenagem",
								exibir_sub_menu: true,
								to: "/pco/contrFisc/contrNotFisc/notasFiscaisArmazenagem"
							},
							*/
							{
								sub_menu: 'NFe-Entrada-CrossDocking',
								exibir_sub_menu: true,
								to: '/pco/contrFisc/contrNotFisc/notasFiscaisArmazenagem',
							},
							{
								sub_menu: 'NFe-Pedido',
								exibir_sub_menu: true,
								to: '/',
							},
						],
					},
					{
						sub_menu: 'Fiscal-Saída',
						href: '#FiscalSAida',
						menu_composto: 1,
						id_submenu: 'FiscalSAida',
						aria_controls: 'FiscalSAida',
						exibir: true,
						opcoes_submenu: [
							{
								sub_menu: 'Saldo Fiscal',
								exibir_sub_menu: true,
								to: '/',
							},
							{
								sub_menu: 'NFE-Emitido',
								exibir_sub_menu: true,
								to: '/',
							},
							{
								sub_menu: 'Emissão de NFe',
								exibir_sub_menu: true,
								to: '/',
							},
							{
								sub_menu: 'Manifestar NFe',
								exibir_sub_menu: true,
								to: '/',
							},
						],
					},
					{
						sub_menu: 'Controle Processo',
						href: '#ControleProcesso',
						menu_composto: 1,
						id_submenu: 'ControleProcesso',
						aria_controls: 'ControleProcesso',
						exibir: true,
						opcoes_submenu: [
							{
								sub_menu: 'Processo de Entrada',
								exibir_sub_menu: true,
								to: '/controle/processo/entrada/lista',
							},
							{
								sub_menu: 'Processo de Saída',
								exibir_sub_menu: true,
								to: '/pco/contrFisc/procEVeiculos/processoSaida',
							},
						],
					},
					{
						sub_menu: 'Pedidos',
						href: '#Pedidos',
						menu_composto: 1,
						id_submenu: 'Pedidos',
						aria_controls: 'Pedidos',
						exibir: true,
						opcoes_submenu: [
							{
								sub_menu: 'Criação de Pedidos',
								exibir_sub_menu: true,
								to: 'admoperacional/pedido/criacao',
							},
							{
								sub_menu: 'Pedidos Pendentes',
								exibir_sub_menu: true,
								to: 'admoperacional/pedido/pendente',
							},
							{
								sub_menu: 'Pedidos em Separação',
								exibir_sub_menu: true,
								to: 'admoperacional/pedido/separacao',
							},
							{
								sub_menu: 'Tarefa Programada',
								exibir_sub_menu: true,
								to: 'tarefa/programada/listagem',
							},
							{
								sub_menu: 'O.S Pendente de Embarque',
								exibir_sub_menu: true,
								to: 'os/pendente/embarque/listagem',
							},
						],
					},
					{
						sub_menu: 'Inventário',
						href: '#Inventario',
						menu_composto: 1,
						id_submenu: 'Inventario',
						aria_controls: 'Inventario',
						exibir: true,
						opcoes_submenu: [
							{
								sub_menu: 'Inventário',
								exibir_sub_menu: true,
								to: '/',
							},
						],
					},
					{
						sub_menu: 'Ajustes',
						href: '#Ajustes',
						menu_composto: 1,
						id_submenu: 'Ajustes',
						aria_controls: 'Ajustes',
						exibir: true,
						opcoes_submenu: [
							{
								sub_menu: 'Ajuste de Estoque',
								exibir_sub_menu: true,
								to: 'ajuste/estoque',
							},
							{
								sub_menu: 'Ajuste em Recebimento',
								exibir_sub_menu: true,
								to: '/',
							},
							{
								sub_menu: 'Ajuste de Movimentação',
								exibir_sub_menu: true,
								to: 'ajuste/estoque/movimento',
							},
						],
					},
				],
			},
		},
		{
			menu_principal: {
				href: '#Estoque',
				aria_controls: 'Estoque',
				data_icon: 'bx:package',
				menu: 'Estoque',
				id: 'Estoque',
				exibir: true,
				sub_menus: [
					{
						sub_menu: 'Gestão de Estoque',
						href: '#GestaoEstoque',
						menu_composto: 1,
						id_submenu: 'GestaoEstoque',
						aria_controls: 'GestaoEstoque',
						exibir: true,
						opcoes_submenu: [
							{
								sub_menu: 'Estoque Detalhado',
								exibir_sub_menu: true,
								to: '/gestEstoque/estoqueDetalhado',
							},
							{
								sub_menu: 'Estoque Sintético',
								exibir_sub_menu: true,
								to: '/gestEstoque/estoqueSintetico',
							},
						],
					},
					{
						sub_menu: 'Romaneio de Barras Ativas',
						href: '#RomaneioBarrasAtivas',
						menu_composto: 1,
						id_submenu: 'RomaneioBarrasAtivas',
						aria_controls: 'RomaneioBarrasAtivas',
						exibir: true,
						opcoes_submenu: [
							{
								sub_menu: 'Consultar',
								exibir_sub_menu: true,
								to: '/gestEstoque/consultaCodigoBarras',
							},
						],
					},
					{
						sub_menu: 'U.A Vazio/Saída e Sem Uso',
						href: '#UaVazioSemUso',
						menu_composto: 1,
						id_submenu: 'UaVazioSemUso',
						aria_controls: 'UaVazioSemUso',
						exibir: true,
						opcoes_submenu: [
							{
								sub_menu: 'Pallet sem Uso',
								exibir_sub_menu: true,
								to: '/gestEstoque/palletSemUso',
							},
						],
					},
				],
			},
		},
		{
			menu_principal: {
				href: '#Operacional',
				aria_controls: 'Operacional',
				data_icon: 'basil:sort-solid',
				menu: 'Operacional',
				id: 'Operacional',
				exibir: true,
				sub_menus: [
					{
						sub_menu: 'Confêrencia de Entrada',
						href: '#ConferenciaEntrada',
						menu_composto: 1,
						id_submenu: 'ConferenciaEntrada',
						aria_controls: 'ConferenciaEntrada',
						exibir: true,
						opcoes_submenu: [
							{
								sub_menu: 'Conferir Recebimento',
								exibir_sub_menu: true,
								to: linkSWM,
								id: 'confEntrada'
							},
						],
					},
					{
						sub_menu: 'Confêrencia de Saída',
						href: '#ConferenciaSaida',
						menu_composto: 1,
						id_submenu: 'ConferenciaSaida',
						aria_controls: 'ConferenciaSaida',
						exibir: true,
						opcoes_submenu: [
							{
								sub_menu: 'Conferir Carregamento',
								exibir_sub_menu: true,
								to: linkSWM,
								id: "confSaida"
							},
						],
					},
					{
						sub_menu: 'Endereçar',
						href: '#Enderecar',
						menu_composto: 1,
						id_submenu: 'Enderecar',
						aria_controls: 'Enderecar',
						exibir: true,
						opcoes_submenu: [
							{
								sub_menu: 'Endereçar U.A',
								exibir_sub_menu: true,
								to: linkSWM,
								id: 'enderecar'
							},
						],
					},
					{
						sub_menu: 'Desfragmentação',
						href: '#Desfragmentar',
						menu_composto: 1,
						id_submenu: 'Desfragmentar',
						aria_controls: 'Desfragmentar',
						exibir: true,
						opcoes_submenu: [
							{
								sub_menu: 'Desfragmentar U.A',
								exibir_sub_menu: true,
								to: linkSWM,
								id: 'desfragmentar'
							},
							{
								sub_menu: 'Análise de Otimização',
								exibir_sub_menu: true,
								to: '/',
							},
						],
					},
					{
						sub_menu: 'Ressuprimento de Picking',
						href: '#RepPicking',
						menu_composto: 1,
						id_submenu: 'RepPicking',
						aria_controls: 'RepPicking',
						exibir: true,
						opcoes_submenu: [
							{
								sub_menu: 'Reposição Primária',
								exibir_sub_menu: true,
								to: '/',
							},
							{
								sub_menu: 'Reposição Complementar',
								exibir_sub_menu: true,
								to: '/',
							},
						],
					},
					{
						sub_menu: 'Impressão de Etiquetas',
						href: '#Etiquetas',
						menu_composto: 1,
						id_submenu: 'Etiquetas',
						aria_controls: 'Etiquetas',
						exibir: true,
						opcoes_submenu: [
							{
								sub_menu: 'Reimpressão',
								exibir_sub_menu: true,
								to: '/',
							},
							{
								sub_menu: 'Gerar U.A Vazio/Saída',
								exibir_sub_menu: true,
								to: '/',
							},
							{
								sub_menu: 'Endereço',
								exibir_sub_menu: true,
								to: '/',
							},
							{
								sub_menu: 'Produto Picking',
								exibir_sub_menu: true,
								to: '/',
							},
						],
					},
				],
			},
		},
		{
			menu_principal: {
				href: '#Gerencial',
				aria_controls: 'Gerencial',
				data_icon: 'oui:app-management',
				menu: 'Gerencial',
				id: 'Gerencial',
				exibir: true,
				sub_menus: [
					{
						sub_menu: 'Exceção',
						href: '#Excecao',
						menu_composto: 1,
						id_submenu: 'Excecao',
						aria_controls: 'Excecao',
						exibir: true,
						opcoes_submenu: [
							{
								sub_menu: 'Quebra-FEFO',
								exibir_sub_menu: true,
								to: '/',
							},
							{
								sub_menu: 'Endereço',
								exibir_sub_menu: true,
								to: '/',
							},
							{
								sub_menu: 'Ler GTIN',
								exibir_sub_menu: true,
								to: linkSWM,
								id: 'confEntrada'
							},
							{
								sub_menu: 'Ler Volume',
								exibir_sub_menu: true,
								to: linkSWM,
								id: 'confEntrada'
							},
						],
					},
				],
			},
		},
		{
			menu_principal: {
				href: '#Relatorios',
				aria_controls: 'Relatorios',
				data_icon: 'carbon:dashboard-reference',
				menu: 'Relatórios',
				id: 'Relatorios',
				exibir: true,
				sub_menus: [
					{
						sub_menu: 'Rastreabilidade',
						href: '#Rastreabilidade',
						menu_composto: 1,
						id_submenu: 'Rastreabilidade',
						aria_controls: 'Rastreabilidade',
						exibir: true,
						opcoes_submenu: [
							{
								sub_menu: 'Rastreabilidade Produto',
								exibir_sub_menu: true,
								to: '/',
							},
						],
					},
					{
						sub_menu: 'Pedidos',
						href: '#Pedidos',
						menu_composto: 1,
						id_submenu: 'Pedidos',
						aria_controls: 'Pedidos',
						exibir: true,
						opcoes_submenu: [
							{
								sub_menu: 'Romaneio de Carga',
								exibir_sub_menu: true,
								to: '/pco/contrFisc/gestPedidos/romaneioSaida',
							},
						],
					},
					{
						sub_menu: 'Movimentação',
						href: '#Movimentacao',
						menu_composto: 1,
						id_submenu: 'Movimentacao',
						aria_controls: 'Movimentacao',
						exibir: true,
						opcoes_submenu: [
							{
								sub_menu: 'Movimentação Período',
								exibir_sub_menu: true,
								to: '/',
							},
							{
								sub_menu: 'Curva ABC',
								exibir_sub_menu: true,
								to: '/',
							},
							{
								sub_menu: 'Histórico de Endereçamento',
								exibir_sub_menu: true,
								to: '/gerenciamento/enderecamento/historicoEnderecamento',
							},
						],
					},
				],
			},
		},
		{
			menu_principal: {
				href: '#Faturamento',
				aria_controls: 'Faturamento',
				data_icon: 'mage:money-exchange',
				menu: 'Fat. de Serviços',
				id: 'Faturamento',
				exibir: true,
				sub_menus: [
					{
						sub_menu: 'Tarifas',
						href: '#Tarifas',
						menu_composto: 1,
						id_submenu: 'Tarifas',
						aria_controls: 'Tarifas',
						exibir: true,
						opcoes_submenu: [
							{
								sub_menu: 'Cadastrar Tarifas',
								exibir_sub_menu: true,
								to: '/faturamento/manuntencao/cadastroTarifas',
							},
						],
					},
					{
						sub_menu: 'Corte Período',
						href: '#CortePeriodo',
						menu_composto: 1,
						id_submenu: 'CortePeriodo',
						aria_controls: 'CortePeriodo',
						exibir: true,
						opcoes_submenu: [
							{
								sub_menu: 'Gerar Corte',
								exibir_sub_menu: true,
								to: '/',
							},
							{
								sub_menu: 'Estornar Corte',
								exibir_sub_menu: true,
								to: '/',
							},
						],
					},
					{
						sub_menu: 'Emissão de Fatura',
						href: '#EmissaoFatura',
						menu_composto: 1,
						id_submenu: 'EmissaoFatura',
						aria_controls: 'EmissaoFatura',
						exibir: true,
						opcoes_submenu: [
							{
								sub_menu: 'Emissão de Fatura',
								exibir_sub_menu: true,
								to: '/',
							},
						],
					},
					{
						sub_menu: 'Serviço Extra',
						href: '#ServiçoExtra',
						menu_composto: 1,
						id_submenu: 'ServiçoExtra',
						aria_controls: 'ServiçoExtra',
						exibir: true,
						opcoes_submenu: [
							{
								sub_menu: 'Lançar Serviço Extra',
								exibir_sub_menu: true,
								to: '/',
							},
						],
					},
					{
						sub_menu: 'Exceção/Ajuste',
						href: '#LiberarFat',
						menu_composto: 1,
						id_submenu: 'LiberarFat',
						aria_controls: 'LiberarFat',
						exibir: true,
						opcoes_submenu: [
							{
								sub_menu: 'Bonificar Movimentação',
								exibir_sub_menu: true,
								to: '/',
							},
							{
								sub_menu: 'Alterar Data Movimentação',
								exibir_sub_menu: true,
								to: '/',
							},
						],
					},
				],
			},
		},
		{
			menu_principal: {
				href: '#Indicadores',
				aria_controls: 'Indicadores',
				data_icon: 'streamline:money-graph-arrow-increase-ascend-growth-up-arrow-stats-graph-right-grow',
				menu: 'Indicadores',
				id: 'Indicadores',
				exibir: true,
				sub_menus: [
					{
						sub_menu: 'Tarifas',
						href: '#Tarifas',
						menu_composto: 0,
						id_submenu: 'Tarifas',
						aria_controls: 'Tarifas',
						exibir: true,
						opcoes_submenu: [
							{
								sub_menu: 'Movimentação',
								exibir_sub_menu: true,
								to: '/',
							},
							{
								sub_menu: 'Produtividade',
								exibir_sub_menu: true,
								to: '/',
							},
							{
								sub_menu: 'Acuracidade',
								exibir_sub_menu: true,
								to: '/',
							},
						],
					},
				],
			},
		},
		{
			menu_principal: {
				href: '#LogDoSISTEMA',
				aria_controls: 'LogDoSISTEMA',
				data_icon: 'tdesign:system-log',
				menu: 'Logs do Sistema',
				id: 'LogDoSISTEMA',
				exibir: true,
				sub_menus: [
					{
						sub_menu: 'Log de Integração',
						href: '#LogIntegração',
						menu_composto: 0,
						id_submenu: 'LogIntegração',
						aria_controls: 'LogIntegração',
						exibir: true,
						opcoes_submenu: [
							{
								sub_menu: 'Log Integração',
								exibir_sub_menu: true,
								to: '/',
							},
							{
								sub_menu: 'Log Ação Usuário',
								exibir_sub_menu: true,
								to: '/',
							},
						],
					},
				],
			},
		},
	]);

	const [menus, setMenus] = useState<any>([]);

	//#region Modifica o status do Modal Carregando
	const [showModalCarregando, setShowModalCarregando] = useState(false);

	useEffect(
		function () {
			setLeftBarInicial([
				{
					menu_principal: {
						href: '#Administrativo',
						aria_controls: 'Administrativo',
						data_icon: 'carbon:operations-record',
						menu: 'Administrativo',
						id: 'Administrativo',
						exibir:
							permissoes.visualizarUsuario ||
								permissoes.visualizarPerfilPermissao ||
								permissoes.visualizarUsuariosFilial ||
								permissoes.visualizarAtividadesUsuario ||
								permissoes.visualizarCodSistema ||
								permissoes.isMaster
								? true
								: false,
						sub_menus: [
							{
								sub_menu: 'Controle de Acessos',
								href: '#ControleDeAcessos',
								menu_composto: 1,
								id_submenu: 'ControleDeAcessos',
								aria_controls: 'ControleDeAcessos',
								exibir:
									permissoes.visualizarUsuario ||
										permissoes.visualizarPerfilPermissao ||
										permissoes.visualizarUsuariosFilial ||
										permissoes.visualizarAtividadesUsuario
										? true
										: false,
								opcoes_submenu: [
									{
										sub_menu: 'Usuários',
										exibir_sub_menu: permissoes.visualizarUsuario,
										to: '/config/segur/usuarios',
									},
									{
										sub_menu: 'Perfil/Permissão',
										exibir_sub_menu: permissoes.visualizarPerfilPermissao,
										to: '/config/segur/cadastroDePermissoes',
									},
									{
										sub_menu: 'Usuários Filial',
										exibir_sub_menu: permissoes.visualizarUsuariosFilial,
										to: '/administrativo/filial/controleacesso/usuariosfilial',
									},
									{
										sub_menu: 'Atividades Usuários',
										exibir_sub_menu: permissoes.visualizarAtividadesUsuario,
										to: '/config/segur/atividadeUsuarios',
									},
								],
							},
							{
								sub_menu: 'Códigos do Sistema',
								href: '#CódigosDoSisyema',
								menu_composto: 1,
								id_submenu: 'CódigosDoSisyema',
								aria_controls: 'CódigosDoSisyema',
								exibir: permissoes.visualizarCodSistema,
								opcoes_submenu: [
									{
										sub_menu: 'Código do Sistema',
										exibir_sub_menu: permissoes.visualizarCodSistema,
										to: '/admin/codsis/codsis',
									},
								],
							},
							{
								sub_menu: 'Cad. Administrativo',
								href: '#CadastroAdmnistrativo',
								menu_composto: 1,
								id_submenu: 'CadastroAdmnistrativo',
								aria_controls: 'CadastroAdmnistrativo',
								exibir: permissoes.isMaster,
								opcoes_submenu: [
									{
										sub_menu: 'Cadastro de Filial',
										exibir_sub_menu: permissoes.isMaster,
										to: '/config/cadast/filial',
									},
									{
										sub_menu: 'Controle de Numeração',
										exibir_sub_menu: permissoes.isMaster,
										to: '/config/cadast/controlenumeracao',
									},
								],
							},
						],
					},
				},
				{
					menu_principal: {
						href: '#Cadastros',
						aria_controls: 'Cadastros',
						data_icon: 'system-uicons:write',
						menu: 'Cadastros',
						id: 'Cadastros',
						exibir:
							permissoes.visualizarEntidade ||
							permissoes.visualizarTransportadora ||
							permissoes.visualizarDepositante ||
							permissoes.visualizarProdutosArmz ||
							permissoes.visualizarProdutosForn ||
							permissoes.visualizarProdutosCross ||
							permissoes.visualizarProdutosPicking ||
							permissoes.visualizarEnderecosArmz ||
							permissoes.visualizarVeiculo ||
							permissoes.visualizarMotorista ||
							permissoes.visualizarEquipamento ||
							permissoes.visualizarColaborador ||
							permissoes.visualizarCFOP ||
							permissoes.visualizarCFOPEntradaSaida ||
							permissoes.visualizarGrupoTributario,
						sub_menus: [
							{
								sub_menu: 'Entidade',
								href: '#Entidade',
								menu_composto: 1,
								id_submenu: 'Entidade',
								aria_controls: 'Entidade',
								exibir: permissoes.visualizarEntidade || permissoes.visualizarTransportadora,
								opcoes_submenu: [
									{
										sub_menu: 'Entidade',
										exibir_sub_menu: permissoes.visualizarEntidade,
										to: '/config/cadast/entidade',
									},
									{
										sub_menu: 'Transportadora',
										exibir_sub_menu: permissoes.visualizarTransportadora,
										to: '/config/cadast/transportadora',
									},
								],
							},
							{
								sub_menu: 'Depositante',
								href: '#Depositante',
								menu_composto: 1,
								id_submenu: 'Depositante',
								aria_controls: 'Depositante',
								exibir: permissoes.visualizarDepositante,
								opcoes_submenu: [
									{
										sub_menu: 'Depositante',
										exibir_sub_menu: permissoes.visualizarDepositante,
										to: '/config/cadast/depositante',
									},
								],
							},
							{
								sub_menu: 'Produtos',
								href: '#Produtos',
								menu_composto: 1,
								id_submenu: 'Produtos',
								aria_controls: 'Produtos',
								exibir:
									permissoes.visualizarProdutosArmz ||
									permissoes.visualizarProdutosForn ||
									permissoes.visualizarProdutosCross ||
									permissoes.visualizarProdutosPicking,
								opcoes_submenu: [
									{
										sub_menu: 'Produtos de Armazenagem',
										exibir_sub_menu: permissoes.visualizarProdutosArmz,
										to: '/cadast/produto/prodarm',
									},
									{
										sub_menu: 'Produtos de Fonecedor',
										exibir_sub_menu: permissoes.visualizarProdutosForn,
										to: '/cadast/produto/prodforn',
									},
									{
										sub_menu: 'Produtos de Cross',
										exibir_sub_menu: permissoes.visualizarProdutosCross,
										to: '/',
									},
									{
										sub_menu: 'Produto Picking',
										exibir_sub_menu: permissoes.visualizarProdutosPicking,
										to: '/cadast/produto/prodpick',
									},
								],
							},
							{
								sub_menu: 'Endereços',
								href: '#Endereços',
								menu_composto: 1,
								id_submenu: 'Endereços',
								aria_controls: 'Endereços',
								exibir: permissoes.visualizarEnderecosArmz,
								opcoes_submenu: [
									{
										sub_menu: 'Endereços de Armazém',
										exibir_sub_menu: permissoes.visualizarEnderecosArmz,
										to: '/cadast/endereco/enderecoarm',
									},
								],
							},
							{
								sub_menu: 'Veículo',
								href: '#Veículo',
								menu_composto: 1,
								id_submenu: 'Veículo',
								aria_controls: 'Veículo',
								exibir: permissoes.visualizarVeiculo || permissoes.visualizarMotorista,
								opcoes_submenu: [
									{
										sub_menu: 'Veículo',
										exibir_sub_menu: permissoes.visualizarVeiculo,
										to: '/cadast/veiculo/veiculo',
									},
									{
										sub_menu: 'Motorista',
										exibir_sub_menu: permissoes.visualizarMotorista,
										to: '/cadast/veiculo/motorista',
									},
								],
							},
							{
								sub_menu: 'Equipamento',
								href: '#Equipamento',
								menu_composto: 1,
								id_submenu: 'Equipamento',
								aria_controls: 'Equipamento',
								exibir: permissoes.visualizarEquipamento,
								opcoes_submenu: [
									{
										sub_menu: 'Equipamento',
										exibir_sub_menu: permissoes.visualizarEquipamento,
										to: '/cadast/equipamento/equipamento',
									},
								],
							},
							{
								sub_menu: 'Colaborador',
								href: '#Colaborador',
								menu_composto: 1,
								id_submenu: 'Colaborador',
								aria_controls: 'Colaborador',
								exibir: permissoes.visualizarColaborador,
								opcoes_submenu: [
									{
										sub_menu: 'Colaborador',
										exibir_sub_menu: permissoes.visualizarColaborador,
										to: '/config/cadast/colaborador',
									},
								],
							},
							{
								sub_menu: 'Fiscal',
								href: '#Fiscal',
								menu_composto: 1,
								id_submenu: 'Fiscal',
								aria_controls: 'Fiscal',
								exibir: permissoes.visualizarCFOP || permissoes.visualizarCFOPEntradaSaida || permissoes.visualizarGrupoTributario,
								opcoes_submenu: [
									{
										sub_menu: 'CFOP',
										exibir_sub_menu: permissoes.visualizarCFOP,
										to: '/cadast/fiscal/cfop',
									},
									{
										sub_menu: 'CFOP Entrada x Saída',
										exibir_sub_menu: permissoes.visualizarCFOPEntradaSaida,
										to: '/cadast/fiscal/cfopentradasaida',
									},
									{
										sub_menu: 'Grupo Tributario',
										exibir_sub_menu: permissoes.visualizarGrupoTributario,
										to: '/pco/contrFisc/gruposTributarios',
									},
								],
							},
						],
					},
				},
				{
					menu_principal: {
						href: '#AdmOperacional',
						aria_controls: 'AdmOperacional',
						data_icon: 'clarity:administrator-line',
						menu: 'Adm.Operacional',
						id: 'AdmOperacional',
						exibir:
							permissoes.visualizarNfeEntradasArmz ||
							permissoes.visualizarNfeEntradasCross ||
							permissoes.visualizarNfeEntradasPedido ||
							permissoes.visualizarNfeSaida ||
							permissoes.visualizarProcEntrada ||
							permissoes.visualizarProcSaida ||
							permissoes.visualizarImportacaoArquivo ||
							permissoes.visualizarNfeParaPedido ||
							permissoes.visualizarPedidoManual ||
							permissoes.visualizarPedidosPendentes ||
							permissoes.visualizarPedidosSeparacao ||
							permissoes.visualizarInventario ||
							permissoes.visualizarAjusteEstoque ||
							permissoes.visualizarAjusteRecebimento ||
							permissoes.visualizarAjusteMovimento,
						sub_menus: [
							{
								sub_menu: 'Fiscal-Entrada',
								href: '#FiscalEntrada',
								menu_composto: 1,
								id_submenu: 'FiscalEntrada',
								aria_controls: 'FiscalEntrada',
								exibir:
									permissoes.visualizarNfeEntradasArmz ||
									permissoes.visualizarNfeEntradasCross ||
									permissoes.visualizarNfeEntradasPedido,
								opcoes_submenu: [
									{
										sub_menu: 'NFe-Entrada-Armazenagem',
										exibir_sub_menu: permissoes.visualizarNfeEntradasArmz,
										to: '/pco/contrFisc/contrNotFisc/impNotFisc',
									},
									{
										sub_menu: 'NFe-Entrada-CrossDocking',
										exibir_sub_menu: permissoes.visualizarNfeEntradasCross,
										to: 'pco/contrFisc/contrNotFisc/notasFiscaisCrossDocking',
									},
									{
										sub_menu: 'NFe-Pedido',
										exibir_sub_menu: permissoes.visualizarNfeEntradasPedido,
										to: '/pco/contrFisc/contrNotFisc/notasFiscaisPedido',
									},
								],
							},
							{
								sub_menu: 'Fiscal-Saída',
								href: '#FiscalSAida',
								menu_composto: 1,
								id_submenu: 'FiscalSAida',
								aria_controls: 'FiscalSAida',
								exibir:
									permissoes.visualizarSaldoFiscal ||
									permissoes.visualizarNfeEmitido ||
									permissoes.visualizarEmissaoNfe ||
									permissoes.visualizarManifestarNfe,
								opcoes_submenu: [
									{
										sub_menu: 'Saldo Fiscal',
										exibir_sub_menu: permissoes.visualizarSaldoFiscal,
										to: '/',
									},
									{
										sub_menu: 'NFE-Emitido',
										exibir_sub_menu: permissoes.visualizarNfeEmitido,
										to: '/',
									},
									{
										sub_menu: 'Emissão de NFe',
										exibir_sub_menu: permissoes.visualizarEmissaoNfe,
										to: '/',
									},
									{
										sub_menu: 'Manifestar NFe',
										exibir_sub_menu: permissoes.visualizarManifestarNfe,
										to: '/',
									},
								],
							},
							{
								sub_menu: 'Controle Processo',
								href: '#ControleProcesso',
								menu_composto: 1,
								id_submenu: 'ControleProcesso',
								aria_controls: 'ControleProcesso',
								exibir: permissoes.visualizarProcEntrada || permissoes.visualizarProcSaida,
								opcoes_submenu: [
									{
										sub_menu: 'Processo de Entrada',
										exibir_sub_menu: permissoes.visualizarProcEntrada,
										to: '/controle/processo/entrada/lista',
									},
									{
										sub_menu: 'Processo de Saída',
										exibir_sub_menu: permissoes.visualizarProcSaida,
										to: '/pco/contrFisc/procEVeiculos/processoSaida',
									},
								],
							},
							{
								sub_menu: 'Pedidos',
								href: '#Pedidos',
								menu_composto: 1,
								id_submenu: 'Pedidos',
								aria_controls: 'Pedidos',
								exibir:
									permissoes.visualizarImportacaoArquivo ||
									permissoes.visualizarNfeParaPedido ||
									permissoes.visualizarPedidoManual ||
									permissoes.visualizarPedidosPendentes ||
									permissoes.visualizarPedidosSeparacao,
								opcoes_submenu: [
									{
										sub_menu: 'Criação de Pedidos',
										exibir_sub_menu:
											permissoes.visualizarImportacaoArquivo ||
											permissoes.visualizarNfeParaPedido ||
											permissoes.visualizarPedidoManual,
										to: 'admoperacional/pedido/criacao',
									},
									{
										sub_menu: 'Pedidos Pendentes',
										exibir_sub_menu: permissoes.visualizarPedidosPendentes,
										to: 'admoperacional/pedido/pendente',
									},
									{
										sub_menu: 'Pedidos em Separação',
										exibir_sub_menu: permissoes.visualizarPedidosSeparacao,
										to: 'admoperacional/pedido/separacao',
									},
									{
										sub_menu: 'Tarefa Programada',
										exibir_sub_menu: true,
										to: 'tarefa/programada/listagem',
									},
									{
										sub_menu: 'O.S Pendente de Embarque',
										exibir_sub_menu: true,
										to: 'os/pendente/embarque/listagem',
									},
								],
							},
							{
								sub_menu: 'Inventário',
								href: '#Inventario',
								menu_composto: 1,
								id_submenu: 'Inventario',
								aria_controls: 'Inventario',
								exibir: permissoes.visualizarInventario,
								opcoes_submenu: [
									{
										sub_menu: 'Inventário',
										exibir_sub_menu: permissoes.visualizarInventario,
										to: '/',
									},
								],
							},
							{
								sub_menu: 'Ajustes',
								href: '#Ajustes',
								menu_composto: 1,
								id_submenu: 'Ajustes',
								aria_controls: 'Ajustes',
								exibir:
									permissoes.visualizarAjusteEstoque ||
									permissoes.visualizarAjusteRecebimento ||
									permissoes.visualizarAjusteMovimento,
								opcoes_submenu: [
									{
										sub_menu: 'Ajuste de Estoque',
										exibir_sub_menu: permissoes.visualizarAjusteEstoque,
										to: 'ajuste/estoque',
									},
									{
										sub_menu: 'Ajuste em Recebimento',
										exibir_sub_menu: permissoes.visualizarAjusteRecebimento,
										to: '/',
									},
									{
										sub_menu: 'Ajuste de Movimentação',
										exibir_sub_menu: permissoes.visualizarAjusteMovimento,
										to: 'ajuste/estoque/movimento',
									},
								],
							},
						],
					},
				},
				{
					menu_principal: {
						href: '#Estoque',
						aria_controls: 'Estoque',
						data_icon: 'bx:package',
						menu: 'Estoque',
						id: 'Estoque',
						exibir:
							permissoes.visualizarEstoqueDetalhado ||
							permissoes.visualizarEstoqueSintetico ||
							permissoes.visualizarRomaneioBarrasAtivas ||
							permissoes.visualizarUAVazioSaidaSemUsoVazio ||
							permissoes.visualizarUAVazioSaidaSemUsoSaida,
						sub_menus: [
							{
								sub_menu: 'Gestão de Estoque',
								href: '#GestaoEstoque',
								menu_composto: 1,
								id_submenu: 'GestaoEstoque',
								aria_controls: 'GestaoEstoque',
								exibir:
									permissoes.visualizarEstoqueDetalhado ||
									permissoes.visualizarEstoqueSintetico ||
									permissoes.visualizarRomaneioBarrasAtivas,
								opcoes_submenu: [
									{
										sub_menu: 'Estoque Detalhado',
										exibir_sub_menu: permissoes.visualizarEstoqueDetalhado,
										to: '/gestEstoque/estoqueDetalhado',
									},
									{
										sub_menu: 'Estoque Sintético',
										exibir_sub_menu: permissoes.visualizarEstoqueSintetico,
										to: '/gestEstoque/estoqueSintetico',
									},
									{
										sub_menu: 'Romaneio de Barras Ativas',
										exibir_sub_menu: permissoes.visualizarRomaneioBarrasAtivas,
										to: '/gestEstoque/romaneioBarrasAtivas',
									},
								],
							},

							{
								sub_menu: 'U.A Vazio/Saída e Sem Uso',
								href: '#UaVazioSemUso',
								menu_composto: 1,
								id_submenu: 'UaVazioSemUso',
								aria_controls: 'UaVazioSemUso',
								exibir: permissoes.visualizarUAVazioSaidaSemUsoVazio || permissoes.visualizarUAVazioSaidaSemUsoSaida,
								opcoes_submenu: [
									{
										sub_menu: 'U.A. sem Uso',
										exibir_sub_menu: permissoes.visualizarUAVazioSaidaSemUsoVazio || permissoes.visualizarUAVazioSaidaSemUsoSaida,
										to: '/gestEstoque/uasemuso',
									},
								],
							},
						],
					},
				},
				{
					menu_principal: {
						href: '#Operacional',
						aria_controls: 'Operacional',
						data_icon: 'basil:sort-solid',
						menu: 'Operacional',
						id: 'Operacional',
						exibir:
							permissoes.visualizarConferirRecebimento ||
							permissoes.visualizarConferirCarregamento ||
							permissoes.visualizarEnderecarUA ||
							permissoes.visualizarDesfragmentarUA ||
							permissoes.visualizarAnaliseOtimizacao ||
							permissoes.visualizarReposicaoPrimaria ||
							permissoes.visualizarReposicaoComplementar ||
							permissoes.visualizarImpressaoEtiquetas,
						sub_menus: [
							{
								sub_menu: 'Confêrencia de Entrada',
								href: '#ConferenciaEntrada',
								menu_composto: 1,
								id_submenu: 'ConferenciaEntrada',
								aria_controls: 'ConferenciaEntrada',
								exibir: permissoes.visualizarConferirRecebimento,
								opcoes_submenu: [
									{
										sub_menu: 'Conferir Recebimento',
										exibir_sub_menu: permissoes.visualizarConferirRecebimento,
										to: linkSWM,
										id: 'confEntrada'
									},
								],
							},
							{
								sub_menu: 'Confêrencia de Saída',
								href: '#ConferenciaSaida',
								menu_composto: 1,
								id_submenu: 'ConferenciaSaida',
								aria_controls: 'ConferenciaSaida',
								exibir: permissoes.visualizarConferirCarregamento,
								opcoes_submenu: [
									{
										sub_menu: 'Conferir Carregamento',
										exibir_sub_menu: permissoes.visualizarConferirCarregamento,
										to: linkSWM,
										id: "confSaida"
									},
								],
							},
							{
								sub_menu: 'Endereçar',
								href: '#Enderecar',
								menu_composto: 1,
								id_submenu: 'Enderecar',
								aria_controls: 'Enderecar',
								exibir: permissoes.visualizarEnderecarUA,
								opcoes_submenu: [
									{
										sub_menu: 'Endereçar U.A',
										exibir_sub_menu: permissoes.visualizarEnderecarUA,
										to: linkSWM,
										id: 'enderecar'
									},
								],
							},
							{
								sub_menu: 'Desfragmentação',
								href: '#Desfragmentar',
								menu_composto: 1,
								id_submenu: 'Desfragmentar',
								aria_controls: 'Desfragmentar',
								exibir: permissoes.visualizarDesfragmentarUA || permissoes.visualizarAnaliseOtimizacao,
								opcoes_submenu: [
									{
										sub_menu: 'Desfragmentar U.A',
										exibir_sub_menu: permissoes.visualizarDesfragmentarUA,
										to: linkSWM,
										id: 'desfragmentar'
									},
									{
										sub_menu: 'Análise de Otimização',
										exibir_sub_menu: permissoes.visualizarAnaliseOtimizacao,
										to: '/',
									},
								],
							},

							{
								sub_menu: 'Picking',
								href: '#Picking',
								menu_composto: 1,
								id_submenu: 'Picking',
								aria_controls: 'Picking',
								exibir: true,
								opcoes_submenu: [
									{
										sub_menu: 'Efetuar picking',
										exibir_sub_menu: true,
										to: linkSWM,
										id: "picking"
									}
								]
							},

							{
								sub_menu: 'Contagem Inventário',
								href: '#Contagem',
								menu_composto: 1,
								id_submenu: 'Contagem',
								aria_controls: 'Contagem',
								exibir: true,
								opcoes_submenu: [
									{
										sub_menu: 'Efetuar contagem',
										exibir_sub_menu: true,
										to: linkSWM,
										id: "contagem"
									}
								]
							},

							{
								sub_menu: 'Reposição de Picking',
								href: '#RepPicking',
								menu_composto: 1,
								id_submenu: 'RepPicking',
								aria_controls: 'RepPicking',
								exibir: permissoes.visualizarReposicaoPrimaria || permissoes.visualizarReposicaoComplementar,
								opcoes_submenu: [
									{
										sub_menu: 'Reposição Primária',
										exibir_sub_menu: permissoes.visualizarReposicaoPrimaria,
										to: '/operaciona/reposicaoPicking/reposicaoPrimaria',
									},
									{
										sub_menu: 'Reposição Complementar',
										exibir_sub_menu: permissoes.visualizarReposicaoComplementar,
										to: '/',
									},
								],
							},

							{
								sub_menu: 'Impressão de Etiquetas',
								href: '#Etiquetas',
								menu_composto: 1,
								id_submenu: 'Etiquetas',
								aria_controls: 'Etiquetas',
								exibir: permissoes.visualizarImpressaoEtiquetas,
								opcoes_submenu: [
									{
										sub_menu: 'Impressão de Etiquetas',
										exibir_sub_menu: permissoes.visualizarImpressaoEtiquetas,
										to: '/operacional/impressaoetiqueta',
									},
								],
							},
						],
					},
				},
				{
					menu_principal: {
						href: '#Gerencial',
						aria_controls: 'Gerencial',
						data_icon: 'oui:app-management',
						menu: 'Gerencial',
						id: 'Gerencial',
						exibir:
							permissoes.visualizarExcecaoQuebraFefo ||
							permissoes.visualizarExcecaoLerGtin ||
							permissoes.visualizarExcecaoLerVolume ||
							permissoes.visualizarExcecaoEndereco ||
							permissoes.visualizarExcecaoZonaEnderecamento,
						sub_menus: [
							{
								sub_menu: 'Exceção',
								href: '#Excecao',
								menu_composto: 1,
								id_submenu: 'Excecao',
								aria_controls: 'Excecao',
								exibir:
									permissoes.visualizarExcecaoQuebraFefo ||
									permissoes.visualizarExcecaoLerGtin ||
									permissoes.visualizarExcecaoLerVolume ||
									permissoes.visualizarExcecaoEndereco ||
									permissoes.visualizarExcecaoZonaEnderecamento,
								opcoes_submenu: [
									{
										sub_menu: 'Quebra-FEFO',
										exibir_sub_menu: permissoes.visualizarExcecaoQuebraFefo,
										to: '/gerencial/excecao/quebrafefo',
									},
									{
										sub_menu: 'Ler GTIN',
										exibir_sub_menu: permissoes.visualizarExcecaoLerGtin,
										to: linkSWM,
										id: 'confEntrada'
									},
									{
										sub_menu: 'Ler Volume',
										exibir_sub_menu: permissoes.visualizarExcecaoLerVolume,
										to: linkSWM,
										id: 'confEntrada'
									},
									{
										sub_menu: 'Endereço',
										exibir_sub_menu: permissoes.visualizarExcecaoEndereco,
										to: '/gerencial/excecao/endereco',
									},
									{
										sub_menu: 'Zona de Endereçamento',
										exibir_sub_menu: permissoes.visualizarExcecaoZonaEnderecamento,
										to: '/gerencial/excecao/zona',
									},
								],
							},
						],
					},
				},
				{
					menu_principal: {
						href: '#Relatorios',
						aria_controls: 'Relatorios',
						data_icon: 'carbon:dashboard-reference',
						menu: 'Relatórios',
						id: 'Relatorios',
						exibir:
							permissoes.visualizarRelRastreabilidadeProduto ||
							permissoes.visualizarRelRomaneioCarga ||
							permissoes.visualizarRelMovimentacaoPeriodo ||
							permissoes.visualizarRelCurvaABC ||
							permissoes.visualizarRelHistoricoEnderecamento,
						sub_menus: [
							{
								sub_menu: 'Rastreabilidade',
								href: '#Rastreabilidade',
								menu_composto: 1,
								id_submenu: 'Rastreabilidade',
								aria_controls: 'Rastreabilidade',
								exibir: permissoes.visualizarRelRastreabilidadeProduto,
								opcoes_submenu: [
									{
										sub_menu: 'Rastreabilidade Produto',
										exibir_sub_menu: permissoes.visualizarRelRastreabilidadeProduto,
										to: '/',
									},
								],
							},
							{
								sub_menu: 'Pedidos',
								href: '#Pedidos',
								menu_composto: 1,
								id_submenu: 'Pedidos',
								aria_controls: 'Pedidos',
								exibir: permissoes.visualizarRelRomaneioCarga,
								opcoes_submenu: [
									{
										sub_menu: 'Romaneio de Carga',
										exibir_sub_menu: permissoes.visualizarRelRomaneioCarga,
										to: '/pco/contrFisc/gestPedidos/romaneioSaida',
									},
								],
							},
							{
								sub_menu: 'Movimentação',
								href: '#Movimentacao',
								menu_composto: 1,
								id_submenu: 'Movimentacao',
								aria_controls: 'Movimentacao',
								exibir:
									permissoes.visualizarRelMovimentacaoPeriodo ||
									permissoes.visualizarRelCurvaABC ||
									permissoes.visualizarRelHistoricoEnderecamento,
								opcoes_submenu: [
									{
										sub_menu: 'Movimentação Período',
										exibir_sub_menu: permissoes.visualizarRelMovimentacaoPeriodo,
										to: 'relatorios/movimentacao/movimentacaoperiodo',
									},
									{
										sub_menu: 'Curva ABC',
										exibir_sub_menu: permissoes.visualizarRelCurvaABC,
										to: '/',
									},
									{
										sub_menu: 'Histórico de Endereçamento',
										exibir_sub_menu: permissoes.visualizarRelHistoricoEnderecamento,
										to: '/gerenciamento/enderecamento/historicoEnderecamento',
									},
								],
							},
						],
					},
				},
				{
					menu_principal: {
						href: '#Faturamento',
						aria_controls: 'Faturamento',
						data_icon: 'mage:money-exchange',
						menu: 'Fat. de Serviços',
						id: 'Faturamento',
						exibir:
							permissoes.visualizarCadastrarTarifas ||
							permissoes.visualizarGerarCorte ||
							permissoes.visualizarEstornarCorte ||
							permissoes.visualizarEmissaoFatura ||
							permissoes.visualizarLancarServicoExtra ||
							permissoes.visualizarBonificarFaturamento,
						sub_menus: [
							{
								sub_menu: 'Tarifas',
								href: '#Tarifas',
								menu_composto: 1,
								id_submenu: 'Tarifas',
								aria_controls: 'Tarifas',
								exibir: permissoes.visualizarCadastrarTarifas,
								opcoes_submenu: [
									{
										sub_menu: 'Cadastrar Tarifas',
										exibir_sub_menu: permissoes.visualizarCadastrarTarifas,
										to: '/faturamento/manuntencao/cadastroTarifas',
									},
								],
							},
							{
								sub_menu: 'Corte Período',
								href: '#CortePeriodo',
								menu_composto: 1,
								id_submenu: 'CortePeriodo',
								aria_controls: 'CortePeriodo',
								exibir: permissoes.visualizarGerarCorte || permissoes.visualizarEstornarCorte,
								opcoes_submenu: [
									{
										sub_menu: 'Gerar Corte',
										exibir_sub_menu: permissoes.visualizarGerarCorte,
										to: '/',
									},
									{
										sub_menu: 'Estornar Corte',
										exibir_sub_menu: permissoes.visualizarEstornarCorte,
										to: '/',
									},
								],
							},
							{
								sub_menu: 'Emissão de Fatura',
								href: '#EmissaoFatura',
								menu_composto: 1,
								id_submenu: 'EmissaoFatura',
								aria_controls: 'EmissaoFatura',
								exibir: permissoes.visualizarEmissaoFatura,
								opcoes_submenu: [
									{
										sub_menu: 'Emissão de Fatura',
										exibir_sub_menu: permissoes.visualizarEmissaoFatura,
										to: '/',
									},
								],
							},
							{
								sub_menu: 'Serviço Extra',
								href: '#ServiçoExtra',
								menu_composto: 1,
								id_submenu: 'ServiçoExtra',
								aria_controls: 'ServiçoExtra',
								exibir: permissoes.visualizarLancarServicoExtra,
								opcoes_submenu: [
									{
										sub_menu: 'Lançar Serviço Extra',
										exibir_sub_menu: permissoes.visualizarLancarServicoExtra,
										to: '/',
									},
								],
							},
							{
								sub_menu: 'Exceção/Ajuste',
								href: '#LiberarFat',
								menu_composto: 1,
								id_submenu: 'LiberarFat',
								aria_controls: 'LiberarFat',
								exibir: permissoes.visualizarBonificarFaturamento,
								opcoes_submenu: [
									{
										sub_menu: 'Bonificar Movimentação',
										exibir_sub_menu: permissoes.visualizarBonificarFaturamento,
										to: '/',
									},
									{
										sub_menu: 'Alterar Data Movimento',
										exibir_sub_menu: true,
										to: '/',
									},
								],
							},
						],
					},
				},
				{
					menu_principal: {
						href: '#Indicadores',
						aria_controls: 'Indicadores',
						data_icon: 'streamline:money-graph-arrow-increase-ascend-growth-up-arrow-stats-graph-right-grow',
						menu: 'Indicadores',
						id: 'Indicadores',
						exibir:
							permissoes.visualizarIndicadoresMovimentacao ||
							permissoes.visualizarIndicadoresProdutividade ||
							permissoes.visualizarIndicadoresAcuracidade,
						sub_menus: [
							{
								sub_menu: 'Tarifas',
								href: '#Tarifas',
								menu_composto: 0,
								id_submenu: 'Tarifas',
								aria_controls: 'Tarifas',
								exibir:
									permissoes.visualizarIndicadoresMovimentacao ||
									permissoes.visualizarIndicadoresProdutividade ||
									permissoes.visualizarIndicadoresAcuracidade,
								opcoes_submenu: [
									{
										sub_menu: 'Movimentação',
										exibir_sub_menu: permissoes.visualizarIndicadoresMovimentacao,
										to: '/'
									},
									{
										sub_menu: 'Produtividade',
										exibir_sub_menu: permissoes.visualizarIndicadoresProdutividade,
										to: '/',
									},
									{
										sub_menu: 'Acuracidade',
										exibir_sub_menu: permissoes.visualizarIndicadoresAcuracidade,
										to: '/',
									},
								],
							},
						],
					},
				},
				{
					menu_principal: {
						href: '#LogDoSISTEMA',
						aria_controls: 'LogDoSISTEMA',
						data_icon: 'tdesign:system-log',
						menu: 'Logs do Sistema',
						id: 'LogDoSISTEMA',
						exibir: permissoes.visualizarLogAcaoUsuario || permissoes.visualizarLogApiPedidos || permissoes.visualizarLogIntegracao,
						sub_menus: [
							{
								sub_menu: 'Log Ação Usuário',
								href: '#LogAçãoUsuário',
								menu_composto: 1,
								id_submenu: 'LogAçãoUsuário',
								aria_controls: 'LogAçãoUsuário',
								exibir: permissoes.visualizarLogAcaoUsuario,
								opcoes_submenu: [
									{
										sub_menu: 'Log Ação Usuário',
										exibir_sub_menu: permissoes.visualizarLogAcaoUsuario,
										to: '/logsistema/logacaousuario',
									},
								],
							},
							{
								sub_menu: 'Log Integração',
								href: '#LogIntegração',
								menu_composto: 1,
								id_submenu: 'LogIntegração',
								aria_controls: 'LogIntegração',
								exibir: permissoes.visualizarLogApiPedidos || permissoes.visualizarLogIntegracao,
								opcoes_submenu: [
									{
										sub_menu: 'Log Integração',
										exibir_sub_menu: permissoes.visualizarLogIntegracao,
										to: '/logsistema/logintegracao',
									},
									{
										sub_menu: 'Log API Pedidos',
										exibir_sub_menu: permissoes.visualizarLogApiPedidos,
										to: '/logsistema/logintapipedido',
									},
								],
							},
						],
					},
				},
			]);
		},
		[permissoes]
	);

	useEffect(
		function () {
			setMenus(LeftBarInicial);
		},
		[LeftBarInicial]
	);

	function CarregarPerfil(id_perfil: string, id_filial: string) {
		setShowModalCarregando(true);
		if (NivelPerfil === '0') {
			id_filial = '0';
		}
		axios
			.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/perfil/criado/${id_filial}/${id_perfil}`, {
				headers: {
					authorization: token,
				},
			})
			.then(function (resposta) {
				const perfil = resposta.data.perfilPermissao;
				setPermissoes((permissoesAtuais) => ({
					...permissoesAtuais,
					isMaster: perfil.master,
					//administrativo
					visualizarUsuario: perfil.visualizarUsuario,
					visualizarPerfilPermissao: perfil.visualizarPerfilPermissao,
					visualizarUsuariosFilial: perfil.visualizarUsuariosFilial,
					visualizarAtividadesUsuario: perfil.visualizarAtividadesUsuario,
					visualizarCodSistema: perfil.visualizarCodSistema,

					//cadastros
					visualizarEntidade: perfil.visualizarEntidade,
					visualizarTransportadora: perfil.visualizarTransportadora,
					visualizarDepositante: perfil.visualizarDepositante,
					visualizarProdutosArmz: perfil.visualizarProdutosArmazenagem,
					visualizarProdutosForn: perfil.visualizarProdutosFornecedor,
					visualizarProdutosCross: perfil.visualizarProdutosCross,
					visualizarProdutosPicking: perfil.visualizarProdutosPicking,
					visualizarEnderecosArmz: perfil.visualizarEnderecosArmz,
					visualizarVeiculo: perfil.visualizarVeiculo,
					visualizarMotorista: perfil.visualizarMotorista,
					visualizarEquipamento: perfil.visualizarEquipamento,
					visualizarColaborador: perfil.visualizarColaborador,
					visualizarCFOP: perfil.visualizarCFOP,
					visualizarCFOPEntradaSaida: perfil.visualizarCFOPEntradaSaida,
					visualizarGrupoTributario: perfil.visualizarGrupoTributario,

					// adm operacional
					visualizarNfeEntradasArmz: perfil.visualizarNfeEntradasArmz,
					visualizarNfeEntradasCross: perfil.visualizarNfeEntradaCrossDocking,
					visualizarNfeEntradasPedido: perfil.visualizarNfePedido,
					visualizarNfeSaida: perfil.visualizarNfeSaida,
					visualizarSaldoFiscal: perfil.visualizarSaldoFiscal,
					visualizarNfeEmitido: perfil.visualizarNfeEmitido,
					visualizarEmissaoNfe: perfil.visualizarEmissaoNfe,
					visualizarManifestarNfe: perfil.visualizarManifestarNfe,
					visualizarProcEntrada: perfil.visualizarProcEntrada,
					visualizarProcSaida: perfil.visualizarProcSaida,
					visualizarImportacaoArquivo: perfil.visualizarImportacaoArquivo,
					visualizarNfeParaPedido: perfil.visualizarNfeParaPedido,
					visualizarPedidoManual: perfil.visualizarPedidoManual,
					visualizarPedidosPendentes: perfil.visualizarPedidosPendentes,
					visualizarPedidosSeparacao: perfil.visualizarPedidosSep,
					visualizarInventario: perfil.visualizarInventario,
					visualizarAjusteEstoque: perfil.visualizarAjusteEstoque,
					visualizarAjusteRecebimento: perfil.visualizarAjusteRecebimento,
					visualizarAjusteMovimento: perfil.visualizarAjusteMovimento,

					// estoque
					visualizarEstoqueDetalhado: perfil.visualizarEstoqueDetalhado,
					visualizarEstoqueSintetico: perfil.visualizarEstoqueSintetico,
					visualizarRomaneioBarrasAtivas: perfil.visualizarRomaneioBarrasAtivas,
					visualizarUAVazioSaidaSemUsoVazio: perfil.visualizarUAVazioSaidaSemUsoVazio,
					visualizarUAVazioSaidaSemUsoSaida: perfil.visualizarUAVazioSaidaSemUsoSaida,

					// operacional
					visualizarConferirRecebimento: perfil.visualizarConferirRecebimento,
					visualizarConferirCarregamento: perfil.visualizarConferirCarregamento,
					visualizarEnderecarUA: perfil.visualizarEnderecarUA,
					visualizarDesfragmentarUA: perfil.visualizarDesfragmentarUA,
					visualizarAnaliseOtimizacao: perfil.visualizarAnaliseOtimizacao,
					visualizarReposicaoPrimaria: perfil.visualizarReposicaoPrimaria,
					visualizarReposicaoComplementar: perfil.visualizarReposicaoComplementar,
					visualizarImpressaoEtiquetas: perfil.visualizarImpressaoEtiquetas,

					// gerencial
					visualizarExcecaoQuebraFefo: perfil.visualizarExcecaoQuebraFefo,
					visualizarExcecaoLerGtin: perfil.visualizarExcecaoLerGtin,
					visualizarExcecaoLerVolume: perfil.visualizarExcecaoLerVolume,
					visualizarExcecaoEndereco: perfil.visualizarExcecaoEndereco,
					visualizarExcecaoZonaEnderecamento: perfil.visualizarExcecaoZonaEnderecamento,

					// relatorios
					visualizarRelRastreabilidadeProduto: perfil.visualizarRelRastreabilidadeProduto,
					visualizarRelRomaneioCarga: perfil.visualizarRelRomaneioCarga,
					visualizarRelMovimentacaoPeriodo: perfil.visualizarRelMovimentacaoPeriodo,
					visualizarRelCurvaABC: perfil.visualizarRelCurvaABC,
					visualizarRelHistoricoEnderecamento: perfil.visualizarRelHistoricoEnderecamento,

					// faturamento de serviços
					visualizarCadastrarTarifas: perfil.visualizarCadastrarTarifas,
					visualizarGerarCorte: perfil.visualizarGerarCorte,
					visualizarEstornarCorte: perfil.visualizarEstornarCorte,
					visualizarEmissaoFatura: perfil.visualizarEmissaoFatura,
					visualizarLancarServicoExtra: perfil.visualizarLancarServicoExtra,
					visualizarBonificarFaturamento: perfil.visualizarBonificarFaturamento,

					// indicadores
					visualizarIndicadoresMovimentacao: perfil.visualizarIndicadoresMovimentacao,
					visualizarIndicadoresProdutividade: perfil.visualizarIndicadoresProdutividade,
					visualizarIndicadoresAcuracidade: perfil.visualizarIndicadoresAcuracidade,

					// logs do sistema
					visualizarLogAcaoUsuario: perfil.visualizarLogAcaoUsuario,
					visualizarLogIntegracao: perfil.visualizarLogIntegracao,
					visualizarLogApiPedidos: perfil.visualizarLogApiPedidos,
				}));
				setShowModalCarregando(false);
			})
			.catch(function (erro) {
				setShowModalCarregando(false);
			});
	}

	useEffect(function () {
		CarregarPerfil(id_perfil, id_filial);
	}, []);

	const [buscar, set_buscar] = useState('');

	function abrirOpcoesMenu() {
		menus.forEach((menu: any) => {
			// Abrir menu principal
			const menuLink = document.querySelector(`a[href="${menu.menu_principal.href}"]`) as HTMLElement;
			if (menuLink) {
				menuLink.click(); // Simular o clique para abrir o menu principal
			}

			// Iterar sobre os submenus
			menu.menu_principal.sub_menus.forEach((submenu: any) => {
				// Abrir submenu
				const submenuLink = document.querySelector(`a[href="${submenu.href}"]`) as HTMLElement;
				if (submenuLink) {
					submenuLink.click(); // Simular o clique para abrir o submenu
				}
			});
		});
	}

	function fecharOpcoesMenu() {
		menus.forEach((menu: any) => {
			// FECHAR menu principal
			const menuLink = document.querySelector(`a[href="${menu.menu_principal.href}"]`) as HTMLElement;
			if (menuLink) {
				menuLink.click(); // Simular o clique para FECHAR o menu principal
			}

			// Iterar sobre os submenus
			menu.menu_principal.sub_menus.forEach((submenu: any) => {
				// FECHAR submenu
				const submenuLink = document.querySelector(`a[href="${submenu.href}"]`) as HTMLElement;
				if (submenuLink) {
					submenuLink.click(); // Simular o clique para FECHAR o submenu
				}
			});
		});
	}

	function filtrarMenusLeftBar(e: string) {
		const textoBuscado = e.toLowerCase().trim();

		const menusFiltrados = LeftBarInicial.map((menu) => {
			const subMenusFiltrados = menu.menu_principal.sub_menus
				.map((submenu) => {
					const opcoesSubmenuFiltradas = submenu.opcoes_submenu.filter((opcao) => opcao.sub_menu.toLowerCase().includes(textoBuscado));

					return {
						...submenu,
						opcoes_submenu: opcoesSubmenuFiltradas,
					};
				})
				.filter((submenu) => submenu.opcoes_submenu.length > 0);

			return {
				menu_principal: {
					...menu.menu_principal,
					exibir: true,
					sub_menus: subMenusFiltrados,
				},
			};
		}).filter((menu) => menu.menu_principal.sub_menus.length > 0);

		setMenus(menusFiltrados);
	}

	function buscaLinkSWM() {
		axios.get(`${process.env.REACT_APP_API_BASE_URL}/busca/links/SWM`, {
			headers: {
				Authorization: token
			}
		}).then((resposta) => {
			setLinkSWM(resposta.data.data)
		}).catch((erro) => {
			const status = erro.response.status;
			if (status == 403 || status == 401) {
				toast.error('Acesso negado, faça seu login novamente');
				navigate('/login');
			} else {
				console.log(erro);
				toast.error(erro.response.data.message);
			}
		})
	}

	useEffect(() => {
		buscaLinkSWM()
	}, [])

	return (
		<div className="leftside-menu " style={{ backgroundColor: 'white' }}>
			<button className="logo text-center border-0" onClick={() => { }}>
				<span className="logo-lg " style={{ backgroundColor: 'white' }}>
					<img src={logo4} alt="" />
				</span>
				<span className="logo-sm">
					<img src={logoSM} alt="" />
				</span>
			</button>
			<div className="w-100 px-2 d-flex">
				<div>
					<input
						type="text"
						placeholder="Procure aqui..."
						className="form-control bordasInferiorInput text-black border-0"
						value={buscar}
						onChange={function (e) {
							set_buscar(e.target.value);
							filtrarMenusLeftBar(e.target.value);
						}}
						onBlur={function () {
							setTimeout(function () {
								fecharOpcoesMenu();
							}, 3000);
						}}
						onClick={abrirOpcoesMenu}
					/>
				</div>
				<div className="pt-1">
					<FaSearch size={20} />
				</div>
			</div>
			<hr className="m-0 mb-3" />

			<div className="h-100" id="leftside-menu-container" data-simplebar>
				<ul className="side-nav">
					{menus.map(function (menu: any, index: any) {
						return (
							<>
								{menu.menu_principal.exibir && (
									<li key={index} className="side-nav-item">
										<a
											data-bs-toggle="collapse"
											href={menu.menu_principal.href}
											aria-expanded="false"
											aria-controls={menu.menu_principal.aria_controls}
											className="side-nav-link text-dark"
										>
											<span
												style={{ fontWeight: 'bold' }}
												className="iconify"
												data-icon={menu.menu_principal.data_icon}
												data-width="22"
											></span>
											<span style={{ margin: '15px', fontWeight: 'bold' }}> {menu.menu_principal.menu} </span>
											<span style={{ fontWeight: 'bold' }} className="menu-arrow text-dark"></span>
										</a>
										<div className="collapse" id={menu.menu_principal.id}>
											<ul className="side-nav-second-level">
												{menu.menu_principal.sub_menus.map(function (submenu: any, subIndex: any) {
													if (submenu.menu_composto === 1) {
														return (
															<>
																{submenu.exibir && (
																	<li key={subIndex} className="side-nav-item">
																		<a
																			data-bs-toggle="collapse"
																			href={submenu.href}
																			aria-expanded="false"
																			aria-controls={submenu.aria_controls}
																		>
																			<span className="text-dark"> {submenu.sub_menu} </span>
																			<span className="menu-arrow text-dark"></span>
																		</a>
																		<div className="collapse" id={submenu.id_submenu}>
																			<ul className="side-nav-third-level">
																				{submenu.opcoes_submenu.map(function (opcao: any, opcaoIndex: any) {
																					return (
																						<>
																							<ul>
																								{opcao.to === linkSWM ? (
																									<a
																										className="text-dark"
																										href={`${opcao.to}?operacao=${opcao.id}&idFilial=${sessionStorage.getItem("IsIDFilialPadraoFKL")}&webToken=${sessionStorage.getItem('IsFKLTOKEN')}`}
																										target="_blank"
																										rel="noopener noreferrer"
																									>
																										{opcao.sub_menu}
																									</a>
																								) : (
																									<li>
																										<Link className="text-dark" to={opcao.to}>
																											{opcao.sub_menu}
																										</Link>
																									</li>
																								)}
																							</ul>
																						</>
																					);
																				})}
																			</ul>
																		</div>
																	</li>
																)}
															</>
														);
													} else {
														return (
															<>
																{submenu.exibir && (
																	<ul className="side-nav-third-level">
																		{submenu.opcoes_submenu.map(function (opcao: any, opcaoIndex: any) {
																			return (
																				<>
																					{opcao.to === linkSWM ? (
																						<a
																							className="text-dark"
																							href={`${opcao.to}?operacao=confEntrada&idFilial=${sessionStorage.getItem("IsIDFilialPadraoFKL")}&webToken=${sessionStorage.getItem('IsFKLTOKEN')}`}
																							target="_blank"
																							rel="noopener noreferrer"
																						>
																							{opcao.sub_menu}
																						</a>
																					) : (
																						<Link className="text-dark" to={opcao.to}>
																							{opcao.sub_menu}
																						</Link>
																					)}
																				</>
																			);
																		})}
																	</ul>
																)}
															</>
														);
													}
												})}
											</ul>
										</div>
									</li>
								)}
							</>
						);
					})}

					{/*<li className="side-nav-item">
						<a
							data-bs-toggle="collapse"
							href="#Configuracoes"
							aria-expanded="false"
							aria-controls="Configuracoes"
							className="side-nav-link text-dark"
						>
							<span className="iconify" data-icon="carbon:operations-record" data-width="22"></span>
							<span style={{ margin: '15px' }}> Configurações </span>
							<span className="menu-arrow text-dark"></span>
						</a>
						<div className="collapse" id="Configuracoes">
							<ul className="side-nav-second-level">
								<li className="side-nav-item" hidden={false}>
									<a data-bs-toggle="collapse" href="#Seguranca" aria-expanded="false" aria-controls="Seguranca">
										<span className='text-dark'> Segurança </span>
										<span className="menu-arrow text-dark"></span>
									</a>
									<div className="collapse" id="Seguranca">
										<ul className="side-nav-third-level">
											<li hidden={validapermissaoEmpresa}>
												<Link className="text-dark" to={'/config/segur/empresas'}>Empresas</Link>
											</li>
											<li hidden={false}>
												<Link className="text-dark" to={'/config/segur/cadastroDePermissoes'}>Cadastro de Permissões</Link>
											</li>
											<li hidden={false}>
												<Link className="text-dark" to={'/config/segur/usuarios'}>Cadastro de Usuários</Link>
											</li>
											<li hidden={true}>
												<Link className="text-dark" to={'/config/segur/atividadeUsuarios'}>Atividades Usuários</Link>
											</li>
										</ul>
									</div>
								</li>
								<li className="side-nav-item" hidden={validapermissaoCadastro}>
									<a data-bs-toggle="collapse" href="#Cadastro" aria-expanded="false" aria-controls="Cadastro">
										<span className="text-dark"> Cadastros </span>
										<span className="menu-arrow text-dark"></span>
									</a>
									<div className="collapse" id="Cadastro">
										<ul className="side-nav-third-level">
											<li hidden={validapermissaoFilial}>
												<Link className="text-dark" to={'/config/cadast/filial'}>Filial</Link>
											</li>
											<li hidden={validapermissaoDepositante}>
												<Link className="text-dark" to={'/config/cadast/depositante'}>Depositante</Link>
											</li>
											<li hidden={validapermissaoEntidade}>
												<Link className="text-dark" to={'/config/cadast/entidade'}>Entidade</Link>
											</li>
											<li hidden={validapermissaoTranspCli}>
												<Link className="text-dark" to={'/config/cadast/transportadora'}>Transportadora/Cliente</Link>
											</li>
											<li hidden={false}>
												<Link className="text-dark" to={'/config/cadast/veiculo'}>Veículos</Link>
											</li>
											<li hidden={validapermissaoMotorista}>
												<Link className="text-dark" to={'/config/cadast/motorista'}>Motoristas</Link>
											</li>
											<li hidden={validapermissaoEmbalagem}>
												<Link className="text-dark" to={'/config/cadast/embalagem'}>Embalagens</Link>
											</li>
											<li hidden={false}>
												<Link className="text-dark" to={'/config/cadast/produto'}>Produtos</Link>
											</li>
											<li hidden={false}>
												<Link className="text-dark" to={'/config/cadast/endereco'}>Endereços</Link>
											</li>
											<li hidden={validapermissaoEquipe}>
												<Link className="text-dark" to={'/config/cadast/colaborador'}>Colaborador</Link>
											</li>

											<li hidden={validapermissaoCodSis}>
												<Link className="text-dark" to={'/config/cadast/codigosDoSistema'}>Códigos do Sistema</Link>
											</li>
											<li hidden={validapermissaoControleNumeracao}>
												<Link className="text-dark" to={'/config/cadast/controleNumeracao'}>Controle de Numeração</Link>
											</li>
											<li>
												<Link className="text-dark" to={'/config/cadast/integracao'}>Integração Ar./Produtos</Link>
											</li>
										</ul>
									</div>
								</li>
							</ul>
						</div>
					</li> */}

					{/*<li className="side-nav-item">
						<a
							data-bs-toggle="collapse"
							href="#Indicadores"
							aria-expanded="false"
							aria-controls="Indicadores"
							className="side-nav-link text-dark"
						>
							<span className="iconify" data-icon="iconoir:reports" data-width="22"></span>
							<span style={{ margin: '15px' }}> Indicadores </span>
							<span className="menu-arrow text-dark"></span>
						</a>
						<div className="collapse" id="Indicadores">
							<ul className="side-nav-second-level">
								<li>
									<a className="text-dark" href="#">Indicadores</a>
								</li>
							</ul>
						</div>
					</li> */}

					{/*<li className="side-nav-item">
						<a
							data-bs-toggle="collapse"
							href="#PCO"
							aria-expanded="false"
							aria-controls="PCO"
							className="side-nav-link text-dark"
						>
							<span className="iconify" data-icon="fluent-mdl2:server-processes" data-width="22"></span>
							<span style={{ margin: '15px' }}> PCO </span>
							<span className="menu-arrow text-dark"></span>
						</a>
						<div className="collapse" id="PCO">
							<ul className="side-nav-second-level">
								<li className="side-nav-item">
									<a data-bs-toggle="collapse" href="#ControleFiscal" aria-expanded="false" aria-controls="ControleFiscal">
										<span className="text-dark"> Controle Fiscal </span>
										<span className="menu-arrow text-dark"></span>
									</a>
									<div className="collapse" id="ControleFiscal">
										<ul className="side-nav-third-level">
											<li className="side-nav-item">
												<a
													data-bs-toggle="collapse"
													href="#ControleNotasFiscais"
													aria-expanded="false"
													aria-controls="ControleNotasFiscais"
												>
													<span className="text-dark"> Controle de Notas Fiscais </span>
													<span className="menu-arrow text-dark"></span>
												</a>
												<div className="collapse" id="ControleNotasFiscais">
													<ul className="side-nav-third-level">
														<li className="side-nav-item">
															<Link className="text-dark" to={'/pco/contrFisc/contrNotFisc/impNotFisc'}>
																Importação de Notas Fiscais
															</Link>
														</li>
														<li className="side-nav-item">
															<Link className="text-dark" to={'/pco/contrFisc/contrNotFisc/notasFiscaisArmazenagem'}>
																Nota Fiscal de Armazenagem
															</Link>
														</li>
														<li className="side-nav-item">
															<Link className="text-dark" to={'/pco/contrFisc/contrNotFisc/notasFiscaisArmazenagem'}>
																Nota Fiscal de Crossdocking
															</Link>
														</li>
													</ul>
												</div>
											</li>
											<li>
												<Link className="text-dark" to={'/pco/contrFisc/contrNotFisc/cfop'}>Cadastro de CFOP's</Link>
											</li>
											<li>
												<Link className="text-dark" to={'pco/contrFisc/contrNotFisc/cfopEntradaXsaida'}>Cadastro CFOP Entrada x Saida</Link>
											</li>
											<li>
												<a className="text-dark" href="#">Geração Nota Fiscal</a>
											</li>
											<li>
												<Link className="text-dark" to={"/pco/contrFisc/gruposTributarios"}>Grupos Tributários</Link>
											</li>
											<li>
												<a className="text-dark" href="#">Notas Fiscais Emitidas</a>
											</li>
											<li>
												<a className="text-dark" href="#">Saldos Fiscais</a>
											</li>
										</ul>
									</div>
								</li>
								<li className="side-nav-item">
									<a data-bs-toggle="collapse" href="#ProcessosVeiculos" aria-expanded="false" aria-controls="ProcessosVeiculos">
										<span className="text-dark"> Processos e Veiculos </span>
										<span className="menu-arrow text-dark"></span>
									</a>
									<div className="collapse" id="ProcessosVeiculos">
										<ul className="side-nav-third-level">
											<li>
												<Link className="text-dark" to={'/controle/processo/entrada/lista'}>Processo Entrada</Link>
											</li>
											<li>
												<Link className="text-dark" to={'/pco/contrFisc/procEVeiculos/processoSaida'}>Processo Saída</Link>
											</li>
											<li>
												<Link className="text-dark" to={'/pco/contrFisc/procEVeiculos/equipamento'}>Equipamentos</Link>
											</li>
											<li>
												<Link className="text-dark" to={'/pco/contrFisc/procEVeiculos/ocorrencia'}>Ocorrências</Link>
											</li>
											<li>
												<Link className="text-dark" to={'/pco/contrFisc/procEVeiculos/movimentoSaidaNF'}>Movimentação Saída NF</Link>
											</li>
										</ul>
									</div>
								</li>
								<li className="side-nav-item">
									<a data-bs-toggle="collapse" href="#GestaoPedidos" aria-expanded="false" aria-controls="GestaoPedidos">
										<span className="text-dark"> Gestão de Pedidos </span>
										<span className="menu-arrow text-dark"></span>
									</a>
									<div className="collapse" id="GestaoPedidos">
										<ul className="side-nav-third-level">
											<li>
												{/* <Link className="text-dark" to={'/pco/contrFisc/gestPedidos/listagemDosPedidos'}>Pedidos</Link> */}
					{/*<Link className="text-dark" to={'/pco/gestaopedidos/pedidos'}>Pedidos</Link>
											</li>
											<li>
												<Link className="text-dark" to={'/pco/contrFisc/gestPedidos/integracaoSaida'}>Integração Saída</Link>
											</li>
											<li>
												<Link className="text-dark" to={'/pco/contrFisc/gestPedidos/romaneioSaida'}>Romaneio Saída</Link>
											</li>
										</ul>
									</div>
								</li>
								<li>
									<a className="text-dark" href="#">Inventário</a>
								</li>
							</ul>
						</div>
					</li> */}
					{/*					<li className="side-nav-item">
						<a
							data-bs-toggle="collapse"
							href="#GestaoEstoque"
							aria-expanded="false"
							aria-controls="GestaoEstoque"
							className="side-nav-link text-dark"
						>
							<span className="iconify" data-icon="material-symbols:inventory-rounded" data-width="22"></span>
							<span style={{ margin: '15px' }}> Gestão Estoque </span>
							<span className="menu-arrow text-dark"></span>
						</a>
						<div className="collapse" id="GestaoEstoque">
							<ul className="side-nav-second-level">
								<li>
									<a className="text-dark" href="#">Gestão do Estoque</a>
								</li>
								<li>
									<a className="text-dark" href="#">Rastreabilidade</a>
								</li>
								<li>
									<a className="text-dark" href="#">Bloqueios/Desbloqueios</a>
								</li>
								<li>
									<a className="text-dark" href="#">Movimentação SIF</a>
								</li>
								<li>
									<Link className="text-dark" to={'/gestEstoque/estoqueDetalhado'}>Estoque Detalhado</Link>
								</li>
								<li>
									<Link className="text-dark" to={'/gestEstoque/estoqueSintetico'}>Estoque Sintético</Link>
								</li>
								<li>
									<Link className="text-dark" to={'/gestEstoque/consultaCodigoBarras'}>Consultar Cód.Barras</Link>
								</li>
								<li>
									<Link className="text-dark" to={'/gestEstoque/palletSemUso'}>Pallet sem Uso</Link>
								</li>
							</ul>
						</div>
					</li> */}
					{/*					<li className="side-nav-item">
						<a
							data-bs-toggle="collapse"
							href="#Operacao"
							aria-expanded="false"
							aria-controls="Operacao"
							className="side-nav-link text-dark"
						>
							<span className="iconify" data-icon="material-symbols:settings" data-width="22"></span>
							<span style={{ margin: '15px' }}> Operação </span>
							<span className="menu-arrow text-dark"></span>
						</a>
						<div className="collapse" id="Operacao">
							<ul className="side-nav-second-level">
								<li className="side-nav-item">
									<a
										data-bs-toggle="collapse"
										href="#ConferenciaEntrada"
										aria-expanded="false"
										aria-controls="ConferenciaEntrada"
									>
										<span className="text-dark"> Conferência Entrada </span>
										<span className="menu-arrow text-dark"></span>
									</a>
									<div className="collapse" id="ConferenciaEntrada">
										<ul className="side-nav-third-level">
											<li>
												<Link className="text-dark" to={'/operacao/confEntrada/confEntrada'}>Conferência Entrada</Link>
											</li>
										</ul>
									</div>
								</li>
								<li>
									<Link className="text-dark" to={'/operacao/confSaida'}>Conferência Saída</Link>
								</li>
								<li>
									<Link className="text-dark" to={'/operacao/enderecamento'}>Endereçamento</Link>
								</li>
								<li>
									<Link className="text-dark" to={'/operacao/desfragmentacaoEstoque'}>Desfragmentação Estoque</Link>
								</li>
								<li>
									<a className="text-dark" href="#">Picking</a>
								</li>
								<li>
									<a className="text-dark" href="#">Quebra FEFO</a>
								</li>
								<li>
									<a className="text-dark" href="#">Reposição Picking</a>
								</li>
								<li>
									<a className="text-dark" href="#">Locais de Picking</a>
								</li>
								<li>
									<a className="text-dark" href="#">Impressão Etiquetas</a>
								</li>
							</ul>
						</div>
					</li> */}
					{/*					<li className="side-nav-item">
						<a
							data-bs-toggle="collapse"
							href="#Gerenciamento"
							aria-expanded="false"
							aria-controls="Gerenciamento"
							className="side-nav-link text-dark"
						>
							<span className="iconify" data-icon="eos-icons:content-lifecycle-management" data-width="22"></span>
							<span style={{ margin: '15px' }}> Gerenciamento </span>
							<span className="menu-arrow text-dark"></span>
						</a>
						<div className="collapse" id="Gerenciamento">
							<ul className="side-nav-second-level">
								<li className="side-nav-item">
									<a
										data-bs-toggle="collapse"
										href="#Enderecamento"
										aria-expanded="false"
										aria-controls="Enderecamento"
									>
										<span className="text-dark"> Endereçamento </span>
										<span className="menu-arrow text-dark"></span>
									</a>
									<div className="collapse" id="Enderecamento">
										<ul className="side-nav-third-level">
											<li>
												<a className="text-dark" href="#">Endereço s/Consistência</a>
											</li>
											<li>
												<Link className="text-dark" to={'/gerenciamento/enderecamento/historicoEnderecamento'}>
													Histórico Endereçamento
												</Link>
											</li>
											<li>
												<Link className="text-dark" to={'/gerenciamento/enderecamento/palletSemEnderecamento'}>
													Pallets s/Endereçamento
												</Link>
											</li>
										</ul>
									</div>
								</li>
								<li className="side-nav-item">
									<a
										data-bs-toggle="collapse"
										href="#Processos"
										aria-expanded="false"
										aria-controls="Processos"
									>
										<span className="text-dark"> Processos </span>
										<span className="menu-arrow text-dark"></span>
									</a>
									<div className="collapse" id="Processos">
										<ul className="side-nav-third-level">
											<li>
												<Link className="text-dark" to={'/gerenciamento/processos/autorizacaoEntrada'}>Autorização Entrada</Link>
											</li>
											<li>
												<a className="text-dark" href="#">Divergência Balança</a>
											</li>
										</ul>
									</div>
								</li>
								<li className="side-nav-item">
									<a
										data-bs-toggle="collapse"
										href="#Estoque"
										aria-expanded="false"
										aria-controls="Estoque"
									>
										<span className="text-dark"> Estoque </span>
										<span className="menu-arrow text-dark"></span>
									</a>
									<div className="collapse" id="Estoque">
										<ul className="side-nav-third-level">
											<li>
												<Link className="text-dark" to={'/gerenciamento/estoque/barraPallet'}>Barra Pallet</Link>
											</li>
											<li>
												<a className="text-dark" href="#">Consulta Estoque</a>
											</li>
											<li>
												<a className="text-dark" href="#">Menu Pedidos</a>
											</li>
										</ul>
									</div>
								</li>
								<li className="side-nav-item">
									<a
										data-bs-toggle="collapse"
										href="#Estatistica"
										aria-expanded="false"
										aria-controls="Estatistica"
									>
										<span className="text-dark"> Estatística </span>
										<span className="menu-arrow text-dark"></span>
									</a>
									<div className="collapse" id="Estatistica">
										<ul className="side-nav-third-level">
											<li>
												<a className="text-dark" href="#">Menu Estatística</a>
											</li>
											<li>
												<a className="text-dark" href="#">Estoque Valorado</a>
											</li>
											<li>
												<a className="text-dark" href="#">Ocupação</a>
											</li>
											<li>
												<a className="text-dark" href="#">Movimentação Geral</a>
											</li>
										</ul>
									</div>
								</li>
							</ul>
						</div>
					</li> */}
					{/*					<li className="side-nav-item">
						<a
							data-bs-toggle="collapse"
							href="#Faturamento"
							aria-expanded="false"
							aria-controls="Faturamento"
							className="side-nav-link text-dark"
						>
							<span className="iconify" data-icon="fluent-mdl2:financial-solid" data-width="22"></span>
							<span style={{ margin: '15px' }}> Faturamento </span>
							<span className="menu-arrow text-dark"></span>
						</a>
						<div className="collapse" id="Faturamento">
							<ul className="side-nav-second-level">
								<li className="side-nav-item">
									<a
										data-bs-toggle="collapse"
										href="#Manutencao"
										aria-expanded="false"
										aria-controls="Manutencao"
									>
										<span className="text-dark"> Manutenção </span>
										<span className="menu-arrow text-dark"></span>
									</a>
									<div className="collapse" id="Manutencao">
										<ul className="side-nav-third-level">
											<li>
												<Link className="text-dark" to={'/faturamento/manuntencao/cadastroTarifas'}>Cadastro de Tarifas</Link>
											</li>
											<li>
												<a className="text-dark" href="#">Lançar Serviço Extra</a>
											</li>
											<li>
												<a className="text-dark" href="#">Liberar Fat.de Processos</a>
											</li>
											<li>
												<a className="text-dark" href="#">Ajustar Saldo Inicial</a>
											</li>
										</ul>
									</div>
								</li>
								<li className="side-nav-item">
									<a
										data-bs-toggle="collapse"
										href="#GeracaoFaturamento"
										aria-expanded="false"
										aria-controls="GeracaoFaturamento"
									>
										<span className="text-dark"> Geração Faturamento </span>
										<span className="menu-arrow text-dark"></span>
									</a>
									<div className="collapse" id="GeracaoFaturamento">
										<ul className="side-nav-third-level">
											<li>
												<a className="text-dark" href="#">Corte Faturamento</a>
											</li>
											<li>
												<a className="text-dark" href="#">Estornar Corte</a>
											</li>
											<li>
												<a className="text-dark" href="#">Gerar Faturamento</a>
											</li>
										</ul>
									</div>
								</li>
							</ul>
						</div>
					</li> */}
					{/*					<li className="side-nav-item">
						<a
							data-bs-toggle="collapse"
							href="#Relatorios"
							aria-expanded="false"
							aria-controls="Relatorios"
							className="side-nav-link text-dark"
						>
							<span className="iconify" data-icon="carbon:report" data-width="22"></span>
							<span style={{ margin: '15px' }}> Relatórios </span>
							<span className="menu-arrow text-dark"></span>
						</a>
						<div className="collapse" id="Relatorios">
							<ul className="side-nav-second-level">
								<li>
									<a className="text-dark" href="#">Relatórios</a>
								</li>
							</ul>
						</div>
					</li> */}
				</ul>
				<div className="clearfix"></div>
			</div>
			<ModalCarregando mensagem="Carregando..." mostrar={showModalCarregando} />
		</div>
	);
};

export { LeftBar };
