import { Modal } from "react-bootstrap";
import { BotaoCustomizado } from "../../../../components/componentes/formulario/BotaoCustomizado";
import { mdiCheckCircle } from '@mdi/js';
import { toast } from "react-toastify";

interface ModalGtinCompostoProps {
    isOpen: boolean;
    fecharModal: () => void;
    inicioGtin: number;
    setInicioGtin: (dados: number) => void
    tamanhoGtin: number
    setTamanhoGtin: (dados: number) => void
    inicioLote: number
    setInicioLote: (dados: number) => void
    tamanhoLote: number
    setTamanhoLote: (dados: number) => void
    setUsaGtinComposto: (dados: boolean) => void
}

// Criação do componente
const ModalGtinComposto: React.FC<ModalGtinCompostoProps> = ({
    isOpen,
    fecharModal,
    inicioGtin,
    setInicioGtin,
    tamanhoGtin,
    setTamanhoGtin,
    inicioLote,
    setInicioLote,
    tamanhoLote,
    setTamanhoLote,
    setUsaGtinComposto
}) => {

    const salvar = () => {
        // Cálculo das posições finais
        const fimGtin = inicioGtin + tamanhoGtin - 1;
        const fimLote = inicioLote + tamanhoLote - 1;
        
        // Verificação de interferência
        if ((inicioGtin <= fimLote && inicioGtin >= inicioLote) ||
            (inicioLote <= fimGtin && inicioLote >= inicioGtin)) {
            toast.error('O GTIN interfere com o Lote. Verifique os índices e tamanhos.');
            return;
        }

        toast.success("Informações de Gtin salvas com sucesso")
        fecharModal()
    };

    return (
        <>
            <Modal show={isOpen}>
                <Modal.Header className="bg-white">
                    <Modal.Title style={{ flex: '1', textAlign: 'center', color: 'black' }}>Informações Gtin composto</Modal.Title>
                    <i
                        className="bi bi-x-circle"
                        style={{ fontSize: '2rem', cursor: 'pointer' }}
                        onClick={() => {
                            setUsaGtinComposto(false)
                            fecharModal()
                        }}
                    ></i>
                </Modal.Header>
                <Modal.Body className="bg-white">

                    <div className="row">
                        <div className="col-6 form-floating mb-1">
                            <input
                                type="number"
                                className="form-control bordasInferiorInput text-black"
                                placeholder="Índice inicial do gtin"
                                id="inicioGtin"
                                name="inicioGtin"
                                value={inicioGtin}
                                onChange={(e) => setInicioGtin(parseInt(e.target.value))}
                            />
                            <label className="form-label">Inicio Gtin</label>
                        </div>

                        <div className="col-6 form-floating mb-1">
                            <input
                                type="number"
                                className="form-control bordasInferiorInput text-black"
                                placeholder="Tamanho do gtin"
                                id="tamanhoGtin"
                                name="tamanhoGtin"
                                value={tamanhoGtin}
                                onChange={(e) => setTamanhoGtin(parseInt(e.target.value))}
                            />
                            <label className="form-label">Tamanho Gtin</label>
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-6 form-floating mb-1">
                            <input
                                type="number"
                                className="form-control bordasInferiorInput text-black"
                                placeholder="Índice inicial do lote"
                                id="inicioLote"
                                name="inicioLote"
                                value={inicioLote}
                                onChange={(e) => setInicioLote(parseInt(e.target.value))}
                            />
                            <label className="form-label">Inicio Lote</label>
                        </div>

                        <div className="col-6 form-floating mb-1">
                            <input
                                type="number"
                                className="form-control bordasInferiorInput text-black"
                                placeholder="Tamanho do lote"
                                id="tamanhoLote"
                                name="tamanhoLote"
                                value={tamanhoLote}
                                onChange={(e) => setTamanhoLote(parseInt(e.target.value))}
                            />
                            <label className="form-label">Tamanho Lote</label>
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <div className="text-sm-end">
                        <BotaoCustomizado
                            type="submit"
                            className="btn btn-light text-info-emphasis bg-white border-0"
                            descricaoBotao="Salvar"
                            icone={mdiCheckCircle}
                            onclick={salvar}
                        // disabled={params.acao === 'criar' ? false : params.acao === 'editar' ? false : true}
                        />
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ModalGtinComposto