import ClickableTable from "../../../../components/ClickableTable/ClickableTabl"
import { ITabela } from "../../../../components/Table/TabelaInterface"
import { useEffect, useState } from "react"
import ModalCarregando from "../../../../components/ModalCarregando/ModalCarregando"
import axios from "axios"
import { toast } from "react-toastify"
import ModalConfirmacao from "../../../../components/ModalDeConfirmacao/ModalConfirmacao"
import { Modal } from "react-bootstrap"
import ModalEditarPedEmSeparacao from "./components/ModalEditar"
import ModalVincUsuario from "./components/modalVincUsuario"
function PedidosSeparacao() {
    const idFilial = sessionStorage.getItem("IsIDFilialPadraoFKL")!
    const token = sessionStorage.getItem('IsFKLTOKEN')!
    const usuarioLogado = sessionStorage.getItem("IsUserFKL")!
    //#region carrega informações do grid
    const url = process.env.REACT_APP_API_BASE_URL
    const coluna: ITabela[] = [
        { titulo: "Tarefa", acesso: "id_tarefa" },
        { titulo: "Depositante", acesso: "fantasia" },
        { titulo: "Id Pedido", acesso: "id_pedido" },
        { titulo: "Pedido", acesso: "pedido" },
        { titulo: "Roteiro", acesso: "roteiro" },
        { titulo: "Volumes", acesso: "volume" },
        { titulo: "Peso Líq", acesso: "pl" },
        { titulo: "Tipo Quebra", acesso: "tipo_quebra" },
        { titulo: "Status", acesso: "status_conclusao" },
        { titulo: "Usuario", acesso: "usuario" }
    ]
    //modal carregando controle
    const [ShowModalCarregando, setShowModalCarregando] = useState(false)
    const [ListaPedidosSeparacao, setListaPedidosSeparacao] = useState([])
    function carregarPedidosSeparacao() {
        setShowModalCarregando(true)
        axios.get(`${url}/carregar/pedidos/separacao/${idFilial}`, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {
            setListaPedidosSeparacao(resposta.data.pedidosSeparacao)
            setShowModalCarregando(false)
        }).catch(function (erro) {
            toast.error(erro.response.data.message || erro.message)
            setShowModalCarregando(false)
        })
    }
    //#region cancelar tarefa
    const [showModalCancelamento, setShowModalCancelamento] = useState(false)
    const [tarefaSelecionada, setTarefaSelecioanda] = useState<any>({})
    function abrirModalCancelamento(tarefa: any) {
        setTarefaSelecioanda(tarefa)
        alterarStateModalCancelamento()
    }
    function alterarStateModalCancelamento() {
        setShowModalCancelamento(!showModalCancelamento)
    }
    const [mensagemCancelarTarefa, setMensagemCancelarTarefa] = useState("")
    function cancelarTarefa() {
        alterarStateModalCancelamento()
        setShowModalCarregando(true)
        axios.delete(url + `/cancelar/tarefa/do/pedido/${idFilial}/${tarefaSelecionada.id_pedido}/${tarefaSelecionada.id_tarefa}?usuario=${usuarioLogado}`, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {
            if (resposta.status === 201) {
                setMensagemCancelarTarefa(resposta.data.message)
                alterarModalCancelarTarefa()
            }
            else {
                toast.success(resposta.data.message)
            }
            setShowModalCarregando(false)
        }).catch(function (erro) {
            toast.error(erro.response.data.message || erro.message)
            setShowModalCarregando(false)
        })
    }
    //#region cancelando tarefa com itens separados
    const [showModalTarefaItensSeparados, setShowModalTarefaItensSeparados] = useState(false)
    function alterarModalCancelarTarefa() {
        setShowModalTarefaItensSeparados(!showModalTarefaItensSeparados)
    }
    const [showModalOpcoes, setShowModalOpcoes] = useState(false)
    function alterarModalOpcoes() {
        setShowModalOpcoes(!showModalOpcoes)
    }
    function continuarCancelamentoTarefaComItens() {
        setShowModalCarregando(true)
        axios.delete(url + `/cancelar/tarefas/separacao/${idFilial}/${tarefaSelecionada.id_tarefa}/${tarefaSelecionada.id_pedido}?usuario=${usuarioLogado}`, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {
            if (resposta.status == 201) {
                alterarModalOpcoes()
            }
            else {
                toast.success(resposta.data.message)
            }
            setShowModalCancelamento(false)
        }).catch(function (erro) {
            toast.error(erro.response.data.message || erro.message)
            setShowModalCancelamento(false)
        })
    }
    //#region opções do modal de opções
    function cancelarTodasAsTarefasDoPedido() {
        alterarModalOpcoes()
        setShowModalCarregando(true)
        axios.delete(url + `/cancelar/todas/tarefas/pedido/${idFilial}/${tarefaSelecionada.id_pedido}`, {
            headers: {
                authorization: token
            }
        }).then(function (resposta) {
            toast.success(resposta.data.message)
            setShowModalCarregando(false)
        }).catch(function (erro) {
            toast.error(erro.response.data.message || erro.message)
            setShowModalCarregando(false)
        })
    }
    function cancelarSomenteEssaTarefaSelecionada() {
        alterarModalOpcoes()
        setShowModalCarregando(true)
        axios.delete(url + `/cancelar/unica/tarefa/pedido/${idFilial}/${tarefaSelecionada.id_cliente}/${tarefaSelecionada.id_pedido}?usuario=${usuarioLogado}`)
            .then(function (resposta) {
                toast.success(resposta.data.message)
                setShowModalCarregando(false)
            }).catch(function (erro) {
                toast.error(erro.response.data.message || erro.message)
                setShowModalCancelamento(false)
            })
    }
    function cancelarTarefaEgerarNovoPedido() {
        setShowModalCarregando(true)
        axios.delete(url + `/cancelar/tarefa/gerar/pedido/${idFilial}/${tarefaSelecionada.id_tarefa}/${tarefaSelecionada.id_pedido}?usuario=${usuarioLogado}`)
    }
    //#region alterar pedido
    const [showModalAlterarPedido, setShowModalAlterarPedido] = useState(false)
    const [idPedido, setIdPedido] = useState(0)
    const [descricao, setDescricao] = useState("")
    const [dataCarga, setDataCarga] = useState<string>('')
    const [destinatario, setDestinatario] = useState<string>('')
    function editarPedido(dado: any) {
        setIdPedido(dado.id_pedido)
        setDescricao(dado.pedido)
        setDataCarga(dado.data_carga)
        setDestinatario(dado.destinatario)

        setShowModalAlterarPedido(true)
    }
    useEffect(function () {
        carregarPedidosSeparacao()
    }, [])
    const [idTarefa, setIdTarefa] = useState<string | number>(0)
    const [showModalVincUsuario, setShowModalVincUsuario] = useState(false)
    function manipularModalVincUsuario() {
        setShowModalVincUsuario(!showModalVincUsuario)
    }
    function abrirModalVincUsuario(dados: any) {
        setIdTarefa(dados.id_tarefa)
        setIdPedido(dados.id_pedido)
        manipularModalVincUsuario()
    }
    return (
        <div className="main">
            <ModalCarregando mostrar={ShowModalCarregando} mensagem="Carregando..." />
            <h4>Pedidos em Separação</h4>
            <div className="container-fluid m-0 p-0">
                <div className="row">
                    <div className="col-sm col-md-12 col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <ClickableTable
                                    coluna={coluna}
                                    data={ListaPedidosSeparacao}
                                    onRowClick={function () {

                                    }}
                                    itemsPerPage={10}
                                    acaoEditar={editarPedido}
                                    acaoExcluir={abrirModalCancelamento}
                                    acaoVisualizar={abrirModalVincUsuario}
                                    usaAcoes={true}
                                    usaEditar={true}
                                    usaExcluir={true}
                                    usaVisualizar={true}
                                    iconeVisualizar="bi bi-people"
                                    iconeExcluir="bi bi-x-circle"
                                    acessopesquisa="pedido"
                                    labelpesquisa="Procurar pelo pedido"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalConfirmacao mostrar={showModalCancelamento} msgAcao1="Voltar" msgAcao2="Confirmar" msgPrincipal="Confirmar o cancelamento da tarefa?" Acao1={alterarStateModalCancelamento} Acao2={cancelarTarefa} />
            <ModalConfirmacao mostrar={showModalTarefaItensSeparados} msgAcao1="Voltar" msgAcao2="Confirmar" msgPrincipal={mensagemCancelarTarefa} Acao1={alterarModalCancelarTarefa} Acao2={continuarCancelamentoTarefaComItens} />
            {/*modal opções*/}
            <Modal centered show={showModalOpcoes}>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm col-md-12 col-lg-12">
                                <p className="text-center mb-0">Existem outras tarefas em separação para o mesmo pedido.</p>
                                <p className="text-center mt-0"><b>Escolha uma das as opções abaixo para prosseguir.</b></p>
                            </div>
                            <div className="col-sm col-md-12 col-lg-12 mt-1">
                                <button onClick={cancelarSomenteEssaTarefaSelecionada} className="btn btn-primary w-100">Canelar somente tarefa selecionada</button>
                            </div>
                            <div className="col-sm col-md-12 col-lg-12 mt-1">
                                <button onClick={cancelarTarefaEgerarNovoPedido} className="btn btn-primary w-100">Cancelar somente tarefa selecionada e gerar um novo pedido com os itens da tarefa</button>
                            </div>
                            <div className="col-sm col-md-12 col-lg-12 mt-1">
                                <button onClick={cancelarTodasAsTarefasDoPedido} className="btn btn-primary w-100">Cancelar todas as tarefas do pedido</button>
                            </div>
                            <div className="col-sm col-md-12 col-lg-12 mt-1">
                                <button onClick={alterarModalOpcoes} className="btn btn-danger w-100">Desistir</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <ModalEditarPedEmSeparacao
                isOpen={showModalAlterarPedido}
                fecharModal={() => {
                    setShowModalAlterarPedido(false)
                    carregarPedidosSeparacao()
                }}
                token={token}
                idFilial={idFilial}
                dataCarga={dataCarga}
                destinatario={destinatario}
                descricao={descricao}
                idPedido={idPedido}
            />
            <ModalVincUsuario idPedido={idPedido} idTarefa={idTarefa} show={showModalVincUsuario} fecharModal={manipularModalVincUsuario} />
        </div>
    )
}

export default PedidosSeparacao